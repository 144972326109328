import React, { useEffect, useMemo, useRef, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Popover,
  SvgIcon,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';

import {
  fetchSearchResults,
  ISearchEntity,
  resetSearchResultsAction,
} from 'common/state/globalSearch/actions';
import {
  selectSearchResults,
  selectSearchResultsLoading,
} from 'common/state/globalSearch/selectors';

import { GlobalSearchGroups } from 'components/GlobalSearch/constants';
import { IConstOption } from 'common/constants/classes';

import { SearchContext } from 'components/SearchContainer/searchContext';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { useAppDispatch } from 'store/hooks';

import { debounce } from 'common/utils';

import { IGlobalSearchResultImt, IHelpInfoSearchResultImt } from 'common/interfaces/search';

import { LoadingBackdrop, ScrollBox } from 'common/components';
import { ReactComponent as XDeleteIcon } from 'img/icons/x-bold_deprecated.svg';
import { ReactComponent as SearchIcon } from 'img/icons/search.svg';
import { TimesCircleIcon } from 'img/icons';

import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > .MuiPaper-root': {
      top: '72px !important',
      right: '8px !important',
      height: 'calc(100% - 80px)',
      width: '440px',

      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  closeBtnIcon: {
    color: theme.palette.darkBackground?.light,
  },
  timesIcon: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
  },
  chip: {
    background: theme.palette.secondary.light,
    border: 'none',
    padding: theme.spacing(0.25, 1),
    height: 'initial',
    marginBottom: theme.spacing(0.5),

    '& .MuiChip-label': {
      padding: 0,
      fontWeight: 700,
      lineHeight: '1rem',
      color: theme.palette.secondary.main,
    },

    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },

    '&:not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
  },
  selectedChip: {
    backgroundColor: theme.palette.primary.main,

    '& .MuiChip-label': {
      color: theme.palette.primary.contrastText,
    },
  },
  searchResultsWrapper: {
    position: 'relative',
    padding: theme.spacing(0, 3),
  },
  noItemsFoundText: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export type IGroupTotalResultsCount = {
  groups: {
    [key: string]: { total: number };
  };
  total: number;
};

interface IProps {
  title: string | JSX.Element;
  anchorEl: null | Element;
  onClose: () => void;
  searchEntity: ISearchEntity;
  searchGroupsLabels?: IConstOption[];
  searchGroupsResults: IGroupTotalResultsCount;
  children: JSX.Element;
  isPeakLevel: boolean;
}

const SearchContainer = ({
  anchorEl,
  onClose,
  title,
  children,
  searchEntity,
  searchGroupsLabels,
  searchGroupsResults,
  isPeakLevel,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const searchResult: IHelpInfoSearchResultImt | IGlobalSearchResultImt = useRootSelector(
    selectSearchResults,
  );
  const isSearchResultLoading: boolean = useRootSelector(selectSearchResultsLoading);

  const [activeSearchGroup, setActiveSearchGroup] = useState<GlobalSearchGroups | 'all'>('all');

  const searchInput = useRef(null);

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const convertedSearchResult = useMemo(() => searchResult.toJS(), [searchResult]);

  useEffect(() => {
    if (searchEntity === 'help') {
      dispatch(fetchSearchResults('', searchEntity, isPeakLevel));
    }
    return () => {
      dispatch(resetSearchResultsAction());
    };
  }, [dispatch, searchEntity, isPeakLevel]);

  const onSearch = debounce((search: string) => {
    if (searchEntity === 'help' || search) {
      dispatch(fetchSearchResults(search, searchEntity, isPeakLevel));
    } else {
      dispatch(resetSearchResultsAction());
    }
  }, 500);

  const onClearSearch = () => {
    searchInput.current.value = '';

    if (searchEntity === 'help') {
      dispatch(fetchSearchResults('', searchEntity, isPeakLevel));
    } else {
      dispatch(resetSearchResultsAction());
    }
  };

  const onSearchGroupSelect = (elementId: GlobalSearchGroups | 'all') => {
    const group = document.getElementById(
      elementId === 'all' ? Object.keys(searchGroupsResults.groups)?.[0] : elementId,
    );

    group.scrollIntoView({ behavior: 'smooth', block: 'start' });

    setActiveSearchGroup(elementId);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={!!anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
      className={classes.root}
    >
      <Paper className={classes.paper}>
        <Box p={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h3">{title}</Typography>

            <IconButton size="small" onClick={onClose}>
              <SvgIcon className={classes.closeBtnIcon} component={XDeleteIcon} />
            </IconButton>
          </Box>

          <Box mt={3}>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              onChange={event => onSearch((event.target.value || '').trim())}
              autoFocus
              placeholder={renderIntlMessage(inputLabels.search)}
              inputRef={searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.closeBtnIcon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" onClick={onClearSearch}>
                    <TimesCircleIcon className={classes.timesIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {!!convertedSearchResult.total && (
            <Box mt={1.5}>
              <Chip
                key="all-search-results"
                label={`${renderIntlMessage(commonMessages.allOption)} (${
                  convertedSearchResult.total
                })`}
                className={cx(classes.chip, {
                  [classes.selectedChip]: activeSearchGroup === 'all',
                })}
                onClick={() => onSearchGroupSelect('all')}
              />

              {searchGroupsLabels?.map(
                (resultGroup: IConstOption<GlobalSearchGroups, GlobalSearchGroups>) => {
                  const groupTotalResults = searchGroupsResults.groups[resultGroup.key]?.total;

                  if (!groupTotalResults) {
                    return null;
                  }

                  return (
                    <Chip
                      key={resultGroup.key}
                      label={`${renderIntlMessage(resultGroup.message)} (${groupTotalResults})`}
                      className={cx(classes.chip, {
                        [classes.selectedChip]: activeSearchGroup === resultGroup.key,
                      })}
                      onClick={() => onSearchGroupSelect(resultGroup.key)}
                    />
                  );
                },
              )}
            </Box>
          )}
        </Box>

        <ScrollBox hasShadowsOnScroll className={classes.searchResultsWrapper}>
          <SearchContext.Provider value={{ search: searchInput.current?.value }}>
            {children}
          </SearchContext.Provider>

          {!convertedSearchResult.total && !isSearchResultLoading && (
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              alignItems="center"
              className={classes.noItemsFoundText}
            >
              <Typography color="textSecondary">
                <FormattedMessage {...commonMessages.noItemsFound} />
              </Typography>
            </Box>
          )}

          <LoadingBackdrop isLoading={isSearchResultLoading} />
        </ScrollBox>
      </Paper>
    </Popover>
  );
};

export default SearchContainer;
