import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { List as ImmutableList } from 'immutable';
import {
  Box,
  CircularProgress,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import cx from 'classnames';
// state
import {
  selectPosKioskCloseRegisterResult,
  selectPosKioskOpenRegisterResult,
  selectPosKioskRegisterData,
  selectPosKioskRegisterDataLoading,
  selectPosKioskRegisterTitle,
  selectSelectedRegisterId,
} from 'modules/pos-kiosk/state/register/selectors';
import {
  fetchPosKioskRegisterData,
  resetPosKioskRegisterData,
  setSelectedRegisterId,
} from 'modules/pos-kiosk/state/register/actions';
// interfaces
import {
  ICloseRegisterResultImt,
  IPosKioskRegisterDataImt,
} from 'modules/pos-kiosk/interfaces/registers';
import { IRegisterListItemImt } from 'modules/pos-settings/interfaces/register';
// icons
import { ArrowDropDown } from '@material-ui/icons';
// messages
import { FormattedMessage } from 'react-intl';
import posKioskMessages from 'modules/pos-kiosk/messages/messages';
import messages from 'modules/front-desk/messages/messages';
import posSettingsMessages from 'modules/pos-settings/messages/messages';
import commonMessages from 'common/messages/messages';
import { TRegisterDictionaryImt } from 'common/interfaces/dictionary';
import {
  selectDictionaryList,
  selectIsDictionaryListLoading,
} from 'common/state/dictionary/selectors';
import { DictionaryList } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';
import { fetchDictionaryList, resetDictionaryListAction } from 'common/state/dictionary/actions';
import { selectUserSelectedOrganization } from 'modules/authentication/state/selectors';
import { ISuccessResponseImt } from 'common/interfaces/http';
import useMenuState from 'common/hooks/useMenuState';
import { useAppDispatch } from 'store/hooks';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { selectCurrentInvoice } from '../../state/invoice/selectors';
import { IInvoiceDetailsImt } from '../../interfaces/invoices';
import useDeviceUUID from 'common/hooks/useDeviceUUID';
import ChangeRegisterModal from './ChangeRegisterModal/ChangeRegisterModal';
import { StatusLabel } from 'common/components/index';
import StorageServices from 'services/storage';
import { CHANGE_INVOICE_UNITS_REGISTER } from 'common/constants/delayedActionsKeys';
import useRootSelector from 'common/hooks/useRootSelector';

const useStyles = makeStyles((theme: Theme) => ({
  dropdown: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  menuItemText: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  alreadyUseText: {
    color: theme.palette.darkBackground.light,
    fontSize: '0.75rem',
  },
  status: {
    marginLeft: theme.spacing(4),
  },
  openInvoicesCount: {
    marginLeft: theme.spacing(1),
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.4,
  },
}));

interface IRegisterSelectorProps {
  isInvoiceView?: boolean; // means if this POS is a step in a wizard
  clubId: string;
  module: PeakModules;
  disabled?: boolean;
}

const { addDelayedAction } = StorageServices.delayedActions;

const RegisterSelector = ({
  isInvoiceView,
  module,
  clubId,
  disabled = false,
}: IRegisterSelectorProps): JSX.Element => {
  const classes = useStyles();

  // local state

  const [isOpenApproveChangeModal, setIsOpenApproveChangeModal] = useState<boolean>(false);
  const [chosenRegister, setChosenRegister] = useState<IRegisterListItemImt>(null);

  // global state

  const dispatch = useAppDispatch();

  const registerTitle = useRootSelector(selectPosKioskRegisterTitle);
  const registersList: ImmutableList<TRegisterDictionaryImt> = useRootSelector(
    selectDictionaryList(DictionaryList.REGISTERS),
  );
  const isRegistersListLoading: boolean = useRootSelector(
    selectIsDictionaryListLoading(DictionaryList.REGISTERS),
  );
  const selectedRegisterId: string = useRootSelector(selectSelectedRegisterId);
  const selectedRegister: IPosKioskRegisterDataImt = useRootSelector(selectPosKioskRegisterData);
  const closeRegisterResult: ICloseRegisterResultImt = useRootSelector(
    selectPosKioskCloseRegisterResult,
  );
  const selectedOrg = useRootSelector(selectUserSelectedOrganization);

  const openRegisterResult: ISuccessResponseImt = useRootSelector(selectPosKioskOpenRegisterResult);

  const isRegisterDataLoading: boolean = useRootSelector(selectPosKioskRegisterDataLoading);

  const selectedInvoice: IInvoiceDetailsImt = useRootSelector(selectCurrentInvoice);

  const renderIntlMessage = useRenderIntlMessage();
  const deviceUUID = useDeviceUUID();

  // constants

  const { menuAnchorEl, handleShowMenu, handleCloseMenu } = useMenuState();

  const selectedOrgId = selectedOrg?.get('id');

  // handlers
  const handleOpenSelectorClick = event => {
    handleShowMenu(event);
  };

  const onSelectRegister = (register: IRegisterListItemImt): void => {
    if (register.get('id') === selectedRegisterId) {
      handleCloseMenu();
      return;
    }

    // if changing register while buying membership
    // we should warn that prev invoice will be canceled

    if (
      !!selectedRegisterId &&
      selectedRegister.get('open') &&
      selectedInvoice?.get('customer') &&
      !!selectedInvoice?.get('invoiceUnits')?.size
    ) {
      setChosenRegister(register);
      setIsOpenApproveChangeModal(true);
      handleCloseMenu();
      return;
    }

    if (!!selectedRegisterId && selectedRegister.get('open') && selectedInvoice?.get('customer')) {
      dispatch(
        setSelectedRegisterId(
          selectedOrgId,
          register.get('id'),
          true,
          isInvoiceView,
          selectedInvoice?.toJS(),
          module,
        ),
      );
      handleCloseMenu();

      return;
    }

    dispatch(
      setSelectedRegisterId(
        selectedOrgId,
        register.get('id'),
        register.get('open'),
        false,
        null,
        module,
      ),
    );

    handleCloseMenu();
  };

  const handleCloseApproval = () => {
    setChosenRegister(null);
    setIsOpenApproveChangeModal(false);
    handleCloseMenu();
  };

  const handleSubmitApproval = (invoiceUnitIds: Array<string>) => {
    const changeInvoiceUnitsRegister = () => {
      dispatch(
        setSelectedRegisterId(
          selectedOrgId,
          chosenRegister.get('id'),
          true,
          isInvoiceView,
          selectedInvoice?.toJS(),
          module,
          invoiceUnitIds,
        ),
      );
    };

    dispatch(resetPosKioskRegisterData());

    if (chosenRegister.get('open')) {
      changeInvoiceUnitsRegister();
    } else {
      addDelayedAction(CHANGE_INVOICE_UNITS_REGISTER, changeInvoiceUnitsRegister);

      dispatch(
        setSelectedRegisterId(
          selectedOrgId,
          chosenRegister.get('id'),
          chosenRegister.get('open'),
          false,
          null,
          module,
        ),
      );
    }

    handleCloseApproval();
  };

  // effects

  useEffect(() => {
    dispatch(
      fetchDictionaryList(DictionaryList.REGISTERS, {
        clubIds: clubId ? `${clubId}` : '',
        module,
        isCustomer: false,
      }),
    );
  }, [clubId, dispatch, module]);

  useEffect(() => {
    if (selectedRegisterId) {
      dispatch(fetchPosKioskRegisterData(selectedRegisterId));
    } else {
      dispatch(resetPosKioskRegisterData());
    }
  }, [dispatch, selectedRegisterId]);

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(resetPosKioskRegisterData());
        dispatch(
          resetDictionaryListAction({
            dictionary: DictionaryList.REGISTERS,
          }),
        );
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (closeRegisterResult.get('reportId') || openRegisterResult.get('success'))
      dispatch(
        fetchDictionaryList(DictionaryList.REGISTERS, {
          clubIds: `${clubId}`,
          module,
          isCustomer: false,
        }),
      );
  }, [openRegisterResult, closeRegisterResult, dispatch, clubId, module]);

  const isRegistersExist = !!registersList?.size;

  return (
    <>
      {isRegistersExist ? (
        <Box
          className={cx({
            [classes.disabled]: disabled,
          })}
          onClick={handleOpenSelectorClick}
          minWidth={130}
        >
          {isRegistersListLoading || isRegisterDataLoading ? (
            <CircularProgress color="primary" size={30} />
          ) : (
            <Typography
              variant={isInvoiceView ? 'h4' : 'h2'}
              color="textPrimary"
              className={classes.dropdown}
            >
              {registerTitle || <FormattedMessage {...posKioskMessages.selectRegisterText} />}
              <ArrowDropDown fontSize="large" />
            </Typography>
          )}
        </Box>
      ) : (
        <Typography variant="h2">
          <FormattedMessage {...commonMessages.pos} />
        </Typography>
      )}

      {!isRegistersListLoading && isRegistersExist && (
        <Menu
          id="menu-list-grow"
          getContentAnchorEl={null}
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {!isRegistersListLoading && registersList.size ? (
            registersList.map((registerListItem: IRegisterListItemImt) => {
              const isOpenedFromAnotherDevice =
                registerListItem.get('privatePerDevice') &&
                registerListItem.get('openedDeviceUuid') !== deviceUUID &&
                registerListItem.get('openedDeviceUuid') !== null;

              return (
                <MenuItem
                  key={registerListItem.get('id')}
                  onClick={() => onSelectRegister(registerListItem)}
                  disabled={isOpenedFromAnotherDevice && registerListItem.get('open')}
                  className={classes.menuItem}
                >
                  <Typography gutterBottom className={classes.menuItemText}>
                    <Typography>
                      <Typography component="span">{registerListItem.get('title')}</Typography>
                      {registerListItem.get('open') && (
                        <Typography
                          component="span"
                          color="textSecondary"
                          className={classes.openInvoicesCount}
                        >
                          {`(${renderIntlMessage(posKioskMessages.invoicesCountText, {
                            invoicesCount: registerListItem.get('openInvoiceCount'),
                            inProgressInvoiceCount: registerListItem.get('inProgressInvoiceCount'),
                          })})`}
                        </Typography>
                      )}
                    </Typography>
                    <StatusLabel
                      isActive={registerListItem.get('open')}
                      activeOptionMessage={commonMessages.opened}
                      inactiveOptionMessage={commonMessages.closed}
                      inactiveOptionColor="error"
                      className={classes.status}
                    />
                  </Typography>

                  {isOpenedFromAnotherDevice && (
                    <Typography className={classes.alreadyUseText}>
                      <FormattedMessage {...posKioskMessages.useOnOtherDeviceText} />
                    </Typography>
                  )}
                </MenuItem>
              );
            })
          ) : (
            <Box p={2}>
              <Typography>
                <FormattedMessage {...messages.noOpenRegisterText} /> &nbsp;
                <Link variant="button" component={NavLink} to="/pos-settings/registers">
                  <FormattedMessage {...posSettingsMessages.posContentTitle} />
                </Link>
              </Typography>
            </Box>
          )}
        </Menu>
      )}

      {isOpenApproveChangeModal && (
        <ChangeRegisterModal
          isOpen={isOpenApproveChangeModal}
          onSubmit={handleSubmitApproval}
          onClose={handleCloseApproval}
          selectedInvoice={selectedInvoice}
        />
      )}
    </>
  );
};
export default RegisterSelector;
