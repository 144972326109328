import React, { useCallback, useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import * as selectors from 'modules/crm/state/crmTemplates/selectors';
import { selectQueryPageList } from 'common/state/queryPage-lists/selectors';
import * as actions from 'modules/crm/state/crmTemplates/actions';

import { ActionResult, QueryPageList } from 'common/constants';
import { RouteBackground } from 'components';
import { LoadingBackdrop } from 'common/components';
import { EmailForm } from 'modules/crm/components';

import { removeLastRouteFromPath } from 'helpers';
import { useAppDispatch } from 'store/hooks';

import {
  ICreateDocumentTemplateDto,
  IDocumentTemplateDtoImt,
} from 'common/interfaces/documentTemplate';
import { PeakModules } from 'common/constants/peakModules';

const EditEmail = (): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const templateLoading: boolean = useRootSelector(selectors.selectGetTemplateIsLoading);
  const template: IDocumentTemplateDtoImt = useRootSelector(selectors.selectTemplate);
  const queryDocumentTemplates: string = useRootSelector(
    selectQueryPageList(QueryPageList.CRM_TEMPLATES),
  );
  const saveTemplateLoading: boolean = useRootSelector(selectors.selectSaveDocumentTemplateLoading);
  const documentTemplateActionResult: ActionResult = useRootSelector(
    selectors.selectSaveTemplateActionResult,
  );

  const { id: editId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const module =
    location.pathname.indexOf('personal-training-crm') === -1
      ? PeakModules.Crm
      : PeakModules.PersonalTrainingCrm;

  // lifecycle

  useEffect(() => {
    if (editId) {
      dispatch(actions.getCRMTemplate(editId, module));
    }
  }, [dispatch, editId, module]);

  useEffect(() => {
    if (documentTemplateActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.saveCrmTemplateActionResult(null));

      navigate(`${removeLastRouteFromPath(location.pathname)}${queryDocumentTemplates}`);
    }
  }, [dispatch, documentTemplateActionResult, location.pathname, navigate, queryDocumentTemplates]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetCRMTemplate());
    };
  }, [dispatch]);

  // handlers

  const onCancel = useCallback((): void => {
    navigate(`${removeLastRouteFromPath(location.pathname)}${queryDocumentTemplates}`);
  }, [location.pathname, navigate, queryDocumentTemplates]);

  const onSubmitForm = useCallback(
    (document: ICreateDocumentTemplateDto): void => {
      if (editId) {
        dispatch(actions.updateDocumentTemplate({ ...document, id: editId }, module));
      } else {
        dispatch(actions.saveDocumentCreator(document, module));
      }
    },
    [dispatch, editId, module],
  );

  return (
    <RouteBackground fullScreenView hasMobilePadding>
      {((!!template.size && !!editId) || !editId) && (
        <EmailForm
          template={template}
          isLoading={saveTemplateLoading}
          onCancel={onCancel}
          onSubmit={onSubmitForm}
        />
      )}
      <LoadingBackdrop isLoading={templateLoading} />
    </RouteBackground>
  );
};

export default EditEmail;
