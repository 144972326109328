import React, { useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { IconButton, Menu, MenuItem, SvgIcon, Typography } from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';

import {
  LanguageLocales,
  LanguageLocalesAbbreviations,
  LanguageLocalesOptions,
} from 'common/state/settings/constants';
import { selectLocale } from 'common/state/settings/selectors';
import { setSelectedLocale } from 'common/state/settings/actions';

import { useAppDispatch } from 'store/hooks';

interface ILanguageSelectorProps {
  className?: string;
}
const LanguageSelector = ({ className }: ILanguageSelectorProps): JSX.Element => {
  const selectedLocale = useRootSelector(selectLocale);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (anchorEl) setAnchorEl(null);
  };

  const setLocale = (locale: LanguageLocales) => {
    dispatch(setSelectedLocale(locale));
  };

  return (
    <>
      <IconButton onClick={handleOpenClick} disableRipple className={className}>
        <Typography variant="h6" color="secondary">
          {LanguageLocalesAbbreviations[selectedLocale]}
        </Typography>
        <SvgIcon component={anchorEl ? ArrowDropUp : ArrowDropDown} color="inherit" />
      </IconButton>
      <Menu
        id="actions-menu"
        keepMounted
        getContentAnchorEl={null}
        title={LanguageLocalesAbbreviations[selectedLocale]}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        onClick={handleClickMenu}
      >
        {LanguageLocalesOptions.map(item => (
          <MenuItem key={item.key} value={item.value} onClick={() => setLocale(item.value)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSelector;
