import React, { FC, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import {
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { IEditablePackageConfiguration } from 'common/interfaces/membership';
import { TEditableInstance } from '../MembershipFormView';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import {
  DurationType,
  DurationTypes,
  PackageCostType,
  PackageType,
  PackageTypes,
} from 'modules/services/constants/packages';
import { AvailabilitiesResultBlock, FieldInfo } from 'common/components/index';
import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';
import { ReactComponent as ResetIcon } from 'img/icons/reset.svg';
import { ReactComponent as AngleDownIcon } from 'img/icons/angle-down.svg';
import { ReactComponent as AngleRightIcon } from 'img/icons/angle-right.svg';
import commonMessages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import packageMessages from 'modules/services/messages/packages';
import servicesMessages from 'modules/services/messages/messages';
import useScrollIntoView from 'common/hooks/useScrollIntoView';
import IntlService from 'services/intl/IntlService';

const useStyles = makeStyles((theme: Theme) => ({
  sectionBtn: {
    padding: 0,
    color: theme.palette.text.secondary,

    '& svg': {
      width: '0.75rem',
      height: '0.75rem',
      marginRight: theme.spacing(1),
    },
  },
  resetBtn: {
    '& svg': {
      width: '1rem',
      height: '1rem',
    },
  },
  editIcon: {
    width: '1.25rem',
    height: '1.25rem',
  },
  timeForAccessField: {
    margin: theme.spacing(1.5, 0),
  },
  chip: {
    height: 'initial',
    padding: theme.spacing(0.25, 1),
    marginBottom: theme.spacing(0.5),

    color: theme.palette.primary.contrastText,
    background: theme.palette.darkBackground?.light,
    fontWeight: 700,

    '& .MuiChip-label': {
      padding: 0,
      lineHeight: '1rem',
    },

    '&:not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
  },
  renewalSection: {
    marginTop: theme.spacing(2),
  },
  renewalStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    width: '1rem',
    height: '1rem',
    marginRight: theme.spacing(0.5),

    '&.primary': {
      color: theme.palette.primary.main,
    },
    '&.error': {
      color: theme.palette.error.main,
    },
  },
}));

interface INewPackageSelectSection {
  onResetConfiguration?: () => void;
  membershipPackage: IEditablePackageConfiguration;
  onEditPackage: (
    servicePackage: IEditablePackageConfiguration,
    instance: TEditableInstance,
  ) => void;
}

const NewPackageSelectSection: FC<INewPackageSelectSection> = ({
  membershipPackage,
  onResetConfiguration,
  onEditPackage,
}) => {
  const [showContent, setShowContent] = useState<boolean>(true);
  const { scrollRef } = useScrollIntoView(showContent);
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();
  const isTrial = membershipPackage.type === PackageType.Trial;
  const isFree = membershipPackage.costType === PackageCostType.Free;

  const customPeriodDescription = useMemo((): string => {
    if (!membershipPackage.customDates) return '';

    const {
      customDates: { startDate, endDate },
    } = membershipPackage;

    return `${moment(startDate).format('MMM D')} - ${moment(endDate).format('MMM D')}`;
  }, [membershipPackage]);

  const handleEditPackage = () => {
    onEditPackage(membershipPackage, 'generalPackage');
  };

  return (
    <Box>
      <Button
        color="primary"
        startIcon={<ResetIcon />}
        onClick={onResetConfiguration}
        className={classes.resetBtn}
      >
        {IntlService.formatMessage(commonMessages.resetConfigBtn)}
      </Button>

      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} mt={2}>
        <Button
          onClick={() => setShowContent(!showContent)}
          startIcon={showContent ? <AngleDownIcon /> : <AngleRightIcon />}
          className={classes.sectionBtn}
        >
          {IntlService.formatMessage(commonMessages.generalLabel)}
        </Button>

        <IconButton color="primary" size="small" onClick={handleEditPackage}>
          <EditIcon className={classes.editIcon} />
        </IconButton>
      </Box>

      <Collapse in={showContent} ref={scrollRef}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FieldInfo
              label={IntlService.formatMessage(inputLabels.packageType)}
              description={
                isTrial && isFree
                  ? IntlService.formatMessage(servicesMessages.trialOptionFree)
                  : PackageTypes.translate(membershipPackage.type)
              }
            />
          </Grid>

          <Grid item xs={4}>
            <FieldInfo
              label={IntlService.formatMessage(inputLabels.duration)}
              description={
                membershipPackage.durationType === DurationType.Custom
                  ? customPeriodDescription
                  : `${membershipPackage.durationEditableNumber.value} ${renderIntlMessage(
                      DurationTypes.message(membershipPackage.durationType),
                    )}`
              }
            />
          </Grid>
        </Grid>

        <FieldInfo
          label={IntlService.formatMessage(inputLabels.daysAndHoursForAccess)}
          description={
            !membershipPackage.dayTimeAvailDtoList?.length ? (
              IntlService.formatMessage(inputLabels.accessAsClub)
            ) : (
              <AvailabilitiesResultBlock availabilities={membershipPackage.dayTimeAvailDtoList} />
            )
          }
          className={classes.timeForAccessField}
        />

        <FieldInfo
          label={IntlService.formatMessage(commonMessages.clubsAvailabilityLabel)}
          labelVariant="subtitle2"
          labelColor="textPrimary"
          description={
            !membershipPackage.clubs?.length
              ? IntlService.formatMessage(packageMessages.limitClubsAllAllowed)
              : undefined
          }
          content={
            !!membershipPackage.clubs?.length && (
              <Box display="flex" flexWrap="wrap">
                {membershipPackage.clubs.map(clubItem => (
                  <Chip key={clubItem.id} label={clubItem.title} className={classes.chip} />
                ))}
              </Box>
            )
          }
        />

        <Grid container spacing={2} className={classes.renewalSection}>
          <Grid item xs={12}>
            <FieldInfo
              label={IntlService.formatMessage(servicesMessages.renewalsLabel)}
              description={`${membershipPackage.renewalNumber}`}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};

export default NewPackageSelectSection;
