import { List } from 'immutable';
import { IPersonDocumentItemDto } from '../../../interfaces/contract';

export const selectContract = state => state.getIn(['newPerson', 'personContract', 'result']);

export const selectContracts = (state): List<IPersonDocumentItemDto> => {
  return state.getIn(['newPerson', 'personContract', 'contracts']);
};

export const selectSignContractActionResult = state =>
  state.getIn(['newPerson', 'personContract', 'actionResult']);

export const selectContractIsLoading = state =>
  state.getIn(['newPerson', 'personContract', 'isLoading']);

export const selectIsContractHTMLLoading = state =>
  state.getIn(['newPerson', 'personContract', 'isContractHTMLLoading']);

export const selectContractError = state => state.getIn(['newPerson', 'personContract', 'error']);
