import React, { useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { Box, Divider, List, Typography, makeStyles } from '@material-ui/core';

import { useAppDispatch } from 'store/hooks';
import {
  selectPersonCampaigns,
  selectPersonCampaignsLoading,
} from 'common/components/PersonProfile/state/selectors';
import { fetchPersonCampaigns } from 'common/components/PersonProfile/state/actions';
import { CustomTheme } from 'common/ui/interfaces';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { ICampaignsImt } from 'common/components/PersonProfile/interfaces';
import messages from 'common/components/PersonProfile/messages';
import crmMessages from 'modules/crm/messages/common';
import commonMessages from 'common/messages/messages';

import { Button, LoadingBackdrop, ScrollBox } from 'common/components';
import { CheckInBlock } from 'common/components/PersonProfile/components';
import CampaignsItem from './CampaignsItem';
import { ReactComponent as HistoryIcon } from 'img/icons/clock-rotate_deprecated.svg';
import CampaignsHistoryModal from './CampaignsHistoryModal/CampaignsHistoryModal';

const useStyles = makeStyles((theme: CustomTheme) => ({
  wrapper: {
    height: '100%',
    minHeight: '150px',
  },
  listWrapper: {
    padding: '0',
    marginRight: theme.spacing(-2),
    height: 'calc(100% - 30px)',
    overflow: 'hidden',
  },
  listLabel: {
    paddingTop: theme.spacing(1),
    fontSize: '0.75rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: theme.palette.secondary.light,
  },
  historyIcon: {
    width: '20px',
    height: '20px',
  },
}));

interface ICampaignsBlockProps {
  personId: number;
  selectedClubId: string;
  module: PeakModuleForNewPersonType;
}

const CampaignsBlock = ({
  personId,
  selectedClubId,
  module,
}: ICampaignsBlockProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const campaigns: ICampaignsImt = useRootSelector(selectPersonCampaigns(personId));
  const campaignsLoading = useRootSelector(selectPersonCampaignsLoading(personId));

  const classes = useStyles();

  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

  useEffect(() => {
    if (personId) {
      dispatch(fetchPersonCampaigns(personId, module));
    }
  }, [personId, selectedClubId, dispatch, module]);

  return (
    <CheckInBlock
      title={<FormattedMessage {...crmMessages.campaigns} />}
      className={classes.wrapper}
      buttonTitle={
        <Button color="primary" hasHoverStyle>
          <HistoryIcon className={classes.historyIcon} />
        </Button>
      }
      onClickButton={() => setIsHistoryModalOpen(true)}
    >
      <Divider className={classes.divider} />
      {campaigns?.get('plannedCampaigns')?.size ||
      campaigns?.get('pastCampaigns')?.size ||
      campaigns?.get('matchCriteriaCampaigns')?.size ? (
        <Box className={classes.listWrapper}>
          <ScrollBox>
            {!!campaigns.get('matchCriteriaCampaigns')?.size && (
              <>
                <Typography className={classes.listLabel}>
                  <FormattedMessage {...commonMessages.matchingToCriteriaLabel} />
                </Typography>

                <List>
                  {campaigns.get('matchCriteriaCampaigns').map(campaignItem => (
                    <CampaignsItem key={campaignItem.get('id')} campaign={campaignItem} />
                  ))}
                </List>
              </>
            )}

            {!!campaigns.get('plannedCampaigns')?.size && (
              <>
                <Typography className={classes.listLabel}>
                  <FormattedMessage {...commonMessages.plannedOption} />
                </Typography>

                <List>
                  {campaigns.get('plannedCampaigns').map(campaignItem => (
                    <CampaignsItem key={campaignItem.get('id')} campaign={campaignItem} />
                  ))}
                </List>
              </>
            )}

            {!!campaigns.get('pastCampaigns')?.size && (
              <>
                <Typography className={classes.listLabel}>
                  <FormattedMessage {...commonMessages.completedOption} />
                </Typography>

                <List>
                  {campaigns.get('pastCampaigns').map(campaignItem => (
                    <CampaignsItem key={campaignItem.get('id')} campaign={campaignItem} />
                  ))}
                </List>
              </>
            )}
          </ScrollBox>
        </Box>
      ) : (
        <Box className="empty-section-placeholder">
          <Typography className="empty-text">
            <FormattedMessage {...messages.emptyCampaignsListTextMessage} />
          </Typography>
        </Box>
      )}

      {isHistoryModalOpen && (
        <CampaignsHistoryModal
          isOpen={isHistoryModalOpen}
          onClose={() => setIsHistoryModalOpen(false)}
          personId={personId}
          module={module}
        />
      )}

      <LoadingBackdrop isLoading={campaignsLoading} />
    </CheckInBlock>
  );
};

export default CampaignsBlock;
