// Libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import moment from 'moment-timezone';
// components
import {
  Alert,
  DateRangeSelector,
  DialogComponent,
  LoadingBackdrop,
  NumberTextField,
  Table,
} from 'common/components';
import { Box, Button, Chip, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
// interfaces
import { IHeadCell, ITableRow } from 'common/interfaces/table';
import { IFreezeItem, FreezeMembershipData } from '../../interfaces';
import { IServerErrorImt } from 'common/interfaces/http';
import { MenuItemTypes } from 'common/interfaces/invoices';
// state
import {
  selectFreezableItems,
  selectFreezableItemsLoading,
  selectFreezeMembershipError,
  selectFreezeMembershipLoading,
  selectFreezeMembershipResult,
} from '../../state/membership/selectors';
import {
  fetchFreezableItems,
  freezeMembership,
  resetFreezableItemsAction,
  resetFreezeMembership,
  unfreezeMembership,
} from '../../state/membership/actions';
// hooks
import { useAppDispatch } from 'store/hooks';
// utils
import { deepClone } from 'common/utils';
import { isFrozen } from './utils/freezeMembershipsModalUtils';
// messages
import commonMessages from 'common/messages/messages';
import messages from '../../messages';
import { ActionResult, TableOrderByParams } from 'common/constants';
import { IDateRangeFilterValue } from 'common/interfaces/filter';
import FreezeMembershipHistoryModal from 'common/components/PersonProfile/modals/FreezeMembershipHistoryModal/FreezeMembershipHistoryModal';
import { AlertTypes } from 'common/interfaces/alerts';
import { FormattedMessageByCode } from 'common/errors/serverErrors';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { IObject } from 'common/interfaces/common';

interface IFreezeMembershipModalProps {
  isOpen: boolean;
  personId: number;
  module: PeakModuleForNewPersonType;

  onSubmit: () => void;
  onCancel: () => void;
  isMembershipCard?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  dateRangeInput: {
    maxWidth: 150,
    marginRight: theme.spacing(1),
    '& .MuiInputBase-root': {
      height: '44px',
    },
  },
  priceControl: {
    maxWidth: 105,
  },
  tableHeader: {
    padding: theme.spacing(2, 2, 1, 2),
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  tableWrapper: {
    '& .MuiTableCell-head': {
      textTransform: 'inherit',
      '& .MuiTableSortLabel-root': {
        color: theme.palette.text.primary,
        fontSize: theme.typography.h5.fontSize,
        fontWeight: theme.typography.h5.fontWeight,
      },
    },
  },
}));

const FreezeMembershipModal: React.FC<IFreezeMembershipModalProps> = ({
  isOpen,
  personId,
  onCancel,
  onSubmit,
  module,
  isMembershipCard,
}: IFreezeMembershipModalProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const initialPeriod = useMemo(
    () => ({
      startDate: moment(),
      endDate: moment().add(1, 'week'),
    }),
    [],
  );

  const [reason, setReason] = useState<string>('');
  const [items, setItems] = useState<IFreezeItem[]>([]);
  const [isOpenFreezeHistory, setIsOpenFreezeHistory] = useState(false);

  const freezableItemsList: List<IFreezeItem> = useRootSelector(selectFreezableItems());
  const freezableItemsLoading = useRootSelector(selectFreezableItemsLoading());
  const freezeMembershipLoading: boolean = useRootSelector(selectFreezeMembershipLoading);
  const freezeMembershipResult: ActionResult = useRootSelector(selectFreezeMembershipResult());
  const freezeMembershipError: IServerErrorImt = useRootSelector(selectFreezeMembershipError());

  // TODO fetch correct data from be.
  const allowPriceChangeOnFreeze = true;

  useEffect(() => {
    dispatch(fetchFreezableItems(personId, module, isMembershipCard));
  }, [dispatch, personId, module, isMembershipCard]);

  useEffect(() => {
    setItems(freezableItemsList.toJS().map(item => ({ selected: false, ...item })));
  }, [freezableItemsList]);

  useEffect(() => {
    return () => {
      dispatch(resetFreezableItemsAction());
      dispatch(resetFreezeMembership());
    };
  }, [dispatch]);

  useEffect(() => {
    if (freezeMembershipResult === ActionResult.SUCCESS_ACTION) {
      onSubmit();
      dispatch(resetFreezeMembership());
    }
  }, [dispatch, freezeMembershipResult, onSubmit]);

  // handlers
  const handleOnSubmit = (): void => {
    const freezingData: FreezeMembershipData = {
      reason,
      startDate: initialPeriod.startDate.toISOString(),
      endDate: initialPeriod.endDate.toISOString(),
      packageServices: items.filter(item => item.selected),
    };

    dispatch(freezeMembership(personId, freezingData, module, isMembershipCard));
  };

  const handleFreezingItemSelect = useCallback(
    (selectedIndexes: IObject) => {
      setItems(prevState => {
        return prevState.map(item => {
          return {
            ...deepClone(item),
            selected: selectedIndexes.selectedIds.includes(item.packageInstanceId),
          };
        });
      });
    },
    [setItems],
  );

  const updateFreezePeriod = (item: IFreezeItem, value: IDateRangeFilterValue) => {
    setItems(prevItems => {
      const updateElIndex = prevItems.findIndex(
        el => el.packageInstanceId === item.packageInstanceId,
      );
      const updateEl = prevItems[updateElIndex];

      updateEl.startDate = value ? (value.startDate as Date).toISOString() : null;
      updateEl.endDate = value ? (value.endDate as Date).toISOString() : null;
      return [...prevItems];
    });
  };

  const handleOnBillingPriceChange = (id: string, value) => {
    setItems(prevState => {
      const updatedItems = [...prevState];
      const updatingItemIndex = updatedItems.findIndex(item => item.packageInstanceId === id);
      updatedItems[updatingItemIndex].pricePerBilling = value;

      return updatedItems;
    });
  };

  const onUnfreezeButtonClick = (packageId: string) => {
    dispatch(unfreezeMembership(personId, module, packageId, isMembershipCard));
  };

  const historyButton: JSX.Element = (
    <Button color="secondary" variant="contained" onClick={() => setIsOpenFreezeHistory(true)}>
      <FormattedMessage {...messages.freezeMembershipModalFreezeHistoryBtnLabel} />
    </Button>
  );

  const headerOptions: IHeadCell[] = [
    {
      id: TableOrderByParams.NAME,
      label: <FormattedMessage {...messages.freezeMembershipModalFreezeTableDescription} />,
      sort: false,
    },
    {
      id: TableOrderByParams.STATUS,
      label: <FormattedMessage {...messages.freezeMembershipModalLastFreezeLabel} />,
      sort: false,
    },
    {
      id: 'membershipStatus',
      label: <FormattedMessage {...messages.freezeMembershipModalFreezeTableFreezeDaysLabel} />,
      sort: false,
    },
    {
      id: TableOrderByParams.SALESPERSON,
      label: <FormattedMessage {...messages.freezeMembershipModalFreezeTableFreezePeriodLabel} />,
      sort: false,
    },
    {
      id: TableOrderByParams.CREATED_DATE,
      label: <FormattedMessage {...messages.freezeMembershipModalFreezeTablePriceDescription} />,
      sort: false,
    },
    {
      id: '',
      label: '',
      sort: false,
    },
  ];

  // table configuration
  const rows: ITableRow[] = items.map((item: IFreezeItem) => {
    const isFreezed =
      (MenuItemTypes.Service === item.type || MenuItemTypes.Membership === item.type) &&
      !item.allowToFreeze;

    return {
      id: item.packageInstanceId,
      disabledSelection: isFreezed,
      cells: [
        {
          align: 'left',
          label: 'freezing-item',
          cellComponent: (
            <Typography
              variant="h5"
              color={allowPriceChangeOnFreeze ? 'textPrimary' : 'textSecondary'}
            >
              {item.type === MenuItemTypes.Membership ? (
                <Grid item>
                  {item.title}&nbsp;&nbsp;
                  <Chip size="small" label="Membership" />
                </Grid>
              ) : (
                item.title
              )}
            </Typography>
          ),
        },
        {
          align: 'left',
          label: 'last-freeze',
          cellComponent: (
            <Typography variant="h5">
              <FormattedMessage {...messages.freezeMembershipModalTableNotFrozenYetLabel} />{' '}
            </Typography>
          ),
        },
        {
          align: 'left',
          label: 'freeze-days',
          cellComponent: <Typography variant="h5">{item.freezeDaysNumber}</Typography>,
        },
        {
          align: 'right',
          label: 'freeze-period',
          cellComponent: (
            <DateRangeSelector
              name="name"
              value={{
                startDate: item.startDate ? moment(item.startDate).toDate() : null,
                endDate: item.endDate ? moment(item.endDate).toDate() : null,
                minDate: moment()
                  .add(1, 'day')
                  .toDate(),
              }}
              onChange={value => updateFreezePeriod(item, value)}
              dateTitleFormat="ll"
            />
          ),
        },
        {
          align: 'left',
          label: 'pricing',
          cellComponent: allowPriceChangeOnFreeze ? (
            <FormControl className={classes.priceControl} fullWidth>
              <NumberTextField
                InputProps={{
                  startAdornment: '$',
                  endAdornment: '/mo',
                }}
                size="small"
                numberFormatProps={{
                  decimalScale: 2,
                  fixedDecimalScale: true,
                  allowLeadingZeros: false,
                  allowNegative: false,
                  maxLength: 10,
                }}
                fullWidth
                variant="outlined"
                value={item.pricePerBilling}
                onChange={value => handleOnBillingPriceChange(item.packageInstanceId, +value)}
                disabled={isFreezed}
              />
            </FormControl>
          ) : (
            <Typography color="textSecondary">
              <FormattedMessage {...messages.freezeMembershipModalFreezeTableRegularPriceLabel} />
            </Typography>
          ),
        },
        {
          align: 'right',
          label: '',
          cellComponent: isFreezed ? (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => onUnfreezeButtonClick(item.packageInstanceId)}
              disabled={!!freezeMembershipLoading}
            >
              <Typography color="primary" variant="inherit">
                <FormattedMessage {...messages.unfreezeLabel} />
              </Typography>
            </Button>
          ) : (
            ''
          ),
        },
      ],
    };
  });

  // renders

  return (
    <>
      <DialogComponent
        isOpen={isOpen}
        title={
          <FormattedMessage
            {...(isMembershipCard
              ? messages.freezeMembershipModalTitle
              : messages.freezeBundleTitle)}
          />
        }
        cancelBtnTitle={<FormattedMessage {...commonMessages.cancelBtn} />}
        submitBtnTitle={<FormattedMessage {...messages.freezeMembershipModalSuccessBtn} />}
        size="xl"
        scroll="paper"
        onClose={onCancel}
        onSubmit={handleOnSubmit}
        loading={freezeMembershipLoading}
        disabled={
          !items.some(
            item =>
              item.selected &&
              item.startDate &&
              item.endDate &&
              isFrozen(item.startDate, item.endDate, item.freezeDaysNumber),
          ) || !reason
        }
        additionalLeftAlignedActionButtons={historyButton}
      >
        <LoadingBackdrop isLoading={freezableItemsLoading} />
        <Box className={classes.tableWrapper}>
          <Table
            showHeaderSelect={false}
            activeSelect
            hideToolbar
            hideSearchInput
            headerOptions={headerOptions}
            rows={rows}
            totalRows={rows.length}
            isLoading={false}
            onSelect={handleFreezingItemSelect}
            hidePagination
            hideSelectedNumber
          />
        </Box>

        <Box>
          <TextField
            value={reason}
            label={<FormattedMessage {...messages.freezeMembershipModalFreezeReasonLabel} />}
            onChange={event => setReason(event.target.value)}
            variant="outlined"
            autoComplete="off"
            fullWidth
          />
        </Box>
        {freezeMembershipError && (
          <Box pt={2}>
            <Alert
              severity={AlertTypes.Danger}
              title={FormattedMessageByCode(freezeMembershipError?.get('codes')?.get(0))}
            />
          </Box>
        )}
      </DialogComponent>
      <FreezeMembershipHistoryModal
        module={module}
        personId={personId}
        isOpen={isOpenFreezeHistory}
        onClose={() => setIsOpenFreezeHistory(false)}
        isMembershipCard={isMembershipCard}
      />
    </>
  );
};

export default React.memo(FreezeMembershipModal);
