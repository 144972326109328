import React from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { Controller, useFormContext } from 'react-hook-form';
import { List as ImmutableList } from 'immutable';
import { Grid, Typography, makeStyles, Theme } from '@material-ui/core';

import { selectDictionaryList } from 'common/state/dictionary/selectors';

import { IDictionaryCommonItemImt } from 'modules/dictionaries/interfaces/interfaces';

import { DictionaryList } from 'common/constants';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { getAccessByPropPath } from 'common/utils/errorObject';

import { MultipleSelect, TextField } from 'common/components/index';

import inputLabels from 'common/messages/inputLabels';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';

const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    marginBottom: theme.spacing(1),
  },
}));

interface IProps {
  name?: string;
  title?: JSX.Element;
  recommendedFields: TShortProfileFieldInfoImt;
}

const InsuranceSection = ({ title, name, recommendedFields }: IProps): JSX.Element => {
  const insuranceCompanies: ImmutableList<IDictionaryCommonItemImt> = useRootSelector(
    selectDictionaryList(DictionaryList.INSURANCE_COMPANY),
  );

  const { control, formState } = useFormContext();
  const { errors } = formState;
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      {title && (
        <Grid item xs={12}>
          <Typography variant="button" color="textSecondary" className={classes.sectionTitle}>
            {title}
          </Typography>
        </Grid>
      )}

      <Grid item xs={6}>
        <Controller
          name={name ? `${name}.insuranceCompany` : 'insuranceCompany'}
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <MultipleSelect
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              fullWidth
              disableClearable
              options={insuranceCompanies?.toJS()}
              label={renderIntlMessage(inputLabels.insuranceCompany)}
              error={
                !!getAccessByPropPath(
                  errors,
                  name ? `${name}.insuranceCompany` : 'insuranceCompany',
                )
              }
              helperText={renderIntlMessage(
                getAccessByPropPath(errors, name ? `${name}.insuranceCompany` : 'insuranceCompany')
                  ?.message,
              )}
              recommended={Boolean(recommendedFields?.get(RequiredProfileInfo.InsuranceInfo))}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          control={control}
          name={name ? `${name}.insuranceMemberId` : 'insuranceMemberId'}
          defaultValue=""
          render={({ field: { value, onChange, onBlur } }) => (
            <TextField
              label={renderIntlMessage(inputLabels.id)}
              variant="outlined"
              fullWidth
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={
                !!getAccessByPropPath(
                  errors,
                  name ? `${name}.insuranceMemberId` : 'insuranceMemberId',
                )
              }
              helperText={renderIntlMessage(
                getAccessByPropPath(
                  errors,
                  name ? `${name}.insuranceMemberId` : 'insuranceMemberId',
                )?.message,
              )}
              recommended={Boolean(recommendedFields?.get(RequiredProfileInfo.InsuranceInfo))}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(InsuranceSection);
