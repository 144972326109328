import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { AlertCondition } from 'common/interfaces/alerts';

import { DialogComponent } from 'common/components';
import AllergiesSection from 'common/components/Steps/AdditionalInfoStep/AllergiesSection/AllergiesSection';

import personMessages from 'common/components/PersonProfile/messages';
import useRootSelector from 'common/hooks/useRootSelector';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { getAllergiesValidationSchema } from 'common/validationSchemas/allergiesValidationSchema';
import { useAppDispatch } from 'store/hooks';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import { fetchDictionaryList, resetDictionaryListAction } from 'common/state/dictionary/actions';
import { DictionaryList } from 'common/constants';
import { IAllergiesFormValues } from 'modules/members/interfaces';

interface IProps {
  alert: AlertCondition | null;
  isOpen: boolean;
  onSubmit: (allergies: IAllergiesFormValues, alertId: string) => void;
  switchingModal: (alertCondition: AlertCondition) => void;
}

const AllergiesModal = ({ isOpen, switchingModal, onSubmit, alert }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const profileRequiredFields = useRootSelector(selectProfileRequiredFields);
  const isSubmitted = useRootSelector(selectIsSubmittedRecommendedFieldsModal);

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();

  const formMethods = useForm<IAllergiesFormValues>({
    defaultValues: {
      allergies: [],
    },
    resolver: yupResolver(
      yup.object().shape(getAllergiesValidationSchema(profileRequiredFields)),
    ) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
  });

  const { handleSubmit, getValues } = formMethods;

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.ALLERGIES));

    return () => {
      dispatch(resetDictionaryListAction({ dictionary: DictionaryList.ALLERGIES }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (isSubmitted) {
      const allergies = getValues();

      onSubmit(allergies, alert);
      switchingModal(alert);

      dispatch(showRecommendedFieldsModal(false));
    }
  }, [alert, dispatch, getValues, isSubmitted, onSubmit, switchingModal]);

  const onSubmitForm = (allergies): void => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty({ allergies });

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(allergies, alert);
      switchingModal(alert);
    }
  };

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...personMessages.allergiesBlockTitle} />}
      isOpen={isOpen}
      onClose={() => switchingModal(alert)}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <FormProvider {...formMethods}>
        <AllergiesSection recommendedFields={profileRecommendedFields} />
      </FormProvider>
    </DialogComponent>
  );
};

export default AllergiesModal;
