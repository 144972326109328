import React, { FC, Dispatch, SetStateAction, useState } from 'react';
import { Typography, Box, Collapse, makeStyles, Theme, Button } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowDown } from '@material-ui/icons';
import cx from 'classnames';
import { ReactComponent as PrinterIcon } from 'img/icons/printer_deprecated.svg';
import { WaiverStatus, WaiverStatusLabels } from 'modules/crm/constants/leads';
import { IContactInfo, IPersonDocumentItemDto } from 'common/interfaces/contract';
import SignDocumentStep from 'common/components/Steps/SignDocumentStep/SignDocumentStep';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(1),
    borderRadius: '4px',
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer',

    '&:last-child': {
      marginBottom: 0,
    },
  },
  header: {
    padding: theme.spacing(1.25, 2, 1.25, 1.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  headerTitle: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  title: {
    marginRight: theme.spacing(1),
  },
  number: {
    opacity: '0.6',
    fontWeight: 500,
  },
  status: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    opacity: '0.6',
    textTransform: 'uppercase',
    marginRight: theme.spacing(1),
  },
  statusSigned: {
    color: theme.palette.primary.main,
  },
  statusWaiting: {
    color: theme.palette.warning.main,
  },
  content: {
    padding: theme.spacing(0, 2, 2),
    cursor: 'initial',
  },
  footer: {
    paddingTop: theme.spacing(2),
    flexWrap: 'nowrap',
  },
  footerMenuPaper: {
    marginTop: theme.spacing(0.7),
  },
  extraSignDocumentStep: {
    height: '55vh',
  },
}));

interface IContractItem {
  fetchApplySignature: (file: File, documentId: string) => void;
  onPrint: (contractId: string) => void;
  contract: Partial<IPersonDocumentItemDto>;
  contactInfo: Partial<IContactInfo>;
  setSignedDocumentData: Dispatch<SetStateAction<Record<string, boolean>>>;
  isSignedDocument: boolean;
}

const ContractItem: FC<IContractItem> = ({
  fetchApplySignature,
  onPrint,
  contract,
  contactInfo,
  setSignedDocumentData,
  isSignedDocument,
}) => {
  const classes = useStyles();
  const [showContent, setShowContent] = useState(false);

  const toggleContent = (): void => {
    setShowContent(!showContent);
  };

  const onClickPrint = (event): void => {
    event.stopPropagation();
    onPrint(contract.documentId);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header} onClick={toggleContent}>
        <Box className={classes.headerLeft}>
          {showContent ? <KeyboardArrowDown /> : <KeyboardArrowRight />}

          <Box className={classes.headerTitle}>
            <Typography className={classes.title} variant="h5">
              {contract.title}
            </Typography>

            <Typography className={classes.number}>{`#${contract.code}`}</Typography>
          </Box>
        </Box>

        {contract.status !== WaiverStatus.NO_SIGN && (
          <Typography
            className={cx(classes.status, {
              [classes.statusSigned]: isSignedDocument,
              [classes.statusWaiting]: !isSignedDocument,
            })}
          >
            {WaiverStatusLabels[isSignedDocument ? WaiverStatus.SIGNED : WaiverStatus.UNSIGNED]}
          </Typography>
        )}

        <Button
          onClick={onClickPrint}
          type="button"
          variant="text"
          size="small"
          startIcon={<PrinterIcon />}
          color="primary"
        />
      </Box>

      <Collapse in={showContent}>
        <Box className={classes.content}>
          <SignDocumentStep
            extraClassName={classes.extraSignDocumentStep}
            additionalFieldName={`:${contract.documentId}`}
            fetchApplySignature={fetchApplySignature}
            contract={contract}
            contactInfo={contactInfo}
            hasHiddenFooter
            hasHiddenHeader
            setIsSignedDocumentData={setSignedDocumentData}
            isSignedDocument={isSignedDocument}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default ContractItem;
