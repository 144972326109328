import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';

import CustomRoute from 'modules/authentication/containers/CustomRoute/CustomRoute';
import {
  EditTemplate,
  NewPackage,
  PackagesList,
  ServicesList,
  FeesList,
  EditFee,
} from './containers';
import { DocumentTemplates } from 'modules/services/containers';
import EditService from './containers/EditService/EditService';
import { MainPage } from './components';
import CatchAll from 'routes/CatchAll';

import { PermissionLevels } from 'common/constants/permissions';

export enum ServicesRoutes {
  SERVICES = '/',
  CONTRACT_DOCUMENT_TEMPLATES = 'document-templates/contract',
  WAIVER_DOCUMENT_TEMPLATES = 'document-templates/waiver',
  CONTRACT_DOCUMENT_TEMPLATES_NEW = 'document-templates/contract/new',
  WAIVER_DOCUMENT_TEMPLATES_NEW = 'document-templates/waiver/new',
  CONTRACT_DOCUMENT_TEMPLATES_EDIT = 'document-templates/contract/:id',
  WAIVER_DOCUMENT_TEMPLATES_EDIT = 'document-templates/waiver/:id',
  SERVICES_LIST = 'services-list',
  SERVICES_LIST_NEW = 'services-list/new',
  SERVICES_LIST_ID = 'services-list/:serviceId',
  SERVICES_PACKAGES = 'service-packages',
  SERVICES_PACKAGES_NEW = 'service-packages/new',
  SERVICES_PACKAGES_EDIT = 'service-packages/:packageId',
  FEES_LIST = 'fees',
  FEES_NEW = 'fees/new',
  FEES_EDIT = 'fees/:feeId',
}

const Routes = (): JSX.Element => (
  <Switch>
    <Route
      path={ServicesRoutes.SERVICES}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <MainPage />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.SERVICES_LIST}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <ServicesList />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.SERVICES_LIST_NEW}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditService />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.SERVICES_LIST_ID}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditService />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.FEES_LIST}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <FeesList />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.FEES_NEW}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditFee />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.FEES_EDIT}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditFee />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.SERVICES_PACKAGES}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <PackagesList />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.SERVICES_PACKAGES_NEW}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <NewPackage />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.SERVICES_PACKAGES_EDIT}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <NewPackage />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.CONTRACT_DOCUMENT_TEMPLATES}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <DocumentTemplates />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.WAIVER_DOCUMENT_TEMPLATES}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <DocumentTemplates />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.CONTRACT_DOCUMENT_TEMPLATES_NEW}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditTemplate />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.WAIVER_DOCUMENT_TEMPLATES_NEW}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditTemplate />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.CONTRACT_DOCUMENT_TEMPLATES_EDIT}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditTemplate />
        </CustomRoute>
      }
    />

    <Route
      path={ServicesRoutes.WAIVER_DOCUMENT_TEMPLATES_EDIT}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditTemplate />
        </CustomRoute>
      }
    />

    <Route path="*" element={<CatchAll />} />
  </Switch>
);

export default Routes;
