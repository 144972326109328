// libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
// interfaces and constants
import {
  IBillingStep,
  IEditPackageDetails,
  IGeneralStep,
  IPackageService,
} from '../interfaces/packages';
import { DurationUnits } from 'common/interfaces/common';

import { ConstOptions } from 'common/constants/classes';
import { RedeemType, ServiceType } from 'common/constants/service';
// messages
import commonMessages from 'common/messages/messages';
import messages from 'modules/services/messages/messages';
import packageMessages from '../messages/packages';

export const actionTypes = {
  FETCH_PACKAGES_LIST: 'FETCH_PACKAGES_LIST',
  FETCH_PACKAGES_LIST_LOADING: 'FETCH_PACKAGES_LIST_LOADING',

  CHANGE_PACKAGE_STATUS: 'CHANGE_PACKAGE_STATUS',
  CHANGE_PACKAGE_STATUS_LOADING: 'CHANGE_PACKAGE_STATUS_LOADING',
  RESET_CHANGE_PACKAGE_STATUS: 'RESET_CHANGE_PACKAGE_STATUS',

  CLONE_PACKAGE: 'CLONE_PACKAGE',
  CLONE_PACKAGE_LOADING: 'CLONE_PACKAGE_LOADING',
  RESET_CLONE_PACKAGE: 'RESET_CLONE_PACKAGE',

  // New/Edit Package
  FETCH_PACKAGE: 'FETCH_PACKAGE',
  FETCH_PACKAGE_LOADING: 'FETCH_PACKAGE_LOADING',
  CREATE_UPDATE_PACKAGE_LOADING: 'CREATE_UPDATE_PACKAGE_LOADING',
  CREATE_UPDATE_PACKAGE_RESULT: 'CREATE_UPDATE_PACKAGE_RESULT',
  RESET_PACKAGE_STATE: 'RESET_PACKAGE_STATE',

  FETCH_PACKAGE_EMPLOYEES: 'FETCH_PACKAGE_EMPLOYEES',
  FETCH_PACKAGE_EMPLOYEES_LOADING: 'FETCH_PACKAGE_EMPLOYEES_LOADING',
  RESET_PACKAGE_EMPLOYEES: 'RESET_PACKAGE_EMPLOYEES',

  FETCH_PACKAGE_RCODES: 'FETCH_PACKAGE_RCODES',
  FETCH_PACKAGE_RCODES_LOADING: 'FETCH_PACKAGE_RCODES_LOADING',
  RESET_PACKAGE_RCODES: 'RESET_PACKAGE_RCODES',

  FETCH_PACKAGE_INVENTORY: 'FETCH_PACKAGE_INVENTORY',
  FETCH_PACKAGE_INVENTORY_LOADING: 'FETCH_PACKAGE_INVENTORY_LOADING',
  RESET_PACKAGE_INVENTORY: 'RESET_PACKAGE_INVENTORY',

  FETCH_PACKAGE_SERVICES_PACKAGES: 'FETCH_PACKAGE_SERVICES_PACKAGES',
  FETCH_PACKAGE_SERVICES_PACKAGES_LOADING: 'FETCH_PACKAGE_SERVICES_LOADING_PACKAGES',
  RESET_PACKAGE_SERVICES_PACKAGES: 'RESET_PACKAGE_SERVICES_PACKAGES',

  FETCH_PACKAGE_INFO: 'FETCH_PACKAGE_INFO',
  FETCH_PACKAGE_INFO_LOADING: 'FETCH_PACKAGE_INFO_LOADING',
  RESET_PACKAGE_INFO: 'RESET_PACKAGE_INFO',

  FETCH_PACKAGE_FEES: 'FETCH_PACKAGE_FEES',
  FETCH_PACKAGE_FEES_LOADING: 'FETCH_PACKAGE_FEES_LOADING',
  RESET_PACKAGE_FEES: 'RESET_PACKAGE_FEES',

  // Billing options
  FETCH_BILLING_OPTIONS: 'FETCH_BILLING_OPTIONS',
  FETCH_BILLING_OPTIONS_LOADING: 'FETCH_BILLING_OPTIONS_LOADING',
  RESET_BILLING_OPTIONS: 'RESET_BILLING_OPTIONS',

  FETCH_BILLING_OPTION: 'FETCH_BILLING_OPTION',
  FETCH_BILLING_OPTION_LOADING: 'FETCH_BILLING_OPTION_LOADING',
  RESET_BILLING_OPTION: 'RESET_BILLING_OPTION',
};

export enum StepToggleAction {
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE',
}

export enum EditPackageStep {
  General = 'GENERAL',
  Membership = 'MEMBERSHIP',
  Inventory = 'INVENTORY',
  Services = 'SERVICES',
  Billing = 'BILLING',
  Fees = 'FEES',
}

export const EditPackageSteps = new ConstOptions([
  { value: EditPackageStep.General, message: commonMessages.generalLabel },
  { value: EditPackageStep.Billing, message: commonMessages.billingLabel },
  { value: EditPackageStep.Membership, message: messages.membershipLabel },
  { value: EditPackageStep.Inventory, message: messages.inventoryLabel },
  { value: EditPackageStep.Services, message: messages.servicesLabel },
  { value: EditPackageStep.Fees, message: commonMessages.fees },
]);

export enum PackageType {
  Recurring = 'RECURRING',
  Trial = 'TRIAL',
}

export const PackageTypes = new ConstOptions([
  { key: PackageType.Recurring, value: PackageType.Recurring, message: messages.recurringOption },
  { key: PackageType.Trial, value: PackageType.Trial, message: messages.trialOption },
]);

export enum PackageCostType {
  Free = 'FREE',
  Paid = 'PAID',
}

export const PackageCostTypes = new ConstOptions([
  { value: PackageCostType.Free, message: messages.freeOption },
  { value: PackageCostType.Paid, message: messages.paidOption },
]);

export enum DurationType {
  Min = 'MINS',
  Hours = 'HOURS',
  Days = 'DAYS',
  Weeks = 'WEEKS',
  BiWeeks = 'BI_WEEKS',
  Months = 'MONTHS',
  Quarters = 'QUARTERS',
  SemiAnnually = 'SEMI_ANNUALLY',
  Years = 'YEARS',
  Custom = 'CUSTOM',
}

export enum AvailabilityType {
  FromNow = 'FROM_NOW',
  StartingOnDate = 'STARTING_ON_DATE',
  ForRange = 'FOR_RANGE',
}

const durationTypesValues = [
  { value: DurationType.Min, message: commonMessages.minutesOption },
  { value: DurationType.Hours, message: commonMessages.hoursOption },
  { value: DurationType.Days, message: commonMessages.daysOption },
  { value: DurationType.Weeks, message: commonMessages.weeksOption },
  { value: DurationType.BiWeeks, message: commonMessages.beWeeksOption },
  { value: DurationType.Months, message: commonMessages.monthsOption },
  { value: DurationType.Quarters, message: commonMessages.quartersOptions },
  { value: DurationType.SemiAnnually, message: commonMessages.semiAnnuallyOption },
  { value: DurationType.Years, message: commonMessages.yearsOption },
  { value: DurationType.Custom, message: commonMessages.customOption },
];

const availabilityTypesValues = [
  { value: AvailabilityType.FromNow, message: commonMessages.fromNowOption },
  { value: AvailabilityType.StartingOnDate, message: commonMessages.startingOnDateOption },
  { value: AvailabilityType.ForRange, message: commonMessages.forRangeOption },
];

export const DurationTypes = new ConstOptions(durationTypesValues);

export const AvailabilityTypes = new ConstOptions(availabilityTypesValues);

export const SimpleDurationTypes = new ConstOptions([
  durationTypesValues[2],
  durationTypesValues[3],
  durationTypesValues[5],
]);

export const GeneralDurationTypes = new ConstOptions(durationTypesValues.slice(2, 8));

export enum FrequencyType {
  Weekly = 'WEEKLY',
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  SemiAnnually = 'SEMI_ANNUALLY',
  Annually = 'ANNUALLY',
  Custom = 'CUSTOM',
}

export const FrequencyTypes = new ConstOptions([
  { value: FrequencyType.Weekly, message: commonMessages.weeklyFrequencyOption },
  { value: FrequencyType.BiWeekly, message: commonMessages.biWeeklyFrequencyOption },
  { value: FrequencyType.Monthly, message: commonMessages.monthlyFrequencyOption },
  { value: FrequencyType.Quarterly, message: commonMessages.quarterlyFrequencyOption },
  { value: FrequencyType.SemiAnnually, message: commonMessages.semiAnnuallyFrequencyOption },
  { value: FrequencyType.Annually, message: commonMessages.annuallyFrequencyOption },
  // TODO: needs refinement { value: FrequencyType.Custom, message: commonMessages.customFrequencyOption },
]);

export enum ConditionForPurchaseType {
  NewMember = 'NEW_MEMBER',
  Downgrade = 'DOWNGRADE',
  Upgrade = 'UPGRADE',
}

export const ConditionForPurchaseTypes = new ConstOptions([
  { value: ConditionForPurchaseType.NewMember, message: messages.newMemberLabel },
  { value: ConditionForPurchaseType.Downgrade, message: messages.downgradeLabel },
  { value: ConditionForPurchaseType.Upgrade, message: messages.upgradeLabel },
]);

export enum IssueFrequencyType {
  OneTime = 'ONE_TIME',
  PerCycle = 'PER_CYCLE',
  PerSession = 'PER_SESSION',
}

export const IssueFrequencyTypes = new ConstOptions([
  { value: IssueFrequencyType.OneTime, message: messages.oneTimeLabel },
  { value: IssueFrequencyType.PerCycle, message: messages.perCycleLabel },
  { value: IssueFrequencyType.PerSession, message: messages.perSessionLabel },
]);

export enum LimitationType {
  Unlimited = 'UNLIMITED',
  Limited = 'LIMITED',
}

export const LimitationTypes = new ConstOptions([
  { key: LimitationType.Unlimited, value: 'false', message: commonMessages.unlimitedLabel },
  { key: LimitationType.Limited, value: 'true', message: commonMessages.limitedLabel },
]);

export enum IncludedPackageBillingType {
  Free = 'FREE',
  OneTimePayment = 'ONE_TIME',
  OwnBillingSettings = 'OWN',
}

export const IncludedPaidPackageBillingTypes = new ConstOptions([
  { value: IncludedPackageBillingType.Free, message: messages.freeOption },
  { value: IncludedPackageBillingType.OneTimePayment, message: messages.oneTimePaymentLabel },
  {
    value: IncludedPackageBillingType.OwnBillingSettings,
    message: messages.billedAccordingToBillingSettingsLabel,
  },
]);

export const IncludedFreePackageBillingTypes = new ConstOptions([
  { value: IncludedPackageBillingType.Free, message: messages.freeOption },
  { value: IncludedPackageBillingType.OneTimePayment, message: messages.oneTimePaymentLabel },
]);

export enum BillingOptionType {
  FINANCED = 'FINANCED',
  PAID_IN_FULL = 'PAID_IN_FULL',
}

export const BillingOptionTypes = new ConstOptions([
  { value: BillingOptionType.FINANCED, message: messages.financedBillingOptionType },
  { value: BillingOptionType.PAID_IN_FULL, message: messages.paidInFullBillingOptionType },
]);

export enum CreditCardFeeUnit {
  PERCENTAGE = 'PERCENTAGE',
  FLAT = 'FLAT',
}

export const CreditCardFeeUnits = new ConstOptions([
  { value: CreditCardFeeUnit.PERCENTAGE, message: messages.percentageCreditCardFeeUnit },
  { value: CreditCardFeeUnit.FLAT, message: messages.flatCreditCardFeeUnit },
]);

export const CreditCardFeeUnitsSymbols = new ConstOptions([
  { value: CreditCardFeeUnit.PERCENTAGE, message: messages.percentageCreditCardFeeUnitSymbol },
  { value: CreditCardFeeUnit.FLAT, message: messages.flatCreditCardFeeUnitSymbol },
]);

// default form data

export const defaultEditableNumber = {
  editable: false,
  value: 1,
  minValue: 0,
  maxValue: 0,
};

export const defaultBillingOption = {
  title: '',
  type: BillingOptionType.FINANCED,
  pricePerBilling: 0,
  totalAmount: 0,
  paymentEditableSchedule: {
    editable: false,
    value: FrequencyType.Weekly.toString(),
  },
  freeze: {
    allow: false,
    editableDaysNumber: defaultEditableNumber,
    billingFrozenMembership: false,
  },
  paymentSplitting: {
    allow: false,
    paymentNumber: 1,
    paymentEditableSplits: [],
    splitPaymentEditableNumber: { editable: false, value: 0 },
    splitPaymentSchedule: FrequencyType.Weekly,
  },
  downPayment: {
    allow: false,
    editableAmount: defaultEditableNumber,
    editableNumber: defaultEditableNumber,
    editableSplits: [],
  },
  paymentGrace: {
    allow: false,
    duration: DurationType.Months,
    editableDurationNumber: defaultEditableNumber,
  },
  creditCardServiceFee: {
    enabled: false,
    value: 0,
    unit: CreditCardFeeUnit.PERCENTAGE,
  },
};

export const defaultBillingStepFormData: IBillingStep = {
  billingSection: {
    revenueCode: null,
    billingOptions: [],
    defaultBillingOptionPresent: false,
  },
};

export const defaultMembershipStepFormData = {
  membershipSection: {
    documentTemplate: '',
    initialFees: [
      {
        active: false,
        purchaseCondition: ConditionForPurchaseType.NewMember,
      },
      {
        active: false,
        purchaseCondition: ConditionForPurchaseType.Downgrade,
      },
      {
        active: false,
        purchaseCondition: ConditionForPurchaseType.Upgrade,
      },
    ],
  },
};

export const defaultGeneralStepFormData: IGeneralStep = {
  generalSection: {
    title: '',
    active: true,
    type: PackageType.Recurring,
    durationType: DurationType.Weeks,
    durationEditableNumber: defaultEditableNumber,
    availabilityType: AvailabilityType.FromNow,
    membership: true,
    allowMembersToPurchaseOnline: false,
    dayTimeAvailDtoList: [],

    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,

    allowAllEmployee: true,
    employees: [],

    allowAccessAllClubs: true,
    allowSellInAllClubs: true,
    accessClubs: [],
    sellerClubs: [],
    renewalNumber: 0,
    documentTemplate: null,
  },
};

export const defaultInventoriesFormData = {
  inventorySection: {
    inventories: [],
  },
};

export const defaultClubServiceData = {
  billingPrice: 0,
  club: {
    id: 'string',
    name: 'string',
  },
  employeesNumber: 0,
  inventoriesNumber: 0,
  servicesNumber: 0,
  type: PackageType.Recurring,
  clubsConfiguration: null,
};

export const defaultEmployeesFormData = {
  employees: [],
};

export const defaultServicesFormData = {
  serviceSection: {
    actualCombinations: [],
    allowAllPackageCombination: false,
    allowPackageCombination: true,
    includedServices: [],
    potentialCombinations: [],
  },
};

export const defaultFeesFormData = {
  feesSection: {
    includedFees: [],
  },
};

export const defaultEditPackageFormData: Omit<IEditPackageDetails, 'id'> = {
  ...defaultGeneralStepFormData,
  ...defaultBillingStepFormData,
  ...defaultMembershipStepFormData,
  ...defaultEmployeesFormData,
  ...defaultInventoriesFormData,
  ...defaultServicesFormData,
  ...defaultFeesFormData,
};

export const getLimitLabelByService = (service: IPackageService): JSX.Element => {
  if (service.type === ServiceType.FamilyMember) {
    return <FormattedMessage {...packageMessages.limitedFamilyMembersServiceLabel} />;
  }

  if (service.redeemType === RedeemType.Session) {
    return <FormattedMessage {...packageMessages.limitedSessionsServiceLabel} />;
  }

  if (service.redeemType === RedeemType.Duration) {
    return DurationUnits.translate(service.redeemDurationUnit);
  }

  return <FormattedMessage {...packageMessages.limitedServiceGeneralLabel} />;
};
