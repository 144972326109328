import React, { useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch } from 'store/hooks';

import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { fetchDictionaryList } from 'common/state/dictionary/actions';

import { getRequiredMessage } from 'common/constants/globalConstants';
import { DictionaryList } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { AlertCondition } from 'common/interfaces/alerts';
import { IEmployeeListItemImt } from 'common/interfaces/dictionary';
import { INamedEntity } from 'common/interfaces/common';

import { DialogComponent, MultipleSelect } from 'common/components';

import inputLabels from 'common/messages/inputLabels';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';

// TODO - PRM-1810 tmp form interface
interface IFormValues {
  salesperson: Record<string, any> | null;
}

interface IProps {
  alert: AlertCondition | null;
  isOpen: boolean;
  onSubmit: (salespersonId: string, alertId: string) => void;
  switchingModal: (alertCondition: AlertCondition) => void;
}

const SalespersonModal = ({ isOpen, switchingModal, onSubmit, alert }: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const profileRequiredFields = useRootSelector(selectProfileRequiredFields);
  const isSubmitted = useRootSelector(selectIsSubmittedRecommendedFieldsModal);
  const employees: ImmutableList<IEmployeeListItemImt> = useRootSelector(
    selectDictionaryList(DictionaryList.EMPLOYEES),
  );

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();

  const formMethods = useForm<IFormValues>({
    defaultValues: {
      salesperson: null,
    },
    resolver: yupResolver(
      yup.object().shape({
        salesperson: yup
          .object()
          .shape({
            id: yup.string(),
          })
          .nullable()
          .when(RequiredProfileInfo.Salesperson, {
            is: () => {
              return (
                profileRequiredFields.get(RequiredProfileInfo.Salesperson) ===
                FieldNecessity.Required
              );
            },
            then: schema => schema.required(getRequiredMessage),
            otherwise: schema => schema,
          }),
      }),
    ) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = formMethods;

  const renderIntlMessage = useRenderIntlMessage();

  useEffect(() => {
    if (isSubmitted) {
      const { salesperson } = getValues();

      onSubmit(salesperson.id, alert);
      switchingModal(alert);

      dispatch(showRecommendedFieldsModal(false));
    }
  }, [alert, dispatch, getValues, isSubmitted, onSubmit, switchingModal]);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchDictionaryList(DictionaryList.EMPLOYEES, { module: PeakModules.FrontDesk }));
    }
  }, [dispatch, isOpen]);

  const onSubmitForm = (values: { salesperson: INamedEntity }): void => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty({
      emergencyContactDto: values,
    });

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(values.salesperson.id, alert);
      switchingModal(alert);
    }
  };

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...inputLabels.salesperson} />}
      isOpen={isOpen}
      onClose={() => switchingModal(alert)}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <Controller
        name="salesperson"
        control={control}
        render={({ field }) => (
          <MultipleSelect
            fullWidth
            label={<FormattedMessage {...inputLabels.salesperson} />}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={!!errors.salesperson}
            helperText={renderIntlMessage(errors?.salesperson?.message)}
            options={employees.toJS()}
            recommended={Boolean(profileRecommendedFields?.get(RequiredProfileInfo.Salesperson))}
          />
        )}
      />
    </DialogComponent>
  );
};

export default SalespersonModal;
