/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { Component } from 'react';
import { createStyles, StyledComponentProps, withStyles } from '@material-ui/core';
import { List } from 'immutable';
import { FormattedMessage } from 'react-intl';

// Components
import { Connect } from 'common/decorators';
import TableSection, {
  ITableHeaderColumn,
  ITableSectionColumn,
} from 'modules/reports/components/TableSection/TableSection';
// Selectors
import {
  selectRegistersSummary,
  selectRegistersSummaryLoading,
} from 'modules/reports/state/salesPOS/selectors';
// Actions
import { fetchRegistersSummary } from 'modules/reports/state/salesPOS/actions';
// Constants
import { DEFAULT_TABLE_PARAMS } from 'common/constants/table';
// Interfaces
import {
  IRegistersSummaryItem,
  IRegistersSummaryItemImt,
} from 'modules/reports/interfaces/salesPOS';
import { DispatchFunction } from 'common/state/interfaces';
import { ITableParams, Order } from 'common/interfaces/table';
// Utils
import { formatPrice } from 'common/utils';
// Messages
import messages from 'modules/reports/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import { selectRoot } from 'common/hooks/useRootSelector';

const styles = () => createStyles({});

interface IInvoicesSummaryProps extends StyledComponentProps {
  mainTableParams: ITableParams;
  registersSummary?: List<IRegistersSummaryItemImt>;
  registersSummaryLoading?: boolean;

  fetchRegistersSummaryData?: (params?) => void;
}

@(withStyles(styles) as any)
@(Connect({
  mapStateToProps: () => ({
    registersSummary: selectRoot(selectRegistersSummary()),
    registersSummaryLoading: selectRoot(selectRegistersSummaryLoading()),
  }),
  mapDispatchToProps: (dispatch: DispatchFunction) => ({
    fetchRegistersSummaryData: (requestOptions?) => dispatch(fetchRegistersSummary(requestOptions)),
  }),
}) as any)
class RegistersSummary extends Component<
  IInvoicesSummaryProps,
  ITableParams<IRegistersSummaryItem>
> {
  private readonly columnSettings: ITableSectionColumn[];

  private readonly headerSettings: ITableHeaderColumn<IRegistersSummaryItem>[];

  constructor(props: IInvoicesSummaryProps) {
    super(props);

    this.state = {
      page: 0,
      perPage: 5,
      orderBy: 'register',
      order: 'desc',
      filters: [],
    };

    this.columnSettings = [
      {
        name: 'register',
        label: <FormattedMessage {...inputLabels.register} />,
      },
      {
        name: 'quantity',
        label: <FormattedMessage {...inputLabels.items} />,
        align: 'right',
      },
      {
        name: 'netSales',
        label: <FormattedMessage {...inputLabels.amount} />,
        align: 'right',
        transform: value => formatPrice(value),
      },
    ];

    this.headerSettings = [
      {
        name: 'register',
        label: <FormattedMessage {...inputLabels.register} />,
        sort: true,
      },
      {
        name: 'quantity',
        label: <FormattedMessage {...inputLabels.items} />,
        align: 'right',
        sort: true,
      },
      {
        name: 'netSales',
        label: <FormattedMessage {...inputLabels.amount} />,
        align: 'right',
        sort: true,
      },
    ];
  }

  componentDidMount(): void {
    const { fetchRegistersSummaryData, mainTableParams } = this.props;
    const { page, perPage, orderBy, order } = this.state;

    this.setState({ filters: mainTableParams.filters });
    fetchRegistersSummaryData({
      ...DEFAULT_TABLE_PARAMS,
      filters: mainTableParams.filters,
      page,
      perPage,
      orderBy,
      order,
    });
  }

  componentDidUpdate(): void {
    const { mainTableParams, fetchRegistersSummaryData } = this.props;
    const { page, perPage, orderBy, order, filters } = this.state;

    if (JSON.stringify(filters) !== JSON.stringify(mainTableParams.filters)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filters: mainTableParams.filters });
      fetchRegistersSummaryData({
        ...DEFAULT_TABLE_PARAMS,
        filters: mainTableParams.filters,
        page,
        perPage,
        orderBy,
        order,
      });
    }
  }

  handleChangeTableProps = (page: number, orderBy, order: Order): void => {
    this.updateTableParams({ ...this.state, page, orderBy, order });
  };

  updateTableParams = (tableParams: ITableParams<IRegistersSummaryItem>): void => {
    this.setState(tableParams);
    const { fetchRegistersSummaryData } = this.props;
    fetchRegistersSummaryData(tableParams);
  };

  render(): JSX.Element {
    const { registersSummary, registersSummaryLoading } = this.props;
    const { page, perPage, orderBy, order } = this.state;

    return (
      <TableSection<IRegistersSummaryItem, IRegistersSummaryItemImt>
        id="registers-summary-section"
        title={<FormattedMessage {...messages.registersSummaryTitle} />}
        columns={this.columnSettings}
        headerColumns={this.headerSettings}
        data={registersSummary}
        dataLoading={registersSummaryLoading}
        page={page}
        total={registersSummary?.size}
        order={order}
        orderBy={orderBy}
        perPage={perPage}
        hidePagination
        onSettingsChanged={this.handleChangeTableProps}
      />
    );
  }
}

export default RegistersSummary;
