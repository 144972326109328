import { useCallback } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { List as ImmutableList } from 'immutable';

import { selectAlertsList, selectAlertSounds } from 'common/state/alerts/selectors';

import usePlaySound from 'common/hooks/usePlaySound';

import { getAlertSound } from 'common/utils/alerts';

import { IAlertSoundItemImt } from 'modules/dictionaries/interfaces/interfaces';
import { AlertCondition } from 'common/interfaces/alerts';

type IAlertPlaySoundReturnFunc = (alerts: AlertCondition[]) => void;

const usePlayAlertSound = (): IAlertPlaySoundReturnFunc => {
  const alertsList = useRootSelector(selectAlertsList);
  const alertSounds: ImmutableList<IAlertSoundItemImt> = useRootSelector(selectAlertSounds);

  const playSound = usePlaySound();

  return useCallback(
    (alerts: AlertCondition[]) => {
      const alertSound = alerts ? getAlertSound(alertsList, alertSounds, alerts) : null;

      if (alertSound) {
        playSound(alertSound.getIn(['storedFileDto', 'filePath']));
      }
    },
    [alertSounds, alertsList, playSound],
  );
};

export default usePlayAlertSound;
