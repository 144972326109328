import React, { useMemo, useState } from 'react';
import { Box, debounce, TextField, Typography } from '@material-ui/core';
import notes from 'common/components/PersonProfile/components/Notes/messages/notes';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { INoteCode } from 'common/components/PersonProfile/interfaces';
import { Autocomplete } from '@material-ui/lab';

interface INoteCodeSelectorProps {
  searchNoteCodes: (search: string, customerId: number) => void;
  profileId: number;
  onChange: (value: INoteCode | string) => void;
  value: INoteCode;
  isLoading?: boolean;
  clearSearchResults: () => void;
  noteCodes: INoteCode[];
  error: boolean;
  helperText: React.ReactNode;
}

const NoteCodeSelector = (props: INoteCodeSelectorProps): JSX.Element => {
  const {
    isLoading,
    searchNoteCodes,
    profileId,
    onChange,
    clearSearchResults,
    value,
    noteCodes,
    error,
    helperText,
  } = props;

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const renderIntlMessage = useRenderIntlMessage();

  const onChangeSearch = useMemo(() => {
    return debounce((search: string) => {
      if (profileId) {
        searchNoteCodes(search, profileId);
      }
    }, 500);
  }, [searchNoteCodes, profileId]);

  const renderNoteCode = ({ title }: INoteCode) => {
    return (
      <Box p={0.5}>
        <Typography variant="body1">{title}</Typography>
      </Box>
    );
  };

  return (
    <Autocomplete
      open={isOpenDropdown}
      onOpen={() => setIsOpenDropdown(true)}
      onClose={() => setIsOpenDropdown(false)}
      onChange={(e, val) => {
        onChange(val);
      }}
      value={value}
      getOptionLabel={option => (option ? option.title : '')}
      renderOption={renderNoteCode}
      options={noteCodes.length ? noteCodes : []}
      loading={isLoading}
      renderInput={params => {
        return (
          <TextField
            {...params}
            label={renderIntlMessage(notes.noteCode)}
            variant="outlined"
            fullWidth
            onChange={e => {
              onChangeSearch(e.target.value);
            }}
            onFocus={e => {
              onChangeSearch(e.target.value);
            }}
            onBlur={clearSearchResults}
            error={error}
            helperText={helperText}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
          />
        );
      }}
    />
  );
};

export default NoteCodeSelector;
