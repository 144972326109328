import { useCallback, useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import moment, { Moment } from 'moment-timezone';

import { selectTimezone } from 'common/state/settings/selectors';
import { setTimezoneView } from 'common/state/settings/actions';

import { useAppDispatch } from 'store/hooks';

export const DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
export const DATE_WITH_UTC_TIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ssZ';

let isDefault = false;

type GetUTCDateString = { date?: string; format?: string };

const useTimezoneMoment = (): [
  (date?: string | Date, format?: string, isDateTimeSeparated?: boolean) => Moment,
  (date?: GetUTCDateString) => string,
  (timezone: string) => void,
] => {
  const dispatch = useAppDispatch();

  const timezone: string = useRootSelector(selectTimezone);

  useEffect(() => {
    if (!isDefault) {
      moment.tz.setDefault(timezone);
      isDefault = true;
    }
  }, [timezone]);

  // return moment instance
  const getMomentInstanceWithTimezone = useCallback(
    (date?: string, format = DATE_WITH_UTC_TIME_FORMAT, isDateTimeSeparated?: boolean) => {
      let dateWithUTCTimeFormat = null;
      const momentParams = [];

      if (date && isDateTimeSeparated) {
        return moment.utc(date, format).tz(timezone);
      }

      if (date && format === DATE_WITH_UTC_TIME_FORMAT) {
        dateWithUTCTimeFormat = date.includes('Z') ? date : `${date}Z`;
      }

      if (dateWithUTCTimeFormat) {
        momentParams.push(dateWithUTCTimeFormat);
      }

      if (date && !dateWithUTCTimeFormat) {
        momentParams.push(date);
      }

      if (momentParams.length) {
        momentParams.push(format);
      }

      return moment(...momentParams);
    },
    [timezone],
  );

  const getUTCDateString = useCallback(
    ({ date, format = DATE_FORMAT }: GetUTCDateString = {}) => {
      if (date?.includes('Z')) {
        return date;
      }

      if (date) {
        return `${date}Z`;
      }

      return moment()
        .utc()
        .format(format);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timezone],
  );

  const handleSetTimezone = useCallback(
    (zone: string) => {
      dispatch(setTimezoneView(zone));
    },
    [dispatch],
  );

  return [getMomentInstanceWithTimezone, getUTCDateString, handleSetTimezone];
};

export default useTimezoneMoment;
