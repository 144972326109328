import React from 'react';
import { ReactToPrintHTML } from 'common/components';
import useRootSelector from 'common/hooks/useRootSelector';
import { selectToPrintHTML } from 'common/state/printHTML/selectors';
import { printHTMLLoading } from 'common/state/printHTML/actions';
import { useAppDispatch } from 'store/hooks';

const PrintHTML = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { html: printingHtml } = useRootSelector(selectToPrintHTML);

  const onBeforePrint = () => {
    dispatch(printHTMLLoading(true));
  };

  const onAfterPrint = () => {
    dispatch(printHTMLLoading(false));
  };

  return (
    <ReactToPrintHTML
      onBeforePrint={onBeforePrint}
      onAfterPrint={onAfterPrint}
      printingHtml={printingHtml}
    />
  );
};

export default React.memo(PrintHTML);
