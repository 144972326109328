import React from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { List as ImmutableList } from 'immutable';

import { ICorporationItemImt } from 'modules/authentication/interfaces';

import * as selectors from 'memberPortalModules/authentication/state/selectors';

import { Login } from 'memberPortalModules/authentication/components';

const SignIn = (): JSX.Element => {
  const signInError = useRootSelector(selectors.selectSignInError);
  const isLoading: boolean = useRootSelector(selectors.selectSignInLoading);
  const corporationsForSignIn: ImmutableList<ICorporationItemImt> = useRootSelector(
    selectors.selectCorporationsForSignIn,
  );
  const isCorporationsForSignInLoading: boolean = useRootSelector(
    selectors.selectCorporationsForSignInLoading,
  );

  return (
    <Login
      signInError={signInError}
      isLoading={isLoading || isCorporationsForSignInLoading}
      corporations={corporationsForSignIn}
    />
  );
};

export default SignIn;
