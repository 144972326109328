import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Box, CardContent, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IFormStepStyle, IMembershipSection } from 'modules/services/interfaces/packages';
import ConditionsForPurchaseSelector from './ConditionsForPurchaseSelector/ConditionsForPurchaseSelector';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    flex: 1,
    minHeight: 0,
    overflow: 'hidden',
  },
  hidden: {
    display: 'none',
  },
  cardContent: {
    padding: ({ smallPaddingContent }: IFormStepStyle) =>
      smallPaddingContent ? theme.spacing(1, 0) : theme.spacing(1, 2),
    '&:last-child': {
      padding: ({ smallPaddingContent }: IFormStepStyle) =>
        smallPaddingContent ? theme.spacing(1, 0) : theme.spacing(1, 2),
    },
  },
  durationEditableWrap: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      padding: 0,
    },
    '& >div .MuiOutlinedInput-root': {
      width: 'calc(100% + 1px)',
    },
    '& >div:last-child .MuiOutlinedInput-root': {
      width: '100%',
    },
  },
}));

interface IMembershipStepProps extends IFormStepStyle {
  formMethods: UseFormReturn<IMembershipSection>;
}

const MembershipStepForm = ({
  formMethods,
  smallPaddingContent,
}: IMembershipStepProps): JSX.Element => {
  const classes = useStyles({ smallPaddingContent });

  return (
    <Box className={classes.contentWrapper}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={4}>
          <ConditionsForPurchaseSelector formMethods={formMethods} />
        </Grid>
      </CardContent>
    </Box>
  );
};

export default React.memo(MembershipStepForm);
