import React, { useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';

import * as actions from 'common/components/PersonProfile/state/actions';
import * as selectors from 'common/components/PersonProfile/state/selectors';

import { IKidZoneCheckInHistoryImt } from 'common/components/PersonProfile/interfaces';

import { useAppDispatch } from 'store/hooks';

import {
  CheckInBlock,
  KidZoneCheckInsHistoryModal,
  KidZoneCheckInsHistoryTable,
} from 'common/components/PersonProfile/components/index';

import commonMessages from 'common/messages/messages';
import permissionsMessages from 'modules/permissions/messages/messages';

import { PeakModules } from 'common/constants/peakModules';

interface IProps {
  personId: number;
  module: PeakModules;
}

const KidZoneCheckInsHistory = ({ personId, module }: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const recentCheckInsHistoryList: ImmutableList<IKidZoneCheckInHistoryImt> = useRootSelector(
    selectors.selectRecentCheckInsHistoryList(personId),
  );
  const isRecentCheckInsHistoryLoading: boolean = useRootSelector(
    selectors.selectRecentCheckInsHistoryLoading(personId),
  );

  const [isCheckInsHistoryModalOpen, setIsCheckInsHistoryModalOpen] = useState(false);

  useEffect(() => {
    dispatch(actions.fetchRecentCheckInsHistory(personId, module));
  }, [dispatch, personId, module]);

  return (
    <>
      <CheckInBlock
        headerDivider
        title={<FormattedMessage {...commonMessages.checkinHistoryTitle} />}
        buttonTitle={<FormattedMessage {...permissionsMessages.viewAll} />}
        onClickButton={() => setIsCheckInsHistoryModalOpen(true)}
      >
        <KidZoneCheckInsHistoryTable
          disableSorting
          checkInsHistory={recentCheckInsHistoryList}
          isCheckInsHistoryLoading={isRecentCheckInsHistoryLoading}
          hidePagination
          hideSearchInput
        />
      </CheckInBlock>

      {isCheckInsHistoryModalOpen && (
        <KidZoneCheckInsHistoryModal
          isOpen={isCheckInsHistoryModalOpen}
          onClose={() => setIsCheckInsHistoryModalOpen(false)}
          personId={personId}
          module={module}
        />
      )}
    </>
  );
};

export default KidZoneCheckInsHistory;
