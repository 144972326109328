import React, { useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import {
  Badge,
  Box,
  IconButton,
  makeStyles,
  Paper,
  Popover,
  SvgIcon,
  Theme,
  Typography,
} from '@material-ui/core';

import useComponentUpdate from 'common/hooks/useComponentUpdate';

import { ReactComponent as CartIcon } from 'img/icons/cart.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/x-bold_deprecated.svg';

import messages from 'common/messages/messages';
import { FormattedMessage } from 'react-intl';
import PaymentStep from 'memberPortalModules/MemberProfile/components/PaymentStep/PaymentStep';
import { selectInvoiceUnitCount } from 'memberPortalModules/MemberProfile/state/invoice/selectors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > .MuiPaper-root': {
      top: '72px !important',
      right: '8px !important',
      height: 'calc(100% - 80px)',
      width: '440px',

      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    paddingBottom: theme.spacing(1),
  },
  iconButton: {
    '& .MuiBadge-badge': {
      top: theme.spacing(0.25),
      right: theme.spacing(0.25),
    },
  },
  closeBtnIcon: {
    color: theme.palette.darkBackground?.light,
  },

  titleWrapper: {
    padding: theme.spacing(2.5, 3, 2.25),
  },
}));

const MemberPortalCart = (): JSX.Element => {
  const invoiceUnitAmount = useRootSelector(selectInvoiceUnitCount);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useComponentUpdate();

  const classes = useStyles();

  const onOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        style={{
          padding: 8,
        }}
        aria-label="notifications"
        onClick={onOpen}
        disableRipple
        className={classes.iconButton}
      >
        <Badge color="error" badgeContent={invoiceUnitAmount}>
          <CartIcon color="inherit" />
        </Badge>
      </IconButton>

      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={onClose}
        className={classes.root}
      >
        <Paper className={classes.paper}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={2.5}
            className={classes.titleWrapper}
          >
            <Typography variant="h3">
              <FormattedMessage {...messages.yourCart} />
            </Typography>
            <IconButton size="small" onClick={onClose}>
              <SvgIcon className={classes.closeBtnIcon} component={DeleteIcon} />
            </IconButton>
          </Box>
          <PaymentStep isMemberCard onCancel={onClose} onNext={onClose} />
        </Paper>
      </Popover>
    </>
  );
};

export default MemberPortalCart;
