import { IAttachment, IUploaderCustomError } from 'common/interfaces/uploadFile';
import { useAppDispatch } from 'store/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import useRootSelector from 'common/hooks/useRootSelector';
import {
  selectUploadedFileById,
  selectUploadedFileDialogId,
  selectUploadFileLoadingById,
} from 'common/state/uploadFile/selectors';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { setUploadId, uploadFile } from 'common/state/uploadFile/actions';
import FilesDropzone from '../FilesDropzone/FilesDropzone';
import { WebcamModal } from 'common/modals';

interface IAttachmentsBlockProps {
  attachments: IAttachment[];
  fieldId: string;
  attachmentsIsLoading: boolean;
  onUploadAttachments: (attachments: IAttachment[]) => void;
  onDeleteAttachment: (attachmentId: string) => void;
  hasVisibleScanDocumentBtn?: boolean;
  hasVisibleTakePhotoBtn?: boolean;
}

const FilesUploader = (props: IAttachmentsBlockProps): JSX.Element => {
  const {
    attachments,
    onUploadAttachments,
    onDeleteAttachment,
    attachmentsIsLoading,
    hasVisibleTakePhotoBtn,
    hasVisibleScanDocumentBtn,
    fieldId,
  } = props;

  const dispatch = useAppDispatch();

  const [error, setError] = useState<FieldErrors | null>(null);
  const [isOpenWebcamModal, setIsOpenWebcamModal] = useState(false);

  const attachmentsRef = useRef<IAttachment[]>([]);
  attachmentsRef.current = attachments || [];

  const isLoading = useRootSelector(selectUploadFileLoadingById(fieldId));
  const file = useRootSelector(selectUploadedFileById(fieldId));
  const uploadId = useRootSelector(selectUploadedFileDialogId);

  const renderIntlMessage = useRenderIntlMessage();

  useEffect(() => {
    if (file) {
      onUploadAttachments([...attachmentsRef.current, file]);
    }
  }, [onUploadAttachments, file]);

  const onToggleWebcamModal = () => setIsOpenWebcamModal(!isOpenWebcamModal);

  const onUploadWebcamFileImg = (fileData: File) => {
    onToggleWebcamModal();
    if (error) {
      setError(null);
    }

    if (uploadId !== fieldId) {
      dispatch(setUploadId(fieldId));
    }

    dispatch(uploadFile(fieldId, fileData));
  };

  const onUploadFiles = (
    currentFiles: IAttachment[],
    uploadFiles: File[],
    deletedFile?: IAttachment,
  ) => {
    if (error) {
      setError(null);
    }

    if (uploadId !== fieldId) {
      dispatch(setUploadId(fieldId));
    }

    if (deletedFile) {
      onDeleteAttachment(deletedFile.id);
    } else {
      dispatch(uploadFile(fieldId, uploadFiles[0]));
    }
  };

  const onRejected = (errors: IUploaderCustomError[]) => {
    const { customError } = errors[0];

    setError({ message: renderIntlMessage(customError) as any }); // TODO - PRM-1810 need type
  };

  return (
    <>
      <FilesDropzone<IAttachment>
        error={error}
        onRejected={onRejected}
        files={attachments}
        onUploadFiles={onUploadFiles}
        onToggleWebcamModal={() => {}}
        height="73px"
        isLoading={isLoading || attachmentsIsLoading}
        hasVisibleScanDocumentBtn={hasVisibleScanDocumentBtn}
        hasVisibleTakePhotoBtn={hasVisibleTakePhotoBtn}
      />
      {isOpenWebcamModal && (
        <WebcamModal
          isOpen={isOpenWebcamModal}
          onToggle={onToggleWebcamModal}
          onUpload={onUploadWebcamFileImg}
        />
      )}
    </>
  );
};

export default FilesUploader;
