import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { ICallNoteDtoImt } from 'common/components/PersonProfile/interfaces';
import { CustomTheme } from 'common/ui/interfaces';
import { IScriptStepDtoListImt } from 'modules/crm/interfaces/scripts';

import {
  selectPersonCallDetails,
  selectPersonCallDetailsLoading,
} from 'common/components/PersonProfile/state/selectors';
import { resetPersonCallDetails } from 'common/components/PersonProfile/state/actions';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { Checkbox, DialogComponent, FieldInfo } from 'common/components/index';

import { ReactComponent as UncheckIcon } from 'img/icons/checkbox-round.svg';
import { ReactComponent as CheckIcon } from 'img/icons/сheckbox-round-checked.svg';

import inputLabels from 'common/messages/inputLabels';
import messages from 'modules/crm/messages/tasks';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';
import { convertPhoneNumber } from 'helpers/common';
import useRootSelector from 'common/hooks/useRootSelector';

const useStyles = makeStyles((theme: CustomTheme) => ({
  divider: {
    width: '100%',
    backgroundColor: theme.palette.borderColor?.main,
  },
  accordion: {
    boxShadow: 'none',
  },
  accordionSummary: {
    minHeight: '48px !important',
    padding: theme.spacing(0, 1),

    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0),
    },
  },
  accordionText: {
    marginBottom: theme.spacing(1.5),
    opacity: '0.7',
    lineHeight: '1.25',
  },
  accordionDetails: {
    paddingLeft: theme.spacing(4.5),
    flexDirection: 'column',
  },
  stepName: {
    fontWeight: 500,
    marginLeft: theme.spacing(1.5),
  },
}));

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onCallNoteLoad: () => void;
  personId: number;
}

const CallDetailsModal = ({ isOpen, onClose, onCallNoteLoad, personId }: IProps): JSX.Element => {
  const dispatch = useDispatch();

  const callNote: ICallNoteDtoImt = useRootSelector(selectPersonCallDetails(personId));
  const isCallNoteLoading: boolean = useRootSelector(selectPersonCallDetailsLoading(personId));

  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  useEffect(() => {
    if (isOpen) {
      onCallNoteLoad();
    }

    return () => {
      dispatch(resetPersonCallDetails(null, personId));
    };
  }, [dispatch, isOpen, personId, onCallNoteLoad]);

  const campaign = callNote.getIn(['campaign', 'title']);
  const callScriptSteps = callNote.get('callScriptSteps');

  return (
    <DialogComponent
      title={<FormattedMessage {...messages.call} />}
      size="xs"
      isOpen={isOpen}
      onClose={onClose}
      loading={isCallNoteLoading}
      cancelBtn={false}
      submitBtn={false}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.from} />}
            description={convertPhoneNumber(callNote.get('salespersonPhone'))}
          />
        </Grid>

        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.to} />}
            description={convertPhoneNumber(callNote.getIn(['leadPhone', 'title']))}
          />
        </Grid>

        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.dateOfCall} />}
            description={timezoneMoment(
              `${callNote.get('date')} ${callNote.get('time')}`,
              'YYYY-MM-DD HH:mm',
              true,
            ).format(DEFAULT_DATE_FORMAT)}
          />
        </Grid>

        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.time} />}
            description={timezoneMoment(
              `${callNote.get('date')} ${callNote.get('time')}`,
              'YYYY-MM-DD HH:mm',
              true,
            ).format('h:mm A')}
          />
        </Grid>

        {!!campaign && (
          <Grid item xs={12}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.campaign} />}
              description={campaign}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          {callScriptSteps?.size ? (
            <>
              <Divider className={classes.divider} />
              {callScriptSteps.map((scriptStep: IScriptStepDtoListImt) => (
                <Box key={scriptStep.get('id')}>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Checkbox
                        checked={scriptStep.get('completed')}
                        icon={<UncheckIcon />}
                        checkedIcon={<CheckIcon />}
                      />

                      <Typography className={classes.stepName}>
                        {scriptStep.get('title')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Typography className={classes.accordionText} variant="body2">
                        {scriptStep.get('instruction')}
                      </Typography>

                      {scriptStep.get('note') && (
                        <FieldInfo
                          label={<FormattedMessage {...inputLabels.note} />}
                          description={scriptStep.get('note')}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                  <Divider className={classes.divider} />
                </Box>
              ))}
            </>
          ) : (
            <FieldInfo
              label={<FormattedMessage {...inputLabels.note} />}
              hasNotEllipsesDescription
              description={callNote.get('note') || '-'}
            />
          )}
        </Grid>
      </Grid>
    </DialogComponent>
  );
};

export default CallDetailsModal;
