import ApiService from './ApiService';
import axios from 'axios';
import { List as ImmutableList } from 'immutable';
import { ISuccessResponse } from 'common/interfaces/http';
import { IClubIds, INamedEntity, INamedEntityImt } from 'common/interfaces/common';
import { IPaginatedCommon } from 'modules/pos-settings/interfaces/common';
import { ITableMultiSelectParams, ITableParams } from 'common/interfaces/table';
import { IRegisterDetailed, IRegisterListItem } from 'modules/pos-settings/interfaces/register';
import {
  IInventoryDto,
  InventoryItemClubsChangingFields,
  IInventoryItemClubsEdit,
  IInventoryItemClubsFormData,
  IInventoryItemClubsFormDataImt,
  IInventoryItemsPosSettingsImt,
  IInventoryViewDto,
  ISimpleClubInventoryItem,
} from 'modules/pos-settings/interfaces/inventoryItems';
import {
  IReceiptDetailed,
  IReceiptItemClubsFormData,
  IReceiptListItem,
} from 'modules/pos-settings/interfaces/receipts';

import {
  IRevenueCodeClub,
  IRevenueCodeDetails,
  IRevenueCodeItemCountListItem,
  IRevenueCodeListItem,
} from 'modules/pos-settings/interfaces/revenueCodes';
import {
  IPaymentMethodCustomInstanceFormData,
  IPaymentMethodCustomItem,
  IPaymentMethodCustomItemClubsFormData,
  IPaymentMethodCustomItemPosSettingsImt,
  IPaymentMethodItem,
  IPaymentMethodsPosSettingsImt,
} from 'modules/pos-settings/interfaces/paymentMethods';
import {
  ITaxRateDetailedItem,
  ITaxRatesListItemImt,
} from 'modules/pos-settings/interfaces/taxRates';
import {
  IVendorDetailed,
  IVendorsListItem,
  IVendorsListItemImt,
} from 'modules/pos-settings/interfaces/vendors';
import { toSnakeCase } from 'common/utils';
import { IClub } from 'common/interfaces/clients';
import { IPaginatedData } from 'common/interfaces/pagination';
import { IPaymentMethodCustomResponse } from 'common/interfaces/invoices';

// interface for clubs list in add/edit element
type IClubsList = Array<
  IReceiptItemClubsFormData | IInventoryItemClubsFormData | IPaymentMethodCustomItemClubsFormData
>;

export default class PosSettingsService extends ApiService {
  private transformClubDto = (
    clubDto: IInventoryItemClubsFormData,
  ): Partial<IInventoryItemClubsFormData> => {
    const newDto: Partial<IInventoryItemClubsFormData> = {};
    Object.keys(clubDto).forEach(item => {
      if (item === InventoryItemClubsChangingFields.VENDOR) {
        if (clubDto[item]) {
          newDto[InventoryItemClubsChangingFields.VENDOR_ID] = clubDto[item].id;
        } else {
          newDto[InventoryItemClubsChangingFields.VENDOR] = null;
          newDto[InventoryItemClubsChangingFields.VENDOR_ID] = null;
        }
      } else if (item === InventoryItemClubsChangingFields.REVENUE_CODE) {
        newDto[InventoryItemClubsChangingFields.REVENUE_CODE_ID] = clubDto[item]?.id;
      } else {
        newDto[item] = clubDto[item];
      }
    });

    return newDto;
  };

  // is used as an internal storage of clubs on the page of adding or editing an item, with the
  // ability to paginate, add, delete and edit the item
  private clubsList: IClubsList = [];

  // pagination for add/edit item clubs list
  private getPaginate = async (
    dataArray: IClubsList,
    requestOptions?: ITableParams,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    const page = requestOptions?.page || 0;
    const perPage = requestOptions?.page || 10;

    const totals = {};

    const data = dataArray.slice(
      Number(page) * Number(perPage),
      Number(page) * Number(perPage) + Number(perPage),
    );
    return {
      data,
      totals,
      meta: {
        page: Number(page),
        perPage: Number(perPage),
        total: dataArray.length,
      },
    };
  };

  // create club for add/edit item clubs list
  public createClubs = async (request: IClubsList): Promise<ISuccessResponse> => {
    this.clubsList = [...request, ...this.clubsList];
    return { success: true };
  };

  // update club for add/edit item clubs list
  public updateClubs = async (request: IInventoryItemClubsEdit): Promise<ISuccessResponse> => {
    this.clubsList = this.clubsList.map(club => {
      if (request.clubIds?.find(id => id.toString() === club.club?.id.toString())) {
        return { ...club, ...request.inventoryClubDto };
      }
      return club;
    });
    return { success: true };
  };

  // TODO refactor this code - works not for all instances for ex - custom pay methods
  // delete clubfor add/edit item clubs list
  public deleteClubs = async (request: Array<string | number>): Promise<ISuccessResponse> => {
    this.clubsList = this.clubsList.filter(
      club => request.indexOf(String(club?.club?.id || club?.id)) === -1,
    );
    return { success: true };
  };

  // clear data clubs list
  public resetClubs = (): void => {
    this.clubsList = [];
  };

  // TODO refactor this code - works not for all instances for ex - custom pay methods
  // get selected clubs from clubs list
  public getSelected = async (
    request: Array<string | number>,
    isAllSelected?: boolean,
  ): Promise<IClubsList> => {
    return this.clubsList.filter(club => {
      return isAllSelected
        ? request.indexOf(String(club?.club?.id || club?.id)) === -1
        : request.indexOf(String(club?.club?.id || club?.id)) !== -1;
    });
  };

  // REGISTER

  public getRegistersList = (
    tableParams?: ITableParams,
  ): Promise<IPaginatedCommon<IRegisterListItem>> => {
    const requestOptions = this.getSearchFromTableParams(tableParams);

    return axios.get('/api/v1/register', { params: requestOptions });
  };

  public changeRegisterStatus = (
    registerId: string,
    isActive: boolean,
  ): Promise<IRegisterListItem> => {
    return axios.patch(`/api/v1/register/${registerId}/change-status`, { active: isActive });
  };

  public getRegisterById = (registerId: string): Promise<IRegisterDetailed> =>
    axios.get(`/api/v1/register/${registerId}`);

  public updateRegister = (
    registerId: string,
    updatedRegister: IRegisterDetailed,
  ): Promise<IRegisterDetailed> => axios.put(`/api/v1/register/${registerId}`, updatedRegister);

  public createRegister = (request: IRegisterDetailed): Promise<IRegisterDetailed> =>
    axios.post('/api/v1/register', request);

  public getRegisterItemByNameOrCode = (
    clubId: string,
    searchObject: { name?: string; revenueCodeIds?: string[] },
  ): Promise<IPaginatedData<ISimpleClubInventoryItem>> => {
    return axios.get(`/api/v1/clubs/${clubId}/inventory/simple`, {
      params: { name: searchObject?.name, revenueCodeIds: searchObject?.revenueCodeIds?.join(',') },
    });
  };

  public getRevCodeByName = (
    clubId: string,
    search?: string,
    availableCodesIds?: string[],
  ): Promise<Array<IRevenueCodeItemCountListItem>> =>
    axios.get(`/api/v1/clubs/${clubId}/revenue-code/simple`, {
      params: { search, revenueCodeIds: availableCodesIds?.join(',') },
    });

  // INVENTORY

  public getInventoryItems = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedCommon<IInventoryItemsPosSettingsImt>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/inventory', { params: searchParams });
  };

  public changeStatusInventoryItems = (
    inventoryItemId: string,
    isActive: boolean,
  ): Promise<ISuccessResponse> => {
    return axios.patch(`/api/v1/inventory/${inventoryItemId}/change-status`, { active: isActive });
  };

  public getInventoryById = (inventoryId: string): Promise<IInventoryDto> => {
    return axios.get(`/api/v1/inventory/${inventoryId}`);
  };

  public getInventoryItemById = async (
    inventoryItemId: string,
    requestOptions?: ITableParams,
  ): Promise<{
    values: Omit<IInventoryDto, 'inventoryClubList'> & {
      inventoryClubList?: Array<IInventoryItemClubsFormData>;
    };
    data: IPaginatedCommon<IInventoryItemClubsFormDataImt>;
  }> => {
    const response: Omit<IInventoryDto, 'inventoryClubList'> & {
      inventoryClubList?: Array<IInventoryItemClubsFormData>;
    } = await this.getInventoryById(inventoryItemId);
    this.clubsList = [...(response.inventoryClubList || [])];
    const paginateClubs = await this.getPaginate(this.clubsList, requestOptions);
    delete response.inventoryClubList;

    return {
      values: response,
      data: { ...paginateClubs, unavailable: this.clubsList.map(item => item.club?.id) },
    };
  };

  public updateInventoryItemById = (
    registerId: string,
    dataDto: IInventoryDto,
  ): Promise<ISuccessResponse> => {
    const data = {
      ...dataDto,
      inventoryClubList: this.clubsList.map(club => this.transformClubDto(club)),
    };
    return axios.put(`/api/v1/inventory/${registerId}`, data);
  };

  public createInventoryItemById = (dataDto: IInventoryDto): Promise<ISuccessResponse> => {
    // const json = JSON.stringify({
    //   ...dataDto,
    //   inventoryClubList: this.clubsList.map(club=> this.transformClubDto(clubSettings)),
    // });
    // const blob = new Blob([json], {
    //   type: 'application/json',
    // });
    // const data = new FormData();
    // data.append('dto', blob);
    // // if (dataFile) {
    // //   const blobFile = new Blob([dataFile], {
    // //     type: 'multipart/form-data',
    // //   });
    // //   data.append('file', blobFile);
    // // }

    // return axios.post(`/api/v1/inventory`, data, {
    //   headers: { 'Content-Type': 'multipart/form-data' },
    // });
    const data = {
      ...dataDto,
      inventoryClubList: this.clubsList.map(club => this.transformClubDto(club)),
    };
    return axios.post('/api/v1/inventory/', data);
  };

  public getInventoryItemByIdPagination = async (
    requestOptions: ITableParams,
  ): Promise<IPaginatedCommon<IInventoryItemClubsFormDataImt>> => {
    const data = await this.getPaginate(this.clubsList, requestOptions);
    return { ...data, unavailable: this.clubsList.map(item => item.club?.id) };
  };

  public getInventoryItemByIdConfigurationClubs = (
    inventoryId: string,
    requestOptions?: ITableParams,
  ): Promise<IPaginatedCommon<IInventoryItemClubsFormDataImt>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/inventory/${inventoryId}/configuration`, { params: searchParams });
  };

  public getInventoryItemByIdConfiguration = (
    inventoryItemId: string,
  ): Promise<IInventoryViewDto> => {
    return axios.get(`/api/v1/inventory/${inventoryItemId}/view`);
  };

  public getInventoryItemByIdConfigurationClubsMultiEdit = (
    inventoryId: string,
    selectedTds: Array<string | number>,
    isAllSelected: boolean,
    tableParamsSelected: ITableMultiSelectParams,
  ): Promise<IPaginatedCommon<IInventoryItemClubsFormDataImt>> => {
    const searchParams = this.getSearchFromTableMultiSelectParams(
      selectedTds,
      isAllSelected,
      tableParamsSelected,
    );

    return axios.get(`/api/v1/inventory/${inventoryId}/configuration/selected`, {
      params: searchParams,
    });
  };

  public updateInventoryItemsConfigurationClubs = (
    inventoryItemId: string,
    request: IInventoryItemClubsEdit,
  ): Promise<ISuccessResponse> => {
    const newDto = this.transformClubDto(request.inventoryClubDto);

    const config = {
      params: {
        changedFields: Object.keys(newDto)
          .filter(key => key !== 'club' && key !== 'vendor')
          .map(value => toSnakeCase(value, true))
          .join(','),
      },
    };

    return axios.put(
      `/api/v1/inventory/${inventoryItemId}/configuration`,
      { ...request, inventoryClubDto: newDto },
      config,
    );
  };

  public createInventoryItemsConfigurationClubs = (
    inventoryItemId: string,
    request: { cubIds: string[]; inventoryClubDto: IInventoryItemClubsFormData },
  ): Promise<ISuccessResponse> => {
    const newDto = {
      ...request,
      inventoryClubDto: this.transformClubDto(request.inventoryClubDto),
    };
    return axios.post(`/api/v1/inventory/${inventoryItemId}/configuration`, newDto);
  };

  public deleteInventoryItemsConfigurationClubs = (
    inventoryItemId: string,
    request: Array<string | number>,
  ): Promise<ISuccessResponse> => {
    return axios.delete(`/api/v1/inventory/${inventoryItemId}/configuration`, {
      params: { ids: request.join(',') },
    });
  };

  // RECEIPTS

  public getReceipts = (
    requestOptions: ITableParams,
  ): Promise<IPaginatedCommon<IReceiptListItem>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/receipt', { params: searchParams });
  };

  public changeReceiptStatus = (receiptId: string, isActive: boolean): Promise<IReceiptListItem> =>
    axios.patch(`/api/v1/receipt/${receiptId}/change-status`, { active: isActive });

  public getReceiptDetailed = (receiptId: string): Promise<IReceiptDetailed> =>
    axios.get(`/api/v1/receipt/${receiptId}`);

  public createReceipt = (receipt: IReceiptDetailed): Promise<IReceiptDetailed> =>
    axios.post(`/api/v1/receipt`, receipt);

  public updateReceipt = (
    receiptId: string,
    updatedReceipt: IReceiptDetailed,
  ): Promise<IReceiptDetailed> => axios.put(`/api/v1/receipt/${receiptId}`, updatedReceipt);

  public getSimpleReceipt = (receiptId: string): Promise<INamedEntity> =>
    axios.get(`/api/v1/receipt/${receiptId}/simple`);

  public getReceiptClubs = (
    receiptId: string,
    tableParams: ITableParams,
  ): Promise<IPaginatedCommon<IClub>> => {
    const searchQueryParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/receipt/${receiptId}/configuration`, { params: searchQueryParams });
  };

  public addReceiptClubs = (receiptId: string, clubIds: Array<string>): Promise<any> =>
    axios.post(`/api/v1/receipt/${receiptId}/configuration`, { clubIds });

  public deleteReceiptClubs = (
    receiptId: string,
    isAllSelected: boolean,
    selectedIds: string[],
    excludedIds: string[],
  ) =>
    axios.delete(`/api/v1/receipt/${receiptId}/configuration`, {
      data: {
        allSelected: isAllSelected,
        excludedIds,
        includedIds: selectedIds,
      },
    });

  // PAYMENT METHODS

  public getPaymentMethodsDefault = (
    requestOptions?: ITableParams,
  ): Promise<ImmutableList<IPaymentMethodsPosSettingsImt>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/paymentMethod/default', {
      params: searchParams,
    });
  };

  public getPaymentMethodsDefaultByClubId = (
    clubId: string,
    requestOptions?: ITableParams,
  ): Promise<ImmutableList<IPaymentMethodsPosSettingsImt>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/paymentMethod/default', {
      params: { ...searchParams, clubId, active: true },
    });
  };

  public editPaymentMethod = (
    request: IPaymentMethodItem,
    paymentMethodId: string,
  ): Promise<ISuccessResponse> => {
    return axios.put(`/api/v1/paymentMethod/${paymentMethodId}`, { ...request });
  };

  public changeStatusPaymentMethod = (
    paymentMethodId: string,
    isActive: boolean,
  ): Promise<IPaymentMethodItem> => {
    return axios.patch(`/api/v1/paymentMethod/${paymentMethodId}/change-status`, {
      active: isActive,
    });
  };

  public getPaymentMethodsCustom = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedCommon<IPaymentMethodsPosSettingsImt>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/paymentMethod/custom', { params: searchParams });
  };

  public createCustomPaymentMethod = (request: IPaymentMethodItem): Promise<ISuccessResponse> => {
    return axios.post('/api/v1/paymentMethod/custom', { ...request });
  };

  public getPaymentMethodCustomByBarcode = (
    barcode: string,
    registerId: string,
  ): Promise<IPaymentMethodCustomResponse> => {
    return axios.get('/api/v1/paymentMethod/custom/instance', {
      params: { barcode, registerId },
    });
  };

  public archivePaymentMethodsItemCustom = (id: string): Promise<ISuccessResponse> => {
    return axios.put(`/api/v1/paymentMethod/custom/${id}/archive`);
  };

  public getPaymentMethodCustom = (
    id: string,
    requestOptions?: ITableParams,
  ): Promise<IPaginatedCommon<IPaymentMethodCustomItemPosSettingsImt>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/paymentMethod/custom/${id}/instance`, { params: searchParams });
  };

  public getPaymentMethodCustomTitle = (id: string): Promise<{ title: string }> => {
    return axios.get(`/api/v1/paymentMethod/custom/${id}/simple`);
  };

  public blockPaymentMethodCustomItems = (
    paymentMethodId: string,
    request: Array<string>,
  ): Promise<ISuccessResponse> => {
    return axios.put(`/api/v1/paymentMethod/custom/${paymentMethodId}/instance`, { ids: request });
  };

  public getPaymentMethodCustomItemMultiEdit = (
    paymentMethodId: string,
    selectedTds: Array<string | number>,
    isAllSelected: boolean,
    tableParamsSelected: ITableMultiSelectParams,
  ): Promise<IPaginatedCommon<IPaymentMethodCustomItemPosSettingsImt>> => {
    const searchParams = this.getSearchFromTableMultiSelectParams(
      selectedTds,
      isAllSelected,
      tableParamsSelected,
    );

    return axios.get(`/api/v1/paymentMethod/custom/${paymentMethodId}/instance/selected`, {
      params: searchParams,
    });
  };

  public createPaymentMethodCustomInstance = (
    paymentMethodId: string,
    request: IPaymentMethodCustomInstanceFormData,
  ): Promise<ISuccessResponse> => {
    return axios.post(`/api/v1/paymentMethod/custom/${paymentMethodId}/instance`, request);
  };

  public getPaymentMethodCustomItemPagination = async (
    requestOptions: ITableParams,
  ): Promise<IPaginatedCommon<INamedEntityImt>> => {
    const data = await this.getPaginate(this.clubsList, requestOptions);
    return { ...data, unavailable: this.clubsList.map((item: INamedEntity) => item.id) };
  };

  public getPaymentMethodCustomItem = (
    paymentMethodId: string,
    itemId: string,
  ): Promise<IPaymentMethodCustomItem> => {
    return axios.get(`/api/v1/paymentMethod/custom/${paymentMethodId}/instance/${itemId}/simple`);
  };

  public getPaymentMethodCustomItemConfigurationClubs = (
    paymentMethodId: string,
    itemId: string,
    requestOptions?: ITableParams,
  ): Promise<IPaginatedCommon<INamedEntityImt>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(
      `/api/v1/paymentMethod/custom/${paymentMethodId}/instance/${itemId}/configuration`,
      {
        params: searchParams,
      },
    );
  };

  public createPaymentMethodCustomItemConfigurationClubs = (
    paymentMethodId: string,
    itemId: string,
    request: IClubIds,
  ): Promise<ISuccessResponse> => {
    return axios.post(
      `/api/v1/paymentMethod/custom/${paymentMethodId}/instance/${itemId}/configuration`,
      request,
    );
  };

  public deletePaymentMethodCustomItemConfigurationClubs = (
    paymentMethodId: string,
    itemId: string,
    request: string[],
  ): Promise<ISuccessResponse> =>
    axios.delete(
      `/api/v1/paymentMethod/custom/${paymentMethodId}/instance/${itemId}/configuration`,
      { params: { clubIds: request.join(',') } },
    );

  public getPaymentMethodCustomItemConfigurationClubsMultiEdit = (
    paymentMethodId: string,
    itemId: string,
    selectedTds: Array<string | number>,
    isAllSelected: boolean,
    tableParamsSelected: ITableMultiSelectParams,
  ): Promise<IPaginatedCommon<IPaymentMethodCustomItemClubsFormData>> => {
    const searchParams = this.getSearchFromTableMultiSelectParams(
      selectedTds,
      isAllSelected,
      tableParamsSelected,
    );

    return axios.get(
      `/api/v1/paymentMethod/custom/${paymentMethodId}/instance/${itemId}/configuration/selected`,
      {
        params: searchParams,
      },
    );
  };

  // REVENUE CODES

  public getRevenueCodes = (requestOptions: ITableParams): Promise<IRevenueCodeListItem[]> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/revenue-code', { params: searchParams });
  };

  public changeRevenueCodeStatus = (id: string, isActive: boolean): Promise<ISuccessResponse> => {
    return axios.patch(`/api/v1/revenue-code/${id}/change-status`, { active: isActive });
  };

  public getRevenueCodeDetails = (id: string): Promise<IRevenueCodeDetails> => {
    return axios.get(`/api/v1/revenue-code/${id}`);
  };

  public createRevenueCode = (rcodeDetails: IRevenueCodeDetails): Promise<ISuccessResponse> => {
    return axios.post(`/api/v1/revenue-code`, rcodeDetails);
  };

  public updateRevenueCode = (
    id: string,
    rcodeDetails: IRevenueCodeDetails,
  ): Promise<ISuccessResponse> => {
    return axios.put(`/api/v1/revenue-code/${id}`, rcodeDetails);
  };

  // Revenue code clubs configuration

  public fetchSimpleRevenueCode = (id: string): Promise<INamedEntity> => {
    return axios.get(`/api/v1/revenue-code/${id}/simple`);
  };

  public fetchRevenueCodeClubs = (
    revenueCodeId: string,
    requestOptions: ITableParams,
  ): Promise<IRevenueCodeClub[]> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`/api/v1/revenue-code/${revenueCodeId}/club-overridden`, {
      params: searchParams,
    });
  };

  public addRevenueCodeClubsConfigurations = (
    revenueCodeId: string,
    data: IRevenueCodeClub[],
  ): Promise<ISuccessResponse> => {
    return axios.post(`/api/v1/revenue-code/${revenueCodeId}/club-overridden`, {
      childRevenueCode: data,
    });
  };

  public editRevenueCodeClubsConfigurations = (
    revenueCodeId: string,
    data: IRevenueCodeClub[],
  ): Promise<ISuccessResponse> => {
    const modifiedData = {
      includedIds: data.map(el => el.club?.id),
      taxes: data[0].taxRateDtoList,
    };
    return axios.put(`/api/v1/revenue-code/${revenueCodeId}/club-overridden`, modifiedData);
  };

  public removeRevenueCodeClubsConfigurations = (
    revenueCodeId: string,
    clubFilters: string[],
  ): Promise<ISuccessResponse> => {
    return axios.delete(`/api/v1/revenue-code/${revenueCodeId}/club-overridden`, {
      params: { clubIdList: clubFilters.join(',') },
    });
  };

  // TAX RATES

  public getTaxRates = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedCommon<ITaxRatesListItemImt>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/taxes', { params: searchParams });
  };

  public changeStatusTaxRate = (
    taxRateId: string,
    isActive: boolean,
  ): Promise<ISuccessResponse> => {
    return axios.patch(`/api/v1/taxes/${taxRateId}/change-status`, { active: isActive });
  };

  public getTaxRateById = (taxRateId: string): Promise<ITaxRateDetailedItem> => {
    return axios.get(`/api/v1/taxes/${taxRateId}`);
  };

  public updateTaxRate = (
    taxRateId: string,
    updatedData: ITaxRateDetailedItem,
  ): Promise<ISuccessResponse> => axios.put(`/api/v1/taxes/${taxRateId}`, updatedData);

  public createTaxRate = (formData: ITaxRateDetailedItem): Promise<ISuccessResponse> =>
    axios.post(`/api/v1/taxes`, formData);

  // VENDORS

  // Vendors List

  public getVendors = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedCommon<IVendorsListItemImt>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/vendor', { params: searchParams });
  };

  public changeVendorStatus = (id: string, isActive: boolean): Promise<IVendorsListItem> => {
    return axios.patch(`/api/v1/vendor/${id}/change-status`, { active: isActive });
  };

  // Vendor item
  public getVendorById = (vendorId: string): Promise<IVendorDetailed> =>
    axios.get(`/api/v1/vendor/${vendorId}`);

  public updateVendor = (
    vendorId: string,
    vendorDetailed: IVendorDetailed,
  ): Promise<IVendorDetailed> => axios.put(`/api/v1/vendor/${vendorId}`, vendorDetailed);

  public createVendor = (vendorDetailed: IVendorDetailed): Promise<IVendorDetailed> =>
    axios.post('/api/v1/vendor', vendorDetailed);
}
