import React, { FC, Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import messages from 'modules/crm/messages/leads';
import { ScrollBox } from 'common/components/index';
import { IContactInfo, IPersonDocumentItemDto } from 'common/interfaces/contract';
import ContractItem from './ContractItem';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    flex: 1,
    height: '100%',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  subTitle: {
    marginBottom: theme.spacing(1.5),
    display: 'inline-block',
  },
}));

interface ISignDocumentsStep {
  contracts: Partial<IPersonDocumentItemDto>[];
  fetchApplySignature: (file: File, documentId: string) => void;
  contactInfo: Partial<IContactInfo>;
  signedDocumentData: { [key: string]: boolean };
  setSignedDocumentData: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  onPrint: (contractId: string) => void;
}

const SignDocumentsStep: FC<ISignDocumentsStep> = ({
  contracts,
  fetchApplySignature,
  contactInfo,
  signedDocumentData,
  setSignedDocumentData,
  onPrint,
}) => {
  const classes = useStyles();

  return (
    <>
      <ScrollBox hasShadowsOnScroll>
        <Box className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {contracts.length ? (
                contracts.map(contract => {
                  return (
                    <ContractItem
                      key={contract.documentId}
                      fetchApplySignature={fetchApplySignature}
                      onPrint={onPrint}
                      contract={contract}
                      contactInfo={contactInfo}
                      setSignedDocumentData={setSignedDocumentData}
                      isSignedDocument={Boolean(signedDocumentData[contract.documentId])}
                    />
                  );
                })
              ) : (
                <Typography color="textSecondary">
                  <FormattedMessage {...messages.newLeadSignContractsStepNoContracts} />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </ScrollBox>
    </>
  );
};

export default SignDocumentsStep;
