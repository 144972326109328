// libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useLocation, useNavigate } from 'react-router-dom';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';
// interfaces
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import { IDocumentTemplateResponseItemImt } from 'modules/services/interfaces/documentTemplates';
// constants
import {
  ActionResult,
  ActiveInactiveStatus,
  QueryPageList,
  TableOrderByParams,
} from 'common/constants';
import { routes } from 'modules/services/constants/routes';
import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';
// hooks
import { useAppDispatch } from 'store/hooks';
// components
import {
  ActionItem,
  ActionsMenu,
  ModuleSidebar,
  ScrollBox,
  StatusLabel,
  Table,
} from 'common/components';
import { Box, makeStyles } from '@material-ui/core';
import { ServicesPageWrapper } from 'modules/services/components';
import ChangeActiveStatusModal from 'common/modals/ChangeActiveStatusModal/ChangeActiveStatusModal';
// icons
import { ReactComponent as EditIcon } from 'img/icons/edit.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import { ReactComponent as XDeleteIcon } from 'img/icons/times.svg';
// utils
import { updateQueryParams } from 'common/state/queryPage-lists/actions';
import { makeTableParams, pushQueryToUrl } from 'common/utils/http';
// redux
import {
  selectDocumentTemplatesList,
  selectDocumentTemplatesListMeta,
  selectDocumentTemplatesLoading,
  selectUpdateDocumentStatusActionResult,
  selectUpdateDocumentStatusIsLoading,
} from 'modules/services/state/documentTemplates/selectors';
import {
  fetchDocumentTemplates,
  resetDocumentTemplatesReset,
  updateDocumentStatusActionResult,
  updateDocumentTemplateStatus,
} from 'modules/services/state/documentTemplates/actions';
// messages
import tableHeaders from 'common/messages/tableHeaders';
import menuItems from 'common/messages/menuItems';
import messages from 'modules/services/messages/messages';
import tableFiltersMessages from 'common/messages/tableFilters';

const headerOptions: IHeadCell[] = [
  {
    id: 'title',
    label: <FormattedMessage {...tableHeaders.name} />,
    sort: true,
    padding: 'none',
  },
  { id: 'status', label: <FormattedMessage {...tableHeaders.status} />, sort: true },
  {
    id: 'actions',
    label: <FormattedMessage {...tableHeaders.actions} />,
    sort: false,
    padding: 'none',
    align: 'center',
  },
];

const tableFilters: IFilterSettings[] = [
  {
    name: 'active',
    title: <FormattedMessage {...tableFiltersMessages.status} />,
    type: FilterTypes.SINGLE,
    defaultValue: ActiveInactiveStatus[0],
    options: ActiveInactiveStatus.map(status => ({
      key: status.key,
      label: status.label,
      value: status.value,
    })),
  },
];

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

const DocumentTemplates = (): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const documentTemplates: ImmutableList<IDocumentTemplateResponseItemImt> = useRootSelector(
    selectDocumentTemplatesList,
  );
  const documentTemplatesListMeta: IPageMetaImt = useRootSelector(selectDocumentTemplatesListMeta);
  const isDocumentTemplatesLoading: boolean = useRootSelector(selectDocumentTemplatesLoading);
  const isLoadingUpdateStatus: boolean = useRootSelector(selectUpdateDocumentStatusIsLoading);
  const documentStatusActionResult: ActionResult = useRootSelector(
    selectUpdateDocumentStatusActionResult,
  );

  const location = useLocation();
  const navigate = useNavigate();

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilters, location.search, {
      orderBy: TableOrderByParams.TITLE,
    }),
  );
  const [documentTemplate, setDocumentTemplate] = useState<IDocumentTemplateResponseItemImt>(null);

  const isContractTemplatesRoute = location.pathname.includes('/contract');

  const templatePurpose: TemplatePurpose = isContractTemplatesRoute
    ? TemplatePurpose.ServiceContractTemplate
    : TemplatePurpose.ServiceWaiverTemplate;

  const classes = useStyles();

  // lifecycle

  useEffect(() => {
    dispatch(fetchDocumentTemplates(TemplateTypes.Document, templatePurpose, tableParams));
  }, [dispatch, tableParams, templatePurpose]);

  useEffect(() => {
    if (documentStatusActionResult === ActionResult.SUCCESS_ACTION && documentTemplate) {
      setDocumentTemplate(null);
      dispatch(updateDocumentStatusActionResult(null));
    }
  }, [dispatch, documentStatusActionResult, documentTemplate]);

  useEffect(() => {
    return () => {
      dispatch(resetDocumentTemplatesReset());
    };
  }, [dispatch]);

  // handlers

  const updateQueryFunction = useCallback(
    query => dispatch(updateQueryParams({ page: QueryPageList.SERVICE_DOCUMENT_TEMPLATES, query })),
    [dispatch],
  );

  const handleChangeTableProps = useCallback(
    (tableProps: ITableParams): void => {
      setTableParams(tableProps);
      pushQueryToUrl(navigate, tableProps, updateQueryFunction);
    },
    [navigate, updateQueryFunction],
  );

  const onCloseModal = useCallback(() => {
    setDocumentTemplate(null);
  }, []);

  const applyChangedStatus = useCallback(() => {
    dispatch(
      updateDocumentTemplateStatus({
        id: documentTemplate.get('id'),
        active: !documentTemplate.get('active'),
      }),
    );
  }, [dispatch, documentTemplate]);

  // rows

  const tableRows: ITableRow[] = useMemo(
    () =>
      documentTemplates
        .map(
          (template: IDocumentTemplateResponseItemImt): ITableRow => ({
            id: template.get('id'),
            cells: [
              {
                label: template.get('title'),
                width: '50%',
                variant: 'h5',
              },
              {
                label: 'status',
                width: '50%',
                cellComponent: <StatusLabel isActive={template.get('active')} />,
              },
              {
                label: 'actions',
                padding: 'none',
                align: 'center',
                width: '54px',
                cellComponent: (
                  <ActionsMenu horizontal tableActionsMode>
                    <ActionItem
                      key="1"
                      icon={<EditIcon className={classes.icon} />}
                      onClick={() => {
                        navigate(`${location.pathname}/${template.get('id')}`);
                      }}
                    >
                      <FormattedMessage {...menuItems.edit} />
                    </ActionItem>
                    <ActionItem
                      key={`delete-${template.get('id')}-package`}
                      icon={
                        template.get('active') ? (
                          <XDeleteIcon className={classes.icon} />
                        ) : (
                          <CheckIcon className={classes.icon} />
                        )
                      }
                      onClick={() => setDocumentTemplate(template)}
                    >
                      <FormattedMessage
                        {...(template.get('active') ? menuItems.deactivate : menuItems.activate)}
                      />
                    </ActionItem>
                  </ActionsMenu>
                ),
              },
            ],
          }),
        )
        .toJS(),
    [documentTemplates, location.pathname, navigate, classes.icon],
  );

  return (
    <>
      <ServicesPageWrapper backRedirectLink="/services" titleMessage={messages.documentTemplates}>
        <Box display="flex">
          <Box width="190px" paddingRight="16px">
            <ModuleSidebar routes={routes} />
          </Box>
          <ScrollBox suppressScrollY>
            <Box minWidth="500px" height="100%" overflow="hidden">
              <Table
                addButtonRedirect="new"
                suppressSmartBehavior
                suppressFiltersMobilePadding
                showPerPageSelect
                filters={tableFilters}
                headerOptions={headerOptions}
                isLoading={isDocumentTemplatesLoading}
                rows={tableRows}
                totalRows={documentTemplatesListMeta.get('total') || 0}
                onChangeParams={handleChangeTableProps}
                tableParams={tableParams}
              />
            </Box>
          </ScrollBox>
        </Box>
      </ServicesPageWrapper>

      {documentTemplate && (
        <ChangeActiveStatusModal
          isOpen
          isActive={documentTemplate.get('active')}
          isLoading={isLoadingUpdateStatus}
          title={documentTemplate.get('title')}
          onClose={onCloseModal}
          onSubmit={applyChangedStatus}
        />
      )}
    </>
  );
};

export default DocumentTemplates;
