import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/core/styles';

import {
  ChangedFilterName,
  FilterTypes,
  IDateRangeFilterValue,
  IFilter,
  IFilterSettings,
} from 'common/interfaces/filter';
import useWindowWidth from 'common/hooks/useWindowWidth';

import FilterList from './FilterList/FilterList';
import MoreFilters from './MoreFilters/MoreFilters';
import ClearButton from './ClearFilterButton/ClearFilterButton';
import useRootSelector from 'common/hooks/useRootSelector';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';

interface IProps {
  settings: IFilterSettings[];
  toolbarFilters?: IFilter[];
  onFiltersChange: (filters: IFilter[], changedFilterName?: ChangedFilterName) => void;

  hidePagination?: boolean;
}

interface IFiltersView {
  isHidingFilters: boolean;
  isExpandingFilters: boolean;
  screenFilters: string[];
  hiddenFilters: string[];
}

const useStyles = makeStyles({
  filtersWrapper: {
    flex: 1,
  },
  filtersContainer: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflowX: 'hidden',
    justifyContent: 'start',
    alignItems: 'center',
  },
});

const FiltersContainer = ({
  settings,
  onFiltersChange,
  hidePagination,
  toolbarFilters,
}: IProps): JSX.Element => {
  const classes = useStyles();
  const windowWidth = useWindowWidth(true);
  const selectedClubId = useRootSelector(selectUserSelectedClubId);

  // refs

  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef(null);

  // state

  const [filtersView, setFiltersView] = useState<IFiltersView>({
    isHidingFilters: false,
    isExpandingFilters: false,
    screenFilters: settings.map(({ name }) => name),
    hiddenFilters: [],
  });
  const [filtersValues, setFiltersValues] = useState<IFilter[]>(toolbarFilters || []);

  // constants

  const hiddenFilterNames: string[] = useMemo(
    () => settings.filter(setting => setting.hidden).map(setting => setting.name),
    [settings],
  );

  const filtersClearToDefault: IFilterSettings[] = useMemo(() => {
    return settings.filter(setting => setting.shouldClearToDefault);
  }, [settings]);

  const isClearButtonShown: boolean = useMemo(
    () =>
      !!filtersValues.filter(filter => {
        if (filter.type === FilterTypes.DATE_RANGE) {
          const filterValue = filtersClearToDefault.find(({ name }) => name === filter.name);
          const hasSameName = Boolean(filterValue);

          const value = filter.value as IDateRangeFilterValue;
          const { endDate, startDate } = value;
          const defaultValue = filterValue?.defaultValue as IDateRangeFilterValue;
          const defaultStartDate = defaultValue?.startDate;
          const defaultEndDate = defaultValue?.endDate;

          const isSameSelectedValueWithDefault =
            (isNull(startDate) &&
              isNull(endDate) &&
              isNull(defaultStartDate) &&
              isNull(defaultEndDate)) ||
            (moment(startDate).isSame(defaultStartDate, 'year') &&
              moment(endDate).isSame(defaultEndDate, 'day') &&
              moment(startDate).isSame(defaultStartDate, 'day'));

          if (hasSameName && isSameSelectedValueWithDefault) {
            return false;
          }
        }

        return !hiddenFilterNames.includes(filter.name);
      }).length,
    [filtersClearToDefault, filtersValues, hiddenFilterNames],
  );

  // handlers

  const handleClearFiltersClick = (): void => {
    let resultFilters = filtersValues.filter(filter => hiddenFilterNames.includes(filter.name));

    if (filtersClearToDefault.length) {
      resultFilters = resultFilters.concat(
        filtersValues
          .filter(filter => filtersClearToDefault.some(({ name }) => name === filter.name))
          .map(filter => {
            const currentFilter = filtersClearToDefault.find(({ name }) => name === filter.name);

            if (
              currentFilter &&
              currentFilter.type === FilterTypes.DATE_RANGE &&
              currentFilter.defaultValue?.startDate
            ) {
              return {
                ...filter,
                value: {
                  startDate: currentFilter.defaultValue.startDate,
                  endDate: currentFilter.defaultValue.endDate,
                  maxDate: currentFilter.defaultValue.maxDate,
                  minDate: currentFilter.defaultValue.minDate,
                },
              };
            }

            return filter;
          }),
      );
    }

    setFiltersValues(resultFilters);
    onFiltersChange(resultFilters, 'clearAll');
  };

  const handleFilterValuesChanged = useCallback(
    (updatedValues: IFilter[], changedFilterName: string) => {
      setFiltersValues(updatedValues);
      onFiltersChange(updatedValues, changedFilterName);
    },
    [onFiltersChange],
  );

  // effects
  // TODO check if filters includes filter by clubId or clubIds
  useEffect(() => {
    setFiltersValues(toolbarFilters || []);
  }, [toolbarFilters, selectedClubId]);

  // hiding and expanding filters effect
  useLayoutEffect(() => {
    if (wrapperRef.current && containerRef.current) {
      const { screenFilters, hiddenFilters, isExpandingFilters, isHidingFilters } = filtersView;
      const { offsetWidth: wrapperWidth } = wrapperRef.current;
      const { scrollWidth: containerWidth } = containerRef.current;

      const screenFiltersCopy = [...screenFilters];
      const hiddenFiltersCopy = [...hiddenFilters];

      if (containerWidth > wrapperWidth && screenFilters.length) {
        // if were expanding - remove the last expanded and finish expanding
        // else - start hiding
        const screenFilter = screenFiltersCopy.pop();

        if (screenFilter) {
          hiddenFiltersCopy.unshift(screenFilter);
        }

        setFiltersView({
          isExpandingFilters: false,
          isHidingFilters: !isExpandingFilters,
          screenFilters: screenFiltersCopy,
          hiddenFilters: hiddenFiltersCopy,
        });
      }

      if (containerWidth < wrapperWidth && hiddenFilters.length) {
        if (isHidingFilters) {
          // if were hiding => finish hiding
          setFiltersView({
            isExpandingFilters: false,
            isHidingFilters: false,
            screenFilters: screenFiltersCopy,
            hiddenFilters: hiddenFiltersCopy,
          });
          // TODO: Replace this mock value with a real calculation
        } else if (wrapperWidth - containerWidth > 200) {
          // is there is a space for expanding - start expanding
          const hiddenFilter = hiddenFiltersCopy.shift();

          if (hiddenFilter) {
            screenFiltersCopy.push(hiddenFilter);
          }

          setFiltersView({
            isExpandingFilters: true,
            isHidingFilters: false,
            screenFilters: screenFiltersCopy,
            hiddenFilters: hiddenFiltersCopy,
          });
        }
      }
    }
  }, [filtersValues, windowWidth, filtersView, hidePagination]);

  // renders

  return (
    <Box {...{ ref: wrapperRef }} className={classes.filtersWrapper}>
      <Grid container ref={containerRef} spacing={1} className={classes.filtersContainer}>
        <FilterList
          filters={filtersView.screenFilters}
          settings={settings}
          values={filtersValues}
          onFiltersChange={handleFilterValuesChanged}
        />

        {filtersView.hiddenFilters.length > 0 && (
          <MoreFilters
            title={filtersView.screenFilters.length === 0 ? 'FILTERS' : 'More...'}
            filters={filtersView.hiddenFilters}
            settings={settings}
            values={filtersValues}
            onFiltersChange={handleFilterValuesChanged}
          />
        )}

        {isClearButtonShown && (
          <Grid item>
            <ClearButton onClick={handleClearFiltersClick} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default React.memo(FiltersContainer);
