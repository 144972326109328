import { useCallback, useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useIntl } from 'react-intl';

import { selectPeakSettings } from 'common/state/settings/selectors';

import { selectIsUserLoggedIn } from 'modules/authentication/state/selectors';
import { selectIsMemberPortalUserLoggedIn } from 'memberPortalModules/authentication/state/selectors';

let isWidgetInitialized = false;

const handleLoaded = () => {
  isWidgetInitialized = true;
};

const useZendeskWidget = (): void => {
  const peakSettings = useRootSelector(selectPeakSettings);
  const isClientPortalUserLoggedIn: boolean = useRootSelector(selectIsUserLoggedIn);
  const isMemberPortalUserLoggedIn: boolean = useRootSelector(selectIsMemberPortalUserLoggedIn);

  const isUserLoggedIn = isClientPortalUserLoggedIn || isMemberPortalUserLoggedIn;

  const { locale } = useIntl();

  const { zE } = window as any;

  const isZendeskEnabled = peakSettings.get('zendeskWidgetEnabled');
  const zendeskWidgetKey = peakSettings.get('zendeskWidgetKey');

  const initZendeskWidget = useCallback((zendeskKey: string) => {
    const script = document.createElement('script');

    script.async = true;
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    document.body.appendChild(script);

    script.onload = handleLoaded;
  }, []);

  useEffect(() => {
    try {
      if (zendeskWidgetKey && isZendeskEnabled && !isWidgetInitialized && isUserLoggedIn) {
        initZendeskWidget(zendeskWidgetKey);
      }

      if (zendeskWidgetKey && isZendeskEnabled && isWidgetInitialized && isUserLoggedIn) {
        zE('messenger', 'show');
      }
    } catch (e) {
      delete (window as any).zE;
      // eslint-disable-next-line no-console
      console.log('Zendesk not initialized');
    }
  }, [initZendeskWidget, isUserLoggedIn, isZendeskEnabled, zE, zendeskWidgetKey]);

  useEffect(() => {
    try {
      if (zE) {
        zE('messenger:set', 'locale', locale);
      }

      if (zE && !isUserLoggedIn) {
        zE('messenger', 'hide');
        zE('messenger', 'logoutUser');
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [isUserLoggedIn, isZendeskEnabled, locale, zE]);

  useEffect(() => {
    try {
      if (zE && !isZendeskEnabled) {
        zE('messenger', 'hide');
        zE('messenger', 'logoutUser');
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [isZendeskEnabled, zE]);
};

export default useZendeskWidget;
