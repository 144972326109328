import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControlLabel, Switch, createStyles, makeStyles, Theme } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { INote, INoteCodeRequestPayload } from 'common/components/PersonProfile/interfaces';
import { INoteCodeDictionaryItemImt } from 'common/interfaces/dictionary';

import { requiredMessage } from 'common/constants/globalConstants';
import notes from 'common/components/PersonProfile/components/Notes/messages/notes';

import { DialogComponent, TextArea } from 'common/components';

import commonMessages from 'common/messages/messages';
import NoteCodeSelector from 'common/components/NoteCodeSelector/NoteCodeSelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(1.5, 0, -1, 0),
      '& span': {
        fontWeight: 500,
      },
    },
  }),
);

interface INoteFormValues {
  text: string;
  noteCode: any;
  alertAtNextCheckIn: boolean;
}

export interface IEditNoteModalProps {
  editNote: INote;
  isNoteModalOpen: boolean;
  isAlertResolve?: boolean;
  onCloseModal?: () => void;
  loading: boolean;
  noteCodesIsLoading: boolean;
  customerId: number;
  noteCodes: List<INoteCodeDictionaryItemImt>;
  clearSearchResults: () => void;
  searchNoteCodes: (search: string, customerId: number) => void;
  onSubmit: (values: INoteCodeRequestPayload) => void;
}

const validationSchema = yup.object().shape({
  text: yup
    .string()
    .nullable()
    .required(requiredMessage),
  noteCode: yup
    .object()
    .nullable()
    .required(requiredMessage),
});

const initialValues = {
  text: '',
  alertAtNextCheckIn: false,
  noteCode: null,
};

const NOTE_MODAL_FORM = 'note-modal-form';

export default function NoteModal(props: IEditNoteModalProps): JSX.Element {
  const {
    isNoteModalOpen,
    onCloseModal,
    loading,
    editNote,
    searchNoteCodes,
    clearSearchResults,
    customerId,
    noteCodes,
    noteCodesIsLoading,
    onSubmit,
    isAlertResolve,
  } = props;

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const formMethods = useForm<INoteFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
  });

  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
  } = formMethods;

  useEffect(() => {
    if (editNote) reset({ ...initialValues, ...editNote });
  }, [editNote, reset]);

  const onCloseModalHandler = () => {
    onCloseModal();
  };

  const onSubmitNoteModal = (values: INoteFormValues) => {
    const { noteCode, ...data } = values;

    onSubmit({ ...data, noteCodeId: noteCode.id, ...{ id: editNote?.id } });
  };

  const existOptions = editNote?.noteCode ? [editNote.noteCode] : [];

  return (
    <DialogComponent
      size="xs"
      formId={NOTE_MODAL_FORM}
      title={<FormattedMessage {...(editNote ? notes.editNote : notes.newNote)} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.saveBtn} />}
      isOpen={isNoteModalOpen}
      loading={loading}
      onClose={onCloseModalHandler}
      onSubmit={handleSubmit(onSubmitNoteModal)}
    >
      <form id={NOTE_MODAL_FORM}>
        <Box mb={2}>
          <Controller
            control={control}
            name="text"
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextArea
                value={value}
                onChange={onChange}
                maxSymbols={1000}
                label={renderIntlMessage(notes.note)}
                variant="outlined"
                fullWidth
                minRows={8}
                error={!!errors.text?.message}
                helperText={renderIntlMessage(errors.text?.message)}
              />
            )}
          />
        </Box>

        <Controller
          control={control}
          name="noteCode"
          render={({ field: { value, onChange } }) => {
            return (
              <NoteCodeSelector
                searchNoteCodes={searchNoteCodes}
                profileId={customerId}
                onChange={v => onChange(v)}
                value={value}
                clearSearchResults={clearSearchResults}
                noteCodes={noteCodes?.size ? noteCodes.toJS() : existOptions}
                error={!!errors.noteCode}
                helperText={renderIntlMessage(errors.noteCode?.message)}
                isLoading={noteCodesIsLoading}
              />
            );
          }}
        />

        {!isAlertResolve && (
          <Controller
            name="alertAtNextCheckIn"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                label={renderIntlMessage(notes.alertAtNextCheckIn)}
                labelPlacement="start"
                className={classes.label}
                control={
                  <Switch
                    onChange={(e, checked) => {
                      onChange(checked);
                    }}
                    checked={value}
                    color="primary"
                  />
                }
              />
            )}
          />
        )}
      </form>
    </DialogComponent>
  );
}
