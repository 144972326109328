// libraries
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { batch } from 'react-redux';
// custom interfaces and components
import { DialogComponent, LoadingBackdrop } from 'common/components/index';
import ServiceDetailsView from './ServiceDetailsView';
// state
import * as selectors from 'common/components/PersonProfile/state/servicesModals/selectors';
import * as actions from 'common/components/PersonProfile/state/servicesModals/actions';
// hooks
import { useAppDispatch } from 'store/hooks';
// messages
import messages from 'common/messages/messages';
// constants
import { PeakModules } from 'common/constants/peakModules';
import { ServiceType } from 'common/constants/service';
import RedeemSubHeader from '../RedeemSubHeader/RedeemSubHeader';
import useRootSelector from 'common/hooks/useRootSelector';

export interface IServiceDetailsModalProps {
  profileId: number;
  module: PeakModules;
}

const ServiceDetailsModal: React.FC<IServiceDetailsModalProps> = ({
  profileId,
  module,
}: IServiceDetailsModalProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const isOpen = useRootSelector(selectors.selectIsDetailsShown);
  const selectedServiceInstanceId = useRootSelector(selectors.selectSelectedServiceItemId);
  const serviceInstanceDetails = useRootSelector(selectors.selectServiceItemDetails);
  const isLoading = useRootSelector(selectors.selectServiceItemDetailsLoading);
  const redeemHistory = useRootSelector(selectors.selectServiceRedeemRecentHistory);
  const isLoadingRedeemHistory = useRootSelector(selectors.selectServiceRedeemRecentHistoryLoading);

  // effects

  useEffect(() => {
    if (isOpen && selectedServiceInstanceId) {
      dispatch(actions.fetchServiceItemDetails(profileId, selectedServiceInstanceId, module));
      dispatch(
        actions.fetchServiceRedeemRecentHistory(profileId, selectedServiceInstanceId, module),
      );
    }

    return () => {
      dispatch(actions.resetServiceItemDetails());
    };
  }, [dispatch, module, profileId, selectedServiceInstanceId, isOpen]);

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(actions.selectServiceItemId(null));
        dispatch(actions.setIsDetailsShown(false));
        dispatch(actions.resetServiceItemDetails());
      });
    };
  }, [dispatch]);

  // handlers

  const handleOnClose = () => {
    batch(() => {
      dispatch(actions.selectServiceItemId(null));
      dispatch(actions.setIsDetailsShown(false));
      dispatch(actions.resetServiceItemDetails());
      dispatch(actions.resetServiceRedeemRecentHistory());
    });
  };

  const handleOnRedeemClick = () => {
    batch(() => {
      dispatch(actions.setIsDetailsShown(false));
      dispatch(actions.setIsRedeemShown(true));
    });
  };

  // constants

  const totalToRedeem = serviceInstanceDetails?.get('totalNumber');
  const availableNumber = serviceInstanceDetails?.get('leftNumber') ?? 0;
  const isDayCareService = serviceInstanceDetails?.get('type') === ServiceType.DayCare;

  // renders

  if (!isOpen) {
    return <></>;
  }

  const isCrmModule = module === PeakModules.Crm || module === PeakModules.PersonalTrainingCrm;

  const subHeader = !!serviceInstanceDetails && (
    <RedeemSubHeader
      limited={serviceInstanceDetails?.get('limited')}
      redeemType={serviceInstanceDetails?.get('redeemType')}
      totalToRedeem={totalToRedeem}
      availableToRedeem={availableNumber}
      handleOnRedeemClick={!isCrmModule && !isDayCareService ? handleOnRedeemClick : null}
      redeemDurationUnit={serviceInstanceDetails?.get('redeemDurationUnit')}
    />
  );

  return (
    <DialogComponent
      title={
        serviceInstanceDetails?.get('title') || <FormattedMessage {...messages.serviceLabel} />
      }
      subHeader={subHeader}
      isOpen={isOpen}
      loading={isLoading}
      cancelBtn={false}
      submitBtn={false}
      onClose={handleOnClose}
      disableFullScreenBehavior
      supressBottomShadow
      hasCustomFooter
    >
      {!!serviceInstanceDetails && (
        <ServiceDetailsView
          module={module}
          profileId={profileId}
          serviceInstance={serviceInstanceDetails}
          redeemHistory={redeemHistory}
        />
      )}
      <LoadingBackdrop isLoading={isLoading && isLoadingRedeemHistory} />
    </DialogComponent>
  );
};

export default React.memo(ServiceDetailsModal);
