import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { AlertCondition } from 'common/interfaces/alerts';
import { ICustomerEmergencyContactDto } from 'common/interfaces/additionalInfo';

import { getEmergencyContactValidationSchema } from 'common/validationSchemas/emergencyContactValidationSchema';

import { DialogComponent } from 'common/components';
import EmergencySection from 'common/components/Steps/AdditionalInfoStep/EmergencySection/EmergencySection';

import messages from 'common/components/Steps/AdditionalInfoStep/messages';
import useRootSelector from 'common/hooks/useRootSelector';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { useAppDispatch } from 'store/hooks';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';

interface IProps {
  alert: AlertCondition | null;
  isOpen: boolean;
  onSubmit: (info: ICustomerEmergencyContactDto, alertId: string) => void;
  switchingModal: (alertCondition: AlertCondition) => void;
}

const EmergencyContactModal = ({
  isOpen,
  switchingModal,
  onSubmit,
  alert,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const profileRequiredFields = useRootSelector(selectProfileRequiredFields);
  const isSubmitted = useRootSelector(selectIsSubmittedRecommendedFieldsModal);

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();

  const formMethods = useForm<any>({
    defaultValues: {
      emergencyAddress1: '',
      emergencyAddress2: '',
      emergencyName: '',
      emergencyPhone: '',
    },
    resolver: yupResolver(
      yup.object().shape(getEmergencyContactValidationSchema(profileRequiredFields)),
    ),
    mode: 'onBlur',
  });

  const { handleSubmit, getValues } = formMethods;

  useEffect(() => {
    if (isSubmitted) {
      const values = getValues();

      onSubmit(values, alert);
      switchingModal(alert);

      dispatch(showRecommendedFieldsModal(false));
    }
  }, [alert, dispatch, getValues, isSubmitted, onSubmit, switchingModal]);

  const onSubmitForm = (values: ICustomerEmergencyContactDto): void => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty({
      emergencyContactDto: values,
    });

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(values, alert);
      switchingModal(alert);
    }
  };

  return (
    <DialogComponent
      size="sm"
      title={<FormattedMessage {...messages.newMemberAdditionalInfoEmergency} />}
      isOpen={isOpen}
      onClose={() => switchingModal(alert)}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <FormProvider {...formMethods}>
        <EmergencySection recommendedFields={profileRecommendedFields} />
      </FormProvider>
    </DialogComponent>
  );
};

export default EmergencyContactModal;
