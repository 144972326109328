import { useCallback } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { FormError } from 'common/types/formTypes';
import { getObject } from 'common/utils/typeUtils';

type IFieldErrorReturnFunc = (
  message: MessageDescriptor | FormError | string | null | undefined,
  values?: { [key: string]: string | number },
) => string | null;

export const useRenderIntlMessage = (): IFieldErrorReturnFunc => {
  const intl = useIntl();

  return useCallback(
    (
      errMsg: MessageDescriptor | FormError | string | null | undefined,
      values: { [key: string]: string | number },
    ): string | null => {
      if (!errMsg) {
        return null;
      }

      if (typeof errMsg === 'string') {
        return errMsg;
      }

      if (typeof errMsg === 'object') {
        if ('id' in errMsg && 'defaultMessage' in errMsg) {
          if ('values' in errMsg) {
            return intl.formatMessage(errMsg, getObject(errMsg.values));
          } else {
            return intl.formatMessage(errMsg, values);
          }
        }

        if ('message' in errMsg && typeof errMsg.message === 'string') {
          return errMsg.message;
        }
      }

      return null;
    },
    [intl],
  );
};
