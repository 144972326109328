class SignContractService {
  private invoiceUnitIds: string[] = [];

  public getInvoiceUnitIds(): string[] {
    return this.invoiceUnitIds;
  }

  public setInvoiceUnitIds(invoiceUnitIds: string[]): void {
    this.invoiceUnitIds = invoiceUnitIds;
  }

  public clearInvoiceUnitIds(): void {
    this.invoiceUnitIds = [];
  }
}

export default new SignContractService();
