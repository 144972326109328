import React from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import cx from 'classnames';
import {
  Box,
  alpha,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SvgIcon,
  Theme,
  Typography,
} from '@material-ui/core';
import { AllowedTo } from 'common/components';
import {
  selectCurrentUserCorporation,
  selectCurrentUserSelectedLevel,
} from 'modules/authentication/state/selectors';

import { commonRoutes, PageRouteSetting } from './constants';
import { PermissionLevels } from 'common/constants/permissions';
import { snackbar } from 'common/utils/snackbarUtils';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useHasPermissions from '../../common/hooks/useHasPermissions';
import CustomNavLink from './CustomNavLink';

interface ISidebarRoutesProps {
  isTextVisible: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  routesList: {
    margin: 0,
    padding: theme.spacing(2, 1),
    overflowX: 'hidden',
  },
  routeItem: {
    borderRadius: 3,
    height: 40,
    width: '100%',
    padding: 11,
    color: alpha(theme.palette.common.white, 0.8),
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  activeNavigationLink: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  routeItemIcon: {
    margin: '0 auto 0 0',
    '& svg': {
      color: theme.palette.common.white,
      height: 20,
    },
  },
  routeItemTextWrapper: {
    overflow: 'hidden',
    marginLeft: theme.spacing(1.5),
    flex: 1,
  },
  routeItemText: {
    color: theme.palette.common.white,
    fontWeight: 700,
  },
  divider: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    margin: theme.spacing(1, 0),
  },
  groupTitle: {
    color: alpha(theme.palette.common.white, 0.45),
    fontWeight: 700,
    margin: theme.spacing(2.5, 0, 1, 1.5),
  },
  groupTitleClosed: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    fontSize: 0,
    margin: theme.spacing(2.75, 0),
  },
}));

const SidebarRoutes = ({ isTextVisible }: ISidebarRoutesProps): JSX.Element => {
  const classes = useStyles();
  const currentUserCorporation = useRootSelector(selectCurrentUserCorporation);
  const currentUserLevel: PermissionLevels = useRootSelector(selectCurrentUserSelectedLevel);
  const renderIntlMessage = useRenderIntlMessage();
  const hasPermissions = useHasPermissions();

  // TODO now we use permissions level instead permissions set
  // neeed to use permissions set in future
  return (
    <List className={classes.routesList}>
      {commonRoutes.map(
        ({
          id,
          isDivider,
          isGroupTitle,
          isDisabled,
          path,
          permissionsSet,
          permissions,
          title,
          icon,
        }: PageRouteSetting) => {
          if (permissions && !hasPermissions(permissions)) {
            return null;
          }

          const isHavePermission =
            !permissionsSet?.length || permissionsSet?.includes(currentUserLevel);
          if (isDivider && isHavePermission)
            return <Box key={title.id} className={classes.divider} />;

          if (isGroupTitle && isHavePermission)
            return (
              <Typography
                key={title.id}
                className={cx(classes.groupTitle, { [classes.groupTitleClosed]: !isTextVisible })}
              >
                {currentUserCorporation?.get('title')}
              </Typography>
            );

          if (isHavePermission) {
            const listItemTitle = renderIntlMessage(title);

            return (
              <ListItem
                id={id}
                key={title.id}
                title={listItemTitle}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                component={CustomNavLink}
                to={path}
                className={classes.routeItem}
                onClick={event => {
                  if (isDisabled) {
                    event.preventDefault();
                    event.stopPropagation();
                    snackbar.warning('Not implemented yet on this permissions level.');
                  }
                }}
              >
                <ListItemIcon className={classes.routeItemIcon}>
                  <SvgIcon viewBox="0 0 20 20" component={icon} />
                </ListItemIcon>

                <AllowedTo perform={isTextVisible}>
                  <ListItemText
                    className={classes.routeItemTextWrapper}
                    primary={listItemTitle}
                    primaryTypographyProps={{ className: classes.routeItemText }}
                  />
                </AllowedTo>
              </ListItem>
            );
          }

          return null;
        },
      )}
    </List>
  );
};

export default SidebarRoutes;
