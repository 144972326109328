import React, { useCallback, useMemo } from 'react';
import { IRewardsHistoryItem } from 'common/components/PersonProfile/interfaces';
import { IHeadCell, ITableRow } from 'common/interfaces/table';
import { DialogComponent, Table } from 'common/components/index';
import commonMessages from 'common/messages/messages';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import tableHeaders from 'common/messages/tableHeaders';
import notes from 'common/components/PersonProfile/components/Notes/messages/notes';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { Typography, useTheme } from '@material-ui/core';
import IntlService from 'services/intl/IntlService';
import { getRewardsTypeLabel } from '../../utils';
import { RewardsType } from '../../constants';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  rewardsHistory: IRewardsHistoryItem[];
  rewardsHistoryLoading: boolean;
}

const RewardsHistoryModal = ({
  isOpen,
  onClose,
  rewardsHistory,
  rewardsHistoryLoading,
}: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();
  const theme = useTheme();

  const headerOptions = useMemo(
    (): IHeadCell[] => [
      {
        id: 'date',
        label: IntlService.formatMessage(tableHeaders.date),
        sort: false,
      },
      {
        id: 'createdBy',
        label: IntlService.formatMessage(tableHeaders.createdBy),
        sort: false,
      },
      {
        id: 'type',
        label: IntlService.formatMessage(tableHeaders.type),
        sort: false,
      },
      {
        id: 'amount',
        label: IntlService.formatMessage(tableHeaders.amount),
        sort: false,
      },
      {
        id: 'note',
        label: IntlService.formatMessage(notes.note),
        sort: false,
      },
    ],
    [],
  );

  const getPointColor = useCallback(
    (type: RewardsType) => {
      switch (type) {
        case RewardsType.Redemption:
          return theme.palette.error.main;

        case RewardsType.Adjustment:
          return theme.palette.warning.main;

        default:
          return theme.palette.primary.main;
      }
    },
    [theme.palette],
  );

  const getPointSymbol = (type: RewardsType) => {
    switch (type) {
      case RewardsType.Redemption:
        return '-';

      case RewardsType.Adjustment:
        return '';

      default:
        return '+';
    }
  };

  const rows = useMemo(() => {
    return rewardsHistory?.map(
      (item: IRewardsHistoryItem): ITableRow => {
        const { type } = item;
        return {
          id: item.id?.toString(),
          cells: [
            {
              label: `${timezoneMoment(item.createdAt).format(DEFAULT_DATE_TIME_FORMAT)}`,
            },
            {
              label: `${item.createdBy.firstName} ${item.createdBy.lastName}`,
            },
            {
              label: getRewardsTypeLabel(type),
            },
            {
              label: '',
              cellComponent: (
                <Typography
                  variant="h6"
                  color="primary"
                  style={{
                    color: getPointColor(type),
                  }}
                >
                  {`${getPointSymbol(type)}${item.points}`}
                </Typography>
              ),
            },
            {
              label: item.note || '-',
            },
          ],
        };
      },
    );
  }, [rewardsHistory, timezoneMoment, getPointColor]);

  return (
    <DialogComponent
      title={IntlService.formatMessage(commonMessages.rewardsHistoryTitle)}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      hideFooterButtons
    >
      <Table
        rows={rows}
        headerOptions={headerOptions}
        hideSearchInput
        hidePagination
        isLoading={rewardsHistoryLoading}
      />
    </DialogComponent>
  );
};

export default RewardsHistoryModal;
