import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPermissions } from 'common/state/permission/actions';
import { getInitialPermissionContext } from 'common/utils/policyUtils';
import { selectPermissions } from 'common/state/permission/selectors';
import { PoliciesPermissionSetItems } from 'common/constants/permissions';
import PermissionContext, { TPermissionContext } from 'common/contexts/PermissionContext';
import useRootSelector from 'common/hooks/useRootSelector';

interface IPermissionContextProvider {
  skip?: boolean;
  children: JSX.Element;
}

const PermissionContextProvider: FC<IPermissionContextProvider> = ({ skip = false, children }) => {
  const permissions = useRootSelector(selectPermissions);
  const dispatch = useDispatch();

  const contextValue = useMemo((): TPermissionContext | null => {
    if (!skip) {
      const permissionContext = getInitialPermissionContext();
      const permissionEntries = Object.entries(PoliciesPermissionSetItems);

      for (const permission of permissions) {
        let pKey: string | null = null;

        for (const [key, val] of permissionEntries) {
          if (val === permission) {
            pKey = key;
            break;
          }
        }

        if (pKey) {
          permissionContext[pKey] = true;
        }
      }

      return permissionContext;
    }

    return null;
  }, [permissions, skip]);

  useEffect(() => {
    if (!skip) {
      dispatch(fetchPermissions());
    }
  }, [dispatch, skip]);

  if (!contextValue) {
    return children;
  }

  return <PermissionContext.Provider value={contextValue}>{children}</PermissionContext.Provider>;
};

export default PermissionContextProvider;
