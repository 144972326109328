import React, { useEffect, useMemo } from 'react';
import Qs from 'qs';
import useRootSelector from 'common/hooks/useRootSelector';
import { List as ImmutableList } from 'immutable';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { transformCampaignFormValuesFromDTO } from 'modules/crm/components/CampaignForm/utils';

import { TDocumentTemplateListItemImt } from 'common/interfaces/dictionary';
import { ICampaignForm } from 'modules/crm/interfaces/campaigns';
import { IScriptListItemImt } from 'modules/crm/interfaces/scripts';
import { QueryPageList, CampaignStatus, CampaignType } from 'common/constants';
import { CampaignTypes } from '../../constants/campaigns';
import { ValidationSchema } from './FormSchema';
import { selectQueryPageList } from 'common/state/queryPage-lists/selectors';
import { ICampaignDtoImt } from 'common/interfaces/campaign';
import { FormContainer } from 'common/components';
import CampaignInfoSection from './CampaignInfoSection/CampaignInfoSection';
import ConditionsSection from './ConditionsSection/ConditionsSection';
import FiltersSection from './FiltersSection/FiltersSection';

// messages
import messages from '../../messages/campaigns';

interface IProps {
  campaign?: ICampaignDtoImt;
  scripts: ImmutableList<IScriptListItemImt>;
  documentTemplates: ImmutableList<TDocumentTemplateListItemImt>;
  isLoading?: boolean;
  onSubmit: (formData: ICampaignForm) => void;
  onCampaignTypeChange: (type: CampaignType | string) => void;
}

export default function CampaignForm({
  campaign,
  scripts,
  documentTemplates,
  onCampaignTypeChange,
  isLoading,
  onSubmit,
}: IProps): JSX.Element {
  const { search } = useLocation();

  const campaignType: CampaignType | string =
    Qs.parse(search, { ignoreQueryPrefix: true })?.type?.toString() || '';

  const isEditCampaign = Boolean(campaign);

  const defaultFormValues: ICampaignForm = useMemo(
    () => ({
      title: '',
      script: null,
      campaignType,
      status: CampaignStatus.Active,
      documentTemplate: null,
      automatic: true,
      runningConditionList: [],
      audienceFilterList: [],
      corporation: null,
    }),
    [campaignType],
  );

  const navigate = useNavigate();
  const location = useLocation();

  const queryCampaigns: string = useRootSelector(selectQueryPageList(QueryPageList.CAMPAIGNS));

  const formMethods = useForm<ICampaignForm>({
    defaultValues: defaultFormValues,
    resolver: yupResolver(ValidationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'all',
  });
  const { reset, handleSubmit, control, setValue } = formMethods;

  const selectedType = useWatch({ name: 'campaignType', control });

  useEffect(() => {
    onCampaignTypeChange(selectedType);
    setValue('documentTemplate', null, { shouldValidate: false });
  }, [selectedType, onCampaignTypeChange, setValue]);

  useEffect(() => {
    if (
      campaignType !== CampaignType.EMAIL &&
      campaignType !== CampaignType.SMS &&
      campaignType !== CampaignType.PHONE
    ) {
      if (isEditCampaign) {
        navigate(`${location.pathname}?type=${selectedType}`, { replace: true });
      } else {
        navigate(`${location.pathname}?type=${CampaignType.EMAIL}`, { replace: true });
        setValue('campaignType', CampaignType.EMAIL);
      }
    } else {
      navigate(`${location.pathname}?type=${selectedType}`, { replace: true });
    }
  }, [campaignType, isEditCampaign, selectedType, setValue, location.pathname, navigate]);

  useEffect(() => {
    if (campaign?.size) {
      reset({
        ...defaultFormValues,
        ...transformCampaignFormValuesFromDTO(campaign.toJS()),
      });
    }
  }, [campaign, defaultFormValues, reset]);

  const handleCancelButton = () => {
    navigate(`/${location.pathname.split('/')[1]}/campaigns${queryCampaigns}`);
  };

  return (
    <FormContainer
      title={
        campaignType && (
          <FormattedMessage
            values={{
              type: campaignType && CampaignTypes.translate(campaignType),
            }}
            {...(campaign ? messages.editCampaign : messages.newCampaign)}
          />
        )
      }
      onSubmit={handleSubmit(onSubmit)}
      maxWidth={650}
      isSubmitting={isLoading}
      onCancel={handleCancelButton}
    >
      <form>
        <FormProvider {...formMethods}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CampaignInfoSection
                scripts={scripts}
                documentTemplates={documentTemplates}
                isEditCampaign={isEditCampaign}
              />
            </Grid>

            <Grid item xs={12}>
              <ConditionsSection />
            </Grid>

            <Grid item xs={12}>
              <FiltersSection />
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </FormContainer>
  );
}
