import { useCallback } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';

import * as registerSelectors from 'modules/pos-kiosk/state/register/selectors';
import {
  removeSocketInvoiceAction,
  updateSocketInvoicesListAction,
} from 'common/state/invoice/actions';

import { useAppDispatch } from 'store/hooks';

import { ISocketMessage, SocketMessageType } from 'common/components/SocketListener/interfaces';
import {
  IInvoiceDetailsDto,
  IInvoiceProductItem,
  ISocketRemoveMessage,
} from 'common/interfaces/invoices';

export const useInvoicesHandler = (): ((msg: ISocketMessage<IInvoiceDetailsDto>) => void) => {
  const dispatch = useAppDispatch();

  const selectedRegisterId: string = useRootSelector(registerSelectors.selectSelectedRegisterId);

  return useCallback(
    (msg: ISocketMessage<IInvoiceDetailsDto | ISocketRemoveMessage | IInvoiceProductItem[]>) => {
      if (
        msg.type === SocketMessageType.INVOICE_UPDATE &&
        (msg.data as IInvoiceDetailsDto).register?.id === selectedRegisterId
      ) {
        dispatch(updateSocketInvoicesListAction(msg.data as IInvoiceDetailsDto));

        return;
      }

      if (
        msg.type === SocketMessageType.INVOICE_REMOVE &&
        (msg.data as ISocketRemoveMessage).registerId === selectedRegisterId
      ) {
        dispatch(removeSocketInvoiceAction(msg.data as ISocketRemoveMessage));
      }
    },
    [dispatch, selectedRegisterId],
  );
};
