import React, { useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import makeStyles from '@material-ui/core/styles/makeStyles';

import * as actions from 'common/state/newPerson/primaryInfo/actions';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import * as selectors from 'common/state/newPerson/primaryInfo/selectors';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { selectCurrentUser } from 'modules/authentication/state/selectors';

import { useAppDispatch } from 'store/hooks';

import { ActionResult, DictionaryList } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';

import { DialogComponent, MemberPrimaryInfoStep } from 'common/components';
import { selectPrimaryInfo } from 'common/state/newPerson/primaryInfo/selectors';

import {
  ICameFromIndicatorDictionaryItemImt,
  IEmployeeListItemImt,
} from 'common/interfaces/dictionary';
import {
  IImmutablePrimaryMemberInfo,
  IPrimaryMemberInfo,
} from 'common/components/PersonProfile/interfaces';

import frontDeskMessages from 'modules/front-desk/messages/messages';

const useStyles = makeStyles({
  dialog: {
    '& .MuiDialog-paper form': {
      padding: 0,
    },
  },
});

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  // make no sense in this param?
  module?: PeakModules.KidZone;
}

const EditKidProfileModal = ({ isOpen, onClose, module }: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const kidProfile: IImmutablePrimaryMemberInfo = useRootSelector(selectPrimaryInfo);
  const employees: ImmutableList<IEmployeeListItemImt> = useRootSelector(
    selectDictionaryList(DictionaryList.EMPLOYEES),
  );
  const cameFromIndicators: ImmutableList<ICameFromIndicatorDictionaryItemImt> = useRootSelector(
    selectDictionaryList(DictionaryList.CAME_FROM),
  );
  const updateProfileActionResult = useRootSelector(selectors.selectActionUpdateResult);
  const currentUser = useRootSelector(selectCurrentUser);
  const isProfileUpdating: boolean = useRootSelector(selectors.selectLoading);

  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.EMPLOYEES, { module }));
    dispatch(fetchDictionaryList(DictionaryList.CAME_FROM, { module }));

    return () => {
      dispatch(actions.clearNewMemberDataAction());
    };
  }, [dispatch, module]);

  useEffect(() => {
    if (updateProfileActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.resetUpdateMemberResult());
      onClose();
    }
  }, [updateProfileActionResult, dispatch, onClose]);

  const handleSubmitEditForm = (data: IPrimaryMemberInfo) => {
    dispatch(actions.updateMember(kidProfile.get('id'), data, PeakModules.KidZone));
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      title={<FormattedMessage {...frontDeskMessages.editProfileCommonTitle} />}
      onClose={onClose}
      loading={isProfileUpdating}
      size="md"
      formId="primary-info-form"
      className={classes.dialog}
    >
      <MemberPrimaryInfoStep
        cameFromIndicators={cameFromIndicators}
        currentUser={currentUser}
        employees={employees}
        primaryInfo={kidProfile}
        hideAutocomplete
        module={module}
        handleOnSubmit={handleSubmitEditForm}
      />
    </DialogComponent>
  );
};

export default EditKidProfileModal;
