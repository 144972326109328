import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { requiredMessage } from 'common/constants/globalConstants';
import { regExp } from 'common/constants';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { IProfileInfo } from 'common/components/PersonProfile/interfaces';
import { AlertCondition } from 'common/interfaces/alerts';

import { DialogComponent, TextField } from 'common/components';

import inputLabels from 'common/messages/inputLabels';
import inputErrors from 'common/messages/inputErrors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import { useAppDispatch } from 'store/hooks';
import useRootSelector from 'common/hooks/useRootSelector';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';

interface IProps {
  alert: AlertCondition | null;
  isOpen: boolean;
  onSubmit: (info: IProfileInfo, alertId: string) => void;
  switchingModal: (alertCondition: AlertCondition) => void;
}

const FirstNameModal = ({ isOpen, switchingModal, onSubmit, alert }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const isSubmitted = useRootSelector(selectIsSubmittedRecommendedFieldsModal);

  const { checkRecommendedFieldsAreNotEmpty } = useRecommendedFields();

  const formMethods = useForm<any>({
    defaultValues: {
      firstName: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup
          .string()
          .trim()
          .matches(regExp.NAME, () => inputErrors.firstNameMatchError)
          .max(50, () => inputErrors.firstNameLengthError)
          .required(requiredMessage),
      }),
    ),
    mode: 'onBlur',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = formMethods;

  const renderIntlMessage = useRenderIntlMessage();

  useEffect(() => {
    if (isSubmitted) {
      const values = getValues();

      onSubmit(values, alert);
      switchingModal(alert);

      dispatch(showRecommendedFieldsModal(false));
    }
  }, [alert, dispatch, getValues, isSubmitted, onSubmit, switchingModal]);

  const onSubmitForm = (values: Partial<IProfileInfo>): void => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty(values);

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(values, alert);
      switchingModal(alert);
    }
  };

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...inputLabels.firstName} />}
      isOpen={isOpen}
      onClose={() => switchingModal(alert)}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <TextField
            variant="outlined"
            label={<FormattedMessage {...inputLabels.firstName} />}
            value={field.value}
            onBlur={field.onBlur}
            onChange={field.onChange}
            fullWidth
            error={!!errors.firstName}
            helperText={renderIntlMessage(errors.firstName?.message)}
          />
        )}
      />
    </DialogComponent>
  );
};

export default FirstNameModal;
