import React, { useMemo } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import moment from 'moment-timezone';
import { Grid } from '@material-ui/core';

import { selectCurrentUserAvailableClubs } from 'modules/authentication/state/selectors';
import {
  selectAppointmentServices,
  selectAppointmentServicesLoading,
} from 'common/components/PersonProfile/state/appointments/selectors';
import {
  selectEventServices,
  selectEventServicesLoading,
} from 'modules/booking/state/events/selectors';
import { selectTimezone } from 'common/state/settings/selectors';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { getAvailableResources } from 'modules/booking/utils/resources';

import { AlertTypes } from 'common/interfaces/alerts';

import { Alert, MultipleSelect } from 'common/components';
import { AppointmentServicesAutocomplete } from 'common/components/ReactHookForm';

import inputLabels from 'common/messages/inputLabels';
import messages from 'modules/booking/messages';
import {
  EventAttendeeType,
  EventPersonType,
  IEventPersonFormValue,
  IShortResourceImt,
  PersonAppointmentStatus,
  PersonAttendanceType,
} from 'modules/booking/interfaces';

interface IProps {
  resources: ImmutableList<IShortResourceImt>;
  searchServices: (search: string, personId?: number) => void;
  clearSearchResults: (personId?: number) => void;
  personId?: number;
}

const ClubSection = ({
  resources,
  personId,
  searchServices,
  clearSearchResults,
}: IProps): JSX.Element => {
  const personAppointmentServices = useRootSelector(selectAppointmentServices(personId));
  const isLoadingPersonAppointmentServices = useRootSelector(
    selectAppointmentServicesLoading(personId),
  );
  const eventServices = useRootSelector(selectEventServices);
  const isLoadingEventServices = useRootSelector(selectEventServicesLoading);
  const clubs = useRootSelector(selectCurrentUserAvailableClubs);
  const currentTimezoneView: string = useRootSelector(selectTimezone);

  const { watch, setValue, getValues, control, formState } = useFormContext();
  const { errors } = formState;

  const services = personId ? personAppointmentServices : eventServices;
  const isLoading = personId ? isLoadingPersonAppointmentServices : isLoadingEventServices;

  const [club, formResources, overbookingResources, date, time] = watch([
    'club',
    'resources',
    'overbookingResources',
    'date',
    'time',
  ]);

  const renderIntlMessage = useRenderIntlMessage();

  const availableResources = getAvailableResources(formResources, resources, overbookingResources);

  const selectedDate = useMemo(() => {
    if (time && date) {
      return moment.utc(`${date} ${time}`).format();
    }
    return '';
  }, [date, time]);

  const clubZoneOffset = moment.tz(club?.timezone).utcOffset();
  const timezoneViewOffset = moment.tz(currentTimezoneView).utcOffset();

  const isSameTimezones = clubZoneOffset === timezoneViewOffset;

  const onChangeResources = (value, reason, prevValue, onChange) => {
    const getResourceIds = list => list?.map(resource => resource.id) || [];

    let persons: IEventPersonFormValue[] | null = null;

    if (reason === 'select-option') {
      const resourceIds = getResourceIds(prevValue);
      const addedResource = value.filter(({ id }) => !resourceIds.includes(id))[0];

      if (addedResource && addedResource.employee) {
        persons = getValues('persons') || [];

        const isSamePerson = persons.some(item => addedResource.employee.id === item.id);

        if (isSamePerson) {
          persons = persons.map(item => {
            const { salesperson, customer } = item;
            const person = salesperson || customer;
            if (addedResource.employee.id === person.id) {
              return {
                id: item.id,
                salesperson: {
                  ...addedResource.employee,
                  type: EventAttendeeType.RESOURCE_EMPLOYEE,
                },
                joinedDate: selectedDate,
                resourceId: addedResource.id,
                attendanceType: PersonAttendanceType.Default,
                status: PersonAppointmentStatus.Pending,
                type: EventPersonType.RESOURCE_EMPLOYEE,
              };
            }

            return item;
          });
        } else {
          persons = [
            ...persons,
            {
              id: null,
              salesperson: {
                ...addedResource.employee,
                type: EventAttendeeType.RESOURCE_EMPLOYEE,
              },
              joinedDate: selectedDate,
              resourceId: addedResource.id,
              attendanceType: PersonAttendanceType.Default,
              status: PersonAppointmentStatus.Pending,
              type: EventPersonType.RESOURCE_EMPLOYEE,
            },
          ];
        }
      }
    }
    if (reason === 'remove-option') {
      const resourceIds = getResourceIds(value);
      const removedResource = prevValue.filter(({ id }) => !resourceIds.includes(id))[0];

      if (removedResource && removedResource.employee) {
        const salespersonId = removedResource.employee.id;
        persons = getValues('persons').filter(({ salesperson }) => {
          if (!salesperson) {
            return true;
          }

          return salesperson.id !== salespersonId;
        });
      }
    }

    if (reason === 'clear') {
      persons = getValues('persons').filter(({ customer, salesperson }) => {
        const person = customer || salesperson;
        return person.type !== EventAttendeeType.RESOURCE_EMPLOYEE;
      });
    }

    if (persons) {
      setValue('persons', persons);
    }

    onChange(value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              name="club"
              control={control}
              render={({ field }) => (
                <MultipleSelect
                  label={<FormattedMessage {...inputLabels.club} />}
                  value={field.value}
                  onChange={option => {
                    if (!option) {
                      setValue('resources', []);
                    }

                    field.onChange(option);
                  }}
                  onBlur={field.onBlur}
                  fullWidth
                  options={clubs.toJS()}
                  error={!!errors.club}
                  helperText={renderIntlMessage(errors?.club?.message)}
                />
              )}
            />
          </Grid>

          {club && !isSameTimezones && (
            <Grid item xs={12}>
              <Alert
                severity={AlertTypes.Warning}
                title={
                  <FormattedMessage
                    {...messages.timezoneIsDifferentMsg}
                    values={{
                      timezoneViewOffset: `(GMT ${moment.tz(currentTimezoneView).format('Z')})`,
                      clubZoneOffset: `(GMT ${moment.tz(club.timezone).format('Z')})`,
                      eventDate: `${moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm')
                        .utc(true)
                        .utcOffset(clubZoneOffset)
                        .format('h:mm A, dddd, MMMM D')}`,
                    }}
                  />
                }
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <AppointmentServicesAutocomplete
              services={services}
              searchServices={searchServices}
              clearSearchResults={clearSearchResults}
              isLoading={isLoading}
              personId={personId}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="resources"
          control={control}
          render={({ field }) => (
            <MultipleSelect
              options={availableResources}
              multiple
              disabled={!club}
              value={field.value || []}
              onBlur={field.onBlur}
              onChange={(data, reason) =>
                onChangeResources(data, reason, field.value, field.onChange)
              }
              label={<FormattedMessage {...inputLabels.resources} />}
              fullWidth
              error={!!errors.resources}
              helperText={renderIntlMessage(errors.resources?.message)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ClubSection;
