import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import { KidZonePaths } from 'modules/kid-zone/constants';
import { DaycareRedeemModal, SearchByBarcodeModal } from 'modules/kid-zone/components';
import { ReactComponent as CLockIcon } from 'img/icons/clock-rotate_deprecated.svg';
import { ReactComponent as SearchIcon } from 'img/icons/search.svg';
import { ReactComponent as BarcodeIcon } from 'img/icons/revenue-code.svg';

import commonMessages from 'common/messages/messages';
import {
  checkInAllChildren,
  fetchMembersToCheckInByBarcodeThunk,
  resetMembersToCheckInByBarcode,
} from 'modules/kid-zone/state/actions';
import { useAppDispatch } from 'store/hooks';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import * as actions from 'modules/kid-zone/state/actions';
import * as selectors from 'modules/kid-zone/state/selectors';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';
import { ICheckInState } from 'modules/kid-zone/KidZone';

const useStyles = makeStyles((theme: Theme) => ({
  buttonsWrapper: {
    width: 'initial',
  },
  buttonIcon: {
    width: '16px',
    height: '16px',
  },
  buttonActive: {
    height: '32px',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.dark} !important`,
      color: theme.palette.primary.contrastText,
    },
  },
}));

interface IProps {
  isKidZoneEnabled?: boolean;
  isManualCheckInPanelOpen: boolean;
  onManualCheckInPanelOpen: () => void;
  setIsCheckInMembersModalOpen: Dispatch<SetStateAction<boolean>>;
  setScannerString: Dispatch<SetStateAction<string>>;
  setCheckInState: Dispatch<SetStateAction<Partial<ICheckInState>>>;
  isCheckInMembersModalOpen: boolean;
  guardianId: number;
  childrenIds: Array<string>;
}

const KidZoneHeader = ({
  isKidZoneEnabled = true,
  isManualCheckInPanelOpen,
  onManualCheckInPanelOpen,
  setIsCheckInMembersModalOpen,
  setCheckInState,
  isCheckInMembersModalOpen,
  setScannerString,
  guardianId,
  childrenIds,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const membersToCheckin = useRootSelector(selectors.selectFoundMembersToCheckinByBarcode);
  const membersToCheckinLoading = useRootSelector(
    selectors.selectFoundMembersToCheckinByBarcodeLoading,
  );
  const isCheckInActionLoading: boolean = useRootSelector(
    selectors.selectCheckInChildActionLoading,
  );
  const isCheckInAllChildrenActionLoading: boolean = useRootSelector(
    selectors.selectCheckInAllChildrenActionLoading,
  );
  const selectedClubId: string = useRootSelector(selectUserSelectedClubId);
  const error = useRootSelector(selectors.selectFoundMembersToCheckinByBarcodeError);

  const [searchMembersByBarcodeModalOpen, setSearchMembersByBarcodeModalOpen] = useState(false);

  const navigate = useNavigate();

  const classes = useStyles();

  useComponentDidUpdate(() => {
    if (membersToCheckin || error) {
      if (!isCheckInMembersModalOpen && !searchMembersByBarcodeModalOpen) {
        dispatch(resetMembersToCheckInByBarcode());
        setScannerString(null);
      }

      if (searchMembersByBarcodeModalOpen) {
        setSearchMembersByBarcodeModalOpen(false);
        setIsCheckInMembersModalOpen(true);
      }
    }
  }, [
    membersToCheckin,
    error,
    isCheckInMembersModalOpen,
    dispatch,
    setScannerString,
    searchMembersByBarcodeModalOpen,
  ]);

  const onSearchByBarcode = useCallback(
    (value: string) => {
      setScannerString(value);
      dispatch(fetchMembersToCheckInByBarcodeThunk(value));
    },
    [dispatch, setScannerString],
  );

  const handleCheckInKid = (serviceId: string) => {
    dispatch(
      actions.checkInKid(
        membersToCheckin.getIn(['member', 'id']),
        guardianId,
        serviceId,
        selectedClubId,
      ),
    );
  };

  const handleCheckInChildren = (serviceId: string) => {
    dispatch(checkInAllChildren(selectedClubId, guardianId, serviceId, childrenIds));
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="h2">
        <FormattedMessage {...commonMessages.kidZone} />
      </Typography>

      {isKidZoneEnabled && (
        <>
          <Grid container spacing={2} className={classes.buttonsWrapper}>
            <Grid item>
              <Button
                startIcon={<CLockIcon />}
                variant="contained"
                onClick={() => navigate(KidZonePaths.KID_ZONE_HISTORY)}
              >
                <FormattedMessage {...commonMessages.historyBtn} />
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<SearchIcon className={classes.buttonIcon} />}
                variant="contained"
                onClick={onManualCheckInPanelOpen}
                className={cx({ [classes.buttonActive]: isManualCheckInPanelOpen })}
              >
                <FormattedMessage {...commonMessages.checkInManually} />
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<BarcodeIcon className={classes.buttonIcon} />}
                variant="contained"
                onClick={() => setSearchMembersByBarcodeModalOpen(true)}
              >
                <FormattedMessage {...commonMessages.scanBarcodeTitle} />
              </Button>
            </Grid>
          </Grid>

          {searchMembersByBarcodeModalOpen && (
            <SearchByBarcodeModal
              isLoading={membersToCheckinLoading}
              onSearchByBarcode={onSearchByBarcode}
              isOpen={searchMembersByBarcodeModalOpen}
              onClose={() => setSearchMembersByBarcodeModalOpen(false)}
            />
          )}

          {!!guardianId && (
            <DaycareRedeemModal
              isOpen={!!guardianId}
              onClose={() => setCheckInState({})}
              guardianId={guardianId}
              isRedeemActionLoading={isCheckInAllChildrenActionLoading || isCheckInActionLoading}
              onSubmit={childrenIds ? handleCheckInChildren : handleCheckInKid}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default KidZoneHeader;
