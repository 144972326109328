import React, { FC, useCallback } from 'react';
import { Avatar, Box, Button, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as DefaultIcon } from 'img/icons/default-icon.svg';
import messages from 'common/messages/messages';
import {
  ISimpleClubInventoryItemImt,
  ISimpleClubInventoryItem,
} from 'modules/pos-settings/interfaces/inventoryItems';
import { CustomTheme } from 'common/ui/interfaces';
import { isOutOfStockProduct } from 'common/utils/product';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    minWidth: 180,
    maxWidth: 250,
  },
  title: {
    marginBottom: '0.5rem',
  },
  status: {
    marginBottom: '0.5rem',
  },
  avatar: {
    width: '100%',
    height: '100%',
    borderRadius: 5,
  },
  errorText: {
    color: theme.palette.error.main,
    fontWeight: 500,
  },
  disabled: {
    opacity: '0.6',
  },
  buttonText: {
    textTransform: 'uppercase',
    '&.MuiButton-text': {
      padding: theme.spacing(1),
    },
    '&.MuiButton-root': {
      padding: theme.spacing(1),
      minWidth: '5px',
      position: 'relative',
      left: theme.spacing(-1),
    },
  },
}));

interface IProductItem {
  product: ISimpleClubInventoryItemImt;
  isAddInvoiceUnitActionLoading: boolean;
  onSell: (product: ISimpleClubInventoryItem) => void;
}

const ProductItem: FC<IProductItem> = ({ product, onSell, isAddInvoiceUnitActionLoading }) => {
  const classes = useStyles();
  const handleSellItem = useCallback(() => onSell(product.toJS()), [product, onSell]);
  const isOutOfStock = isOutOfStockProduct(product.toJS());

  return (
    <Grid item className={classes.root} xs={6}>
      <Box width="78px" minWidth="78px" mr={1}>
        <Avatar className={classes.avatar} src={product.get('imageUrl')} alt="avatar-icon">
          <DefaultIcon />
        </Avatar>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography component="span" variant="h5" className={classes.title}>
          {product.get('title')}
        </Typography>

        <Typography component="span" className={classes.status}>
          ${product.get('retailPrice')}
        </Typography>

        {isOutOfStock && (
          <Typography variant="caption" className={classes.errorText}>
            <FormattedMessage {...messages.outOfStockText} />
          </Typography>
        )}

        <Link variant="button" className={`${classes.buttonText}`} color="primary" underline="none">
          <Button
            color="primary"
            className={classes.buttonText}
            disabled={isAddInvoiceUnitActionLoading}
            onClick={handleSellItem}
          >
            <FormattedMessage {...messages.sellBtn} />
          </Button>
        </Link>
      </Box>
    </Grid>
  );
};

export default ProductItem;
