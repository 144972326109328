import { useEffect } from 'react';
import { resetUpdateInvoiceWithSync } from 'common/state/invoice/actions';
import { PeakModules } from 'common/constants/peakModules';
import * as personActions from 'common/components/PersonProfile/state/actions';
import { fetchPosKioskRegisterData } from 'modules/pos-kiosk/state/register/actions';
import { useAppDispatch } from 'store/hooks';
import { IUserOrganizationImt } from 'common/interfaces/clients';
import useRootSelector from 'common/hooks/useRootSelector';
import { selectUserSelectedOrganization } from 'modules/authentication/state/selectors';
import { selectSelectedRegisterId } from 'modules/pos-kiosk/state/register/selectors';
import { selectUpdatedInvoiceResult } from 'common/state/invoice/selectors';
import { IInvoiceDetailsImt } from 'common/interfaces/invoices';

export const useUpdatePaymentData = (
  module: PeakModules,
  profileId: number,
  onClose: () => void,
): IInvoiceDetailsImt | null => {
  const dispatch = useAppDispatch();

  const selectedOrg: IUserOrganizationImt = useRootSelector(selectUserSelectedOrganization);
  const selectedRegisterId: string = useRootSelector(selectSelectedRegisterId);
  const updatedInvoiceResult = useRootSelector(selectUpdatedInvoiceResult);

  useEffect(() => {
    if (updatedInvoiceResult) {
      dispatch(resetUpdateInvoiceWithSync());
      onClose();
      if (module === PeakModules.FrontDesk && profileId) {
        dispatch(personActions.fetchPersonSuggestedProducts(profileId, selectedOrg.get('id')));
      }
      dispatch(fetchPosKioskRegisterData(selectedRegisterId));
    }

    return () => {
      dispatch(resetUpdateInvoiceWithSync());
    };
  }, [dispatch, onClose, updatedInvoiceResult, selectedRegisterId, profileId, selectedOrg, module]);

  return updatedInvoiceResult;
};
