import { List } from 'immutable';

import { ImmutableObject } from 'common/state/interfaces';
import { IPaginatedData } from 'common/interfaces/pagination';
import { IDateRangeFilterValue } from 'common/interfaces/filter';
import { DurationUnit, IDayTimeAvailabilityDto, INamedEntity } from 'common/interfaces/common';
import { IServicesListItem } from './services';
import {
  AvailabilityType,
  BillingOptionType,
  ConditionForPurchaseType,
  CreditCardFeeUnit,
  DurationType,
  EditPackageStep,
  FrequencyType,
  IncludedPackageBillingType,
  PackageCostType,
  PackageType,
  StepToggleAction,
} from 'modules/services/constants/packages';
import { IClub } from 'common/interfaces/clients';
import { IEmployeeListItem } from 'common/interfaces/dictionary';
import { RedeemType, ServiceType } from 'common/constants/service';
import { IFee } from './fees';
import { IPackageInventory } from 'common/interfaces/invoices';
import { IConfiguredIncludedPackage, ICustomDates, IService } from 'common/interfaces/membership';
import { IPackageRCodeListItem } from 'modules/pos-settings/interfaces/revenueCodes';

export interface IPaymentOption {
  frequency: string;
  perPayment: number;
  title: string;
  totalAmount: number;
}

export interface IPackageListItem {
  id: string;
  title: string;
  billingPrice: number;
  inventoriesNumber: number;
  servicesNumber: number;
  packagesNumber: number;
  active: boolean;
  type: PackageType;
  membership: boolean;
  billingOptions?: List<IPaymentOption>;
}

export type IPackageListItemImt = ImmutableObject<IPackageListItem>;

export interface IPaginatedPackagesList extends IPaginatedData<IPackageListItem> {
  data: Array<IPackageListItem>;
}

export interface IOrderedValue {
  value: number;
  order?: number;
}

export interface IEditable extends IOrderedValue {
  editable: boolean;
  maxValue?: number;
  minValue?: number;
}

export interface IGeneralSection {
  title: string;
  active: boolean;
  type: PackageType;
  costType?: PackageCostType;
  durationType: DurationType;
  durationEditableNumber?: IEditable | null;
  availabilityType: AvailabilityType;
  membership: boolean;
  allowMembersToPurchaseOnline: boolean;
  customDates?: IDateRangeFilterValue;
  dayTimeAvailDtoList: IDayTimeAvailabilityDto[];
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  allowAllEmployee?: boolean;
  employees: IEmployeeListItem[];
  allowSellInAllClubs?: boolean;
  sellerClubs: INamedEntity[];
  allowAccessAllClubs?: boolean;
  accessClubs: INamedEntity[];
  renewalNumber: number;
  documentTemplate?: INamedEntity | null;
}

export interface IGeneralStep {
  generalSection: IGeneralSection;
}

export interface IPaymentSplitting {
  allow: boolean;
  paymentNumber: number;
  paymentEditableSplits: Array<IEditable>;
  splitPaymentEditableNumber: IEditable;
  paymentEditableNumber: IEditable;
  splitPaymentSchedule: FrequencyType;
}

export interface IDownPayment {
  allow: boolean;
  editableAmount: IEditable;
  editableNumber: IEditable;
  editableSplits: Array<IEditable>;
}

export interface IPaymentGrace {
  allow: boolean;
  duration: DurationType;
  editableDurationNumber: IEditable;
}

export interface IInitialFeeSector {
  id?: string;
  active: boolean;
  purchaseCondition: ConditionForPurchaseType;
  override?: boolean;
  immediatePayment?: boolean;
  editableAmount?: IEditable;
  editableNumber?: IEditable;
  paymentEditablePeriodInDays?: IEditable;
}

export interface IBillingRCode {
  id: string;
  title: string;
  totalTax: number;
}

export interface ICreditCardServiceFee {
  enabled: boolean;
  value: number;
  unit: CreditCardFeeUnit;
}

export type IPaymentEditableSchedule = Omit<IEditable, 'value'> & { value: FrequencyType };

export interface IBillingOption {
  creditCardServiceFee: ICreditCardServiceFee;
  downPayment: IDownPayment;
  freeze: IFreezeSector;
  id: string;
  paymentEditableSchedule: IPaymentEditableSchedule;
  paymentGrace: IPaymentGrace;
  paymentSplitting: IPaymentSplitting;
  splitting: IPaymentSplitting;
  title: string;
  totalAmount?: number;
  type: BillingOptionType;
  useAsDefault: boolean;
  pricePerBilling: number;
  isOutOfTerm: boolean;
}

export interface IBillingOptionImt extends ImmutableObject<IBillingOption> {
  get<K extends keyof IBillingOption>(key: K): IBillingOption[K];

  get(key: 'splitting'): ImmutableObject<IPaymentSplitting>;
}

export interface IBillingSection {
  revenueCode: IBillingRCode;
  billingOptions: IBillingOption[];
  defaultBillingOptionPresent: boolean;
  outOfTermBillingOption?: IBillingOption;
  outOfTermBillingOptionPresent?: boolean;
}

export interface IBillingStep {
  billingSection: IBillingSection;
}

export interface IFreezeSector {
  allow?: boolean;
  editableDaysNumber: IEditable;
  billingFrozenMembership: boolean;
}

export interface IMembershipSection {
  initialFees: Array<IInitialFeeSector>;
}

export interface IMembershipStep {
  membershipSection: IMembershipSection;
}

export interface IInventorySection {
  inventories: Array<IPackageInventory>;
}

export interface IInventoryStep {
  inventorySection: IInventorySection;
}

export interface IEmployeeSection {
  firstName: string;
  id: string;
  imageUrl: string;
  lastName: string;
  canSell: boolean;
}

export interface IEmployeesStep {
  employees: Array<IEmployeeSection>;
}

export interface IPackageServicesPackagesList {
  services: IPackageService[];
  packages: INamedEntity[];
}

export interface IPackageServicesPackagesListImt
  extends ImmutableObject<IPackageServicesPackagesList> {
  get<K extends keyof IPackageServicesPackagesList>(key: K): IPackageServicesPackagesList[K];

  get(key: 'services'): List<IPackageServiceImt>;

  get(key: 'packages'): List<IPackageServiceBundleImt>;
}

export enum IncludedServicePackageType {
  Service,
  Package,
}

export interface IPackageService extends INamedEntity {
  type?: ServiceType;
  redeemType?: RedeemType;
  redeemDurationUnit?: DurationUnit;
}

export interface IPackageServiceBundle extends IConfiguredIncludedPackage {
  hasOwnBillingSettings: boolean;
}

export type IPackageServiceBundleImt = ImmutableObject<IPackageServiceBundle>;

export type IPackageServiceImt = ImmutableObject<IPackageService>;

export interface IIncludedService {
  id?: string;
  excludable: boolean;
  service?: IPackageService;
  hasCustomDayTimeAvailability?: boolean;
  dayTimeAvailDtoList?: IDayTimeAvailabilityDto[];
  limited?: boolean;
  limitedRedeemNumber?: number | string;
  limitationType?: string;
  billingAmount?: number;
  billingOption?: IBillingOption;
  billingType?: IncludedPackageBillingType;
  package?: IPackageServiceBundle;
}

interface ICombinationItem extends IServicesListItem {
  excludable: boolean;
}

interface IServiceCombination {
  billingAmount?: number | string;
  billingType: string;
  combinationPkg: ICombinationItem;
  id: string;
}

export interface IServicesSection {
  includedServices: Array<IIncludedService>;
  actualCombinations: Array<IServiceCombination>;
  allowAllPackageCombination: boolean;
  allowPackageCombination: boolean;
}

export interface IServicesStep {
  serviceSection: IServicesSection;
}

export interface IPackageFee {
  allowSplitting: boolean;
  amountToPay?: number;
  chargeAfterDays: IEditable;
  excludable: boolean;
  fee: IFee;
  id?: string;
  paymentSchedule: FrequencyType;
  splitPaymentSchedule: FrequencyType;
  totalAmount: IEditable;
  paymentsNumber: IEditable;
  paymentSplits?: IEditable[];

  // This is responsible for removing service upon membership package sale
  included?: boolean;
  split?: boolean;
  editableSplits?: IEditable[];
}

export interface IFeesSection {
  includedFees: IPackageFee[];
}

export interface IFeesStep {
  feesSection: IFeesSection;
}

interface INewPackageWithoutClubsDetails
  extends IGeneralStep,
    IBillingStep,
    IMembershipStep,
    IInventoryStep,
    IEmployeesStep,
    IServicesStep,
    IFeesStep {}

export interface IClubElementSector {
  billingPrice: number;
  club: IClub;
  employeesNumber: number;
  id?: string;
  inventoriesNumber: number;
  servicesNumber: number;
  type: string;
  clubsConfiguration: INewPackageWithoutClubsDetails;
}

export interface IClubsStep {
  clubs?: Array<IClubElementSector>;
}

export interface IEditPackageDetails extends INewPackageWithoutClubsDetails, IClubsStep {
  id: string;
}

export interface IFormStepStyle {
  smallPaddingContent?: boolean;
}

export interface IPackageTemplate {
  id: string;
  title: string;
  active: boolean;
}

export interface IErrorsSteps {
  generalSection: boolean | null;
  billingSection: boolean | null;
}

export interface IPackageStepProps<
  T extends
    | IGeneralSection
    | IBillingSection
    | IMembershipSection
    | Array<IEmployeeSection>
    | IInventorySection
    | IServicesSection
    | IFeesSection
> {
  editPackageSectionFormData: T;
  handleChange: (data, errors?) => void;
  renderFooter: (onSubmit?, onNext?, onBack?, disabled?) => JSX.Element;
  submitAttempted: boolean;
  onPackageSubmit: (data, submitErrors) => void;
  disabledSteps?: Array<EditPackageStep>;
  toggleStep?: (step: EditPackageStep, action: StepToggleAction) => void;
}

export interface IBundleFeeView {
  allowSplitting: boolean;
  chargeAfterDays: number;
  excludable: number;
  fee: IFee;
  id: string;
  paymentSchedule: FrequencyType;
  splitPaymentSchedule: FrequencyType;
  splits: Array<IOrderedValue>;
  totalAmount: number;
}

export interface IBundleFreezeView {
  billingFrozenMembership: boolean;
  daysNumber: number;
}

export interface IPaymentGraceView {
  allow: boolean;
  duration: DurationType;
  durationNumber: number;
}

export interface IBundleSplittingView {
  allow: boolean;
  splits: Array<IOrderedValue>;
}

export interface IBundleServiceView {
  billingAmount: number;
  billingType: IncludedPackageBillingType;
  dayTimeAvailDtoList: Array<IDayTimeAvailabilityDto>;
  excludable: boolean;
  hasCustomDayTimeAvailability: boolean;
  id: string;
  limited: boolean;
  limitedRedeemNumber: number;
  package: IBundleView;
  service: IService;
}

export interface IBillingOptionView {
  billingOptionType: BillingOptionType;
  creditCardServiceFee: ICreditCardServiceFee;
  freeze: IBundleFreezeView;
  id: string;
  paymentGrace: IPaymentGraceView;
  paymentSchedule: FrequencyType;
  pricePerBilling: number;
  revenueCode: IPackageRCodeListItem;
  splitting: IBundleSplittingView;
  taxPerBilling: number;
  taxedAmount: number;
  title: string;
  totalAmount: number;
}

export interface IBundleView {
  billingOption: IBillingOptionView;
  clubs: Array<INamedEntity>;
  combinationBillingType: IncludedPackageBillingType;
  costType: PackageCostType;
  customDates: ICustomDates;
  dayTimeAvailDtoList: Array<IDayTimeAvailabilityDto>;
  durationNumber: number;
  durationType: DurationType;
  fees: Array<IBundleFeeView>;
  id: string;
  title: string;
  inventories: Array<IPackageInventory>;
  oneTimeBillingAmount: number;
  services: Array<IBundleServiceView>;
  type: PackageType;
  renewalNumber: number | null;
  outOfTermBillingOption: IBillingOptionView;
}

export interface IBundleViewImt extends ImmutableObject<IBundleView> {
  get<K extends keyof IBundleView>(key: K): IBundleView[K];
}
