import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import cx from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  activeNavigationLink: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const CustomNavLink = React.forwardRef<HTMLAnchorElement>(
  ({ to, className, ...otherProps }: NavLinkProps, ref) => {
    const classes = useStyles();
    const location = useLocation();

    return (
      <NavLink
        ref={ref}
        to={to}
        {...otherProps}
        className={({ isActive }) => {
          let isActiveMenuItem: boolean;

          if (String(to).startsWith('/reports') && location.pathname.startsWith('/reports')) {
            isActiveMenuItem = true;
          } else {
            isActiveMenuItem = isActive;
          }

          return cx(className as string, { [classes.activeNavigationLink]: isActiveMenuItem });
        }}
      />
    );
  },
);

export default CustomNavLink;
