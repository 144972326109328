import { useCallback } from 'react';
import { batch } from 'react-redux';

import * as selectors from 'common/state/invoice/selectors';
import { fetchOpenInvoicesListAction, selectInvoice } from 'common/state/invoice/actions';

import { useAppDispatch } from 'store/hooks';

import { PeakModules } from 'common/constants/peakModules';
import Services from 'services/network';

import { IInvoiceDetailsDto } from 'common/interfaces/invoices';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import useRootSelector from 'common/hooks/useRootSelector';

interface IUseFetchInvoiceListPayload {
  module: PeakModules;
  registerId: string;
  isMembershipStep?: boolean;
  shouldFocus?: boolean;
}

export const fetchInvoicesList = async (
  module: PeakModules,
  registerId: string,
  isMembershipStep?: boolean,
): Promise<IInvoiceDetailsDto[]> => {
  let result: IInvoiceDetailsDto[];

  switch (module) {
    case PeakModules.FrontDesk:
      if (isMembershipStep) {
        result = await Services.FrontDesk.fetchOpenInvoicesInMembership(registerId);
      } else {
        result = await Services.FrontDesk.fetchOpenInvoicesInPOS(registerId);
      }
      break;
    case PeakModules.Crm:
      result = await Services.Leads.fetchOpenInvoices(registerId);
      break;
    case PeakModules.PersonalTrainingCrm:
      result = await Services.PTLeads.fetchOpenInvoices(registerId);
      break;
    case PeakModules.Members:
      result = await Services.Members.fetchOpenInvoices(registerId);
      break;
    default:
      result = await Services.PosKiosk.fetchOpenInvoices(registerId);
      break;
  }

  return result;
};

const useFetchInvoicesList = (
  payload: IUseFetchInvoiceListPayload,
): ((callback?: (invoicesList: IInvoiceDetailsDto[]) => void) => Promise<boolean>) => {
  const { registerId, isMembershipStep, module, shouldFocus } = payload;

  const dispatch = useAppDispatch();

  const isFetchedInvoiceList = useRootSelector(selectors.selectIsFetchedInvoiceList);
  const invoices = useRootSelector(selectors.selectInvoicesForRegisterList);

  return useCallback(
    async (callback?: (invoicesList: IInvoiceDetailsDto[]) => void) => {
      try {
        let invoicesList = invoices?.toJS() || [];

        if (registerId && !isFetchedInvoiceList) {
          invoicesList = await fetchInvoicesList(module, registerId, isMembershipStep);
        }

        batch(() => {
          dispatch(fetchOpenInvoicesListAction(invoicesList));

          if (shouldFocus) {
            dispatch(selectInvoice(invoicesList[0]?.id));
          }
        });

        if (callback) {
          callback(invoicesList);
        }

        return true;
      } catch (error) {
        dispatch(enqueueErrorNotification(error));
        return false;
      }
    },
    [isFetchedInvoiceList, invoices, dispatch, isMembershipStep, module, registerId, shouldFocus],
  );
};

export default useFetchInvoicesList;
