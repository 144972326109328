// Libraries
import React, { useCallback, useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useLocation, useNavigate } from 'react-router-dom';

// Selectors
import { selectScriptCreating, selectCreatedScript } from 'modules/crm/state/scripts/selectors';
import { selectQueryPageList } from 'common/state/queryPage-lists/selectors';
import { QueryPageList } from 'common/constants';

// hooks
import { useAppDispatch } from 'store/hooks';

// Interfaces
import { IScriptDetailed, IScriptDetailedImt } from 'modules/crm/interfaces/scripts';

// Custom components
import { RouteBackground } from 'components';
import { ScriptForm } from 'modules/crm/components';

// Actions
import * as scriptActions from '../../state/scripts/actions';

// messages
import { FormattedMessage } from 'react-intl';
import messages from '../../messages/scripts';
import { PeakModules } from 'common/constants/peakModules';

const NewScript = (): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const isScriptCreating: boolean = useRootSelector(selectScriptCreating());
  const createdScript: IScriptDetailedImt | null = useRootSelector(selectCreatedScript());
  const queryScripts: string = useRootSelector(selectQueryPageList(QueryPageList.SCRIPTS));

  const location = useLocation();
  const navigate = useNavigate();
  const module =
    location.pathname.indexOf('personal-training-crm') === -1
      ? PeakModules.Crm
      : PeakModules.PersonalTrainingCrm;

  useEffect(() => {
    if (createdScript !== null) {
      navigate(`/${location.pathname.split('/')[1]}/scripts${queryScripts}`);
    }
  }, [createdScript, location.pathname, navigate, queryScripts]);

  useEffect(() => {
    return () => {
      dispatch(scriptActions.resetScriptForm());
    };
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    (formValues: IScriptDetailed): void => {
      dispatch(scriptActions.createScript(formValues, module));
    },
    [dispatch, module],
  );

  return (
    <RouteBackground>
      <ScriptForm
        title={<FormattedMessage {...messages.newScript} />}
        onSubmit={handleSubmitForm}
        isLoading={isScriptCreating}
      />
    </RouteBackground>
  );
};

export default NewScript;
