import React, { useMemo } from 'react';
import { Box, Button, Theme, Typography, makeStyles, Paper, Avatar } from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
// icons
import { ReactComponent as DefaultIcon } from 'img/icons/default-icon.svg';
// interfaces
import { IRegisterProductItem } from 'modules/pos-settings/interfaces/register';
import { MenuItemTypes } from 'common/interfaces/invoices';
// utils
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
// components
import ActionsItemMenu from './ActionsItemMenu';
// messages
import commonMessages from 'common/messages/messages';
import { debounce } from 'common/utils';
import { isOutOfStockProduct } from 'common/utils/product';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-flex',
    position: 'relative',
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    height: '100%',
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
    borderRadius: 3,
  },
  wrapContent: {
    padding: theme.spacing(2),
    minHeight: 88,
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
    cursor: 'default',
  },
  avatarWrapper: {
    minWidth: 56,
    width: 56,
    height: 56,
  },
  errorText: {
    color: theme.palette.error.main,
    fontWeight: 500,
  },
  avatar: {
    width: '100%',
    height: '100%',
    borderRadius: 5,
  },
  title: {
    marginBottom: '0.5rem',
    marginTop: '0.25rem',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 'normal',
  },
  status: {
    marginBottom: '0.25rem',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
  },
  category: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    alignSelf: 'center',
    width: '100%',
  },
  moveWrapper: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    top: 0,
    left: 0,
    padding: '4px 8px',
    opacity: 0,
    transitionDuration: '0.3s',
  },
  stepButton: {
    width: 18,
    height: 18,
    color: theme.palette.text.primary,
    opacity: 0.4,
    padding: 0,
    minWidth: 0,
    '& svg': {
      width: 20,
    },
  },
  cartQuantity: {
    borderRadius: '100%',
    position: 'absolute',
    right: 8,
    bottom: 8,
    width: 24,
    height: 24,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

interface IProductItemProps {
  product: IRegisterProductItem;
  isSortingItem?: boolean;
  cartQuantity?: number | null;
  onProductClick?: (product: IRegisterProductItem) => void;
  selectCategory: () => void;
  toggleSortingMode?: (value: boolean) => void;
  setShowDialog?: (value) => void;
  disabled?: boolean;
}

const ProductItem = React.memo(
  ({
    product,
    isSortingItem,
    cartQuantity,
    disabled,
    onProductClick,
    toggleSortingMode,
    selectCategory,
    setShowDialog,
  }: IProductItemProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const renderIntlMessage = useRenderIntlMessage();
    const classes = useStyles();
    const open = Boolean(anchorEl);
    const subItems = product.subItems;
    const isOutOfStock = !subItems && isOutOfStockProduct(product);

    const title = `${product.title}${
      !product.active ? ` (${renderIntlMessage(commonMessages.inactiveOption)})` : ''
    }`;

    const handleProductClick = useMemo(() => {
      return debounce(() => {
        onProductClick(product);
      });
    }, [onProductClick, product]);

    return (
      <Paper elevation={2} className={classes.root}>
        {isSortingItem && (
          <Box className={`${classes.moveWrapper} show-actions`} style={open ? { opacity: 1 } : {}}>
            <Button
              className={classes.stepButton}
              onMouseDown={() => toggleSortingMode && toggleSortingMode(true)}
            >
              <DragIndicator />
            </Button>
            <ActionsItemMenu
              product={product}
              type={product.type}
              setShowDialog={setShowDialog}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
          </Box>
        )}

        {product.type === MenuItemTypes.Item ? (
          <Box
            className={cx(classes.wrapContent, { [classes.disabled]: disabled })}
            onClick={() => onProductClick && handleProductClick()}
          >
            <Box mr={1} className={classes.avatarWrapper}>
              <Avatar className={classes.avatar} src={product?.imageUrl} alt="avatar-icon">
                <DefaultIcon />
              </Avatar>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography component="span" variant="h6" className={classes.title}>
                {title}
              </Typography>

              <Typography component="span" className={classes.status}>
                ${product.price?.toFixed(2)}
              </Typography>

              {isOutOfStock && (
                <Typography variant="caption" className={classes.errorText}>
                  <FormattedMessage {...commonMessages.outOfStockText} />
                </Typography>
              )}
            </Box>
            {cartQuantity && (
              <Box
                className={classes.cartQuantity}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography color="inherit" component="span">
                  {cartQuantity}
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <Box
            className={cx(classes.wrapContent, { [classes.disabled]: disabled })}
            onClick={selectCategory}
          >
            <Box className={classes.category}>{product.title}</Box>
          </Box>
        )}
      </Paper>
    );
  },
);

export default ProductItem;
