import React, { useEffect, useMemo } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';

import { DialogComponent, Table } from 'common/components/index';

import { useAppDispatch } from 'store/hooks';

import {
  fetchModificationHistoryItem,
  resetModificationHistoryAction,
} from 'common/components/PersonProfile/state/actions';
import {
  selectModificationHistoryItem,
  selectModificationHistoryItemLoading,
} from 'common/components/PersonProfile/state/selectors';

import {
  IChangedFieldImt,
  IModificationHistoryItemImt,
} from 'common/components/PersonProfile/interfaces';
import { IHeadCell, ITableRow } from 'common/interfaces/table';

import { PeakModules } from 'common/constants/peakModules';

import tableHeaders from 'common/messages/tableHeaders';

const headerOptions: IHeadCell[] = [
  {
    id: 'name',
    label: <FormattedMessage {...tableHeaders.name} />,
    sort: false,
  },
  {
    id: 'pastValue',
    label: <FormattedMessage {...tableHeaders.pastValue} />,
    sort: false,
  },
  {
    id: 'newValue',
    label: <FormattedMessage {...tableHeaders.newValue} />,
    sort: false,
  },
];

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  revisionId: number;
  personId: number;
  module: PeakModules;
}

const ModificationHistoryItemModal = ({
  isOpen,
  onClose,
  revisionId,
  personId,
  module,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const modificationHistoryItem: IModificationHistoryItemImt = useRootSelector(
    selectModificationHistoryItem(personId),
  );
  const isModificationHistoryItemLoading: boolean = useRootSelector(
    selectModificationHistoryItemLoading(personId),
  );

  useEffect(() => {
    dispatch(fetchModificationHistoryItem(personId, revisionId, module));
  }, [dispatch, module, personId, revisionId]);

  useEffect(() => {
    return () => {
      dispatch(resetModificationHistoryAction(null, personId));
    };
  }, [dispatch, personId]);

  const rows = useMemo(
    () =>
      modificationHistoryItem
        ?.get('changedFields')
        ?.map(
          (changedField: IChangedFieldImt): ITableRow => ({
            id: `${changedField.get('name')}-${changedField.get('newValue')} `,
            cells: [
              { label: changedField.get('name'), variant: 'h6' },
              { label: changedField.get('previousValue'), variant: 'h6' },
              { label: changedField.get('newValue'), variant: 'h6' },
            ],
          }),
        )
        .toJS() || [],
    [modificationHistoryItem],
  );

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      hideFooterButtons
      title={<FormattedMessage {...tableHeaders.historyOfChanges} />}
      loading={isModificationHistoryItemLoading}
      size="lg"
    >
      <Table
        rows={rows}
        isLoading={isModificationHistoryItemLoading}
        headerOptions={headerOptions}
        hidePagination
        hideSearchInput
        hideToolbar
      />
    </DialogComponent>
  );
};

export default ModificationHistoryItemModal;
