import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import thunk from 'redux-thunk';
import rootReducer from 'store/reducers';
import baseApi from 'api/baseApi';
import { ImmutableObject } from 'common/state/interfaces';

const store = configureStore({
  reducer: {
    root: rootReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: getDefaultMiddleware => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    });

    return defaultMiddleware.concat(thunk, baseApi.middleware);
  },
  devTools: process.env.NODE_ENV === 'development',
});

setupListeners(store.dispatch);

export type AppStoreType = ReturnType<typeof rootReducer>;
export type AppStoreTypeImt = ImmutableObject<AppStoreType>;
export type AppDispatch = typeof store.dispatch;

export default store;

/* const configureStore = () => {
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
};

const store = configureStore2();

export type AppDispatch = typeof store.dispatch;

export default store; */
