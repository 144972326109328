import React, { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MultipleSelect } from 'common/components';
import messages from '../../../messages/messages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { List as ImmutableList } from 'immutable';
import { TDocumentTemplateListItemImt } from 'common/interfaces/dictionary';
import useRootSelector from 'common/hooks/useRootSelector';
import * as dictionarySelectors from 'common/state/dictionary/selectors';
import { DictionaryList } from 'common/constants';
import { IGeneralSection } from '../../../interfaces/packages';
import IntlService from 'services/intl/IntlService';

const ContractTemplateField: FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IGeneralSection>();

  const renderIntlMessage = useRenderIntlMessage();

  const documentsList: ImmutableList<TDocumentTemplateListItemImt> = useRootSelector(
    dictionarySelectors.selectDictionaryList(DictionaryList.DOCUMENT_TEMPLATES),
  );

  const documentsOptions = useMemo(() => documentsList.toJS(), [documentsList]);

  return (
    <Controller
      control={control}
      name="documentTemplate"
      render={({ field }) => (
        <MultipleSelect
          options={documentsOptions}
          fullWidth
          label={IntlService.formatMessage(messages.waiverTemplateLabel)}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          error={!!errors.documentTemplate}
          helperText={renderIntlMessage(errors.documentTemplate?.message)}
        />
      )}
    />
  );
};

export default ContractTemplateField;
