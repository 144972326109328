// libraries
import React from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FieldArray, Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import cx from 'classnames';
// material
import { Box, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Cancel, Info } from '@material-ui/icons';
// state
import { fetchBillingOptions, resetBillingOptions } from 'modules/services/state/packages/actions';
import {
  selectBillingOptions,
  selectBillingOptionsLoading,
} from 'modules/services/state/packages/selectors';
// custom interfaces and components
import CheckBoxWithReset from 'common/components/CheckBoxWithReset/CheckBoxWithReset';
import { NumberTextField, Select } from 'common/components';
import StatusBlock from 'common/components/StatusBlock/StatusBlock';
import { CustomTheme } from 'common/ui/interfaces';
import {
  IncludedPackageBillingType,
  IncludedPaidPackageBillingTypes,
  IncludedFreePackageBillingTypes,
} from 'modules/services/constants/packages';
import { IIncludedService } from 'modules/services/interfaces/packages';
import { INamedEntityImt } from 'common/interfaces/common';
// utils
import { checkObjectPropertyExist } from 'common/utils';
import { useAppDispatch } from 'store/hooks';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
// messages

import messages from 'modules/services/messages/messages';
import inputLabels from 'common/messages/inputLabels';

interface IIncludedPackageItemProps {
  index: number;
  field: FieldArray;
  onRemove: (index: number) => void;
  onDetailsClick: (packageId: string) => void;
  onSelectBillingOption: (billingOptionId: string, packageId: string) => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  wrapFlexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  packageStatus: {
    marginLeft: theme.spacing(1),
  },
  relative: {
    position: 'relative',
  },
  headerTitle: {
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
  },
  removeButton: {
    color: theme.palette.text.primary,
    opacity: 0.4,
    position: 'absolute',
    right: theme.spacing(1.5),
  },
  excludableCheckbox: {
    justifyContent: 'flex-end',
    marginRight: theme.spacing(4),
  },
  billingOptionWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  viewBillingInfo: {
    marginLeft: theme.spacing(1),
  },
}));

const IncludedPackageItem: React.FC<IIncludedPackageItemProps> = ({
  index,
  field: inputField,
  onRemove,
  onDetailsClick,
  onSelectBillingOption,
}: IIncludedPackageItemProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const billingOptions: ImmutableList<INamedEntityImt> = useRootSelector(selectBillingOptions);
  const isBillingOptionsLoading: boolean = useRootSelector(selectBillingOptionsLoading);
  const { control, formState, watch } = useFormContext();
  const { errors } = formState;
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();
  const field = inputField as IIncludedService; // TODO - PRM-1810 need types

  // form

  const billingType = watch(`includedServices.${index}.billingType`);
  const billingOption = watch(`includedServices.${index}.billingOption`);

  // handlers

  const handleOpenPackageInfo = () => {
    onDetailsClick(field.package.id);
  };

  const handleOpenBillingOptionInfoModal = () => {
    onSelectBillingOption(billingOption.id, field.package.id);
  };

  const getBillingOptions = () => {
    dispatch(fetchBillingOptions(field.package.id));
  };

  const handleResetBillingOptions = () => {
    dispatch(resetBillingOptions());
  };

  const { hasOwnBillingSettings } = field.package || {};

  // renders

  return (
    <Grid container spacing={2} key={field.id}>
      {/* title */}

      <Grid item xs={12} className={cx(classes.wrapFlexCenter, classes.relative)}>
        <Grid item xs={6}>
          <Box className={classes.header}>
            <Typography variant="h5" className={classes.headerTitle}>
              {field.package.title}
            </Typography>
            <IconButton size="small" type="button" color="primary" onClick={handleOpenPackageInfo}>
              <Info />
            </IconButton>

            {checkObjectPropertyExist(field.package, 'active') && !field.package.active && (
              <StatusBlock isActive={false} className={classes.packageStatus} />
            )}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            name={`includedServices.${index}.excludable`}
            defaultValue={field.excludable || false}
            render={({ field: formField }) => (
              <CheckBoxWithReset
                value={formField.value}
                onChange={formField.onChange}
                label={<FormattedMessage {...messages.canBeRemovedUponSaleLabel} />}
                wrapperClassName={classes.excludableCheckbox}
              />
            )}
          />
        </Grid>
        <IconButton
          size="small"
          type="button"
          className={classes.removeButton}
          onClick={() => onRemove(index)}
        >
          <Cancel fontSize="inherit" />
        </IconButton>
      </Grid>

      {/* configuration */}

      <Grid item xs={4}>
        <Controller
          control={control}
          name={`includedServices.${index}.billingType`}
          defaultValue={field.billingType}
          render={({ field: formField }) => {
            const PackageBillingTypes = hasOwnBillingSettings
              ? IncludedPaidPackageBillingTypes
              : IncludedFreePackageBillingTypes;
            return (
              <Select
                fullWidth
                label={<FormattedMessage {...inputLabels.paymentType} />}
                variant="outlined"
                name={formField.name}
                value={formField.value}
                onChange={formField.onChange}
                onBlur={formField.onBlur}
                error={!!errors.includedServices?.[index]?.limited?.message}
                helperText={renderIntlMessage(
                  errors.includedServices?.[index]?.billingType?.message,
                )}
              >
                {PackageBillingTypes.getSelectOptions()}
              </Select>
            );
          }}
        />
      </Grid>

      {billingType === IncludedPackageBillingType.OneTimePayment && (
        <Grid item xs={3}>
          <Controller
            control={control}
            name={`includedServices.${index}.billingAmount`}
            defaultValue={field.billingAmount || 0}
            render={({ field: formField }) => (
              <NumberTextField
                numberFormatProps={{
                  prefix: '$',
                  decimalScale: 2,
                  fixedDecimalScale: true,
                  allowLeadingZeros: false,
                  allowNegative: false,
                  isNumericString: true,
                  type: 'text',
                }}
                variant="outlined"
                label={<FormattedMessage {...inputLabels.amount} />}
                value={formField.value}
                onChange={v => {
                  if (v !== null && v !== '') {
                    formField.onChange(Number(v));
                  } else {
                    formField.onChange(v);
                  }
                }}
                onBlur={formField.onBlur}
                fullWidth
                error={!!errors.includedServices?.[index]?.billingAmount?.message}
                helperText={renderIntlMessage(
                  errors.includedServices?.[index]?.billingAmount?.message,
                )}
              />
            )}
          />
        </Grid>
      )}

      {billingType === IncludedPackageBillingType.OwnBillingSettings && (
        <Grid item xs={4} className={classes.billingOptionWrapper}>
          <Controller
            name={`includedServices.${index}.billingOption`}
            control={control}
            render={({ field: formField }) => (
              <MuiAutocomplete
                fullWidth
                value={formField.value}
                onBlur={formField.onBlur}
                onChange={(_, option) => formField.onChange(option)}
                getOptionSelected={(option, selected) => option?.id === selected?.id}
                multiple={false}
                disableClearable
                onOpen={getBillingOptions}
                onClose={handleResetBillingOptions}
                loading={isBillingOptionsLoading}
                options={billingOptions.toJS()}
                getOptionLabel={option => (option ? option.title : '')}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={<FormattedMessage {...inputLabels.paymentOption} />}
                    variant="outlined"
                    fullWidth
                    error={!!errors.includedServices?.[index]?.billingOption}
                    helperText={renderIntlMessage(
                      errors.includedServices?.[index]?.billingOption?.message,
                    )}
                  />
                )}
              />
            )}
          />

          {billingOption && (
            <IconButton
              size="small"
              type="button"
              color="primary"
              className={classes.viewBillingInfo}
              onClick={handleOpenBillingOptionInfoModal}
            >
              <Info />
            </IconButton>
          )}
        </Grid>
      )}

      <Controller
        name={`includedServices.${index}.package`}
        control={control}
        defaultValue={field.package}
        render={() => null}
      />
    </Grid>
  );
};

export default IncludedPackageItem;
