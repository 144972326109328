import { fromJS } from 'immutable';

import { ActionPrimaryInfoTypes } from 'common/state/newPerson/primaryInfo/actions';
import { actionTypes } from 'common/state/newPerson/contract/constants';

const initialContractState = fromJS({
  contracts: [],
  result: {},
  actionResult: null,
  error: null,
  isLoading: false,
  isContractHTMLLoading: false,
});

const contractReducer = (
  state = initialContractState,
  { type, payload }: ActionPrimaryInfoTypes,
) => {
  switch (type) {
    case actionTypes.SET_IS_LOADING:
      return state.set('isLoading', payload);
    case actionTypes.FETCH_CONTRACT_HTML_LOADING:
      return state.set('isContractHTMLLoading', payload);
    case actionTypes.GET_CONTRACTS:
      return state.set('contracts', fromJS(payload));
    case actionTypes.GET_CONTRACT:
    case actionTypes.APPLY_CONTRACT:
    case actionTypes.SIGN_CONTRACT:
      return state.set('result', payload);
    case actionTypes.SIGN_CONTRACT_ACTION_RESULT:
      return state.set('actionResult', payload);
    case actionTypes.SET_ERROR:
      return state.set('error', payload);
    case actionTypes.CLEAR_CONTRACT:
      return initialContractState;
    default:
      return state;
  }
};

export default contractReducer;
