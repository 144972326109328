import { Message } from 'common/interfaces/common';
import inputErrors from 'common/messages/inputErrors';

export function getRequiredErrorMessage(): Message {
  return inputErrors.requiredFieldError;
}

export function getNumberErrorMessage(): Message {
  return inputErrors.numberFieldError;
}

export function getMinErrorMessage(params: { min: number }): Message {
  return {
    ...inputErrors.valueMinLengthError,
    values: {
      value: params.min,
    },
  };
}

export function getMaxErrorMessage(params: { max: number }): Message {
  return {
    ...inputErrors.valueMaxLengthError,
    values: {
      value: params.max,
    },
  };
}
