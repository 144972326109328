import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import useRootSelector from 'common/hooks/useRootSelector';
import { v4 as uuid } from 'uuid';

import * as actions from 'common/components/PersonProfile/state/actions';
import * as selectors from 'common/components/PersonProfile/state/selectors';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import tableFilters from 'common/messages/tableFilters';
import { automaticManualOptions } from 'common/constants/historyModal';
import modalMessages from 'common/messages/modalTitles';
import commonMessages from 'common/messages/messages';
import tableHeaders from 'common/messages/tableHeaders';
import { makeTableParams } from 'common/utils/http';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { useAppDispatch } from 'store/hooks';
import { TableOrderByParams } from 'common/constants';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';

import { DialogComponent, Table } from 'common/components/index';

const headerOptions: IHeadCell[] = [
  {
    id: TableOrderByParams.DATE,
    label: <FormattedMessage {...tableHeaders.date} />,
    sort: true,
  },
  {
    id: TableOrderByParams.CAMPAIGN,
    label: <FormattedMessage {...tableHeaders.campaign} />,
    sort: true,
  },
  {
    id: TableOrderByParams.TYPE,
    label: <FormattedMessage {...tableHeaders.type} />,
    sort: true,
  },
];

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  personId: number;
  module: PeakModuleForNewPersonType;
}

const CampaignsHistoryModal = ({ isOpen, onClose, personId, module }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [timezoneMoment] = useTimezoneMoment();

  const historyMeta = useRootSelector(selectors.selectPersonCampaignsHistoryMeta(personId));
  const history = useRootSelector(selectors.selectPersonCampaignsHistory(personId));
  const loading = useRootSelector(selectors.selectPersonCampaignsHistoryLoading(personId));

  const tableFilterSettings: IFilterSettings[] = useMemo(
    () => [
      {
        name: 'range',
        title: <FormattedMessage {...tableFilters.period} />,
        type: FilterTypes.DATE_RANGE,
        options: {
          startDate: null,
          endDate: null,
        },
      },
      {
        name: 'automatic',
        title: <FormattedMessage {...tableFilters.type} />,
        type: FilterTypes.SINGLE,
        options: automaticManualOptions,
      },
    ],
    [],
  );

  const rows: ITableRow[] = useMemo(() => {
    return history
      ?.map(campaignItem => {
        return {
          id: uuid(),
          cells: [
            {
              label: campaignItem.get('date')
                ? timezoneMoment(campaignItem.get('date')).format(DEFAULT_DATE_TIME_FORMAT)
                : '-',
            },
            {
              label: campaignItem.get('title'),
            },
            {
              label: (
                <FormattedMessage
                  {...(campaignItem.get('automatic')
                    ? commonMessages.automaticLabel
                    : commonMessages.manualLabel)}
                />
              ),
            },
          ],
        };
      })
      .toJS();
  }, [history, timezoneMoment]);

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilterSettings, null, {
      orderBy: TableOrderByParams.DATE,
      order: 'desc',
    }),
  );

  const onChangeTableParams = useCallback((tableProps: ITableParams): void => {
    setTableParams(tableProps);
  }, []);

  useEffect(() => {
    dispatch(actions.fetchPersonCampaignsHistory(personId, module, tableParams));
  }, [dispatch, module, personId, tableParams]);

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage {...modalMessages.campaignsHistory} />}
      size="xl"
      hideFooterButtons
    >
      <Table
        rows={rows}
        tableParams={tableParams}
        headerOptions={headerOptions}
        filters={tableFilterSettings}
        onChangeParams={onChangeTableParams}
        totalRows={historyMeta?.get('total')}
        page={historyMeta?.get('page')}
        isLoading={loading}
        hideToolbar
        showPerPageSelect
        hideSearchInput
      />
    </DialogComponent>
  );
};

export default CampaignsHistoryModal;
