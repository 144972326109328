import React, { useEffect } from 'react';

import { EXECUTE_MEMBER_PORTAL_ACTIONS } from 'common/constants/delayedActionsKeys';
import StorageServices from 'services/storage';
import InvoiceOperating from '../InvoiceOperating/InvoiceOperating';
import { useDispatch } from 'react-redux';
import { selectMemberInvoice } from 'memberPortalModules/MemberProfile/state/invoice/selectors';
import { IInvoiceDetailsImt } from 'common/interfaces/invoices';
import { fetchMemberInvoiceThunk } from 'memberPortalModules/MemberProfile/state/invoice/actions';
import useRootSelector from 'common/hooks/useRootSelector';

interface IProps {
  onCancel?: () => void;
  onNext?: () => void;
  isMemberCard?: boolean;
}

const { execute, isExitDelayedAction } = StorageServices.delayedActions;

const PaymentStep = ({ onNext, onCancel, isMemberCard }: IProps): JSX.Element => {
  const dispatch = useDispatch();

  const memberInvoice: IInvoiceDetailsImt = useRootSelector(selectMemberInvoice);

  const isExitMemberPortalDelayedAction = isExitDelayedAction(EXECUTE_MEMBER_PORTAL_ACTIONS);

  useEffect(() => {
    if (memberInvoice && isExitMemberPortalDelayedAction) {
      execute(EXECUTE_MEMBER_PORTAL_ACTIONS, [memberInvoice]);
    }
  }, [memberInvoice, isExitMemberPortalDelayedAction]);

  useEffect(() => {
    if (!memberInvoice) {
      dispatch(fetchMemberInvoiceThunk());
    }
  }, [memberInvoice, dispatch]);

  return (
    <>
      <InvoiceOperating
        shouldGoBackAfterRevokeInvoice
        onBack={onCancel}
        onNext={onNext}
        isMemberCard={isMemberCard}
      />
    </>
  );
};

export default PaymentStep;
