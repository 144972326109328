import React, { useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';

import { useAppDispatch } from 'store/hooks';

import {
  fetchInvoiceInventoryInfo,
  resetInvoiceInventoryInfoAction,
} from 'modules/reports/state/invoiceHistory/actions';
import {
  selectInventoryDetailedInfo,
  selectInventoryDetailedInfoLoading,
} from 'modules/reports/state/invoiceHistory/selectors';
import { IInventoryDetailedInfoImt } from 'modules/reports/interfaces/invoiceHistory';

import { InventoryViewModal } from 'common/components';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  inventoryId: string;
  invoiceId: string;
  isPackageInventory?: boolean;
}

const ViewInventoryInfoModal = ({
  inventoryId,
  invoiceId,
  isOpen,
  isPackageInventory,
  onClose,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const inventoryInfo = useRootSelector(selectInventoryDetailedInfo);
  const isInventoryInfoLoading: boolean = useRootSelector(selectInventoryDetailedInfoLoading);

  useEffect(() => {
    dispatch(fetchInvoiceInventoryInfo(invoiceId, inventoryId, isPackageInventory));
  }, [dispatch, inventoryId, invoiceId, isPackageInventory]);

  useEffect(() => {
    return () => {
      dispatch(resetInvoiceInventoryInfoAction());
    };
  }, [dispatch]);

  return (
    <InventoryViewModal<IInventoryDetailedInfoImt>
      inventoryInfo={inventoryInfo}
      onClose={onClose}
      isOpen={isOpen}
      isLoading={isInventoryInfoLoading}
    />
  );
};

export default ViewInventoryInfoModal;
