import React, { useCallback, useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useFormContext, useFieldArray, Controller, useWatch } from 'react-hook-form';
import { List as ImmutableList } from 'immutable';
import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { MultipleSelect } from 'common/components';
// interfaces
import { IEmployeeRoleImt, IEmployeeRole } from 'common/interfaces/employee';
// constants
import { PermissionLevels, PermissionsLevelsLabels } from 'common/constants/permissions';
// redux
import { selectRoles } from 'modules/employees/state/selectors';
import { selectCurrentCorporateUserAvailableClubs } from 'modules/authentication/state/selectors';
import { fetchRolesList } from 'modules/employees/state/actions';
// components
import ViewRoleModal from 'modules/permissions/components/ViewRoleModal/ViewRoleModal';
// hooks
import { useAppDispatch } from 'store/hooks';
// messages
import { FormattedMessage } from 'react-intl';
import commonMessages from 'common/messages/messages';
import employeesMessages from 'common/messages/employeesMessages';
import inputLabels from 'common/messages/inputLabels';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { IFormEmployeePermission } from '../../../modules/employees/interfaces/employeeInterfaces';

interface IPermissionsSectionProps {
  isVisibleTitle?: boolean;
}

function PermissionsSection(props: IPermissionsSectionProps): JSX.Element {
  const { isVisibleTitle = true } = props;
  const { control, formState } = useFormContext<IFormEmployeePermission>();
  const dispatch = useAppDispatch();
  const { errors } = formState;
  const [selectedRow, setSelectedRow] = useState<Partial<IEmployeeRole> | null>(null);

  const availableRoles: ImmutableList<IEmployeeRoleImt> = useRootSelector(selectRoles);
  const clubs = useRootSelector(selectCurrentCorporateUserAvailableClubs);

  const handleViewDialogShow = useCallback((roleItem: Partial<IEmployeeRole>) => {
    setSelectedRow(roleItem);
  }, []);

  const handleViewDialogClose = useCallback(() => {
    setSelectedRow(null);
  }, []);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'roles',
  });

  const roles = useWatch({ control, name: 'roles' });

  const renderIntlMessage = useRenderIntlMessage();

  useEffect(() => {
    dispatch(fetchRolesList());
  }, [dispatch]);

  return (
    <>
      <Grid container spacing={1}>
        {isVisibleTitle && (
          <Grid item xs={12}>
            <Typography variant="button" color="textSecondary" gutterBottom>
              <FormattedMessage {...employeesMessages.permissions} />
            </Typography>
          </Grid>
        )}
        {fields.map(({ clubList, securityRole, id }, index) => {
          const roleItem = roles[index]?.securityRole;
          const level = roles[index]?.securityRole?.level;
          const showClubInput = !roleItem || level === PermissionLevels.CLIENT;

          return (
            <Grid item container xs={12} spacing={1} key={id} wrap="nowrap">
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <Controller
                            name={`roles.${index}.securityRole`}
                            control={control}
                            defaultValue={securityRole}
                            render={({ field }) => (
                              <MultipleSelect
                                fullWidth
                                onBlur={field.onBlur}
                                value={field.value}
                                onChange={field.onChange}
                                options={availableRoles
                                  ?.sort(role =>
                                    role.get('level') === PermissionLevels.CORPORATE ? -1 : 1,
                                  )
                                  .toJS()}
                                groupBy={option => PermissionsLevelsLabels[option.level]}
                                label={<FormattedMessage {...inputLabels.role} />}
                                error={!!errors.roles?.[index]?.securityRole}
                                helperText={renderIntlMessage(
                                  errors.roles?.[index]?.securityRole?.message,
                                )}
                              />
                            )}
                          />
                          <Box mt={1} mb={2}>
                            <Button
                              type="button"
                              variant="text"
                              className="btn-text"
                              onClick={() => handleViewDialogShow(roleItem)}
                            >
                              <Typography variant="body1" style={{ textTransform: 'none' }}>
                                <FormattedMessage {...employeesMessages.viewPermissions} />
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                      {showClubInput ? (
                        <Grid item xs={12} sm={6}>
                          <Controller
                            name={`roles.${index}.clubList`}
                            control={control}
                            defaultValue={clubList}
                            render={({ field }) => (
                              <MultipleSelect
                                multiple
                                fullWidth
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                options={clubs?.toJS()}
                                label={<FormattedMessage {...commonMessages.clubsLabel} />}
                                error={!!errors.roles?.[index]?.clubList}
                                helperText={renderIntlMessage(
                                  errors.roles?.[index]?.clubList?.message,
                                )}
                              />
                            )}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={6}>
                          <Box pl={2} pt={2}>
                            <Typography>
                              <FormattedMessage {...employeesMessages.corporateAccess} />
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Box pt={1}>
                  <IconButton onClick={() => remove(index)} style={{ padding: '6px' }}>
                    <DeleteIcon fontSize="small" color="secondary" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Button
            type="button"
            variant="text"
            className="btn-text"
            disabled={!!errors.roles}
            onClick={() => append({ clubList: [], securityRole: null })}
            color="primary"
          >
            <AddIcon />
            <FormattedMessage {...commonMessages.addBtn} />
          </Button>
        </Grid>
      </Grid>
      <ViewRoleModal
        selectedRow={selectedRow}
        isOpen={!!selectedRow}
        onClose={handleViewDialogClose}
      />
    </>
  );
}

export default React.memo(PermissionsSection);
