import { defineMessages } from 'react-intl';

export default defineMessages({
  privateKey: {
    id: 'app.common.inputLabels.privateKey',
    defaultMessage: 'Private key',
  },
  digitalCertificateKey: {
    id: 'app.common.inputLabels.digitalCertificateKey',
    defaultMessage: 'Digital Certificate key',
  },
  date: {
    id: 'app.common.inputLabels.date',
    defaultMessage: 'Date',
  },
  time: {
    id: 'app.common.inputLabels.time',
    defaultMessage: 'Time',
  },
  availability: {
    id: 'app.common.inputLabels.availability',
    defaultMessage: 'Days and hours of availability',
  },
  availabilityNoLimit: {
    id: 'app.common.inputLabels.availabilityNoLimit',
    defaultMessage: 'Availabile all the time',
  },
  totalCash: {
    id: 'app.common.inputLabels.totalCash',
    defaultMessage: 'Total cash',
  },
  total: {
    id: 'app.common.inputLabels.total',
    defaultMessage: 'Total',
  },
  count: {
    id: 'app.common.inputLabels.count',
    defaultMessage: 'Count',
  },
  discounts: {
    id: 'app.common.inputLabels.discounts',
    defaultMessage: 'Discounts',
  },
  netSales: {
    id: 'app.common.inputLabels.netSales',
    defaultMessage: 'Net sales',
  },
  taxRate: {
    id: 'app.common.inputLabels.taxRate',
    defaultMessage: 'Tax Rate',
  },
  register: {
    id: 'app.common.inputLabels.register',
    defaultMessage: 'Register',
  },
  items: {
    id: 'app.common.inputLabels.items',
    defaultMessage: 'Items',
  },
  paidTotal: {
    id: 'app.common.inputLabels.paidTotal',
    defaultMessage: 'Paid total',
  },
  deferred: {
    id: 'app.common.inputLabels.deferred',
    defaultMessage: 'Deferred',
  },
  taxAmount: {
    id: 'app.common.inputLabels.taxAmount',
    defaultMessage: 'Tax amount',
  },
  gratuity: {
    id: 'app.common.inputLabels.gratuity',
    defaultMessage: 'Gratuity',
  },
  accessAsClub: {
    id: 'app.common.inputLabels.accessAsClub',
    defaultMessage: 'Same as club working hours',
  },
  accessAsPackage: {
    id: 'app.common.inputLabels.accessAsPackage',
    defaultMessage: 'Same as bundle access',
  },
  addCondition: {
    id: 'app.common.inputLabels.addCondition',
    defaultMessage: 'Add condition',
  },
  addFilter: {
    id: 'app.common.inputLabels.addFilter',
    defaultMessage: 'Add filter',
  },
  category: {
    id: 'app.common.inputLabels.category',
    defaultMessage: 'Category',
  },
  categories: {
    id: 'app.common.inputLabels.categories',
    defaultMessage: 'Categories',
  },
  name: {
    id: 'app.common.inputLabels.name',
    defaultMessage: 'Name',
  },
  templateName: {
    id: 'app.common.inputLabels.templateName',
    defaultMessage: 'Template name',
  },
  script: {
    id: 'app.common.inputLabels.script',
    defaultMessage: 'Script',
  },
  firstName: {
    id: 'app.common.inputLabels.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'app.common.inputLabels.lastName',
    defaultMessage: 'Last Name',
  },
  businessName: {
    id: 'app.common.inputLabels.businessName',
    defaultMessage: 'Business Name',
  },
  status: {
    id: 'app.common.inputLabels.status',
    defaultMessage: 'Status',
  },
  barcode: {
    id: 'app.common.inputLabels.barcode',
    defaultMessage: 'Barcode',
  },
  barcodeHelpText: {
    id: 'app.common.inputLabels.barcode.help',
    defaultMessage: 'If you leave this field blank barcode will be autogenerated later.',
  },
  description: {
    id: 'app.common.inputLabels.description',
    defaultMessage: 'Description',
  },
  location: {
    id: 'app.common.inputLabels.location',
    defaultMessage: 'Location',
  },
  preferredVendor: {
    id: 'app.common.inputLabels.preferredVendor',
    defaultMessage: 'Preferred Vendor',
  },
  vendorCatalog: {
    id: 'app.common.inputLabels.vendorCatalog',
    defaultMessage: 'Vendor Catalog',
  },
  costPerItem: {
    id: 'app.common.inputLabels.costPerItem',
    defaultMessage: 'Cost per Item $',
  },
  retailPrice: {
    id: 'app.common.inputLabels.retailPrice',
    defaultMessage: 'Retail Price $',
  },
  pricePerBilling: {
    id: 'app.common.inputLabels.pricePerBilling',
    defaultMessage: 'Price per billing',
  },
  revenueCode: {
    id: 'app.common.inputLabels.revenueCode ',
    defaultMessage: 'Revenue Code',
  },
  retailPriceTax: {
    id: 'app.common.inputLabels.retailPriceTax',
    defaultMessage: 'Retail Price + Tax',
  },
  onHand: {
    id: 'app.common.inputLabels.onHand',
    defaultMessage: 'On Hand',
  },
  idealStock: {
    id: 'app.common.inputLabels.idealStock',
    defaultMessage: 'Ideal Stock',
  },
  club: {
    id: 'app.common.inputLabels.club',
    defaultMessage: 'Club',
  },
  reorder: {
    id: 'app.common.inputLabels.reorder',
    defaultMessage: 'Reorder',
  },
  dateOfBirth: {
    id: 'app.common.inputLabels.dateOfBirth',
    defaultMessage: 'Date of Birth',
  },
  email: {
    id: 'app.common.inputLabels.email',
    defaultMessage: 'Email',
  },
  emailLogin: {
    id: 'app.common.inputLabels.emailLogin',
    defaultMessage: 'Email (Login)',
  },
  emailTemplate: {
    id: 'app.common.inputLabels.emailTemplate',
    defaultMessage: 'Email template',
  },
  smsTemplate: {
    id: 'app.common.inputLabels.smsTemplate',
    defaultMessage: 'Sms template',
  },
  country: {
    id: 'app.common.inputLabels.country',
    defaultMessage: 'Country',
  },
  state: {
    id: 'app.common.inputLabels.state',
    defaultMessage: 'State',
  },
  city: {
    id: 'app.common.inputLabels.city',
    defaultMessage: 'City',
  },
  line: {
    id: 'app.common.inputLabels.line',
    defaultMessage: 'Line',
  },
  zipCode: {
    id: 'app.common.inputLabels.zipCode',
    defaultMessage: 'Zip Code',
  },
  phone: {
    id: 'app.common.inputLabels.phone',
    defaultMessage: 'Phone',
  },
  payerSupportPhone: {
    id: 'app.common.inputLabels.payerSupportPhone',
    defaultMessage: 'Payer Support Phone',
  },
  clientSupportPhone: {
    id: 'app.common.inputLabels.clientSupportPhone',
    defaultMessage: 'Client Support Phone',
  },
  type: {
    id: 'app.common.inputLabels.type',
    defaultMessage: 'Type',
  },
  level: {
    id: 'app.common.inputLabels.level',
    defaultMessage: 'Level',
  },
  activities: {
    id: 'app.common.inputLabels.activities',
    defaultMessage: 'Activities',
  },
  limitations: {
    id: 'app.common.inputLabels.limitations',
    defaultMessage: 'Limitations',
  },
  limitation: {
    id: 'app.common.inputLabels.limitation',
    defaultMessage: 'Limitation',
  },
  dateOfCreation: {
    id: 'app.common.inputLabels.dateOfCreation',
    defaultMessage: 'Date of Creation',
  },
  channel: {
    id: 'app.common.inputLabels.channel',
    defaultMessage: 'Channel',
  },
  value: {
    id: 'app.common.inputLabels.value',
    defaultMessage: 'Value',
  },
  salesValue: {
    id: 'app.common.inputLabels.salesValue',
    defaultMessage: 'Sales value',
  },
  preferableWayOfCommunication: {
    id: 'app.common.inputLabels.preferableWayOfCommunication',
    defaultMessage: 'Preferable way of communication',
  },
  assignedTo: {
    id: 'app.common.inputLabels.assignedTo',
    defaultMessage: 'Assigned to',
  },
  leadType: {
    id: 'app.common.inputLabels.leadType',
    defaultMessage: 'Lead type',
  },
  lead: {
    id: 'app.common.inputLabels.lead',
    defaultMessage: 'Lead',
  },
  ptLead: {
    id: 'app.common.inputLabels.ptLead',
    defaultMessage: 'PT Lead',
  },
  from: {
    id: 'app.common.inputLabels.from',
    defaultMessage: 'From',
  },
  fromName: {
    id: 'app.common.inputLabels.fromName',
    defaultMessage: 'From name',
  },
  to: {
    id: 'app.common.inputLabels.to',
    defaultMessage: 'To',
  },
  allowTextMessages: {
    id: 'app.common.inputLabels.allowTextMessages',
    defaultMessage: 'Allow text messages',
  },
  allowAutomatedCalls: {
    id: 'app.common.inputLabels.allowAutomatedCalls',
    defaultMessage: 'Allow automated calls',
  },
  useAsDefault: {
    id: 'app.common.inputLabels.useAsDefault',
    defaultMessage: 'Use as default',
  },
  stepName: {
    id: 'app.common.inputLabels.stepName',
    defaultMessage: 'Step name',
  },
  allowsInput: {
    id: 'app.common.inputLabels.allowsInput',
    defaultMessage: 'Allows input',
  },
  instructions: {
    id: 'app.common.inputLabels.instructions',
    defaultMessage: 'Instructions',
  },
  startDate: {
    id: 'app.common.inputLabels.startDateLabel',
    defaultMessage: 'Start date',
  },
  endDate: {
    id: 'app.common.inputLabels.endDateLabel',
    defaultMessage: 'End date',
  },
  startTime: {
    id: 'app.common.inputLabels.startTime',
    defaultMessage: 'Start time',
  },
  endTime: {
    id: 'app.common.inputLabels.endTime',
    defaultMessage: 'End time',
  },
  allDay: {
    id: 'app.common.inputLabels.allDay',
    defaultMessage: 'All day',
  },
  varianceNote: {
    id: 'app.common.inputLabels.varianceNote',
    defaultMessage: 'Variance note',
  },
  paymentType: {
    id: 'app.common.inputLabels.paymentType',
    defaultMessage: 'Payment type',
  },
  paymentMethod: {
    id: 'app.common.inputLabels.paymentMethod',
    defaultMessage: 'Payment method',
  },
  amountTendered: {
    id: 'app.common.inputLabels.amountTendered',
    defaultMessage: 'Amount Tendered',
  },
  taxes: {
    id: 'app.common.inputLabels.taxes',
    defaultMessage: 'Taxes',
  },
  tax: {
    id: 'app.common.inputLabels.tax',
    defaultMessage: 'Tax',
  },
  rate: {
    id: 'app.common.inputLabels.rate',
    defaultMessage: 'Rate %',
  },
  topic: {
    id: 'app.common.inputLabels.topic',
    defaultMessage: 'Topic',
  },
  phoneToCall: {
    id: 'app.common.inputLabels.phoneToCall',
    defaultMessage: 'Phone used to call',
  },
  leadMemberPhone: {
    id: 'app.common.inputLabels.leadMemberPhone',
    defaultMessage: 'Lead/Member phone',
  },
  dateOfCall: {
    id: 'app.common.inputLabels.dateOfCall',
    defaultMessage: 'Date of call',
  },
  campaign: {
    id: 'app.common.inputLabels.campaign',
    defaultMessage: 'Campaign',
  },
  note: {
    id: 'app.common.inputLabels.note',
    defaultMessage: 'Note',
  },
  callNote: {
    id: 'app.common.inputLabels.callNote',
    defaultMessage: 'Call note',
  },
  appointmentType: {
    id: 'app.common.inputLabels.appointmentType',
    defaultMessage: 'Appointment type',
  },
  salespersonAssigned: {
    id: 'app.common.inputLabels.salespersonAssigned',
    defaultMessage: 'Salesperson assigned',
  },
  salesperson: {
    id: 'app.common.inputLabels.salesperson',
    defaultMessage: 'Salesperson',
  },
  customer: {
    id: 'app.common.inputLabels.customer',
    defaultMessage: 'Customer',
  },
  duration: {
    id: 'app.common.inputLabels.duration',
    defaultMessage: 'Duration',
  },
  reminderBefore: {
    id: 'app.common.inputLabels.reminderBefore',
    defaultMessage: 'Reminder before',
  },
  descriptionOptional: {
    id: 'app.common.inputLabels.descriptionOptional',
    defaultMessage: 'Description (optional)',
  },
  line2: {
    id: 'app.common.inputLabels.line2',
    defaultMessage: 'Address 2',
  },
  line1: {
    id: 'app.common.inputLabels.line1',
    defaultMessage: 'Address 1',
  },
  preferredName: {
    id: 'app.common.inputLabels.preferredName',
    defaultMessage: 'Preferred Name (Optional)',
  },
  contactFullName: {
    id: 'app.common.inputLabels.contactFullName',
    defaultMessage: 'Contact Full Name',
  },
  fullName: {
    id: 'app.common.inputLabels.fullName',
    defaultMessage: 'Full Name',
  },
  address: {
    id: 'app.common.inputLabels.address',
    defaultMessage: 'Address',
  },
  addressLine1: {
    id: 'app.common.inputLabels.addressLine1',
    defaultMessage: 'Address Line 1',
  },
  addressLine2: {
    id: 'app.common.inputLabels.addressLine2',
    defaultMessage: 'Address Line 2 (optional)',
  },
  address1: {
    id: 'app.common.inputLabels.address1',
    defaultMessage: 'Address 1',
  },
  address2: {
    id: 'app.common.inputLabels.address2',
    defaultMessage: 'Address 2 (Optional)',
  },
  id: {
    id: 'app.common.inputLabels.id',
    defaultMessage: 'Id',
  },
  website: {
    id: 'app.common.inputLabels.website',
    defaultMessage: 'Website',
  },
  contact: {
    id: 'app.common.inputLabels.contact',
    defaultMessage: 'Contact',
  },
  accountNumber: {
    id: 'app.common.inputLabels.accountNumber',
    defaultMessage: 'Account number',
  },
  code: {
    id: 'app.common.inputLabels.code',
    defaultMessage: 'Code',
  },
  printReceipt: {
    id: 'app.common.inputLabels.printReceipt',
    defaultMessage: 'Print receipt',
  },
  sendEmail: {
    id: 'app.common.inputLabels.sendEmail',
    defaultMessage: 'Send via Email',
  },
  sendSms: {
    id: 'app.common.inputLabels.sendSms',
    defaultMessage: 'Send SMS text',
  },
  sendNotification: {
    id: 'app.common.inputLabels.sendNotification',
    defaultMessage: 'Send via Notification',
  },
  sendReceipt: {
    id: 'app.common.inputLabels.sendReceipt',
    defaultMessage: 'Send receipt via',
  },
  referralOptional: {
    id: 'app.common.inputLabels.referralOptional',
    defaultMessage: 'Referral (Optional)',
  },
  referral: {
    id: 'app.common.inputLabels.referral',
    defaultMessage: 'Referral',
  },
  socialMediaOptional: {
    id: 'app.common.inputLabels.socialMediaOptional',
    defaultMessage: 'Social media (Optional)',
  },
  otherOptional: {
    id: 'app.common.inputLabels.otherOptional',
    defaultMessage: 'Other (Optional)',
  },
  policyType: {
    id: 'app.common.inputLabels.policyType',
    defaultMessage: 'Policy type',
  },
  policies: {
    id: 'app.common.inputLabels.policies',
    defaultMessage: 'Policies',
  },
  resourceName: {
    id: 'app.common.inputLabels.resourceName',
    defaultMessage: 'Resource name',
  },
  linkedEmployee: {
    id: 'app.common.inputLabels.linkedEmployee',
    defaultMessage: 'Linked employee (optional)',
  },
  resourcesTags: {
    id: 'app.common.inputLabels.resourcesTags',
    defaultMessage: 'Resources tags',
  },
  resources: {
    id: 'app.common.inputLabels.resources',
    defaultMessage: 'Resources',
  },
  eventsTags: {
    id: 'app.common.inputLabels.eventsTags',
    defaultMessage: 'Events tags',
  },
  maritalStatus: {
    id: 'app.common.inputLabels.maritalStatus',
    defaultMessage: 'Marital Status',
  },
  region: {
    id: 'app.common.inputLabels.region',
    defaultMessage: 'Region',
  },
  timezone: {
    id: 'app.common.inputLabels.timezone',
    defaultMessage: 'Timezone',
  },
  sicCode: {
    id: 'app.common.inputLabels.sicCode',
    defaultMessage: 'SIC Code',
  },
  role: {
    id: 'app.common.inputLabels.role',
    defaultMessage: 'Role',
  },
  clubCode: {
    id: 'app.common.inputLabels.clubCode',
    defaultMessage: 'Club code',
  },
  cardHolderName: {
    id: 'app.common.inputLabels.cardHolderName',
    defaultMessage: 'Cardholder Name',
  },
  cardNumber: {
    id: 'app.common.inputLabels.cardNumber',
    defaultMessage: 'Card number',
  },
  cardExpiredIn: {
    id: 'app.common.inputLabels.cardExpiredIn',
    defaultMessage: 'MM/YY',
  },
  cvvCode: {
    id: 'app.common.inputLabels.cvvCode',
    defaultMessage: 'CVV',
  },
  amountPercent: {
    id: 'app.common.inputLabels.amountPercent',
    defaultMessage: 'Amount $',
  },
  amount: {
    id: 'app.common.inputLabels.amount',
    defaultMessage: 'Amount',
  },
  percentOf: {
    id: 'app.common.inputLabels.percentOf',
    defaultMessage: '% of',
  },
  allergies: {
    id: 'app.common.inputLabels.allergies',
    defaultMessage: 'ex. ‘peanuts’',
  },
  insuranceCompany: {
    id: 'app.common.inputLabels.insuranceCompany',
    defaultMessage: 'Insurance Company',
  },
  healthPlan: {
    id: 'app.common.inputLabels.healthPlan',
    defaultMessage: 'Health Plan',
  },
  member: {
    id: 'app.common.inputLabels.member',
    defaultMessage: 'Member',
  },
  membersName: {
    id: 'app.common.inputLabels.membersName',
    defaultMessage: 'Members name',
  },
  memberFirstName: {
    id: 'app.common.inputLabels.memberFirstName',
    defaultMessage: 'Member First Name',
  },
  memberSecondName: {
    id: 'app.common.inputLabels.memberSecondName',
    defaultMessage: 'Member Second Name',
  },
  group: {
    id: 'app.common.inputLabels.group',
    defaultMessage: 'Group',
  },
  groupBy: {
    id: 'app.common.inputLabels.groupBy',
    defaultMessage: 'Group By',
  },
  contracts: {
    id: 'app.common.inputLabels.contracts',
    defaultMessage: 'Contracts',
  },
  familyMembers: {
    id: 'app.common.inputLabels.familyMembers',
    defaultMessage: 'Family Members',
  },
  includeNewMember: {
    id: 'app.common.inputLabels.includeNewMember',
    defaultMessage: 'Include New Member',
  },
  includeRenewal: {
    id: 'app.common.inputLabels.includeRenewal',
    defaultMessage: 'Include Renewal',
  },
  includeAddOnService: {
    id: 'app.common.inputLabels.includeAddOnService',
    defaultMessage: 'Include Add-On Service',
  },
  includeAddOnMember: {
    id: 'app.common.inputLabels.includeAddOnMember',
    defaultMessage: 'Include Add-On Member',
  },
  includeRewrite: {
    id: 'app.common.inputLabels.includeRewrite',
    defaultMessage: 'Include Rewrite',
  },
  includeOther: {
    id: 'app.common.inputLabels.includeOther',
    defaultMessage: 'Include Other',
  },
  showMemberPhotos: {
    id: 'app.common.inputLabels.showMemberPhotos',
    defaultMessage: 'Show Member Photos',
  },
  showFastScanRejectReasons: {
    id: 'app.common.inputLabels.showFastScanRejectReasons',
    defaultMessage: 'Show Fast Scan Reject Reasons',
  },
  addPageBreaksByEmployee: {
    id: 'app.common.inputLabels.addPageBreaksByEmployee',
    defaultMessage: 'Add Page Breaks by Employee (printer-friendly version)',
  },
  includeDetails: {
    id: 'app.common.inputLabels.includeDetails',
    defaultMessage: 'Include Details',
  },
  totalByDateRange: {
    id: 'app.common.inputLabels.totalByDateRange',
    defaultMessage: 'Total by Date Range',
  },
  totalByEventType: {
    id: 'app.common.inputLabels.totalByEventType',
    defaultMessage: 'Total by Event Type',
  },
  totalBySalesperson: {
    id: 'app.common.inputLabels.totalBySalesperson',
    defaultMessage: 'Total by Salesperson',
  },
  totalByDayOfWeek: {
    id: 'app.common.inputLabels.totalByDayOfWeek',
    defaultMessage: 'Total by Day of Week',
  },
  marketSourceTotal: {
    id: 'app.common.inputLabels.marketSourceTotal',
    defaultMessage: 'Market Source Total',
  },
  memberTypeTotal: {
    id: 'app.common.inputLabels.memberTypeTotal',
    defaultMessage: 'Member Type Total',
  },
  paymentMethodTotal: {
    id: 'app.common.inputLabels.paymentMethodTotal',
    defaultMessage: 'Payment Method Total',
  },
  managerOverridesOnly: {
    id: 'app.common.inputLabels.managerOverridesOnly',
    defaultMessage: 'Manager Overrides Only',
  },
  showReferralCount: {
    id: 'app.common.inputLabels.showReferralCount',
    defaultMessage: 'Show Referral Count',
  },
  effectiveDate: {
    id: 'app.common.inputLabels.effectiveDate',
    defaultMessage: 'Effective Date',
  },
  repeats: {
    id: 'app.common.inputLabels.repeats',
    defaultMessage: 'Repeats',
  },
  frequency: {
    id: 'app.common.inputLabels.frequency',
    defaultMessage: 'Frequency',
  },
  endRepeat: {
    id: 'app.common.inputLabels.endRepeat',
    defaultMessage: 'End repeat',
  },
  eventTagsOptional: {
    id: 'app.common.inputLabels.eventTagsOptional',
    defaultMessage: 'Event tags (optional)',
  },
  eventDescriptionOptional: {
    id: 'app.common.inputLabels.eventDescriptionOptional',
    defaultMessage: 'Event description (optional)',
  },
  eventLabel: {
    id: 'app.common.inputLabels.eventLabel',
    defaultMessage: 'Event',
  },
  participants: {
    id: 'app.common.inputLabels.participants',
    defaultMessage: 'Participants',
  },
  spots: {
    id: 'app.common.inputLabels.spots',
    defaultMessage: 'Spots',
  },
  whoCanAttend: {
    id: 'app.common.inputLabels.whoCanAttend',
    defaultMessage: 'Who can attend',
  },
  overbooking: {
    id: 'app.common.inputLabels.overbooking',
    defaultMessage: 'Overbooking',
  },
  resourcesForDuplicatedEvent: {
    id: 'app.common.inputLabels.resourcesForDuplicatedEvent',
    defaultMessage: 'Resources for duplicated event',
  },
  maxInvoice: {
    id: 'app.common.inputLabels.maxInvoice',
    defaultMessage: 'Max invoice %',
  },
  balance: {
    id: 'app.common.inputLabels.balance',
    defaultMessage: 'Balance',
  },
  gender: {
    id: 'app.common.inputLabels.gender',
    defaultMessage: 'Gender',
  },
  userId: {
    id: 'app.common.inputLabels.userId',
    defaultMessage: 'User ID',
  },
  employeeId: {
    id: 'app.common.inputLabels.employeeId',
    defaultMessage: 'Employee ID',
  },
  passwordHint: {
    id: 'app.common.inputLabels.passwordHint',
    defaultMessage: 'Password Hint (Question)',
  },
  currentPassword: {
    id: 'app.common.inputLabels.currentPassword',
    defaultMessage: 'Current Password',
  },
  newPassword: {
    id: 'app.common.inputLabels.newPassword',
    defaultMessage: 'New Password',
  },
  confirmNewPassword: {
    id: 'app.common.inputLabels.newPassword',
    defaultMessage: 'Confirm New Password',
  },
  confirmVerbalPassword: {
    id: 'app.common.inputLabels.confirmVerbalPassword',
    defaultMessage: 'Confirm Verbal Password',
  },
  verbalPassword: {
    id: 'app.common.inputLabels.verbalPassword',
    defaultMessage: 'Verbal Password',
  },
  hireDate: {
    id: 'app.common.inputLabels.hireDate',
    defaultMessage: 'Hire date',
  },
  terminationDate: {
    id: 'app.common.inputLabels.terminationDate',
    defaultMessage: 'Termination Date',
  },
  posDiscountPercentage: {
    id: 'app.common.inputLabels.posDiscountPercentage',
    defaultMessage: 'POS discount %',
  },
  title: {
    id: 'app.common.inputLabels.title',
    defaultMessage: 'Title',
  },
  department: {
    id: 'app.common.inputLabels.department',
    defaultMessage: 'Department',
  },
  payType: {
    id: 'app.common.inputLabels.payType',
    defaultMessage: 'Pay Type',
  },
  salary: {
    id: 'app.common.inputLabels.salary',
    defaultMessage: 'Salary',
  },
  hideUnchecked: {
    id: 'app.common.inputLabels.hideUnchecked',
    defaultMessage: 'Hide unchecked',
  },
  username: {
    id: 'app.common.inputLabels.username',
    defaultMessage: 'Username',
  },
  password: {
    id: 'app.common.inputLabels.password',
    defaultMessage: 'Password',
  },
  url: {
    id: 'app.common.inputLabels.url',
    defaultMessage: 'URL',
  },
  openInNewWindow: {
    id: 'app.common.inputLabels.openInNewWindow',
    defaultMessage: 'Open in a new window',
  },
  subject: {
    id: 'app.common.inputLabels.subject',
    defaultMessage: 'Subject',
  },
  reason: {
    id: 'app.common.inputLabels.reason',
    defaultMessage: 'Reason',
  },
  otherReason: {
    id: 'app.common.inputLabels.otherReason',
    defaultMessage: 'Other reason',
  },
  membershipPackage: {
    id: 'app.common.inputLabels.membershipPackage',
    defaultMessage: 'Membership bundle',
  },
  packageType: {
    id: 'app.common.inputLabels.packageType',
    defaultMessage: 'Package type',
  },
  payments: {
    id: 'app.common.inputLabels.payments',
    defaultMessage: 'Payments',
  },
  textTemplate: {
    id: 'app.common.inputLabels.textTemplate',
    defaultMessage: 'Text template',
  },
  automaticRun: {
    id: 'app.common.inputLabels.automaticRun',
    defaultMessage: 'Automatic Run',
  },
  unit: {
    id: 'app.common.inputLabels.unit',
    defaultMessage: 'Unit',
  },
  cameFrom: {
    id: 'app.common.inputLabels.cameFrom',
    defaultMessage: 'Came from',
  },
  minLabel: {
    id: 'app.common.inputLabels.min',
    defaultMessage: 'Min',
  },
  maxLabel: {
    id: 'app.common.inputLabels.max',
    defaultMessage: 'Max',
  },
  editableLabel: {
    id: 'app.common.inputLabels.editable',
    defaultMessage: 'EDITABLE',
  },
  apiKey: {
    id: 'app.common.inputLabels.apiKey',
    defaultMessage: 'API Key',
  },
  widgetKey: {
    id: 'app.common.inputLabels.widgetKey',
    defaultMessage: 'Widget Key',
  },
  domain: {
    id: 'app.common.inputLabels.domain',
    defaultMessage: 'Domain',
  },
  ownerEmail: {
    id: 'app.common.inputLabels.ownerEmail',
    defaultMessage: 'Owner email',
  },
  apiSecret: {
    id: 'app.common.inputLabels.apiSecret',
    defaultMessage: 'API Secret',
  },
  merchantId: {
    id: 'app.common.inputLabels.merchantId',
    defaultMessage: 'Merchant ID',
  },
  terminalId: {
    id: 'app.common.inputLabels.terminalId',
    defaultMessage: 'Terminal ID',
  },
  transamorToken: {
    id: 'app.common.inputLabels.transamorToken',
    defaultMessage: 'Transarmor Token',
  },
  pzSecret: {
    id: 'app.common.inputLabels.pzSecret',
    defaultMessage: 'Payment.JS Secret',
  },
  reportToken: {
    id: 'app.common.inputLabels.reportToken',
    defaultMessage: 'Reporting Token',
  },
  homeClub: {
    id: 'app.common.inputLabels.homeClub',
    defaultMessage: 'Home Club',
  },
  employeesLabel: {
    id: 'app.common.inputLabels.employees',
    defaultMessage: 'Employees',
  },
  search: {
    id: 'app.common.inputLabels.search',
    defaultMessage: 'Search',
  },
  totalAmount: {
    id: 'app.common.inputLabels.totalAmount',
    defaultMessage: 'Total amount',
  },
  totalOrders: {
    id: 'app.common.inputLabels.orderAmount',
    defaultMessage: 'Total orders',
  },
  taxedTotalAmount: {
    id: 'app.common.inputLabels.taxedTotalAmount',
    defaultMessage: 'Taxed amount',
  },
  searchItems: {
    id: 'app.common.inputLabels.searchItems',
    defaultMessage: 'Search items',
  },
  searchInvoiceNumber: {
    id: 'app.common.inputLabels.searchInvoiceNumber',
    defaultMessage: 'Search invoice number',
  },
  searchServices: {
    id: 'app.common.inputLabels.searchServices',
    defaultMessage: 'Search for Items to include',
  },
  text: {
    id: 'app.common.inputLabels.text',
    defaultMessage: 'Text',
  },
  period: {
    id: 'app.common.inputLabels.period',
    defaultMessage: 'Period',
  },
  payOption: {
    id: 'app.common.inputLabels.payOption',
    defaultMessage: 'Pay Option',
  },
  start: {
    id: 'app.common.inputLabels.start',
    defaultMessage: 'Start',
  },
  locked: {
    id: 'app.common.inputLabels.locked',
    defaultMessage: 'Locked',
  },
  account: {
    id: 'app.common.inputLabels.account',
    defaultMessage: 'Account',
  },
  routingNumber: {
    id: 'app.common.inputLabels.routingNumber',
    defaultMessage: 'Routing Number',
  },
  bankName: {
    id: 'app.common.inputLabels.bankName',
    defaultMessage: 'Bank Name',
  },
  bankCode: {
    id: 'app.common.inputLabels.bankCode',
    defaultMessage: 'Bank Code',
  },
  bankDescription: {
    id: 'app.common.inputLabels.bankDescription',
    defaultMessage: 'Bank Description',
  },
  currency: {
    id: 'app.common.inputLabels.currency',
    defaultMessage: 'Currency',
  },
  controlledBy: {
    id: 'app.common.inputLabels.controlledBy',
    defaultMessage: 'Controlled By',
  },
  softDescriptorAch: {
    id: 'app.common.inputLabels.softDescriptorAch',
    defaultMessage: 'ACH Soft Descriptor',
  },
  softDescriptorCreditCard: {
    id: 'app.common.inputLabels.softDescriptorCreditCard',
    defaultMessage: 'Credit Card Soft Descriptor',
  },
  returnedItemCharge: {
    id: 'app.common.inputLabels.returnedItemCharge',
    defaultMessage: 'Returned Item Charge Amount',
  },
  lateFeeCharge: {
    id: 'app.common.inputLabels.lateFeeCharge',
    defaultMessage: 'Late Fee Charge Amount',
  },
  maxNOSInvoice: {
    id: 'app.common.inputLabels.maxNOSInvoice',
    defaultMessage: 'Max NOS Invoice Amount',
  },
  taxClassification: {
    id: 'app.common.inputLabels.taxClassification',
    defaultMessage: 'Tax Classification',
  },
  idEIN: {
    id: 'app.common.inputLabels.idEIN',
    defaultMessage: 'ID (SSN/EIN)',
  },
  documentsRepository: {
    id: 'app.common.inputLabels.documentsRepository',
    defaultMessage: 'Documents Repository',
  },
  doesHaveLoan: {
    id: 'app.common.inputLabels.doesHaveLoan',
    defaultMessage: 'Does Client Have Loan?',
  },
  holidayGiftsDelivery: {
    id: 'app.common.inputLabels.holidayGiftsDelivery',
    defaultMessage: 'Holiday gifts delivery address',
  },
  holidayGiftsPOCName: {
    id: 'app.common.inputLabels.holidayGiftsPOCName',
    defaultMessage: 'Holiday gifts POC Name',
  },
  holidayGiftsPOCPhone: {
    id: 'app.common.inputLabels.holidayGiftsPOCPhone',
    defaultMessage: 'Holiday gifts POC Phone',
  },
  entranceName: {
    id: 'app.common.inputLabels.entranceName',
    defaultMessage: 'Entrance name',
  },
  staffedHours: {
    id: 'app.common.inputLabels.staffedHours',
    defaultMessage: 'Staffed Hours',
  },
  operatingHours: {
    id: 'app.common.inputLabels.operatingHours',
    defaultMessage: 'Operating Hours',
  },
  conditionOperator: {
    id: 'app.common.inputLabels.conditionOperator',
    defaultMessage: 'Operator',
  },
  calculationPercentageOf: {
    id: 'app.common.inputLabels.calculationPercentageOf',
    defaultMessage: 'Total Sales Price',
  },
  conditionField: {
    id: 'app.common.inputLabels.conditionField',
    defaultMessage: 'Field Form New Condition',
  },
  kidZoneDuration: {
    id: 'app.common.inputLabels.kidZoneDuration',
    defaultMessage: 'Kidzone duration',
  },
  module: {
    id: 'app.common.inputLabels.module',
    defaultMessage: 'Module',
  },
  chargeAfterDays: {
    id: 'app.common.inputLabels.chargeAfter',
    defaultMessage: 'Charge after(days)',
  },
  daysAmount: {
    id: 'app.common.inputLabels.daysAmount',
    defaultMessage: 'Days',
  },
  signDate: {
    id: 'app.common.inputLabels.signDate',
    defaultMessage: 'Sign date',
  },
  renewalDate: {
    id: 'app.common.inputLabels.renewalDate',
    defaultMessage: 'Renewal date',
  },
  pinCode: {
    id: 'app.common.inputLabels.pinCode',
    defaultMessage: 'PIN Code',
  },
  paymentSchedule: {
    id: 'app.common.inputLabels.paymentSchedule',
    defaultMessage: 'Payment schedule',
  },
  splitPayment: {
    id: 'app.common.inputLabels.splitPayment',
    defaultMessage: 'Split payment',
  },
  paymentsNumber: {
    id: 'app.common.inputLabels.paymentsNumber',
    defaultMessage: 'Payments number',
  },
  daysPriorAmount: {
    id: 'app.common.inputLabels.daysPrior',
    defaultMessage: 'Days prior',
  },
  newZipCode: {
    id: 'app.common.inputLabels.newZipCode',
    defaultMessage: 'New Zip Code',
  },
  amountToPay: {
    id: 'app.common.inputLabels.amountToPay',
    defaultMessage: 'Amount to pay',
  },
  changeAmount: {
    id: 'app.common.inputLabels.change',
    defaultMessage: 'Change',
  },
  left: {
    id: 'app.common.inputLabels.left',
    defaultMessage: 'Left',
  },
  paymentOption: {
    id: 'app.common.inputLabels.paymentOption',
    defaultMessage: 'Payment option',
  },
  mdLicence: {
    id: 'app.common.inputLabels.mdLicence',
    defaultMessage: 'MD Licence',
  },
  availableItemsForRedeem: {
    id: 'app.common.inputLabels.availableItemsForRedeem',
    defaultMessage: '{items} items available for redeem',
  },
  linkToService: {
    id: 'app.common.inputLabels.linkToService',
    defaultMessage: 'Link to service (optional)',
  },
  expiryDate: {
    id: 'app.common.inputLabels.expiryDate',
    defaultMessage: 'Expiry Date',
  },
  expirationDate: {
    id: 'app.common.inputLabels.expirationDate',
    defaultMessage: 'Expiration Date',
  },
  daysAndHoursForAccess: {
    id: 'app.common.inputLabels.daysAndHoursForAccess',
    defaultMessage: 'Days & Hours For Access',
  },
  dayOfTheWeek: {
    id: 'app.common.inputLabels.dayOfTheWeek',
    defaultMessage: 'Day of the week',
  },
  checksAmount: {
    id: 'app.common.inputLabels.checksAmount',
    defaultMessage: 'Checks amount',
  },
  paymentProcessorType: {
    id: 'app.common.inputLabels.paymentProcessorType',
    defaultMessage: 'Payment processor type',
  },
  corporation: {
    id: 'app.common.inputLabels.corporation',
    defaultMessage: 'Corporation',
  },
  taxpayerName: {
    id: 'app.common.inputLabels.taxpayerName',
    defaultMessage: 'Taxpayer Name',
  },
  taxpayerType: {
    id: 'app.common.inputLabels.taxpayerType',
    defaultMessage: 'Taxpayer Type',
  },
  achAccountType: {
    id: 'app.common.inputLabels.achAccountType',
    defaultMessage: 'Ach Account Type',
  },
  document: {
    id: 'app.common.inputLabels.document',
    defaultMessage: 'Document',
  },
  subjectOptional: {
    id: 'app.common.inputLabels.subjectOptional',
    defaultMessage: 'Subject (Optional)',
  },
  submitted: {
    id: 'app.common.inputLabels.submitted',
    defaultMessage: 'Submitted',
  },
  requester: {
    id: 'app.common.inputLabels.requester',
    defaultMessage: 'Requester',
  },
  workedBy: {
    id: 'app.common.inputLabels.workedBy',
    defaultMessage: 'Worked By',
  },
  unknown: {
    id: 'app.common.inputLabels.unknown',
    defaultMessage: 'Unknown',
  },
  actionOptional: {
    id: 'app.common.inputLabels.actionOptional',
    defaultMessage: 'Action (Optional)',
  },
  mainContactPhone: {
    id: 'app.common.inputLabels.mainContactPhone',
    defaultMessage: 'Main Contact Phone',
  },
});
