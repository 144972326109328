import React, { useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { Box } from '@material-ui/core';
import { useAppDispatch } from 'store/hooks';

import {
  selectUserSelectedClubId,
  selectUserSelectedOrganization,
} from 'modules/authentication/state/selectors';
import {
  selectPosKioskRegisterData,
  selectSelectedRegisterId,
} from 'modules/pos-kiosk/state/register/selectors';

import { PeakModules } from 'common/constants/peakModules';
import {
  CHANGE_INVOICE_UNITS_REGISTER,
  EXECUTE_MEMBERSHIP_ACTIONS,
} from 'common/constants/delayedActionsKeys';
import StorageServices from 'services/storage';
import { ENotificationType } from 'modules/booking/constants/notificationType';

import { IPosKioskRegisterDataImt } from 'modules/pos-kiosk/interfaces/registers';

import { InvoiceOperating, RegisterSelector } from 'common/components/index';
import { InvoiceStatus } from 'common/interfaces/invoices';
import { selectCurrentInvoice } from 'common/state/invoice/selectors';
import { StepContext } from 'common/createContext/stepContext';
import * as selectors from 'common/state/invoice/selectors';
import { setSelectedRegisterIdAction } from 'modules/pos-kiosk/state/register/actions';

const { execute, isExitDelayedAction } = StorageServices.delayedActions;

interface IProps {
  onCancel: () => void;
  onNext: () => void;
  module: PeakModules;
  personId: number;
  disableInvoiceCreation?: boolean;
  disableInvoiceSelection?: boolean;
  autoSelectRegister?: boolean;
}

const { Provider: StepProvider } = StepContext;

const PaymentStep = ({
  onNext,
  personId,
  onCancel,
  module,
  disableInvoiceSelection,
  disableInvoiceCreation,
  autoSelectRegister = false,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const clubId = useRootSelector(selectUserSelectedClubId);
  const selectedRegisterId: string = useRootSelector(selectSelectedRegisterId);
  const selectedInvoice = useRootSelector(selectCurrentInvoice);
  const selectedRegister: IPosKioskRegisterDataImt = useRootSelector(selectPosKioskRegisterData);
  const invoices = useRootSelector(selectors.selectInvoicesForRegisterList);
  const isFetchedInvoiceList = useRootSelector(selectors.selectIsFetchedInvoiceList);
  const selectedOrg = useRootSelector(selectUserSelectedOrganization);

  const selectedOrgId = selectedOrg?.get('id');
  const isDisabledRegister = selectedInvoice?.get('status') === InvoiceStatus.IN_PROGRESS;

  useEffect(() => {
    if (
      selectedRegisterId &&
      isExitDelayedAction(EXECUTE_MEMBERSHIP_ACTIONS) &&
      isFetchedInvoiceList &&
      selectedRegister.get('open')
    ) {
      execute(EXECUTE_MEMBERSHIP_ACTIONS, [invoices, selectedRegisterId]);
    }

    if (
      selectedRegisterId &&
      isExitDelayedAction(CHANGE_INVOICE_UNITS_REGISTER) &&
      selectedRegister.get('open')
    ) {
      execute(CHANGE_INVOICE_UNITS_REGISTER, []);
    }
  }, [selectedRegister, selectedRegisterId, isFetchedInvoiceList, invoices]);

  useEffect(() => {
    if (autoSelectRegister && !selectedRegisterId && selectedInvoice) {
      const selectedInvoiceRegId = selectedInvoice.getIn(['register', 'id']);

      if (selectedInvoiceRegId) {
        dispatch(setSelectedRegisterIdAction({ [selectedOrgId]: selectedInvoiceRegId }));
      }
    }
  }, [dispatch, autoSelectRegister, selectedOrgId, selectedRegisterId, selectedInvoice]);

  return (
    <>
      <Box mb={2} pl={2}>
        <RegisterSelector
          disabled={isDisabledRegister}
          isInvoiceView
          module={module}
          clubId={clubId}
        />
      </Box>
      <StepProvider
        value={{
          helperData: { profileId: personId },
        }}
      >
        <InvoiceOperating
          disableInvoiceSelection={disableInvoiceSelection}
          disableInvoiceCreation={disableInvoiceCreation}
          clubId={clubId}
          module={module}
          event={ENotificationType.FRONTDESK_POS_RECEIPT_SENDING}
          isSalespersonSelectDisabled
          isCustomerSelectDisabled
          shouldGoBackAfterRevokeInvoice
          onBack={onCancel}
          onNext={onNext}
        />
      </StepProvider>
    </>
  );
};

export default PaymentStep;
