import { defineMessages } from 'react-intl';

export default defineMessages({
  ptTasks: {
    id: 'app.modules.ptCrm.mainPage.ptTasks',
    defaultMessage: 'PT Tasks',
  },
  ptLeads: {
    id: 'app.modules.ptCrm.mainPage.ptLeads',
    defaultMessage: 'PT Leads',
  },
  ptScripts: {
    id: 'app.modules.ptCrm.mainPage.ptScripts',
    defaultMessage: 'PT Scripts',
  },
  ptCampaigns: {
    id: 'app.modules.ptCrm.mainPage.ptCampaigns',
    defaultMessage: 'PT Campaigns',
  },
  ptDocumentTemplates: {
    id: 'app.modules.ptCrm.mainPage.ptDocumentTemplates',
    defaultMessage: 'PT SMS & Email Template',
  },
  ptLeadProfile: {
    id: 'app.modules.ptCrm.leads.leadProfile.title',
    defaultMessage: 'PT Lead Profile',
  },
  newPtLead: {
    id: 'app.modules.ptCrm.leads.newPtLead.title',
    defaultMessage: 'New PT Lead',
  },
  editPtLeadProfile: {
    id: 'app.modules.ptCrm.leads.editLeadProfile.title',
    defaultMessage: 'Edit PT Lead Profile',
  },
  changeStatusPtLeadProfileTitle: {
    id: 'app.modules.ptCrm.leads.changeStatusLeadProfile.title',
    defaultMessage: 'Change PT Lead Profile Status',
  },
  completePtTasksLabel: {
    id: 'app.modules.ptCrm.tasks.completePtTasksLabel',
    defaultMessage: 'Complete this pt tasks?',
  },
  ptCrmStatusTitle: {
    id: 'app.modules.ptCrm.leads.ptCrmStatus.title',
    defaultMessage: 'PT CRM Status',
  },
  ptCrmLeadStatus: {
    id: 'app.modules.ptCrm.leads.ptCrmLeadStatus.title',
    defaultMessage: 'PT Lead Status',
  },
});
