import { defineMessages } from 'react-intl';

export default defineMessages({
  uploadSignatureButton: {
    id: 'components.eSignature.uploadSignatureButton',
    defaultMessage: 'Upload Signature',
  },
  clearButton: {
    id: 'components.eSignature.clearButton',
    defaultMessage: 'Clear',
  },
});
