import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import * as yup from 'yup';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';

import {
  AvatarWithInitials,
  DialogComponent,
  PhoneNumberInputWithReset,
  Select,
  TextArea,
} from 'common/components';
import { PhoneTypes } from 'common/components/PersonProfile/constants';
import inputLabels from 'common/messages/inputLabels';
import inputErrors from 'common/messages/inputErrors';
import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';
import { getRequiredMessage } from 'common/constants/globalConstants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import {
  INoteCode,
  IReferralCreateDto,
  IFormReferralFields,
} from 'common/components/PersonProfile/interfaces';
import { PeakModules } from 'common/constants/peakModules';
import { phoneSchema } from 'common/validationSchemas/phoneSchema';
import Services from 'services/network';
import { getInitials } from 'helpers/common';
import IntlService from 'services/intl/IntlService';
import { INamedEntityImt } from 'common/interfaces/common';
import { List } from 'immutable';
import NoteCodeSelector from '../../../../NoteCodeSelector/NoteCodeSelector';
import { resetNoteCodes } from 'common/components/PersonProfile/state/actions';
import { useDispatch } from 'react-redux';

interface IReferralCreateModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onCreateReferral: (dto: IReferralCreateDto) => void;
  onClose: () => void;
  module?: PeakModules;
  noteCodeList: INoteCode[];
  searchNoteCodes: (search: string, customerId: number) => void;
  profileId: number;
  clubs: List<INamedEntityImt>;
}

const useStyles = makeStyles(() => ({
  checkbox: {
    alignItems: 'start',
    '& .MuiCheckbox-root': {
      width: 20,
      height: 20,
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#66CD00',
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: 500,
    },
  },
}));

const validationSchema = yup.object().shape({
  firstName: yup.string().required(getRequiredMessage),
  lastName: yup.string().required(getRequiredMessage),
  email: yup
    .string()
    .trim()
    .email(() => inputErrors.emailInvalidError)
    .required(getRequiredMessage),
  phone: yup
    .string()
    .nullable()
    .when('smsEnabled', {
      is: value => value,
      then: phoneSchema.nullable().required(getRequiredMessage),
    })
    .required(getRequiredMessage),
  salespersonId: yup.string().required(getRequiredMessage),
  note: yup
    .string()
    .nullable()
    .required(getRequiredMessage),

  noteCode: yup
    .object({ id: yup.string(), active: yup.boolean(), title: yup.string() })
    .nullable()
    .required(getRequiredMessage),
  clubId: yup
    .string()
    .nullable()
    .required(getRequiredMessage),
});

const ReferralCreateModal = (props: IReferralCreateModalProps): JSX.Element => {
  const {
    isOpen,
    onClose,
    isLoading,
    onCreateReferral,
    module,
    noteCodeList,
    searchNoteCodes,
    profileId,
    clubs,
  } = props;

  const [salespersonList, setSalesPersonList] = useState([]);

  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();
  const dispatch = useDispatch();

  const formMethods = useForm<IFormReferralFields>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      salespersonId: '',
      note: '',
      smsEnabled: false,
      noteCode: null,
      clubId: '',
    },
    resolver: yupResolver(validationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onSubmit',
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const loadEmployees = useCallback(async () => {
    const employees = await Services.DictionaryLists.getEmployeesList({
      module,
    });

    setSalesPersonList(employees.data);
  }, [module]);

  useEffect(() => {
    void loadEmployees();
  }, [module, loadEmployees]);

  const submitForm = (values: IFormReferralFields) => {
    const { firstName, lastName, email, salespersonId, phone, note, noteCode, clubId } = values;

    const addReferralDto = {
      firstName,
      lastName,
      email,
      salespersonId,
      note: { text: note, noteCodeId: noteCode.id },
      phone: {
        phoneType: PhoneTypes.Mobile,
        phone,
        allowAutomatedCalls: true,
        canReceiveSms: values.smsEnabled,
        useAsDefault: true,
      },
      clubId,
    };

    onCreateReferral(addReferralDto);
  };

  const clearSearchResults = () => {
    dispatch(resetNoteCodes(null, profileId));
  };

  return (
    <DialogComponent
      title={IntlService.formatMessage(messages.newReferralTitle)}
      size="sm"
      isOpen={isOpen}
      submitBtnTitle={IntlService.formatMessage(commonMessages.sendBtn)}
      loading={isLoading}
      onClose={onClose}
      onSubmit={handleSubmit(submitForm)}
    >
      <form>
        <FormProvider {...formMethods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="clubId"
                control={control}
                render={({ field }) => (
                  <Select
                    name={field.name}
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={(id: string) => {
                      field.onChange(id);
                    }}
                    fullWidth
                    label={<FormattedMessage {...inputLabels.homeClub} />}
                    error={!!errors.clubId}
                    helperText={renderIntlMessage(errors.clubId?.message)}
                  >
                    {clubs?.map(clubItem => (
                      <MenuItem key={clubItem.get('id')} value={clubItem.get('id')}>
                        {clubItem.get('title')}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    name={field.name}
                    variant="outlined"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    label={IntlService.formatMessage(inputLabels.firstName)}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    error={!!errors.firstName}
                    helperText={renderIntlMessage(errors?.firstName?.message)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    name={field.name}
                    variant="outlined"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    label={IntlService.formatMessage(inputLabels.lastName)}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    error={!!errors.lastName}
                    helperText={renderIntlMessage(errors?.lastName?.message)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label={IntlService.formatMessage(inputLabels.email)}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    fullWidth
                    error={!!errors.email}
                    helperText={renderIntlMessage(errors.email?.message)}
                    value={field.value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneNumberInputWithReset
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    label={IntlService.formatMessage(inputLabels.phone)}
                    error={!!errors.phone}
                    helperText={renderIntlMessage(errors.phone?.message)}
                  />
                )}
              />
            </Grid>
            <Box display="flex" alignItems="center">
              <Controller
                control={control}
                name="smsEnabled"
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="medium"
                        checked={field.value}
                        onChange={async e => {
                          const isChecked = e.target.checked;
                          field.onChange(isChecked);
                        }}
                        onBlur={field.onBlur}
                      />
                    }
                    label={IntlService.formatMessage(messages.newReferralNote)}
                    className={classes.checkbox}
                  />
                )}
              />
            </Box>
            <Grid item xs={12}>
              <Controller
                name="salespersonId"
                control={control}
                render={({ field }) => (
                  <Select
                    name={field.name}
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={optionId => {
                      field.onChange(optionId);
                    }}
                    fullWidth
                    label={IntlService.formatMessage(inputLabels.salesperson)}
                    error={!!errors?.salespersonId}
                    helperText={renderIntlMessage(errors.salespersonId?.message)}
                  >
                    {salespersonList.map(el => {
                      const [firstName, lastName] = el.title.split(/\s(.*)/);

                      const initials = getInitials(firstName, lastName);
                      return (
                        <MenuItem key={el.id} value={el.id}>
                          <Box display="flex" key={el.id}>
                            <AvatarWithInitials
                              imageUrl={el.imageUrl}
                              width="36px"
                              height="36px"
                              fontSize="1rem"
                              initials={initials}
                            />

                            <Box display="flex" alignItems="center">
                              <Typography>{el.title}</Typography>
                            </Box>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextArea
                    value={field.value}
                    label={IntlService.formatMessage(inputLabels.note)}
                    variant="outlined"
                    autoComplete="none"
                    fullWidth
                    maxSymbols={1000}
                    rows={4}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!errors.note}
                    helperText={renderIntlMessage(errors.note?.message)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="noteCode"
                render={({ field: { value, onChange } }) => {
                  return (
                    <NoteCodeSelector
                      searchNoteCodes={searchNoteCodes}
                      profileId={profileId}
                      onChange={v => onChange(v)}
                      value={value}
                      clearSearchResults={clearSearchResults}
                      noteCodes={noteCodeList}
                      error={!!errors.noteCode}
                      helperText={renderIntlMessage(errors.noteCode?.message)}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </DialogComponent>
  );
};

export default ReferralCreateModal;
