import React, { useCallback, useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { List as ImmutableList } from 'immutable';
import { Grid } from '@material-ui/core';

import { IResourceForm, IResourceImt, ITagImt } from 'modules/booking/interfaces';
import { QueryPageList } from 'common/constants';
import { INamedEntityImt } from 'common/interfaces/common';

import { selectQueryPageList } from 'common/state/queryPage-lists/selectors';
import { sortAvailabilities } from '../../../services/utils/servicePackage';

import { ResourceValidationSchema } from './Schemas';

import AvailabilitiesSection from './AvailabilitiesSection/AvailabilitiesSection';
import TagsSection from './TagsSection/TagsSection';
import ResourceInfoSection from './ResourceInfoSection/ResourceInfoSection';
import { FormContainer } from 'common/components';

interface IProps {
  title: JSX.Element;
  resource?: IResourceImt | null;
  clubs: ImmutableList<INamedEntityImt>;
  resourceTags?: ImmutableList<ITagImt>;
  eventTags?: ImmutableList<ITagImt>;
  isLoading?: boolean;
  onSubmit: (formData: IResourceForm) => void;
}

const convertValuesFromDTO = formData => {
  const { availability, ...otherFormValues } = formData;

  const transformedAvailability = availability.map(availabilityItem => {
    const { dayTimeAvails } = availabilityItem;

    const transformedRanges = dayTimeAvails.map(rangeItem => ({
      ...rangeItem,
      editableRangeIndex: null,
    }));

    const sortedAvailabilities = sortAvailabilities(transformedRanges);

    return {
      ...availabilityItem,
      dayTimeAvails: sortedAvailabilities,
      weekdays: sortedAvailabilities,
    };
  });

  return {
    ...otherFormValues,
    availability: transformedAvailability,
  };
};

const convertFormValuesToDTO = formValues => {
  const { availability, ...otherFields } = formValues;

  return {
    ...otherFields,
    availability: availability.map(availabilityItem => ({
      ...availabilityItem,
      dayTimeAvails: availabilityItem.dayTimeAvails.map(rangeItem => ({
        ...rangeItem,
        timeRanges: rangeItem.allDay ? [] : rangeItem.timeRanges,
      })),
    })),
  };
};

const defaultFormValues = {
  label: '',
  employee: null,
  resourceTag: [],
  eventTag: [],
  availability: [
    {
      startDate: null,
      endDate: null,
      clubs: [],
      dayTimeAvails: [],
      weekdays: [],
    },
  ],
};

const ResourceForm = (props: IProps): JSX.Element => {
  const navigate = useNavigate();

  const { title, clubs, resource, resourceTags, eventTags, isLoading, onSubmit } = props;

  const [occupiedClubs, setOccupiedClubs] = useState([]);

  // form

  const queryResources: string = useRootSelector(selectQueryPageList(QueryPageList.RESOURCES));

  const formMethods = useForm<any>({
    defaultValues: defaultFormValues,
    resolver: yupResolver(ResourceValidationSchema),
    mode: 'all',
    shouldUnregister: false,
  });

  // handlers

  const handleCancelButton = useCallback(() => {
    navigate(`/booking/resources${queryResources}`);
  }, [navigate, queryResources]);

  // effects

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    if (resource?.size) {
      const transformedResource = resource.toJS();

      const initialOccupiedClubs = transformedResource.availability.reduce(
        (acc, fieldItem) => [...acc, ...fieldItem.clubs],
        [],
      );

      if (initialOccupiedClubs.length) {
        setOccupiedClubs(initialOccupiedClubs);
      }

      reset({
        ...defaultFormValues,
        ...convertValuesFromDTO(resource.toJS()),
      });
    }
  }, [reset, resource]);

  const handleSubmitForm = values => {
    onSubmit(convertFormValuesToDTO(values));
  };

  // render

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(handleSubmitForm)}
        autoComplete="none"
        style={{ flex: 1, minHeight: 0 }}
      >
        <FormContainer
          title={title}
          maxWidth={650}
          onCancel={handleCancelButton}
          isSubmitting={isLoading}
        >
          <Grid container spacing={4}>
            <ResourceInfoSection formMethods={formMethods} />

            <TagsSection
              resourceTags={resourceTags}
              eventTags={eventTags}
              formMethods={formMethods}
            />

            <AvailabilitiesSection
              clubs={clubs}
              formMethods={formMethods}
              occupiedClubs={occupiedClubs}
              setOccupiedClubs={setOccupiedClubs}
            />
          </Grid>
        </FormContainer>
      </form>
    </FormProvider>
  );
};

export default ResourceForm;
