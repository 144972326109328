/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { Component } from 'react';
import { Box, List, StyledComponentProps, Typography, withStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { Connect } from 'common/decorators';
import { LoadingBackdrop, ScrollBox } from 'common/components';
import { Slider, CheckInBlock } from 'common/components/PersonProfile/components';
import { IEmailImt, ISendEmailRequestPayload } from 'common/components/PersonProfile/interfaces';
import {
  selectPersonCreateEmailActionResult,
  makeSelectPersonRecentEmails,
  makeSelectPersonRecentEmailsLoading,
} from 'common/components/PersonProfile/state/selectors';
import * as actions from 'common/components/PersonProfile/state/actions';
import messages from 'common/components/PersonProfile/messages';
import NewEmailModal from './NewEmailModal/NewEmailModal';
import EmailDetailsModal from './EmailDetailsModal/EmailDetailsModal';
import EmailsItem from './EmailsItem/EmailsItem';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { ActionResult, DictionaryList } from 'common/constants';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { INamedEntityImt } from 'common/interfaces/common';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { fetchPersonCampaigns } from 'common/components/PersonProfile/state/actions';
import EmailsBlockSectionActions from './EmailsBlockSectionActions/EmailsBlockSectionActions';
import EmailsHistoryModal from './EmailsHistoryModal/EmailsHistoryModal';
import { selectRoot } from 'common/hooks/useRootSelector';

const styles = () =>
  createStyles({
    wrapper: {
      height: '100%',
      minHeight: '150px',
    },
    list: {
      padding: '0',
      marginRight: '-16px',
      height: 'calc(100% - 30px)',
      overflow: 'hidden',
    },
    icon: {
      width: 20,
      height: 20,
    },
  });

interface IEmailsBlockProps extends StyledComponentProps {
  personId: number;
  selectedClubId: string;
  emails?: ImmutableList<IEmailImt>;
  emailsLoading?: boolean;
  personEmail?: string;
  fetchCampaigns?: (personId: number, module: PeakModuleForNewPersonType) => void;
  fetchDictionaryPersonCampaigns?: (personId: number, module: PeakModuleForNewPersonType) => void;
  campaigns?: ImmutableList<INamedEntityImt>;
  isMobile?: boolean;
  fetchPersonRecentEmails?: (personId: number, module: PeakModuleForNewPersonType) => void;
  createEmailActionResult?: ActionResult;
  addPersonEmail?: (
    personId: number,
    email: ISendEmailRequestPayload,
    module: PeakModuleForNewPersonType,
  ) => void;
  resetCreateEmailActionResult?: (personId: number) => void;
  deletePersonEmail?: (
    personId: number,
    emailId: string,
    module: PeakModuleForNewPersonType,
  ) => void;
  module: PeakModuleForNewPersonType;
}

interface IEmailsBlockState {
  selectedEmailId: string;
  isNewModalOpen: boolean;
  isDetailsModalOpen: boolean;
  isHistoryModalOpen: boolean;
  fetchPersonEmails?: (personId: number) => void;
}

@(Connect({
  makeStateToProps: () => {
    const selectPersonEmails = selectRoot(makeSelectPersonRecentEmails());
    const selectPersonEmailsLoading = selectRoot(makeSelectPersonRecentEmailsLoading());

    return (state, ownProps) => {
      return {
        emails: selectPersonEmails(state, ownProps),
        emailsLoading: selectPersonEmailsLoading(state, ownProps),
        campaigns: selectRoot(selectDictionaryList(DictionaryList.MESSAGING_CAMPAIGN_EMAIL))(state),
        createEmailActionResult: selectRoot(
          selectPersonCreateEmailActionResult(ownProps?.personId),
        )(state),
      };
    };
  },
  mapDispatchToProps: dispatch => ({
    fetchPersonRecentEmails: (personId: number, module: PeakModuleForNewPersonType) => {
      dispatch(actions.fetchPersonRecentEmails(personId, module));
    },
    addPersonEmail: (
      personId: number,
      email: ISendEmailRequestPayload,
      module: PeakModuleForNewPersonType,
    ) => {
      dispatch(actions.addPersonEmail(personId, email, module));
    },
    resetCreateEmailActionResult: personId =>
      dispatch(actions.resetPersonCreateEmailActionResult(null, personId)),
    deletePersonEmail: (personId: number, emailId: string, module: PeakModuleForNewPersonType) => {
      dispatch(actions.deletePersonEmail(personId, emailId, module));
    },
    fetchDictionaryPersonCampaigns: (personId: number, module: PeakModuleForNewPersonType) =>
      dispatch(fetchDictionaryList(DictionaryList.MESSAGING_CAMPAIGN_EMAIL, { personId, module })),
    fetchCampaigns: (personId: number, module: PeakModuleForNewPersonType) =>
      dispatch(fetchPersonCampaigns(personId, module)),
  }),
}) as any)
@(withStyles(styles) as any)
class EMailsBlock extends Component<IEmailsBlockProps, IEmailsBlockState> {
  constructor(props) {
    super(props);

    this.state = {
      selectedEmailId: null,
      isNewModalOpen: false,
      isDetailsModalOpen: false,
      isHistoryModalOpen: false,
    };
  }

  componentDidMount(): void {
    const { personId, fetchPersonRecentEmails, module } = this.props;

    fetchPersonRecentEmails(personId, module);
  }

  componentDidUpdate(prevProps: IEmailsBlockProps, prevState: IEmailsBlockState): void {
    const {
      selectedClubId,
      personId,
      fetchPersonRecentEmails,
      fetchCampaigns,
      fetchDictionaryPersonCampaigns,
      resetCreateEmailActionResult,
      createEmailActionResult,
      module,
    } = this.props;
    const { isNewModalOpen } = this.state;

    if (isNewModalOpen && isNewModalOpen !== prevState.isNewModalOpen) {
      fetchDictionaryPersonCampaigns(personId, module);
    }

    if (createEmailActionResult) {
      fetchCampaigns(personId, module);
      fetchPersonRecentEmails(personId, module);
      resetCreateEmailActionResult(personId);
    }

    if (prevProps.selectedClubId === selectedClubId && prevProps.personId === personId) return;
    fetchPersonRecentEmails(personId, module);
  }

  onOpenNewModal = (): void => {
    this.setState({ isNewModalOpen: true });
  };

  onCloseNewModal = (): void => {
    const { isDetailsModalOpen } = this.state;

    this.setState(prev => {
      if (isDetailsModalOpen) {
        return { ...prev, isNewModalOpen: false };
      }

      return { ...prev, isNewModalOpen: false, selectedEmailId: null };
    });
  };

  onSendNewEmail = (data: ISendEmailRequestPayload): void => {
    const { personId, addPersonEmail, module } = this.props;

    addPersonEmail(personId, data, module);
    this.onCloseNewModal();
  };

  onOpenEmailDetails = (id: string): void => {
    this.setState({ isDetailsModalOpen: true, selectedEmailId: id });
  };

  onOpenHistoryModal = (): void => {
    this.setState({ isHistoryModalOpen: true });
  };

  onReply = (id: string) => {
    this.setState({ isNewModalOpen: true, selectedEmailId: id });
  };

  onCloseEmailDetails = (): void => {
    this.setState({ isDetailsModalOpen: false, selectedEmailId: null });
  };

  onCloseEmailHistory = (): void => {
    this.setState({ isHistoryModalOpen: false });
  };

  onReplyToEmail = (): void => {
    this.setState({
      isNewModalOpen: true,
    });
  };

  /* onDeleteEmail = (email: IEmailImt): void => {
    const { personId, deletePersonEmail, module } = this.props;

    deletePersonEmail(personId, email.get('id'), module);
  }; */

  render(): JSX.Element {
    const { isNewModalOpen, isDetailsModalOpen, isHistoryModalOpen, selectedEmailId } = this.state;
    const {
      classes,
      isMobile,
      emails,
      emailsLoading,
      personEmail,
      campaigns,
      personId,
      module,
    } = this.props;

    const listItems =
      Boolean(emails?.size) &&
      emails?.map(item => (
        <EmailsItem
          key={item.get('id')}
          email={item}
          personId={personId}
          onReply={this.onReply}
          onEmailClick={this.onOpenEmailDetails}
        />
      ));

    return (
      <CheckInBlock
        title="Emails"
        buttonGroup={
          <EmailsBlockSectionActions
            onAddClick={this.onOpenNewModal}
            onHistoryClick={this.onOpenHistoryModal}
          />
        }
        className={classes?.wrapper}
      >
        {listItems ? (
          <List className={classes?.list}>
            {isMobile === true && <Slider rows={3}>{listItems}</Slider>}
            {isMobile === false && <ScrollBox>{listItems}</ScrollBox>}
          </List>
        ) : (
          <Box className="empty-section-placeholder">
            <Typography className="empty-text">
              <FormattedMessage {...messages.emptyEmailsListTextMessage} />
            </Typography>
          </Box>
        )}

        <LoadingBackdrop isLoading={emailsLoading} />

        {isNewModalOpen && (
          <NewEmailModal
            module={module}
            personId={personId}
            emailId={selectedEmailId}
            campaigns={campaigns}
            personEmail={personEmail}
            isOpen={isNewModalOpen}
            isDetailsModalOpen={isDetailsModalOpen}
            onClose={this.onCloseNewModal}
            onSubmit={this.onSendNewEmail}
          />
        )}

        {isDetailsModalOpen && (
          <EmailDetailsModal
            module={module}
            emailId={selectedEmailId}
            personId={personId}
            isOpen={isDetailsModalOpen}
            onReply={this.onReplyToEmail}
            onClose={this.onCloseEmailDetails}
          />
        )}

        {isHistoryModalOpen && (
          <EmailsHistoryModal
            onOpenEmailDetails={this.onOpenEmailDetails}
            module={module}
            personId={personId}
            isOpen={isHistoryModalOpen}
            onClose={this.onCloseEmailHistory}
          />
        )}
      </CheckInBlock>
    );
  }
}

export default EMailsBlock;
