import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import useRootSelector from 'common/hooks/useRootSelector';
import { Variant } from '@material-ui/core/styles/createTypography';
import { Controller, useFormContext } from 'react-hook-form';
import { Accept } from 'react-dropzone';

import {
  selectUploadedFileById,
  selectUploadedFileDialogId,
  selectUploadFileErrorById,
  selectUploadFileLoadingById,
} from 'common/state/uploadFile/selectors';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import {
  clearUploadFile,
  resetUploadFile,
  setUploadId,
  uploadFile,
} from 'common/state/uploadFile/actions';
import { IAttachment, IUploaderCustomError } from 'common/interfaces/uploadFile';
import FilesDropzone from '../FilesDropzone/FilesDropzone';
import { WebcamModal } from 'common/modals';
import { getAccessByPropPath } from 'common/utils/errorObject';

interface IFormFilesUploaderProps {
  fieldName?: string;
  shouldBeVisibleTableHeader?: boolean;
  hasVisibleTakePhotoBtn?: boolean;
  filesListTextVariant?: Variant;
  hasVisibleScanDocumentBtn?: boolean;
  hasVisibleDownloadBtn?: boolean;
  acceptFileTypes?: Accept;
  className?: string;
}

const FormFilesToServerUploader = (props: IFormFilesUploaderProps) => {
  const {
    fieldName = 'attachments',
    shouldBeVisibleTableHeader,
    hasVisibleTakePhotoBtn,
    hasVisibleScanDocumentBtn,
    filesListTextVariant,
    hasVisibleDownloadBtn,
    acceptFileTypes,
    className,
  } = props;

  const dispatch = useAppDispatch();

  const isLoading = useRootSelector(selectUploadFileLoadingById(fieldName));
  const file = useRootSelector(selectUploadedFileById(fieldName));
  const requestError = useRootSelector(selectUploadFileErrorById(fieldName));
  const uploadId = useRootSelector(selectUploadedFileDialogId);

  const {
    setValue,
    setError,
    clearErrors,
    formState: { errors: formErrors },
    control,
    getValues,
  } = useFormContext();
  const error = getAccessByPropPath(formErrors, fieldName);

  const renderIntlMessage = useRenderIntlMessage();

  const [isOpenWebcamModal, setIsOpenWebcamModal] = useState(false);

  const onToggleWebcamModal = () => setIsOpenWebcamModal(!isOpenWebcamModal);

  useEffect(() => {
    if (file && !requestError) {
      dispatch(clearUploadFile(fieldName));
      const convertedFile = file.toJS();
      const files = getValues(fieldName);

      setValue(fieldName, files ? [...files, convertedFile] : [convertedFile]);
    }
  }, [file, setValue, requestError, dispatch, fieldName, getValues]);

  useEffect(() => {
    return () => {
      dispatch(resetUploadFile());
    };
  }, [dispatch]);

  const onUploadWebcamFileImg = (fileData: File) => {
    onToggleWebcamModal();
    if (error) {
      clearErrors(fieldName);
    }

    if (uploadId !== fieldName) {
      dispatch(setUploadId(fieldName));
    }

    dispatch(uploadFile(fieldName, fileData));
  };

  const onRejected = (errors: IUploaderCustomError[]) => {
    // One file is always added
    const { customError } = errors[0];

    setError(fieldName, { message: renderIntlMessage(customError) });
  };

  const onUploadFiles = (
    currentFiles: IAttachment[],
    uploadFiles: File[],
    deletedFile?: IAttachment,
  ) => {
    if (error) {
      clearErrors(fieldName);
    }

    if (uploadId !== fieldName) {
      dispatch(setUploadId(fieldName));
    }

    if (deletedFile) {
      setValue(fieldName, currentFiles);
    } else {
      dispatch(uploadFile(fieldName, uploadFiles[0]));
    }
  };

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => {
          return (
            <FilesDropzone<IAttachment>
              filesListTextVariant={filesListTextVariant}
              className={className}
              acceptFileTypes={acceptFileTypes}
              files={field.value}
              onUploadFiles={onUploadFiles}
              onToggleWebcamModal={onToggleWebcamModal}
              height="73px"
              error={error as any} // TODO - PRM-1810 need type
              isLoading={isLoading}
              onRejected={onRejected}
              showTableHeader={shouldBeVisibleTableHeader}
              hasVisibleDownloadBtn={hasVisibleDownloadBtn}
              hasVisibleTakePhotoBtn={hasVisibleTakePhotoBtn}
              hasVisibleScanDocumentBtn={hasVisibleScanDocumentBtn}
            />
          );
        }}
      />
      {isOpenWebcamModal && (
        <WebcamModal
          isOpen={isOpenWebcamModal}
          onToggle={onToggleWebcamModal}
          onUpload={onUploadWebcamFileImg}
        />
      )}
    </>
  );
};

export default FormFilesToServerUploader;
