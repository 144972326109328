import React, { useCallback, useMemo } from 'react';
import { Box, Divider, Grid, IconButton, SvgIcon, TextField, Typography } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { List as ImmutableList } from 'immutable';

import { IAudienceFilter } from 'modules/crm/interfaces/filters';

import RemoveIcon from '../RemoveIcon/RemoveIcon';

import { LeadStatuses } from '../../../constants/leads';
import { ageGroupsLabels, genderTypeLabels } from 'common/constants/personConstants';

import { FormattedMessage, useIntl } from 'react-intl';
import inputLabels from 'common/messages/inputLabels';
import campaigns from '../../../messages/campaigns';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { CameFromFields, MultipleSelect } from 'common/components';
import { IConstOption } from 'common/constants/classes';
import { INamedEntityImt } from 'common/interfaces/common';
import useRootSelector from 'common/hooks/useRootSelector';
import { selectServicesList } from 'modules/services/state/services/selectors';
import { selectCurrentUserAvailableClubs } from 'modules/authentication/state/selectors';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { CloseTag } from 'img/icons';
import { AudienceFilterType } from 'common/constants/campaign';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { DictionaryList } from 'common/constants';
import { ICameFromIndicatorDictionaryItemImt } from 'common/interfaces/dictionary';
import {
  selectPersonsFoundList,
  selectPersonsFoundListLoading,
} from 'common/state/newPerson/primaryInfo/selectors';
import {
  resetPersonsFound,
  searchReferralMembers,
} from 'common/state/newPerson/primaryInfo/actions';
import { useAppDispatch } from 'store/hooks';

const cameFromLayoutConfig = {
  singleInputField: { xs: 6 },
  dropdownSelectField: { xs: 6 },
  referralListField: { xs: 6 },
  cameFromField: { xs: 6 },
} as const;

const FiltersSection = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const clubs: ImmutableList<INamedEntityImt> = useRootSelector(selectCurrentUserAvailableClubs);
  const serviceList: ImmutableList<INamedEntityImt> = useRootSelector(selectServicesList());
  const cameFromIndicators: ImmutableList<ICameFromIndicatorDictionaryItemImt> = useRootSelector(
    selectDictionaryList(DictionaryList.CAME_FROM),
  );
  const personsAutocompleteList: ImmutableList<INamedEntityImt> = useRootSelector(
    selectPersonsFoundList,
  );
  const isPersonsAutocompleteListLoading: boolean = useRootSelector(selectPersonsFoundListLoading);

  const { control, formState, watch } = useFormContext();
  const { errors } = formState;

  const intl = useIntl();
  const renderIntlMessage = useRenderIntlMessage();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'audienceFilterList',
    keyName: 'filterKey',
  });

  const filterList: IAudienceFilter[] = useMemo(
    () => [
      {
        title: intl.formatMessage({ ...campaigns.leadStatusFilter }),
        type: AudienceFilterType.LEAD_STATUS,
        options: LeadStatuses,
        fieldName: 'leadStatuses',
      },
      {
        title: intl.formatMessage({ ...campaigns.locationsFilter }),
        type: AudienceFilterType.LOCATIONS,
        entitiesList: clubs,
        fieldName: 'clubList',
      },
      {
        title: intl.formatMessage({ ...campaigns.genderFilter }),
        type: AudienceFilterType.GENDER,
        options: genderTypeLabels,
        fieldName: 'genders',
      },
      {
        title: intl.formatMessage({ ...campaigns.ageGroupFilter }),
        type: AudienceFilterType.AGE_GROUP,
        options: ageGroupsLabels,
        fieldName: 'ageGroups',
      },
      {
        title: intl.formatMessage({ ...campaigns.servicesFilter }),
        type: AudienceFilterType.BASED_ON_SERVICES,
        entitiesList: serviceList,
        fieldName: 'serviceList',
      },
      {
        title: intl.formatMessage({ ...campaigns.cameFromFilter }),
        type: AudienceFilterType.CAME_FROM,
        entitiesList: cameFromIndicators,
        fieldName: 'cameFromList',
      },
    ],
    [cameFromIndicators, clubs, intl, serviceList],
  );

  const getReferralMembersByValue = useCallback(
    (searchString: string) => {
      dispatch(searchReferralMembers(searchString));
    },
    [dispatch],
  );

  const clearSearchResults = useCallback(() => {
    dispatch(resetPersonsFound());
  }, [dispatch]);

  const renderFilter = (settings: IAudienceFilter, index: number) => {
    const { type, title, options, entitiesList, fieldName } = settings;

    switch (type) {
      case AudienceFilterType.CAME_FROM:
        const currentCameFromIndicatorId = watch(`audienceFilterList.${index}.${fieldName}`)
          ?.cameFromIndicatorId;

        const restCameFromIndicators = cameFromIndicators.filter(
          cameFromItem =>
            !watch('audienceFilterList').find(
              filterItem =>
                filterItem.cameFromList?.cameFromIndicatorId === cameFromItem.get('id') &&
                currentCameFromIndicatorId !== cameFromItem.get('id'),
            ),
        );

        return (
          <Grid container spacing={1}>
            <CameFromFields
              cameFromIndicators={restCameFromIndicators}
              cameFromIndicator={cameFromIndicators.find(
                cameFromItem => cameFromItem.get('id') === currentCameFromIndicatorId,
              )}
              persons={personsAutocompleteList}
              getReferralMembersByValue={getReferralMembersByValue}
              clearSearchResults={clearSearchResults}
              layoutConfig={cameFromLayoutConfig}
              isPersonSearchLoading={isPersonsAutocompleteListLoading}
              fieldName={`audienceFilterList.${index}.${fieldName}`}
              isMultipleSelect
            />
          </Grid>
        );
      case AudienceFilterType.LOCATIONS:
      case AudienceFilterType.BASED_ON_SERVICES:
        return (
          <Controller
            name={`audienceFilterList.${index}.${fieldName}`}
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <MultipleSelect
                multiple
                fullWidth
                value={value || []}
                onChange={onChange}
                onBlur={onBlur}
                options={entitiesList.toJS()}
                placeholder={title}
                error={!!errors.audienceFilterList?.[index]?.[fieldName]}
                helperText={renderIntlMessage(
                  errors.audienceFilterList?.[index]?.[fieldName]?.message,
                  { value: 1 },
                )}
                size="small"
              />
            )}
          />
        );

      case AudienceFilterType.GENDER:
      case AudienceFilterType.AGE_GROUP:
      case AudienceFilterType.LEAD_STATUS:
        return (
          <Controller
            control={control}
            name={`audienceFilterList.${index}.${fieldName}`}
            render={({ field: { onChange, value } }) => (
              <MuiAutocomplete
                value={value || []}
                options={options.values.map((option: IConstOption) => option.value)}
                multiple
                getOptionLabel={option => renderIntlMessage(options.find(option).message)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={title}
                    error={!!errors.audienceFilterList?.[index]?.[fieldName]}
                    helperText={renderIntlMessage(
                      errors.audienceFilterList?.[index]?.[fieldName]?.message,
                      { value: 1 },
                    )}
                  />
                )}
                ChipProps={{
                  deleteIcon: <SvgIcon component={CloseTag} />,
                }}
                onChange={(e, v) => onChange(v)}
                size="small"
              />
            )}
          />
        );
      default:
        return null;
    }
  };

  const renderFilters = () => {
    return fields.map((filterSettings: IAudienceFilter & { filterKey: string }, index) => {
      const currentFilter = filterList.find(({ type }) => type === filterSettings.type);

      return (
        <Grid key={filterSettings.filterKey} item xs={12}>
          <div style={{ padding: 16 }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={2}>
                <Typography component="span">{currentFilter.title}</Typography>
              </Grid>

              <Grid item xs={10} sm={8} style={{ marginRight: 'auto' }}>
                <Controller
                  name={`audienceFilterList.${index}.type`}
                  control={control}
                  defaultValue={currentFilter.type}
                  render={() => <></>}
                />
                {renderFilter(currentFilter, index)}
              </Grid>

              <Grid item xs={2} sm={2}>
                <Box display="flex" justifyContent="flex-end">
                  <IconButton size="small" onClick={() => remove(index)}>
                    <RemoveIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </div>
          <Divider />
        </Grid>
      );
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="button" color="textSecondary" gutterBottom>
          <FormattedMessage {...campaigns.targetAudienceFiltering} />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <MuiAutocomplete
          multiple
          value={fields
            .filter(({ type }: any) => type !== AudienceFilterType.CAME_FROM) // TODO - PRM-1810 need type
            .map(({ type }: any) => ({
              // TODO - PRM-1810 need type
              type,
            }))}
          options={filterList}
          getOptionLabel={(option: any) => {
            if (option.title) return option.title;
            return '';
          }}
          disableClearable
          getOptionSelected={(option: IAudienceFilter, value: IAudienceFilter) =>
            value?.type === option?.type
          }
          filterSelectedOptions
          renderTags={() => <></>}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label={<FormattedMessage {...inputLabels.addFilter} />}
            />
          )}
          onChange={(e, filtersValues: IAudienceFilter[]) => {
            const filterToAppend = filtersValues.find(
              ({ type }) =>
                !fields.find((filter: any) => type === filter.type) || // TODO - PRM-1810 need type
                type === AudienceFilterType.CAME_FROM,
            );

            append(filterToAppend);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container>{renderFilters()}</Grid>
      </Grid>
    </Grid>
  );
};

export default FiltersSection;
