import { useCallback, useMemo } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import moment from 'moment-timezone';

import * as selectors from 'modules/authentication/state/selectors';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { checkIsNowInOperatingHours } from 'common/utils/time';

import { IEntranceItemImt } from 'modules/clubs/interfaces';
import { IUserOrganizationImt } from 'common/interfaces/clients';
import { IDayTimeAvailabilityDto, Weekday } from 'common/interfaces/common';

export enum EntranceStatus {
  ALL_DAY = 'allDay',
  CLOSED = 'closed',
  DO_NOT_WORKING_HOURS = 'doNotWorkingHours',
  OPEN = 'open',
  INACTIVE = 'inactive',
  NOT_SELECTED = 'notSelected',
}

const useEntranceWorkingHours = (entrance: IEntranceItemImt): [EntranceStatus, string[]] => {
  const selectedClub: IUserOrganizationImt = useRootSelector(
    selectors.selectUserSelectedOrganization,
  );
  const clubTimezone = selectedClub.get('timezone');
  const clubDate = moment.tz(clubTimezone);

  const [timezoneMoment] = useTimezoneMoment();

  const transformedEntrance = useMemo(() => entrance?.toJS(), [entrance]);

  const getClubZoneInstance = useCallback(
    (time: string) => moment.tz(time, 'HH:mm', clubTimezone),
    [clubTimezone],
  );

  const getEntranceWorkingHoursRangesLabel = useCallback(
    (range: IDayTimeAvailabilityDto): string[] => {
      const viewTimezoneOffset = timezoneMoment().utcOffset();
      const clubTimezoneOffset = clubDate.utcOffset();
      const differenceBetweenZones = viewTimezoneOffset - clubTimezoneOffset;

      if (range.allDay) {
        return [
          `${clubDate
            .startOf('day')
            .add(differenceBetweenZones, 'minutes')
            .format('h:mm A, ddd')} - ${clubDate
            .endOf('day')
            .add(differenceBetweenZones, 'minutes')
            .format('h:mm A, ddd')}`,
        ];
      }

      return range.timeRanges.map(
        timeRangeItem =>
          `${getClubZoneInstance(timeRangeItem.startTime as string)
            .add(differenceBetweenZones, 'minutes')
            .format('h:mm A, ddd')} - ${getClubZoneInstance(timeRangeItem.endTime as string)
            .add(differenceBetweenZones, 'minutes')
            .format('h:mm A, ddd')}`,
      );
    },
    [clubDate, getClubZoneInstance, timezoneMoment],
  );

  const workingHoursRange = useMemo(() => {
    const currentClubDay = clubDate.day();

    if (!transformedEntrance) {
      return null;
    }

    return transformedEntrance.operatingHours.ranges.find(
      (rangeItem: IDayTimeAvailabilityDto) =>
        rangeItem.weekday === Object.values(Weekday)[currentClubDay],
    );
  }, [clubDate, transformedEntrance]);

  const getEntranceStatus = useCallback(() => {
    if (!transformedEntrance) {
      return EntranceStatus.NOT_SELECTED;
    }

    const {
      operatingHours: { active, ranges },
    } = transformedEntrance;

    if (!active) {
      return EntranceStatus.INACTIVE;
    }

    if (!workingHoursRange) {
      return EntranceStatus.CLOSED;
    }

    if (workingHoursRange.allDay) {
      return EntranceStatus.ALL_DAY;
    }

    if (checkIsNowInOperatingHours(ranges, clubTimezone)) {
      return EntranceStatus.OPEN;
    }

    return EntranceStatus.DO_NOT_WORKING_HOURS;
  }, [clubTimezone, transformedEntrance, workingHoursRange]);

  const entranceStatus: EntranceStatus = getEntranceStatus();
  const entranceWorkingHoursRangesLabel: string[] =
    entranceStatus === EntranceStatus.OPEN ||
    entranceStatus === EntranceStatus.DO_NOT_WORKING_HOURS ||
    entranceStatus === EntranceStatus.ALL_DAY
      ? getEntranceWorkingHoursRangesLabel(workingHoursRange)
      : null;

  return [entranceStatus, entranceWorkingHoursRangesLabel];
};

export default useEntranceWorkingHours;
