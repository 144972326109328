import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { Box } from '@material-ui/core';

import { IServiceItemDetailsImt } from 'common/interfaces/service';
import { IBookingEventImt } from 'modules/booking/interfaces';
import { AlertTypes } from 'common/interfaces/alerts';

import * as selectors from 'common/components/PersonProfile/state/servicesModals/selectors';
import { selectTimezone } from 'common/state/settings/selectors';
import * as actions from 'common/components/PersonProfile/state/servicesModals/actions';

import { PeakModules } from 'common/constants/peakModules';

import EventData from './EventData';
import ServiceEventsCalendar from './ServiceEventsCalendar';
import { Alert, LoadingBackdrop } from 'common/components/index';
import useRootSelector from 'common/hooks/useRootSelector';

import commonMessages from 'common/messages/messages';

interface IScheduledRedeemFormProps {
  serviceInstance: IServiceItemDetailsImt;
  personId: number;
  module: PeakModules;
  appointmentId?: string;
  appointmentStartDate?: string;
}

const ScheduledRedeemForm: React.FC<IScheduledRedeemFormProps> = ({
  serviceInstance,
  personId,
  module,
  appointmentId,
  appointmentStartDate,
}: IScheduledRedeemFormProps): JSX.Element => {
  // state
  const dispatch = useDispatch();

  const eventDetails: IBookingEventImt = useRootSelector(selectors.selectServiceEventDetails);
  const isEventDetailsLoading: boolean = useRootSelector(
    selectors.selectServiceEventDetailsLoading,
  );
  const currentTimezoneView: string = useRootSelector(selectTimezone);

  const { watch } = useFormContext();
  const selectedClub = watch('club');

  const clubTimezone = selectedClub.timezone;
  const clubZoneOffset = moment.tz(clubTimezone).utcOffset();
  const timezoneViewOffset = moment.tz(currentTimezoneView).utcOffset();

  const isSameTimezones = clubZoneOffset === timezoneViewOffset;

  useEffect(() => {
    if (appointmentId && appointmentStartDate) {
      dispatch(
        actions.fetchServiceEventDetails(
          personId,
          appointmentId,
          appointmentStartDate,
          module,
          !appointmentId,
        ),
      );
    }
  }, [dispatch, appointmentId, appointmentStartDate, personId, module]);

  // handlers

  const handleSelectEvent = (eventId: string, eventStartDate: string): void => {
    dispatch(
      actions.fetchServiceEventDetails(personId, eventId, eventStartDate, module, !appointmentId),
    );
  };

  // renders

  return (
    <>
      {!isSameTimezones && (
        <Box marginY={2}>
          <Alert
            severity={AlertTypes.Warning}
            title={
              <FormattedMessage
                {...commonMessages.timezoneIsDifferentBody}
                values={{
                  timezoneViewOffset: `(GMT ${moment.tz(currentTimezoneView).format('Z')})`,
                  clubZoneOffset: `(GMT ${moment.tz(clubTimezone).format('Z')})`,
                }}
              />
            }
          />
        </Box>
      )}

      <ServiceEventsCalendar
        serviceId={serviceInstance.get('serviceId')}
        personId={personId}
        onSelectEvent={handleSelectEvent}
        module={module}
      />

      {eventDetails && <EventData event={eventDetails} serviceInstance={serviceInstance} />}

      <LoadingBackdrop isLoading={isEventDetailsLoading} />
    </>
  );
};

export default ScheduledRedeemForm;
