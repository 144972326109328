import { defineMessages } from 'react-intl';

export default defineMessages({
  // New Lead Form
  newLeadTitle: {
    id: 'app.modules.crm.leads.newLead.title',
    defaultMessage: 'New Lead',
  },
  // Edit Lead Form
  editLeadTitle: {
    id: 'app.modules.crm.leads.editLead.title',
    defaultMessage: 'Edit Lead Profile',
  },
  selectedSalespersonNotListAvailable: {
    id: 'app.modules.crm.leads.selectedSalespersonNotListAvailable.title',
    defaultMessage: 'The selected salesperson is not on the list of available',
  },
  leadProfileTitle: {
    id: 'app.modules.crm.leads.leadProfile.title',
    defaultMessage: 'Lead Profile',
  },

  // Leads Table

  changeStatusLeadProfileTitle: {
    id: 'app.modules.crm.leads.changeStatusLeadProfile.title',
    defaultMessage: 'Change Lead Profile Status',
  },
  crmStatusTitle: {
    id: 'app.modules.crm.leads.crmStatus.title',
    defaultMessage: 'CRM Status',
  },
  employeeHasNoPermissions: {
    id: 'app.modules.crm.leads.changeSalespersonModal.employeeHasNoPermissions',
    defaultMessage:
      "Selected employee don't have assigned permissions per such members club, So employee won't be able to work with this member.",
  },

  // Sale Type
  leadSalesTypeWalkIn: {
    id: 'app.modules.crm.leads.const.walkIn',
    defaultMessage: 'Walk In',
  },
  leadSalesTypePhone: {
    id: 'app.modules.crm.leads.const.phone',
    defaultMessage: 'Phone',
  },
  leadSalesTypeOutreach: {
    id: 'app.modules.crm.leads.const.outreach',
    defaultMessage: 'Outreach',
  },

  // LeadStatusView
  leadStatusTypeCold: {
    id: 'app.modules.crm.leads.leadStatus.cold',
    defaultMessage: 'Cold',
  },
  leadStatusTypeInterested: {
    id: 'app.modules.crm.leads.leadStatus.interested',
    defaultMessage: 'Interested',
  },
  leadStatusTypeActive: {
    id: 'app.modules.crm.leads.leadStatus.active',
    defaultMessage: 'Active',
  },
  leadStatusTypeActiveTrialExpired: {
    id: 'app.modules.crm.leads.leadStatus.activeTrialExpired',
    defaultMessage: 'Active (Trial Expired)',
  },
  leadStatusTypeInactive: {
    id: 'app.modules.crm.leads.leadStatus.inactive',
    defaultMessage: 'Inactive',
  },
  leadStatusTypeClosed: {
    id: 'app.modules.crm.leads.leadStatus.closed',
    defaultMessage: 'Closed',
  },
  leadStatusTypeDead: {
    id: 'app.modules.crm.leads.leadStatus.dead',
    defaultMessage: 'Dead',
  },

  leadStatusTypeEmpty: {
    id: 'app.modules.crm.leads.leadStatus.empty',
    defaultMessage: 'Empty',
  },

  // Communications
  leadCommunicationsAny: {
    id: 'app.modules.crm.leads.communications.any',
    defaultMessage: 'Any',
  },
  leadCommunicationsEmail: {
    id: 'app.modules.crm.leads.communications.email',
    defaultMessage: 'Email',
  },
  leadCommunicationsPhoneCalls: {
    id: 'app.modules.crm.leads.communications.phoneCalls',
    defaultMessage: 'Phone calls',
  },
  leadCommunicationsNotifications: {
    id: 'app.modules.crm.leads.communications.notifications',
    defaultMessage: 'Notifications',
  },

  // Primary Information Step
  newLeadPrimaryInfoStepTitle: {
    id: 'app.modules.crm.leads.newLead.primaryInfo.title',
    defaultMessage: 'Primary Information',
  },
  newLeadPrimaryInfoStepContactInfo: {
    id: 'app.modules.crm.leads.newLead.primaryInfo.sections.contactInfo',
    defaultMessage: 'Contact Information',
  },
  newLeadPrimaryInfoStepInterests: {
    id: 'app.modules.crm.leads.newLead.primaryInfo.sections.interests',
    defaultMessage: 'Interests (Optional)',
  },
  newLeadPrimaryInfoStepSalesInfo: {
    id: 'app.modules.crm.leads.newLead.primaryInfo.sections.salesInfo',
    defaultMessage: 'Sales Info',
  },
  newLeadPrimaryInfoStepLeadStatus: {
    id: 'app.modules.crm.leads.newLead.primaryInfo.sections.leadStatus',
    defaultMessage: 'Lead Status',
  },
  newLeadPrimaryInfoStepLeadDoNotContactText: {
    id: 'app.modules.crm.leads.newLead.primaryInfo.sections.doNotContact',
    defaultMessage: 'Do not contact hours',
  },

  // Trial Membership Step
  newLeadTrialMembershipStepTitle: {
    id: 'app.modules.crm.leads.newLead.membershipStep.title',
    defaultMessage: 'Add Membership',
  },
  changeMembershipStepTitle: {
    id: 'app.modules.crm.leads.changeMembership.title',
    defaultMessage: 'Change Membership',
  },
  newLeadTrialMembershipStepSelect: {
    id: 'app.modules.crm.leads.newLead.trialMembership.select',
    defaultMessage: 'Membership bundle',
  },

  // Additional info Step
  newLeadAdditionalInfoStepTitle: {
    id: 'app.modules.crm.leads.newLead.additionalInfo.title',
    defaultMessage: 'Additional Information',
  },

  // Payment Step
  newLeadPaymentStepTitle: {
    id: 'app.modules.crm.leads.newLead.Payment.title',
    defaultMessage: 'Payment',
  },

  // Family Member Step
  newLeadFamilyMembersStepTitle: {
    id: 'app.modules.crm.leads.newLead.FamilyMembers.title',
    defaultMessage: 'Add Family Members',
  },

  // Sign contract Step
  newLeadSignContractStepTitle: {
    id: 'app.modules.crm.leads.newLead.SignContract.title',
    defaultMessage: 'Sign Contract',
  },

  // Sign Waivers Step
  newLeadSignWaiversStepTitle: {
    id: 'app.modules.crm.leads.newLead.signWaivers.title',
    defaultMessage: 'Sign Waivers',
  },
  newLeadSignWaiversStepMembership: {
    id: 'app.modules.crm.leads.newLead.signWaivers.membership',
    defaultMessage: 'Membership',
  },
  newLeadSignWaiversStepServices: {
    id: 'app.modules.crm.leads.newLead.signWaivers.services',
    defaultMessage: 'Services',
  },
  newLeadSignWaiversStepNoWaivers: {
    id: 'app.modules.crm.leads.newLead.signWaivers.noWaivers',
    defaultMessage: 'No Waivers',
  },
  newLeadSignContractsStepNoContracts: {
    id: 'app.modules.crm.leads.newLead.signContracts.noContracts',
    defaultMessage: 'No Contracts',
  },
  newLeadSignWaiversStepSigned: {
    id: 'app.modules.crm.leads.newLead.signWaivers.signed',
    defaultMessage: 'Signed',
  },
  newLeadSignWaiversStepUnsigned: {
    id: 'app.modules.crm.leads.newLead.signWaivers.unsigned',
    defaultMessage: 'Unsigned',
  },
  newLeadSignWaiversStepWaitingForSigning: {
    id: 'app.modules.crm.leads.newLead.signWaivers.waitingForSigning',
    defaultMessage: 'Waiting for signing',
  },
  newLeadSignWaiversStepSignLaterBtn: {
    id: 'app.modules.crm.leads.newLead.signWaivers.signLater',
    defaultMessage: 'Sign Later',
  },
  newLeadSignWaiversStepSignNowBtn: {
    id: 'app.modules.crm.leads.newLead.signWaivers.signNow',
    defaultMessage: 'Sign Now',
  },
  newLeadSignWaiversStepSign: {
    id: 'app.modules.crm.leads.newLead.signWaivers.sign',
    defaultMessage: 'Sign',
  },
  newLeadSignWaiversStepSendViaEmailBtn: {
    id: 'app.modules.crm.leads.newLead.signWaivers.sendViaEmail',
    defaultMessage: 'Send via Email',
  },
  newLeadSignWaiversStepSendViaSMSBtn: {
    id: 'app.modules.crm.leads.newLead.signWaivers.sendViaSMS',
    defaultMessage: 'Send via SMS',
  },

  // Schedule Tour Step
  newLeadScheduleTourStepTitle: {
    id: 'app.modules.crm.leads.newLead.scheduleTour.title',
    defaultMessage: 'Schedule a Tour (Optional)',
  },
  leadCreatedMessage: {
    id: 'app.modules.crm.leads.leadCreatedMessage',
    defaultMessage: 'New lead has been created',
  },
  memberCreatedMessage: {
    id: 'app.modules.crm.leads.memberCreatedMessage',
    defaultMessage: 'New member has been created',
  },
  changeSalesTitle: {
    id: 'app.modules.crm.leads.const.changeSalesTitle',
    defaultMessage: 'Change salesperson',
  },
});
