import { defineMessages } from 'react-intl';

export default defineMessages({
  clubsAvailabilityLabel: {
    id: 'app.modules.pos-settings.labels.clubsAvailability',
    defaultMessage: 'Clubs availability',
  },
  configurationPerClubLabel: {
    id: 'app.modules.pos-settings.labels.configurationPerClub',
    defaultMessage: 'Configuration per club',
  },
  editOverridesModalTitle: {
    id: 'app.modules.pos-settings.modals.editOverrides.title',
    defaultMessage:
      'Edit {count, plural, =0 {club override} one {a club override} other {# clubs overrides}}',
  },
  deleteOverridesModalTitle: {
    id: 'app.modules.pos-settings.modals.deleteOverrides.title',
    defaultMessage:
      'Delete {count, plural, =0 {club override} one {a club override} other {# clubs overrides}}',
  },
  deleteEntitiesModalMessage: {
    id: 'app.modules.pos-settings.modals.deleteOverrides.message',
    defaultMessage: 'Are you sure you want to delete ',
  },
  clubOverridePluralLabel: {
    id: 'app.modules.pos-settings.labels.clubOverrides',
    defaultMessage:
      '{count, plural, =0 {club override} one {a club override} other {# clubs overrides}}',
  },
  privateLabel: {
    id: 'app.modules.pos-settings.labels.clubOverrides',
    defaultMessage: 'Private (per device)',
  },

  addClubsOverridesBtn: {
    id: 'app.modules.pos-settings.clubOverrides.addClubBtn',
    defaultMessage: 'Add Club',
  },

  // sidebar menu items
  recieptTemplates: {
    id: 'app.modules.pos-settings.sidebar.recieptTemplates',
    defaultMessage: 'Receipt templates',
  },
  taxRates: {
    id: 'app.modules.pos-settings.sidebar.taxRates',
    defaultMessage: 'Tax rates',
  },
  revenueCodes: {
    id: 'app.modules.pos-settings.sidebar.revenueCodes',
    defaultMessage: 'Revenue codes',
  },
  vendors: {
    id: 'app.modules.pos-settings.sidebar.vendors',
    defaultMessage: 'Vendors',
  },
  inventory: {
    id: 'app.modules.pos-settings.sidebar.inventory',
    defaultMessage: 'Inventory',
  },
  registers: {
    id: 'app.modules.pos-settings.sidebar.registers',
    defaultMessage: 'Registers',
  },
  paymentMethods: {
    id: 'app.modules.pos-settings.sidebar.paymentMethods',
    defaultMessage: 'Payment methods',
  },
  paymentMethodCreatedMessage: {
    id: 'app.modules.pos-settings.paymentMethodCreatedMessage',
    defaultMessage: 'New payment method has been created',
  },
  // InventoryItems
  changeStatusInventoryItemsBody: {
    id: 'app.modules.pos-settings.InventoryItem.changeStatusInventoryItemsBody.body',
    defaultMessage: 'Are you sure you want to {action} {name} inventory?',
  },
  changeStatusInventoryItems: {
    id: 'app.modules.pos-settings.InventoryItem.titles.changeStatusInventoryItems',
    defaultMessage: 'Change status',
  },
  newInventoryItemTitle: {
    id: 'app.modules.pos-settings.InventoryItem.titles.newInventoryItem',
    defaultMessage: 'New Inventory',
  },
  editInventoryItemTitle: {
    id: 'app.modules.pos-settings.InventoryItem.titles.editInventoryItem',
    defaultMessage: 'Edit Inventory',
  },
  categoriesTitle: {
    id: 'app.modules.pos-settings.InventoryItem.titles.categories',
    defaultMessage: 'Categories',
  },
  categoryLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.category',
    defaultMessage: 'Category',
  },
  editClubDataLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.editClubData',
    defaultMessage: 'Edit Club Data',
  },
  editClubsDataLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.editClubsData',
    defaultMessage: 'Edit Clubs Data',
  },
  locationLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.location',
    defaultMessage: 'Location',
  },
  preferredVendorLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.preferredVendor',
    defaultMessage: 'Preferred Vendor',
  },
  vendorCatalogLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.vendorCatalog',
    defaultMessage: 'Vendor Catalog',
  },
  costPerItemLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.costPerItem',
    defaultMessage: 'Cost per Item $',
  },
  retailPriceLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.retailPrice',
    defaultMessage: 'Retail Price $',
  },
  retailPriceWithTaxLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.retailPriceWithTax',
    defaultMessage: 'Retail Price + Tax',
  },
  onHandLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.onHand',
    defaultMessage: 'On Hand',
  },
  idealStockLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.idealStock',
    defaultMessage: 'Ideal Stock',
  },
  stockTrackingLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.stockTrackingLabel',
    defaultMessage: 'Stock Tracking',
  },
  showInSuggestedProductsLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.showInSuggestedProducts',
    defaultMessage: 'Show in suggested products',
  },
  reorderPointLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.reorderPoint',
    defaultMessage: 'Reorder point',
  },
  vendorsLabel: {
    id: 'app.modules.pos-settings.InventoryItem.labels.vendors',
    defaultMessage: 'Vendors',
  },
  inventoryItemCreatedMessage: {
    id: 'app.modules.pos-settings.inventoryItemCreatedMessage',
    defaultMessage: 'New inventory item has been created',
  },
  // Receipts
  archiveItemReceipts: {
    id: 'app.modules.pos-settings.Receipt.archiveItemReceipts.title',
    defaultMessage: 'Archive receipt',
  },
  archiveItemReceiptsBody: {
    id: 'app.modules.pos-settings.Receipt.archiveItemReceiptsBody.body',
    defaultMessage: 'Are you sure you want to {action} {name} receipt?',
  },
  newReceiptItemTitle: {
    id: 'app.modules.pos-settings.Receipt.newReceiptItem.title',
    defaultMessage: 'New Receipt',
  },
  editReceiptItemTitle: {
    id: 'app.modules.pos-settings.Receipt.editReceiptItem.title',
    defaultMessage: 'Edit Receipt',
  },
  receiptItemTopFields: {
    id: 'app.modules.pos-settings.Receipt.receiptItem.receiptItemTopFields',
    defaultMessage: 'Top fields',
  },
  receiptItemBottomFields: {
    id: 'app.modules.pos-settings.Receipt.receiptItem.receiptItemBottomFields',
    defaultMessage: 'Bottom fields',
  },
  receiptCreatedMessage: {
    id: 'app.modules.pos-settings.receiptCreatedMessage',
    defaultMessage: 'New receipt has been created',
  },
  // Payment Methods
  archiveItemPaymentMethod: {
    id: 'app.modules.pos-settings.PaymentMethods.titles.archiveItemPaymentMethod',
    defaultMessage: 'Archive payment method',
  },
  blockPaymentMethodTitle: {
    id: 'app.common.titles.blockPaymentMethod',
    defaultMessage: 'Block payment method',
  },
  editPaymentMethodTitle: {
    id: 'app.modules.pos-settings.PaymentMethods.titles.editPaymentMethod',
    defaultMessage: 'Edit Payment Method',
  },
  newPaymentMethodTitle: {
    id: 'app.modules.pos-settings.PaymentMethods.titles.newPaymentMethod',
    defaultMessage: 'New Payment Method',
  },
  posContentTitle: {
    id: 'app.modules.pos-settings.PaymentMethods.titles.posContent',
    defaultMessage: 'POS CONTENT',
  },
  archiveItemPaymentMethodBody: {
    id: 'app.modules.pos-settings.PaymentMethods.bodies.archiveItemPaymentMethodBody',
    defaultMessage: 'Are you sure you want to {action} {name} payment method?',
  },
  questionBlockPaymentMethodBody: {
    id: 'app.modules.pos-settings.PaymentMethods.bodies.questionBlockPaymentMethod',
    defaultMessage: 'Are you sure you want to block the method ',
  },
  questionBlockPaymentMethodsBody: {
    id: 'app.modules.pos-settings.PaymentMethods.bodies.questionBlockPaymentMethods',
    defaultMessage: 'Are you sure you want to block the {method} methods',
  },
  creditCardLabel: {
    id: 'app.modules.pos-settings.PaymentMethods.labels.creditCard',
    defaultMessage: 'Credit card (Visa, MC)',
  },
  peakLabel: {
    id: 'app.modules.pos-settings.PaymentMethods.labels.peak',
    defaultMessage: 'Peak',
  },
  inClubTerminalLabel: {
    id: 'app.modules.pos-settings.PaymentMethods.labels.inClubTerminal',
    defaultMessage: 'In club terminal',
  },
  applyToAllLabel: {
    id: 'app.modules.pos-settings.PaymentMethods.labels.applyToAll',
    defaultMessage: 'Apply to all',
  },
  processingLabel: {
    id: 'app.modules.pos-settings.PaymentMethods.labels.processing',
    defaultMessage: 'Processing',
  },
  customPaymentMethodLabel: {
    id: 'app.modules.pos-settings.PaymentMethods.labels.customPaymentMethod',
    defaultMessage: 'Custom Payment Method',
  },
  maxInvoiceTableLabel: {
    id: 'app.modules.pos-settings.PaymentMethods.tableLabels.maxInvoice',
    defaultMessage: 'MAX INVOICE %',
  },
  blockPayMethod: {
    id: 'app.modules.pos-settings.overridesTableblockPayMethodText',
    defaultMessage:
      'Are you sure you want to block the {count, plural, =0 {method} one {method} other {# methods}}',
  },
  blockPayMethodModalTitle: {
    id: 'app.modules.pos-settings.overridesTableblockPayMethodModalTitle',
    defaultMessage: 'Block {count, plural, =0 {method} one {method} other {methods}}',
  },
  // no ovverides table text
  noOvveridesTableText: {
    id: 'app.modules.pos-settings.overridesTable.noOverridesText',
    defaultMessage: 'No overrides yet',
  },
  // clubs
  addClubsBtn: {
    id: 'app.modules.pos-settings.clubs.addClubsBtn',
    defaultMessage: 'CLUBS',
  },
  addClubTitle: {
    id: 'app.modules.pos-settings.overridesTable.addClubTitle',
    defaultMessage: 'Add club',
  },
  registerCreatedMessage: {
    id: 'app.modules.pos-settings.registerCreatedMessage',
    defaultMessage: 'New register has been created',
  },
  revenuCodeCreatedMessage: {
    id: 'app.modules.pos-settings.revenuCodeCreatedMessage',
    defaultMessage: 'New revenu code has been created',
  },
  taxRateCreatedMessage: {
    id: 'app.modules.pos-settings.taxRateCreatedMessage',
    defaultMessage: 'New tax rate has been created',
  },
  vendorCreatedMessage: {
    id: 'app.modules.pos-settings.vendorCreatedMessage',
    defaultMessage: 'New vendor has been created',
  },
  inventoryRecalculatePriceMessage: {
    id: 'app.modules.pos-settings.inventoryRecalculatePriceMessage',
    defaultMessage: 'Value will be recalculated after saving',
  },
  maxAmountLabel: {
    id: 'app.modules.pos-settings.maxAmountLabel',
    defaultMessage: 'Max amount',
  },
  overridesPerClubsLabel: {
    id: 'app.modules.pos-settings.overridesPerClubsLabel',
    defaultMessage: 'Overrides per clubs',
  },
});
