import React, { useCallback } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { Box, Typography } from '@material-ui/core';

import { selectTimezone } from 'common/state/settings/selectors';
import { selectUserSelectedOrganization } from 'modules/authentication/state/selectors';

import { IAccessHoursItem } from 'modules/dictionaries/interfaces/accessHours';
import { AlertTypes } from 'common/interfaces/alerts';
import { IUserOrganizationImt } from 'common/interfaces/clients';

import { ReactComponent as KidZoneIcon } from 'img/icons/kidzone.svg';
import { Alert, AvailabilitiesResultBlock, UnavailableModule } from 'common/components';

import messages from 'modules/kid-zone/messages';
import commonMessages from 'common/messages/messages';

interface IKidZoneOutsideOfWorkingHours {
  operatingHours: IAccessHoursItem;
}

const KidZoneOutsideOfWorkingHours = ({
  operatingHours,
}: IKidZoneOutsideOfWorkingHours): JSX.Element => {
  const selectedClub: IUserOrganizationImt = useRootSelector(selectUserSelectedOrganization);
  const currentTimezoneView: string = useRootSelector(selectTimezone);

  const clubTimezone = selectedClub.get('timezone');
  const clubZoneOffset = moment.tz(clubTimezone).utcOffset();
  const timezoneViewOffset = moment.tz(currentTimezoneView).utcOffset();

  const isSameTimezones = clubZoneOffset === timezoneViewOffset;

  const renderOperatingHours = useCallback(
    () => (
      <>
        {!operatingHours?.active && (
          <Box mb={1}>
            <Typography variant="h4">
              <FormattedMessage
                {...messages.kidZoneOutsideWorkingHoursInactiveLabel}
                values={{ title: operatingHours.title }}
              />
            </Typography>
          </Box>
        )}

        <AvailabilitiesResultBlock availabilities={operatingHours?.ranges} />

        {!isSameTimezones && (
          <Box mt={2}>
            <Alert
              severity={AlertTypes.Warning}
              title={
                <FormattedMessage
                  {...commonMessages.timezoneIsDifferentBody}
                  values={{
                    timezoneViewOffset: `(GMT ${moment.tz(currentTimezoneView).format('Z')})`,
                    clubZoneOffset: `(GMT ${moment.tz(clubTimezone).format('Z')})`,
                  }}
                />
              }
            />
          </Box>
        )}
      </>
    ),
    [
      clubTimezone,
      currentTimezoneView,
      isSameTimezones,
      operatingHours.active,
      operatingHours.ranges,
      operatingHours.title,
    ],
  );

  return (
    <UnavailableModule
      lockIcon={<KidZoneIcon />}
      title={<FormattedMessage {...messages.kidZoneOutsideWorkingHoursTitle} />}
      description={renderOperatingHours()}
    />
  );
};

export default KidZoneOutsideOfWorkingHours;
