import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

// components
import ClockInEmployeeModal from '../ClockInEmployeeModal/ClockInEmployeeModal';
import ClockInWithEmployeeAndPositionsModal from '../ClockInWithEmployeeAndPositionsModal/ClockInWithEmployeeAndPositionsModal';

// common
import { ReactComponent as CoinIcon } from 'img/icons/coin.svg';
import { NavigationContext } from 'common/createContext/navigationContext';
import useToggleFullScreen from 'common/hooks/useFullScreen';
import commonMessages from 'common/messages/messages';
import messages from 'modules/timeclock/messages';

// state
import { IUnlockCredentials } from 'modules/authentication/interfaces';
import { IServerError } from 'common/interfaces/http';
import {
  clockInTimeclockSignIn,
  clockInTimeclockSignInAction,
} from 'modules/timeclock/state/actions';
import {
  selectClockInEmployeeActionResult,
  selectClockInEmployeeError,
} from 'modules/timeclock/state/selectors';
import { IClockedInEmployee } from 'modules/timeclock/interfaces/timeclock';
import useRootSelector from 'common/hooks/useRootSelector';

const useStyles = makeStyles({
  buttonsWrapper: {
    width: 'initial',
  },
});

const TimeclockHeader = (): JSX.Element => {
  const dispatch = useDispatch();

  const [isClockInEmployeeModalOpen, setIsClockInEmployeeModalOpen] = useState(false);
  const [isClockInWithEmployeeModalOpen, setIsClockInWithEmployeeModalOpen] = useState(false);

  const clockedInEmployee: IClockedInEmployee = useRootSelector(selectClockInEmployeeActionResult);
  const clockInEmployeeError: IServerError = useRootSelector(selectClockInEmployeeError);

  const { showNavigation, toggleShowNavigation } = useContext(NavigationContext);

  const { toggleFullScreenMode } = useToggleFullScreen();

  const navigate = useNavigate();

  const classes = useStyles();

  useEffect(() => {
    if (clockedInEmployee) {
      setIsClockInEmployeeModalOpen(false);
      setIsClockInWithEmployeeModalOpen(true);
    }
  }, [clockedInEmployee]);

  const toggleLockMode = async () => {
    if (showNavigation) {
      toggleShowNavigation(false);
      await toggleFullScreenMode(true);
      navigate('/timeclock');
    } else {
      setIsClockInEmployeeModalOpen(true);
    }
  };

  const handleClockIn = (data: IUnlockCredentials) => {
    dispatch(clockInTimeclockSignIn(data));
  };

  const handleCloseClockInModal = () => {
    setIsClockInWithEmployeeModalOpen(false);
    dispatch(clockInTimeclockSignInAction(null));
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h2">
        <FormattedMessage {...commonMessages.timeclock} />
      </Typography>

      <Grid container spacing={2} className={classes.buttonsWrapper}>
        <Grid item>
          <Button
            startIcon={<CoinIcon />}
            onClick={() => navigate('/timeclock/payroll-report')}
            variant="contained"
          >
            <FormattedMessage {...messages.payrollReportLabel} />
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsClockInEmployeeModalOpen(true)}
          >
            <FormattedMessage {...messages.clockInEmployeeBtn} />
          </Button>
        </Grid>
      </Grid>

      {isClockInEmployeeModalOpen && (
        <ClockInEmployeeModal
          isOpen={isClockInEmployeeModalOpen}
          onClose={() => setIsClockInEmployeeModalOpen(false)}
          onSubmit={handleClockIn}
          serverError={clockInEmployeeError}
          toggleLockMode={toggleLockMode}
        />
      )}
      {isClockInWithEmployeeModalOpen && (
        <ClockInWithEmployeeAndPositionsModal
          isOpen={isClockInWithEmployeeModalOpen}
          onClose={handleCloseClockInModal}
          clockedInEmployee={clockedInEmployee}
        />
      )}
    </Box>
  );
};

export default TimeclockHeader;
