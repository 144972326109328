// libraries
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Box, List, Typography } from '@material-ui/core';
import { List as ImmutableList } from 'immutable';
import useRootSelector from 'common/hooks/useRootSelector';
// custom interfaces
import {
  EventAttendeeType,
  IEventPersonEntity,
  IParticipantImt,
  PersonAppointmentStatus,
  PersonAttendanceType,
} from '../../../interfaces';
import { fetchDictionaryList, resetDictionaryListAction } from 'common/state/dictionary/actions';
import { DictionaryList } from 'common/constants';
import { SearchInputWithOptions } from 'common/components';
import { IParticipantListItem } from 'common/interfaces/dictionary';
import {
  selectDictionaryList,
  selectIsDictionaryListLoading,
} from 'common/state/dictionary/selectors';
import messages from '../../../../services/messages/messages';
import { PeakModules } from 'common/constants/peakModules';
import { useAppDispatch } from 'store/hooks';
import { AttendeeTypes } from 'modules/booking/constants';

interface IParticipantsListSearchProps {
  module: PeakModules;
  personId?: number;
  types?: Array<EventAttendeeType>;
  eventDate?: string;

  onAddParticipant: (participant: IEventPersonEntity) => void;
}

const ParticipantsListSearch: React.FC<IParticipantsListSearchProps> = (
  props: IParticipantsListSearchProps,
): JSX.Element => {
  const intl = useIntl();
  const { module, personId, types, eventDate, onAddParticipant } = props;

  // state

  const dispatch = useAppDispatch();

  const participants: ImmutableList<IParticipantImt> = useRootSelector(
    selectDictionaryList(DictionaryList.PARTICIPANTS),
  );
  const isParticipantsLoading: boolean = useRootSelector(
    selectIsDictionaryListLoading(DictionaryList.PARTICIPANTS),
  );

  // effects

  useEffect(() => {
    if (types?.length) {
      dispatch(
        fetchDictionaryList(DictionaryList.PARTICIPANTS, {
          module,
          types,
          personId,
        }),
      );
    }

    return () => {
      dispatch(resetDictionaryListAction({ dictionary: DictionaryList.PARTICIPANTS }));
    };
  }, [dispatch, module, personId, types]);

  // handlers

  const handleAddParticipant = (event, option: IParticipantListItem): void => {
    const participant: IEventPersonEntity = {
      attendanceType: PersonAttendanceType.Default,
      status: PersonAppointmentStatus.Pending,
      joinedDate: eventDate,
    };

    const person = {
      id: option.id,
      firstName: option.firstName,
      lastName: option.lastName,
      active: option.active,
      imageUrl: option.imageUrl,
      type: option.type,
    };

    if (option.type === EventAttendeeType.EMPLOYEE) {
      participant.salesperson = person;
    } else {
      participant.customer = person;
    }

    onAddParticipant(participant);
  };

  // renders

  return (
    <SearchInputWithOptions<IParticipantListItem>
      fullWidth
      isAutoDeselect
      blackStyle
      disabled={!types?.length}
      options={participants?.toJS() || []}
      loading={isParticipantsLoading}
      placeholder={intl.formatMessage(messages.searchParticipiantsLabel)}
      renderOption={({ firstName, lastName }: IParticipantListItem) => (
        <Box width="100%">{`${firstName} ${lastName}`}</Box>
      )}
      getOptionLabel={option => option && `${option.firstName} ${option.lastName}`}
      onChange={handleAddParticipant}
      getOptionsByValue={null}
      groupBy={option => option.type}
      renderGroup={params => {
        return (
          <React.Fragment key={params.key}>
            <Box p={1} pl={2}>
              <Typography variant="button" color="textSecondary">
                {AttendeeTypes.translate(params.group)}
              </Typography>
            </Box>
            <List>{params.children}</List>
          </React.Fragment>
        );
      }}
    />
  );
};

export default React.memo(ParticipantsListSearch, (prevProps, nextProps) => {
  let propsAreEqual = true;
  Object.keys(prevProps).forEach(key => {
    if (key === 'types') {
      const prevTypes = prevProps[key];
      const nextTypes = nextProps[key];

      if (prevTypes.length !== nextTypes.length) {
        propsAreEqual = false;
      }

      const hasSameElements = prevTypes.every(type => nextTypes.includes(type));
      propsAreEqual = propsAreEqual && hasSameElements;
    } else {
      propsAreEqual = propsAreEqual && prevProps[key] === nextProps[key];
    }
  });

  return propsAreEqual;
});
