import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useNavigate } from 'react-router-dom';

import { ITableParams } from 'common/interfaces/table';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';
import usePreviousValue from 'common/hooks/usePreviousValue';
import { makeRequestUrlFromFilter, makeTableParams } from 'common/utils/http';
import { IFilter, IFilterSettings } from 'common/interfaces/filter';
import { makeFilterChangeHandler } from 'common/utils/filter';
import useFirst from 'common/hooks/useFirst';

interface IFilterInfoForClub {
  filterSettings: IFilterSettings[];
  clubFilterName?: string;
}

export const useUpdateFiltersOnChangeClub = (
  filterInfo: IFilterInfoForClub,
  locationSearch: string | null = null,
  defaultParams?: Partial<ITableParams>,
): [ITableParams, Dispatch<SetStateAction<ITableParams>>] => {
  const { filterSettings, clubFilterName } = filterInfo;

  const selectedClubId = useRootSelector(selectUserSelectedClubId);

  const prevSelectedClubId = usePreviousValue(selectedClubId);

  const navigate = useNavigate();

  const firstRender = useFirst();

  const [tableParams, setTableParams] = useState(() => {
    return makeTableParams(filterSettings, locationSearch, defaultParams);
  });

  useEffect(() => {
    if (!firstRender && selectedClubId !== prevSelectedClubId) {
      const params = makeTableParams(filterSettings, locationSearch, defaultParams);

      if (locationSearch !== null && clubFilterName) {
        const filterSetting = filterSettings.find(setting => clubFilterName === setting.name);

        if (filterSetting) {
          const clubFilterValue = {
            name: clubFilterName,
            type: filterSetting.type,
            value: filterSetting.defaultValue,
          } as IFilter;

          const handleFilterChange = makeFilterChangeHandler(params.filters || [], filters => {
            setTableParams(prevState => ({
              ...prevState,
              filters,
            }));
          });

          const filterList = handleFilterChange(clubFilterValue);

          if (!filterList) {
            setTableParams(params);
          }

          navigate(makeRequestUrlFromFilter('', filterList || params.filters || []));
        }
      } else {
        setTableParams(params);
      }
    }
  }, [
    locationSearch,
    defaultParams,
    prevSelectedClubId,
    selectedClubId,
    clubFilterName,
    navigate,
    filterSettings,
    firstRender,
  ]);

  return [tableParams, setTableParams];
};
