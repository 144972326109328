import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

export function getArray<T = any>(val: any): T[] {
  return isArray(val) ? val : [];
}

export function getObject<T extends Record<string, any> = Record<string, any>>(val: any): T {
  return isObject(val) ? (val as T) : ({} as T);
}

export function getString(val: any): string {
  return isString(val) ? val : '';
}
