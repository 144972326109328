import React, { useContext, useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Box, Button, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';

import * as selectors from 'modules/pos-kiosk/state/register/selectors';
import { fetchRegisterCashDeskInfo } from 'modules/pos-kiosk/state/register/actions';

import { IHeadCell, ITableRow } from 'common/interfaces/table';
import { IPosKioskReconcileDTO, TCashDeskInfoImt } from 'modules/pos-kiosk/interfaces/registers';

import { DeviceActionButton, LoadingBackdrop, Table } from 'common/components';

import { StepContext } from 'common/createContext/stepContext';
import { Denominations, denominations } from 'modules/pos-kiosk/constants/registers';
import { ReconcileBillsValidationSchema } from './validationSchema';

import { formatNumberToPrice } from 'common/utils';
import { useAppDispatch } from 'store/hooks';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { ReactComponent as PosIcon } from 'img/icons/sidebar/cash-register.svg';

import tableHeaders from 'common/messages/tableHeaders';
import inputLabels from 'common/messages/inputLabels';
import { DeviceType } from 'common/constants/scanner';
import messages from 'modules/pos-kiosk/messages/messages';
import { ICustomDeviceActionButtonProps } from 'common/interfaces/webHid';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.text.secondary,

    '& svg': {
      width: 16,
      height: 16,
    },
  },
  totalLabel: {
    marginRight: theme.spacing(1),
    textTransform: 'uppercase',
  },
  totalBlock: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const headerOptions: IHeadCell[] = [
  { id: 'bills', label: <FormattedMessage {...tableHeaders.billOrCoin} />, sort: false },
  { id: 'count', label: <FormattedMessage {...inputLabels.count} />, align: 'right', sort: false },
  { id: 'total', label: <FormattedMessage {...tableHeaders.total} />, align: 'right', sort: false },
];

// TODO - PRM-1810 tmp form interface
type FormValues = {
  [key in Denominations]: any;
};

interface IProps {
  registerId: string;
  reconcileData: Partial<IPosKioskReconcileDTO>;
}

const CustomButton = (props: ICustomDeviceActionButtonProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={`${classes.button} iconButton--contained`}
      startIcon={<PosIcon />}
      {...props}
    >
      <FormattedMessage {...messages.openDrawer} />
    </Button>
  );
};

const ReconcileBillsStep = ({ registerId, reconcileData }: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const cashDeskInfo: TCashDeskInfoImt = useRootSelector(
    selectors.selectPosKioskRegisterCashDeskInfo,
  );
  const isCashDeskInfoLoading: boolean = useRootSelector(
    selectors.selectPosKioskRegisterCashDeskInfoLoading,
  );

  const formMethods = useForm<FormValues>({
    defaultValues: {},
    resolver: yupResolver(ReconcileBillsValidationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = formMethods;

  const { onBack, renderFooter, onNext } = useContext(StepContext);
  const classes = useStyles();
  const renderIntlMessage = useRenderIntlMessage();

  useEffect(() => {
    if (!reconcileData.cashDesk) {
      dispatch(fetchRegisterCashDeskInfo(registerId));
    }
  }, [dispatch, reconcileData, registerId]);

  useEffect(() => {
    if (reconcileData.cashDesk || cashDeskInfo?.size) {
      reset({ ...(reconcileData.cashDesk || cashDeskInfo.toJS()) });
    }
  }, [cashDeskInfo, reconcileData.cashDesk, reset]);

  const cashTotal = denominations.reduce(
    (total, denominationItem) => total + denominationItem.value * +watch(denominationItem.key),
    0,
  );

  const onSubmit = values => {
    onNext({ cashDesk: { ...values, cashAmount: cashTotal } });
  };

  const tableRows: ITableRow[] = denominations.map(denominationItem => ({
    id: denominationItem.key,
    cells: [
      { label: denominationItem.label },
      {
        label: '',
        maxWidth: '52px',
        align: 'right',
        cellComponent: (
          <Controller
            name={denominationItem.key}
            control={control}
            defaultValue={0}
            render={({ field: { value, onChange, onBlur } }) => (
              <TextField
                variant="outlined"
                size="small"
                type="number"
                inputProps={{ min: 0 }}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors[denominationItem.key]}
                helperText={renderIntlMessage(errors[denominationItem.key]?.message)}
              />
            )}
          />
        ),
      },

      {
        label: `$${formatNumberToPrice(
          watch(denominationItem.key) ? denominationItem.value * +watch(denominationItem.key) : 0,
        )}`,
        variant: 'h6',
        align: 'right',
      },
    ],
  }));

  return (
    <>
      <Box position="relative">
        <form>
          <Table
            rows={tableRows}
            hideToolbar
            hidePagination
            hideSearchInput
            isLoading={false}
            headerOptions={headerOptions}
          />
        </form>

        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <DeviceActionButton deviceType={DeviceType.CashDrawer} CustomButton={CustomButton} />

          <Typography component="p" className={classes.totalBlock}>
            <Typography component="span" className={classes.totalLabel}>
              <FormattedMessage {...inputLabels.total} />
            </Typography>
            <Typography component="span" variant="h2">
              {`$${formatNumberToPrice(cashTotal)}`}
            </Typography>
          </Typography>
        </Box>

        <LoadingBackdrop isLoading={isCashDeskInfoLoading} />
      </Box>

      {renderFooter(onBack, handleSubmit(onSubmit))}
    </>
  );
};

export default ReconcileBillsStep;
