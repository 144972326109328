import React, { useContext, useEffect } from 'react';
import { List as ImmutableList } from 'immutable';
import { batch } from 'react-redux';

// components
import { ScheduleTourStep } from 'common/components';
// state
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { resetSaveTourActionResult } from 'common/state/newPerson/scheduleTour/actions';
import { selectCreatedLead } from 'modules/crm/state/leads/selectors';
import { selectPrimaryInfo } from 'common/state/newPerson/primaryInfo/selectors';
import { fetchDictionaryList, resetDictionaryListAction } from 'common/state/dictionary/actions';
import {
  selectIsLoading,
  selectSaveTourActionResult,
} from 'common/state/newPerson/scheduleTour/selectors';
import {
  selectCurrentUser,
  selectCurrentUserAvailableClubs,
} from 'modules/authentication/state/selectors';
// constants
import { ActionResult, DictionaryList } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';
// interfaces
import { IEmployeeListItemImt } from 'common/interfaces/dictionary';
import { ILeadProfileImt } from 'modules/crm/interfaces/leads';
import { IUserProfileInfoImt } from 'modules/authentication/interfaces';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { ITagImt } from 'modules/booking/interfaces';
import { ITourStep } from 'modules/front-desk/interfaces';
import { useAppDispatch } from 'store/hooks';
// common
import { StepContext } from 'common/createContext/stepContext';
import * as actions from 'common/components/PersonProfile/state/appointments/actions';
import useRootSelector from 'common/hooks/useRootSelector';

interface IScheduleTourStepContainerProps {
  event?: ITourStep;
  module: PeakModuleForNewPersonType;
  clubId: string;
}

const ScheduleTourStepContainer = ({
  event,
  module,
  clubId,
}: IScheduleTourStepContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const primaryInfo = useRootSelector(selectPrimaryInfo);
  const currentUser: IUserProfileInfoImt = useRootSelector(selectCurrentUser);
  const isLoading = useRootSelector(selectIsLoading);
  const employees: ImmutableList<IEmployeeListItemImt> = useRootSelector(
    selectDictionaryList(DictionaryList.EMPLOYEES),
  );
  const saveTourActionResult = useRootSelector(selectSaveTourActionResult);
  const leadPrimaryInfo: ILeadProfileImt = useRootSelector(selectCreatedLead);
  const clubs = useRootSelector(selectCurrentUserAvailableClubs);
  const tags: ImmutableList<ITagImt> = useRootSelector(
    selectDictionaryList(DictionaryList.EVENT_TAGS),
  );

  const { done } = useContext(StepContext);

  const isCrmOrPTCrmModule =
    module === PeakModules.Crm || module === PeakModules.PersonalTrainingCrm;

  const isResponseSuccess = saveTourActionResult === ActionResult.SUCCESS_ACTION;

  const bookATourClub = clubs.find(clubItem => clubItem.get('id') === clubId);

  useEffect(() => {
    if (isResponseSuccess) {
      dispatch(resetSaveTourActionResult());
      done();
    }
  }, [isResponseSuccess, dispatch, done]);

  useEffect(() => {
    const personId = isCrmOrPTCrmModule ? leadPrimaryInfo.get('id') : primaryInfo.get('id');
    dispatch(
      fetchDictionaryList(DictionaryList.EVENT_TAGS, {
        module,
        usedId: personId,
      }),
    );
    dispatch(
      fetchDictionaryList(DictionaryList.EMPLOYEES, {
        module,
      }),
    );
    return () => {
      batch(() => {
        dispatch(resetDictionaryListAction({ dictionary: DictionaryList.EVENT_TAGS }));
        dispatch(resetDictionaryListAction({ dictionary: DictionaryList.EMPLOYEES }));
      });
      dispatch(actions.fetchRecentPersonAppointments(personId, module));
    };
  }, [dispatch, isCrmOrPTCrmModule, leadPrimaryInfo, primaryInfo, module]);

  return (
    <ScheduleTourStep
      currentUser={currentUser}
      event={event}
      club={bookATourClub}
      employees={employees}
      person={isCrmOrPTCrmModule ? leadPrimaryInfo : primaryInfo}
      isLoading={isLoading}
      module={module}
      eventTags={tags}
    />
  );
};

export default ScheduleTourStepContainer;
