import React, { useCallback, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core';

import * as registerActions from 'modules/pos-kiosk/state/register/actions';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';

import { useAppDispatch } from 'store/hooks';

import {
  ICloseRegisterResultImt,
  IPosKioskReconcileDTO,
} from 'modules/pos-kiosk/interfaces/registers';

import { PeakModules } from 'common/constants/peakModules';
import { CloseRegisterSteps } from 'modules/pos-kiosk/constants/registers';
import { StepContext } from 'common/createContext/stepContext';

import { DialogComponent } from 'common/components';
import ReconcileBillsStep from '../ReconcileModal/Steps/ReconcileBillsStep/ReconcileBillsStep';
import ReconcileChecksStep from '../ReconcileModal/Steps/ReconcileChecksStep/ReconcileChecksStep';
import ReconcileTotalsStep from '../ReconcileModal/Steps/ReconcileTotalsStep/ReconcileTotalsStep';
import Footer from './Footer';
import CloseRegisterConfirmationStep from './Steps/CloseRegisterConfirmationStep/CloseRegisterConfirmationStep';
import LinkToReportStep from './Steps/LinkToReportStep/LinkToReportStep';

import messages from 'modules/pos-kiosk/messages/messages';
import useRootSelector from 'common/hooks/useRootSelector';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiDialog-paper': {
      overflowY: 'hidden',
      paddingBottom: theme.spacing(9),
      position: 'relative',
      '& .MuiDialogActions-root': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        paddingRight: theme.spacing(2),
      },
    },
  },
}));

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Partial<IPosKioskReconcileDTO>) => void;
  module: PeakModules;
  isCloseRegisterActionLoading: boolean;
  closeRegisterResult: ICloseRegisterResultImt;
  emptyInvoiceCount: number;
  registerId: string;
}

const steps: CloseRegisterSteps[] = Object.values(CloseRegisterSteps);

const { Provider: StepProvider } = StepContext;

const CloseRegisterModal = ({
  isOpen,
  onClose,
  onSubmit,
  module,
  isCloseRegisterActionLoading,
  closeRegisterResult,
  emptyInvoiceCount,
  registerId,
}: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const selectedClubId: string = useRootSelector(selectUserSelectedClubId);

  const [currentStep, setCurrentStep] = useState(CloseRegisterSteps.ReconcileBills);
  const [closeRegisterData, setCloseRegisterData] = useState<Partial<IPosKioskReconcileDTO>>({});
  const [reportId, setReportId] = useState(null);

  const currentStepIndex: number = steps.indexOf(currentStep);
  const nextStep: CloseRegisterSteps =
    currentStepIndex + 1 < steps.length ? steps[currentStepIndex + 1] : null;
  const prevStep: CloseRegisterSteps =
    currentStepIndex - 1 < 0 ? null : steps[currentStepIndex - 1];

  const classes = useStyles();

  useEffect(() => {
    if (closeRegisterResult?.get('reportId')) {
      setCurrentStep(CloseRegisterSteps.LinkToReport);
      setReportId(closeRegisterResult.get('reportId'));

      batch(() => {
        dispatch(registerActions.resetPosKioskCloseRegisterItemResult());
        dispatch(registerActions.resetSelectedRegisterId(selectedClubId));
        dispatch(registerActions.resetPosKioskRegisterData());
      });
    }
  }, [closeRegisterResult, dispatch, selectedClubId]);

  const goNextStep = useCallback(
    (data: IPosKioskReconcileDTO) => {
      if (
        currentStep === CloseRegisterSteps.CloseConfirmation ||
        (currentStep === CloseRegisterSteps.ReconcileTotals && !emptyInvoiceCount)
      ) {
        onSubmit({ ...closeRegisterData });
        setCurrentStep(CloseRegisterSteps.ReconcileBills);
      } else {
        setCloseRegisterData(prevState => ({
          ...prevState,
          ...data,
          cashDesk: { ...prevState?.cashDesk, ...data.cashDesk },
        }));
        setCurrentStep(nextStep);
      }
    },
    [closeRegisterData, currentStep, emptyInvoiceCount, nextStep, onSubmit],
  );

  const goPrevStep = useCallback(() => {
    if (prevStep) {
      setCurrentStep(prevStep);
    } else {
      onClose();
    }
  }, [onClose, prevStep]);

  const renderFooter = useCallback(
    (onBack, onNext) => {
      return (
        <Footer
          currentStep={currentStep}
          onBack={onBack}
          onNext={onNext}
          goBack={onClose}
          isLoading={isCloseRegisterActionLoading}
        />
      );
    },
    [currentStep, isCloseRegisterActionLoading, onClose],
  );

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...messages.closeRegister} />}
      footer={<></>}
      isOpen={isOpen}
      onClose={onClose}
      supressBottomShadow
      className={classes.root}
    >
      <StepProvider value={{ onNext: goNextStep, onBack: goPrevStep, renderFooter }}>
        {currentStep === CloseRegisterSteps.ReconcileBills && (
          <ReconcileBillsStep registerId={registerId} reconcileData={closeRegisterData} />
        )}

        {currentStep === CloseRegisterSteps.ReconcileChecks && <ReconcileChecksStep />}

        {currentStep === CloseRegisterSteps.ReconcileTotals && (
          <ReconcileTotalsStep module={module} actual={closeRegisterData} registerId={registerId} />
        )}

        {currentStep === CloseRegisterSteps.CloseConfirmation && (
          <CloseRegisterConfirmationStep emptyInvoiceCount={emptyInvoiceCount} />
        )}

        {currentStep === CloseRegisterSteps.LinkToReport && (
          <LinkToReportStep reportId={reportId} />
        )}
      </StepProvider>
    </DialogComponent>
  );
};

export default CloseRegisterModal;
