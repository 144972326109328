import React, { useCallback, useEffect, useMemo } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { List as ImmutableList } from 'immutable';
import moment from 'moment-timezone';
import { Box } from '@material-ui/core';

import { useAppDispatch } from 'store/hooks';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';

import { PeakModules } from 'common/constants/peakModules';

import { ICheckInImt } from 'modules/front-desk/interfaces';

import {
  fetchRecentCheckIns,
  resetCurrentCheckinAction,
  setKidZoneCurrentCheckInAction,
  setSelectedCheckIn,
} from 'modules/kid-zone/state/actions';
import {
  selectCurrentCheckIn,
  selectRecentCheckInMembers,
  selectRecentCheckInMembersLoading,
  selectSelectedCheckIn,
} from 'modules/kid-zone/state/selectors';

import { CheckInList, DeviceListener } from 'common/components';
import commonMessages from 'common/messages/messages';

let timerId: NodeJS.Timeout = null;

interface IProps {
  onScanningBarcodeSuccess: (scannerString: string) => void;
}

const CheckInListPanel = ({ onScanningBarcodeSuccess }: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const selectedClubId = useRootSelector(selectUserSelectedClubId);
  const recentCheckIns: ImmutableList<ICheckInImt> = useRootSelector(selectRecentCheckInMembers);
  const isRecentCheckInsLoading: boolean = useRootSelector(selectRecentCheckInMembersLoading);
  const selectedCheckIn: ICheckInImt = useRootSelector(selectSelectedCheckIn);
  const currentCheckIn: ICheckInImt = useRootSelector(selectCurrentCheckIn);

  useEffect(() => {
    dispatch(fetchRecentCheckIns(selectedClubId));
    dispatch(resetCurrentCheckinAction());
  }, [dispatch, selectedClubId]);

  useComponentDidUpdate(() => {
    clearTimeout(timerId);

    if (currentCheckIn) {
      const timeDifferenceMs: number = moment()
        .add(1, 'minutes')
        .diff(moment(`${currentCheckIn.get('checkInTime')}Z`));

      timerId = setTimeout(() => {
        dispatch(setKidZoneCurrentCheckInAction(null));
        dispatch(fetchRecentCheckIns(selectedClubId));
      }, timeDifferenceMs);
    }
  }, [currentCheckIn]);

  const onSelectCheckInPerson = useCallback(
    (personId: number) => {
      dispatch(setSelectedCheckIn(personId));
    },
    [dispatch],
  );

  const filteredRecentCheckIns = useMemo(
    () =>
      currentCheckIn
        ? recentCheckIns.filter(
            checkIn => checkIn.get('personId') !== currentCheckIn.get('personId'),
          )
        : recentCheckIns,
    [currentCheckIn, recentCheckIns],
  );

  return (
    <Box display="flex" flexDirection="column" mr={2} width="305px" minWidth="305px">
      <DeviceListener
        onScanningBarcodeSuccess={onScanningBarcodeSuccess}
        successMessage={commonMessages.barcodeScannerForAutoCheckinsConnected}
        errorMessage={commonMessages.barcodeScannerForAutoCheckinsNotConnected}
      />

      <CheckInList
        isRecentCheckInsListLoading={isRecentCheckInsLoading}
        recentCheckInsList={filteredRecentCheckIns}
        selectedCheckIn={selectedCheckIn}
        onSelectCheckInPerson={onSelectCheckInPerson}
        currentCheckIn={currentCheckIn}
        module={PeakModules.KidZone}
      />
    </Box>
  );
};

export default CheckInListPanel;
