import React, { useEffect, useState } from 'react';
import { Alert, DialogComponent } from '../index';
import { useDispatch } from 'react-redux';
import * as actions from 'common/state/invoice/actions';
import { selectCommerceHubCredentials } from '../../state/invoice/selectors';
import { AlertTypes } from '../../interfaces/alerts';
import { FormattedMessage } from 'react-intl';
import messages from '../InvoiceOperating/messages';
import * as memberPortalActions from 'memberPortalModules/MemberProfile/state/billingActions/actions';
import { IMagneticStripeCreditCardData } from '../InvoiceOperating/InvoicePaymentWizard/steps/creditCard/CreditCardPaymentStep';
import useRootSelector from 'common/hooks/useRootSelector';

interface CommerceHubIFrameModalProps {
  memberId: number;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (event) => void;
  isMemberPortal: boolean;
  presetCreditCard?: IMagneticStripeCreditCardData;
}

const CommerceHubIFrameModal = ({
  memberId,
  isOpen,
  onClose,
  onSubmit,
  isMemberPortal,
  presetCreditCard,
}: CommerceHubIFrameModalProps) => {
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState(null);
  const commerceHubCredentials = useRootSelector(selectCommerceHubCredentials);

  const handleSuccessSubmit = event => {
    onSubmit(event);
  };

  const handleSubmit = () => {
    setFormErrors(null);
    (document.getElementById('commerceHubIFrameId') as HTMLIFrameElement).contentWindow.postMessage(
      'SUBMIT_FORM',
    );
  };

  useEffect(() => {
    if (isMemberPortal) {
      dispatch(memberPortalActions.fetchCommerceHubCredentials());
    } else {
      dispatch(actions.fetchCommerceHubCredentials(memberId));
    }

    function proceedEvent(event) {
      const data = event?.data;
      if (data) {
        if (data.result === 'SUCCESS') {
          handleSuccessSubmit(event.data.sessionId);
        } else if (data.result === 'VALIDATION_ERROR') {
          if (data.message === 'BAD_FORM_DATA') {
            setFormErrors(
              <FormattedMessage
                {...messages.creditCardCommerceHubTokenizeBadFormData}
                values={{ variable_1: data.errors?.join(',') }}
              />,
            );
          }
          if (data.message === 'BAD_SUBMIT_CONFIG') {
            setFormErrors(
              <FormattedMessage
                {...messages.creditCardCommerceHubTokenizeBadSubmitConfig}
                values={{ variable_1: data.errors?.join(',') }}
              />,
            );
          }
        } else if (data.result === 'HTTP_ERROR') {
          const gateway =
            !!data.gatewayCode && !!data.gatewayMessage
              ? ` Gateway error code: ${data.gatewayCode} - ${data.gatewayMessage}`
              : '';
          setFormErrors(
            <FormattedMessage
              {...messages.creditCardCommerceHubTokenizeHttpError}
              values={{
                variable_1: data.statusCode,
                variable_2: data.statusText,
                variable_3: gateway,
              }}
            />,
          );
        } else if (data.result === 'UNDEFINED_ERROR') {
          setFormErrors(
            <FormattedMessage {...messages.creditCardCommerceHubTokenizeUndefinedError} />,
          );

          // eslint-disable-next-line no-console
          console.error(JSON.stringify(data));
        }
      }
    }

    // Attach the scroll event listener to the window object
    window.addEventListener('message', proceedEvent);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('message', proceedEvent);
    };
    // eslint-disable-next-line
  }, []);

  const passParamsToIFrame = () => {
    const nameOnCardParam = presetCreditCard?.nameOnCard
      ? `&nameOnCard=${presetCreditCard?.nameOnCard}`
      : '';
    const cardNumberParam = presetCreditCard?.cardNumber
      ? `&cardNumber=${presetCreditCard?.cardNumber}`
      : '';
    const securityCodeParam = presetCreditCard?.securityCode
      ? `&securityCode=${presetCreditCard?.securityCode}`
      : '';
    const expMonthParam = presetCreditCard?.expMonth
      ? `&expirationMonth=${presetCreditCard?.expMonth}`
      : '';
    const expYearParam = presetCreditCard?.expYear
      ? `&expirationYear=${presetCreditCard?.expYear}`
      : '';

    return (
      `/commerceHubIFrame.html?` +
      `apiKey=${commerceHubCredentials.apiKey}` +
      `&publicKey=${commerceHubCredentials.publicKey}` +
      `&accessToken=${commerceHubCredentials.accessToken}` +
      `&keyId=${commerceHubCredentials.keyId}` +
      `&merchantId=${commerceHubCredentials.merchantId}` +
      `&terminalId=${commerceHubCredentials.terminalId}` +
      `&sessionId=${commerceHubCredentials.sessionId}${nameOnCardParam}${cardNumberParam}${securityCodeParam}${expMonthParam}${expYearParam}`
    );
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      title={<FormattedMessage {...messages.creditCardCommerceHubTokenizeTitle} />}
      onClose={onClose}
      onSubmit={handleSubmit}
      size="md"
    >
      {commerceHubCredentials && (
        <iframe
          title="commerceHubIFrame"
          id="commerceHubIFrameId"
          src={passParamsToIFrame()}
          style={{ height: '180px', width: '600px', borderStyle: 'none' }}
        />
      )}
      {formErrors && <Alert title={formErrors} severity={AlertTypes.Danger} />}
    </DialogComponent>
  );
};

export default CommerceHubIFrameModal;
