import React, { useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
// messages
import messages from 'modules/services/messages/messages';
import commonMessages from 'common/messages/messages';
// components
import CheckboxLabeled from 'common/components/CheckboxLabeled/CheckboxLabeled';
import { MinMaxNumberInput } from 'common/components';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { IFreezeSector } from '../../../../interfaces/packages';
import CheckBoxWithReset from 'common/components/CheckBoxWithReset/CheckBoxWithReset';
import IntlService from 'services/intl/IntlService';

const useStyles = makeStyles(() => ({
  mainCheckboxLabel: {
    fontWeight: 500,
  },
  fieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '64px',
  },
}));

interface IFreezeSectorProps {
  formMethods: UseFormReturn<any>;
  isAllowFreeze: boolean;
  defaultValues?: IFreezeSector;
}

const FreezeSector = ({
  formMethods,
  defaultValues,
  isAllowFreeze,
}: IFreezeSectorProps): JSX.Element => {
  const classes = useStyles();
  const freezeConfigRef = useRef(null);

  const {
    control,
    trigger,
    formState: { errors },
  } = formMethods;

  // effects

  useComponentDidUpdate(() => {
    if (isAllowFreeze && freezeConfigRef.current) {
      freezeConfigRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isAllowFreeze]);

  // renders

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CheckboxLabeled
            control={control}
            defaultValue={isAllowFreeze}
            name="freeze.allow"
            label={
              <Typography className={classes.mainCheckboxLabel}>
                <FormattedMessage {...messages.allowMembershipFreezeLabel} />
              </Typography>
            }
          />
        </Grid>
        {isAllowFreeze && (
          <Grid item xs={12} ref={freezeConfigRef}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <MinMaxNumberInput
                  control={control}
                  errors={errors}
                  trigger={trigger}
                  baseName="freeze.editableDaysNumber"
                  label={IntlService.formatMessage(commonMessages.daysOption)}
                  numberFormatProps={{ maxLength: 3 }}
                  baseDefaultValue={defaultValues.editableDaysNumber}
                />
              </Grid>

              <Grid item xs={8} className={classes.fieldWrapper}>
                <Controller
                  render={({ field }) => (
                    <CheckBoxWithReset
                      value={field.value}
                      onChange={field.onChange}
                      label={IntlService.formatMessage(messages.billingFrozenMembershipLabel)}
                    />
                  )}
                  control={control}
                  name="freeze.billingFrozenMembership"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FreezeSector;
