import { PeakModules } from '../../../../constants/peakModules';
import { useAppDispatch } from '../../../../../store/hooks';
import {
  CartUnitTypes,
  IInvoiceDetailsImt,
  InvoiceStatus,
  ISearchGiftCard,
  ISearchGiftCardImt,
} from '../../../../interfaces/invoices';
import useRootSelector from 'common/hooks/useRootSelector';
import * as selectors from '../../../../state/invoice/selectors';
import SearchInputInvoice from './SearchInput';
import inputLabels from '../../../../messages/inputLabels';
import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Box, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import * as actions from '../../../../state/invoice/actions';
import { FormattedMessage } from 'react-intl';
import commonMessages from '../../../../messages/messages';
import { useSearchInvoiceProductsState } from '../../../../createContext/searchInvoiceProductsContext';
import { List as ImmutableList } from 'immutable';
import { LoadingBackdrop } from 'common/components';
import InvoiceGiftCardItem from '../../InvoiceGiftCardItem/InvoiceGiftCardItem';
import { ReactComponent as FilterIcon } from 'img/icons/filter.svg';
import useMenuState from '../../../../hooks/useMenuState';
import SearchGiftCardFilter from './SearchGiftCardFilter/SearchGiftCardFilter';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  pushRight: {
    marginLeft: 'auto',
  },
  itemTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: theme.spacing(0.5),
  },
  rowWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  maxTotalInvoice: {
    display: 'inline-block',
    marginTop: theme.spacing(0.5),
    color: theme.palette.secondary.dark,
  },
  heavyTitle: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  filterButtonWrapper: {
    position: 'relative',
  },
  filterButton: {
    height: `calc(100% - ${theme.spacing(2)}px)`,
    marginRight: theme.spacing(1),
    width: theme.spacing(4.5),
    backgroundColor: theme.palette.secondary.light,
  },
  filterIcon: {
    height: '20px',
    width: '20px',
  },
  selectedColor: {
    color: theme.palette.primary.main,
  },
}));

interface ISearchGiftCardsProps {
  module: PeakModules;
  isPaymentStep?: boolean;

  selectedInvoiceStatus?: InvoiceStatus;
}

export default function SearchGiftCards({
  module,
  isPaymentStep,
}: ISearchGiftCardsProps): JSX.Element {
  const classes = useStyles();
  // state
  const dispatch = useAppDispatch();

  const invoice: IInvoiceDetailsImt = useRootSelector(selectors.selectCurrentInvoice);
  const searchGiftCards: ImmutableList<ISearchGiftCardImt> = useRootSelector(
    selectors.selectSearchGiftCardsData,
  );
  const searchGiftCardsLoading: boolean = useRootSelector(selectors.selectSearchGiftCardsLoading);

  const invoiceJS = useMemo(() => invoice?.toJS(), [invoice]);
  const giftCardsJs: ISearchGiftCard[] = useMemo(() => searchGiftCards?.toJS(), [searchGiftCards]);

  const { toggleShowAddGiftCards } = useSearchInvoiceProductsState();

  const [availableInClubs, setAvailableInClubs] = useState<string[]>([]);
  const [searchStr, setSearchStr] = useState<string>('');

  const {
    menuAnchorEl: searchMenuAnchorEl,
    handleShowMenu: searchHandleShowMenu,
    handleCloseMenu: searchHandleCloseMenu,
  } = useMenuState();

  const handleAddGiftCard = (giftCard: ISearchGiftCard) => {
    toggleShowAddGiftCards(false);

    const existedGiftCard = false;

    if (!existedGiftCard) {
      dispatch(
        actions.addInvoiceUnit(
          invoiceJS.id,
          {
            giftCards: [giftCard.id],
          },
          CartUnitTypes.GIFT_CARD,
          module,
          isPaymentStep,
        ),
      );
    }
  };

  const handleDeleteGiftCard = (invoiceUnitId: string) => {
    dispatch(actions.deleteInvoiceUnit(invoiceJS.id, invoiceUnitId, module, isPaymentStep));
  };

  const onSearchProductsStringChange = (search: string) => {
    setSearchStr(search);
  };

  const renderGiftCard = (giftCard: ISearchGiftCard) => {
    const currentInvoiceUnitItem = invoiceJS?.invoiceUnits?.find(
      item => item.type === CartUnitTypes.GIFT_CARD && item.giftCard.id === giftCard.id,
    );

    const isAlreadyAdded = !!currentInvoiceUnitItem;

    return (
      <Grid item xs={12} key={giftCard.id}>
        <InvoiceGiftCardItem
          giftCard={giftCard}
          isAddItems
          isAlreadyAdded={isAlreadyAdded}
          onDelete={() => handleDeleteGiftCard(currentInvoiceUnitItem.id)}
          onAdd={() => handleAddGiftCard(giftCard)}
        />
      </Grid>
    );
  };

  useEffect(() => {
    dispatch(
      actions.searchClubGiftCards({
        module,
        searchStr,
        clubIds: availableInClubs,
        isCustomer: isPaymentStep,
      }),
    );
  }, [availableInClubs, dispatch, isPaymentStep, module, searchStr]);

  return (
    <Box display="flex" flexDirection="column" flex="1" height="100%" pt={1}>
      <Box display="flex" flexDirection="row">
        <Box className={classes.filterButtonWrapper}>
          <IconButton
            className={classnames(classes.filterButton, {
              [classes.selectedColor]: availableInClubs?.length,
            })}
            size="small"
            onClick={searchHandleShowMenu}
          >
            {availableInClubs?.length ? (
              <Badge badgeContent={availableInClubs?.length} color="primary">
                <FilterIcon className={classes.filterIcon} />
              </Badge>
            ) : (
              <FilterIcon className={classes.filterIcon} />
            )}
          </IconButton>
        </Box>
        <SearchInputInvoice
          placeholder={inputLabels.searchServices}
          callback={onSearchProductsStringChange}
        />
      </Box>

      {searchGiftCardsLoading && <LoadingBackdrop isLoading />}

      {!searchGiftCardsLoading && !giftCardsJs.length && (
        <Box display="flex" flex="1" alignItems="center" justifyContent="center">
          <Typography variant="body1" color="textSecondary">
            <FormattedMessage {...commonMessages.noItemsFound} />
          </Typography>
        </Box>
      )}

      <Grid container> {giftCardsJs?.map(giftCard => renderGiftCard(giftCard))}</Grid>

      {!!searchHandleShowMenu && (
        <SearchGiftCardFilter
          searchMenuAnchorEl={searchMenuAnchorEl}
          handleCloseMenu={searchHandleCloseMenu}
          setAvailableInClubs={setAvailableInClubs}
        />
      )}
    </Box>
  );
}
