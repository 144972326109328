import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Message } from 'common/interfaces/common';
import { getRequiredErrorMessage } from 'common/utils/validation';
import { regExp } from 'common/constants';
import inputErrors from '../messages/inputErrors';

export function getSelectOptionSchema() {
  return {
    label: yup.string(),
    value: yup.string(),
  };
}

export function getSelectSchema(): yup.ArraySchema<yup.AnyObjectSchema> {
  return yup.array().of(yup.object().shape(getSelectOptionSchema()));
}

export function getRequiredSelectSchema(message: string): yup.ArraySchema<yup.StringSchema> {
  return yup
    .array()
    .of(yup.string())
    .test('required', message, value => !isEmpty(get(value, 0)));
}

export function getRequiredStringSchema(
  message: () => Message = getRequiredErrorMessage,
): yup.StringSchema {
  return yup.string().required(message);
}

export function testRequiredArray(
  schema: yup.ArraySchema<yup.AnyObjectSchema>,
  message: string,
): yup.ArraySchema<yup.AnyObjectSchema> {
  return schema.test('required', message, value => !isEmpty(get(value, 0)));
}

export function getAddressLineSchema(required: boolean = false): yup.StringSchema {
  let schema = yup.string().matches(/^.{0,100}$/, {
    message: () => inputErrors.addressLengthError,
    excludeEmptyString: true,
  });

  if (required) {
    schema = schema.required(getRequiredErrorMessage);
  }

  return schema;
}

export function getAddressCitySchema(required: boolean = false): yup.StringSchema {
  let schema = yup
    .string()
    .matches(/^[aA-zZ\s]+$/, {
      message: () => inputErrors.cityMatchError,
      excludeEmptyString: true,
    })
    .max(100, () => inputErrors.cityLengthError);

  if (required) {
    schema = schema.required(getRequiredErrorMessage);
  }

  return schema;
}

export function getAddressStateSchema(required: boolean = false): yup.AnyObjectSchema {
  let schema = yup
    .object({
      id: yup.string(),
      title: yup.string(),
    })
    .nullable();

  if (required) {
    schema = schema.required(getRequiredErrorMessage);
  }

  return schema;
}

export function getAddressZipCodeSchema(required: boolean = false): yup.StringSchema {
  let schema = yup
    .string()
    .nullable()
    .matches(regExp.ZIP_CODE, {
      message: () => inputErrors.invalidZipCode,
      excludeEmptyString: true,
    });

  if (required) {
    schema = schema.required(getRequiredErrorMessage);
  }

  return schema;
}
