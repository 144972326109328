import React, { FC, useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { batch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { PeakModules } from 'common/constants/peakModules';
import * as selectors from 'common/components/PersonProfile/state/servicesModals/selectors';
import * as actions from 'common/components/PersonProfile/state/servicesModals/actions';
import RedeemSubHeader from 'common/components/PersonProfile/components/Services/modals/RedeemSubHeader/RedeemSubHeader';
import { DialogComponent, LoadingBackdrop } from 'common/components/index';
import serviceMessages from 'modules/services/messages/messages';
import FamilyMemberServiceDetailsView from './FamilyMemberServiceDetailsView';
import useRootSelector from 'common/hooks/useRootSelector';

interface IFamilyMemberDetailsModalProps {
  profileId: number;
  module: PeakModules;
}

const FamilyMemberServiceDetailsModal: FC<IFamilyMemberDetailsModalProps> = props => {
  const { profileId, module } = props;

  const dispatch = useAppDispatch();

  const isOpen = useRootSelector(selectors.selectIsFamilyMemberServiceDetailsShown);
  const selectedFamilyMemberServiceInstanceId = useRootSelector(
    selectors.selectSelectedFamilyMemberServiceId,
  );
  const selectedFamilyMemberServiceDetails = useRootSelector(
    selectors.selectFamilyMemberServiceDetails,
  );
  const isLoadingFamilyMemberServiceDetails = useRootSelector(
    selectors.selectFamilyMemberServiceDetailsLoading,
  );
  const redeemHistory = useRootSelector(selectors.selectServiceRedeemRecentHistory);
  const isLoadingRedeemHistory = useRootSelector(selectors.selectServiceRedeemRecentHistoryLoading);

  const handleOnClose = () => {
    batch(() => {
      dispatch(actions.selectFamilyMemberServiceId(null));
      dispatch(actions.setIsFamilyMemberServiceDetailsShown(false));
      dispatch(actions.resetFamilyMemberServiceDetails());
      dispatch(actions.resetServiceRedeemRecentHistory());
    });
  };

  const handleOnRedeemClick = () => {
    batch(() => {
      dispatch(actions.setIsFamilyMemberServiceDetailsShown(false));
      dispatch(actions.setIsFamilyMemberServiceRedeemShown(true));
    });
  };

  useEffect(() => {
    if (isOpen && selectedFamilyMemberServiceInstanceId) {
      dispatch(
        actions.fetchFamilyMemberServiceDetails(
          profileId,
          selectedFamilyMemberServiceInstanceId,
          module,
        ),
      );
      dispatch(
        actions.fetchServiceRedeemRecentHistory(
          profileId,
          selectedFamilyMemberServiceInstanceId,
          module,
        ),
      );
    }
  }, [selectedFamilyMemberServiceInstanceId, profileId, module, isOpen, dispatch]);

  if (!isOpen) {
    return null;
  }

  const isCrmModule = module === PeakModules.Crm || module === PeakModules.PersonalTrainingCrm;

  const subHeader = Boolean(selectedFamilyMemberServiceDetails) && (
    <RedeemSubHeader
      disabled={!selectedFamilyMemberServiceDetails.get('leftSpotNumber')}
      limited={selectedFamilyMemberServiceDetails.get('limited')}
      totalToRedeem={selectedFamilyMemberServiceDetails.get('limitedSpotNumber')}
      availableToRedeem={selectedFamilyMemberServiceDetails.get('leftSpotNumber')}
      handleOnRedeemClick={!isCrmModule ? handleOnRedeemClick : undefined}
    />
  );

  return (
    <DialogComponent
      title={
        selectedFamilyMemberServiceDetails?.get('title') || (
          <FormattedMessage {...serviceMessages.inventoryLabel} />
        )
      }
      subHeader={subHeader}
      isOpen={isOpen}
      loading={isLoadingFamilyMemberServiceDetails}
      cancelBtn={false}
      submitBtn={false}
      onClose={handleOnClose}
      disableFullScreenBehavior
      supressBottomShadow
      hasCustomFooter
    >
      {Boolean(selectedFamilyMemberServiceDetails) && (
        <FamilyMemberServiceDetailsView
          module={module}
          profileId={profileId}
          familyMemberServiceInstance={selectedFamilyMemberServiceDetails}
          redeemHistory={redeemHistory}
        />
      )}
      <LoadingBackdrop isLoading={isLoadingFamilyMemberServiceDetails || isLoadingRedeemHistory} />
    </DialogComponent>
  );
};

export default FamilyMemberServiceDetailsModal;
