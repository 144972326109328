import { useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';

import TokenService from 'services/auth/TokenService';
import { setUserRole } from 'common/state/userRole/actions';
import { useAppDispatch } from 'store/hooks';
import { selectUserRole } from 'common/state/userRole/selectors';

export const useUpdateUserRole = () => {
  const dispatch = useAppDispatch();

  const userRole = useRootSelector(selectUserRole);

  useEffect(() => {
    if (!userRole) {
      const accessToken = TokenService.getAccessToken();
      const refreshToken = TokenService.getRefreshToken();
      const isRefreshExpired = TokenService.isTokenExpired(refreshToken);
      const tokenData = accessToken ? TokenService.getTokenData(accessToken) : null;
      let userData;
      try {
        userData = JSON.parse(tokenData?.userData);
      } catch (error) {
        userData = null;
      }

      const isMemberPortal = Boolean(userData?.memberPortal);

      if (accessToken && refreshToken && !isRefreshExpired) {
        dispatch(setUserRole(isMemberPortal ? 'member' : 'client'));
      }
    }
  }, [userRole, dispatch]);

  return userRole;
};
