import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TAllergyImtList } from 'common/interfaces/additionalInfo';
import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import inputLabels from 'common/messages/inputLabels';
import { DialogComponent, LoadingBackdrop } from 'common/components';
import { IAllergiesFormValues } from 'modules/members/interfaces';
import TaggedAutocomplete from 'common/components/TaggedAutocomplete/TaggedAutocomplete';
import { changeAllergies } from 'common/utils/http';
import { getAllergiesValidationSchema } from 'common/validationSchemas/allergiesValidationSchema';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import useRootSelector from 'common/hooks/useRootSelector';

interface AllergiesModalProps {
  allergies: TAllergyImtList;
  allergyDictionaryList: TAllergyImtList;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (values: IAllergiesFormValues) => void;
}

const FORM_ID = 'allergies-form';

const AllergiesModal = (props: AllergiesModalProps) => {
  const {
    isLoading: isUpdateLoading,
    allergies,
    allergyDictionaryList,
    isOpen,
    onClose,
    onSubmit,
  } = props;

  const dispatch = useDispatch();
  const profileRequiredFields = useRootSelector(selectProfileRequiredFields);
  const isSubmitted = useRootSelector(selectIsSubmittedRecommendedFieldsModal);
  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();
  const [isLoading, setIsLoading] = useState(false);
  const renderIntlMessage = useRenderIntlMessage();

  const formMethods = useForm<any>({
    defaultValues: {
      allergies: [],
    },
    resolver: yupResolver(yup.object().shape(getAllergiesValidationSchema(profileRequiredFields))),
    mode: 'onBlur',
  });

  const { handleSubmit, control, formState, reset, getValues } = formMethods;
  const { errors } = formState;

  useEffect(() => {
    if (isSubmitted) {
      const values = getValues();

      onSubmit(values);
      dispatch(showRecommendedFieldsModal(false));

      if (!values.allergies?.length) {
        onClose();
      }
    }
  }, [dispatch, getValues, isSubmitted, onSubmit, onClose]);

  useEffect(() => {
    if (allergies?.size) {
      reset({
        allergies: allergies.toJS(),
      });
    }
  }, [allergies, reset]);

  const convertedAllergies = allergyDictionaryList.toJS();

  const onSave = (values: IAllergiesFormValues) => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty(values);

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(values);
    }
  };

  return (
    <DialogComponent
      size="xs"
      formId={FORM_ID}
      title={<FormattedMessage {...messages.allergiesBlockTitle} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.saveBtn} />}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSave)}
      disableFullScreenBehavior
      loading={isUpdateLoading}
    >
      <form id={FORM_ID} onSubmit={handleSubmit(onSave)}>
        <Controller
          control={control}
          name="allergies"
          render={({ field: { onChange, value, onBlur } }) => (
            <TaggedAutocomplete
              expandable
              label={renderIntlMessage(inputLabels.allergies)}
              autocompleteProps={{
                multiple: true,
                freeSolo: true,
                filterSelectedOptions: true,
                clearOnBlur: true,
                selectOnFocus: true,
                options: convertedAllergies,
                onBlur,
                value,
              }}
              onChange={values => {
                changeAllergies(values, onChange, dispatch, setIsLoading);
              }}
              fullWidth
              helperText={renderIntlMessage(errors.allergies?.message)}
              error={Boolean(errors.allergies)}
              recommended={Boolean(profileRecommendedFields?.get(RequiredProfileInfo.Allergies))}
            />
          )}
        />
        <LoadingBackdrop isLoading={isLoading} />
      </form>
    </DialogComponent>
  );
};

export default AllergiesModal;
