// libraries
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// ui components
import { FormattedMessage } from 'react-intl';
import { Grid, TextField } from '@material-ui/core';
// custom interfaces and components
import { CustomTheme } from 'common/ui/interfaces';
import Button from 'common/components/Button/Button';
import BarcodeCard from './BarcodeCard';
import { DeviceActionButton, NumberTextField } from 'common/components';
// constants
import { getStepStyles } from '../styleConstants';
import { IInvoicePaymentSplit, PaymentsType } from 'common/interfaces/invoices';
import { DeviceType } from 'common/constants/scanner';
// utils
import { formatPrice } from 'common/utils';
// messages
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import messages from '../../../messages';
// state
import {
  fetchPaymentMethodByBarcode,
  resetFetchPMBarcode,
  resetUpdateInvoiceWithSync,
  updateInvoiceWithSync,
} from 'common/state/invoice/actions';
import {
  selectBarcodePaymentMethod,
  selectBarcodePaymentMethodError,
  selectBarcodePaymentMethodLoading,
  selectCurrentInvoice,
  selectUpdatedInvoiceLoading,
} from 'common/state/invoice/selectors';
// icons
import { PeakModules } from 'common/constants/peakModules';
import { StepContext } from 'common/createContext/stepContext';
import { useUpdatePaymentData } from '../useUpdatePaymentData';
import { defaultPriceNumberProps } from 'common/components/NumberController/NumberController';
import useRootSelector from 'common/hooks/useRootSelector';

interface IOtherTypePaymentStepProps {
  leftToPay: number;
  registerId: string;

  module: PeakModules;
  isPaymentStep?: boolean;
  clubId: string;

  onClose: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  ...getStepStyles(theme),
  barcodeIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  barcodeButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(0, 0, 0, -1),

    '& .MuiButton-startIcon': {
      margin: theme.spacing(0, 0.35, 0, 0),
    },
  },
}));

const OtherTypePaymentStep: React.FC<IOtherTypePaymentStepProps> = (
  props: IOtherTypePaymentStepProps,
): JSX.Element => {
  const { registerId, module, isPaymentStep, leftToPay, onClose, clubId } = props;
  const classes = useStyles(props);

  // local state

  const [barcodeNumber, setBarcodeNumber] = useState<string>('');
  const [amountToPay, setAmountToPay] = useState<number>(0);

  // global state

  const dispatch = useDispatch();

  const paymentMethod = useRootSelector(selectBarcodePaymentMethod);
  const isLoading = useRootSelector(selectBarcodePaymentMethodLoading);
  const paymentMethodError = useRootSelector(selectBarcodePaymentMethodError);
  const currentInvoice = useRootSelector(selectCurrentInvoice);
  const isUpdatingInvoiceLoading = useRootSelector(selectUpdatedInvoiceLoading);

  const { helperData } = useContext(StepContext);
  const { profileId } = helperData || {};

  // constants

  const maxAllowedAmount =
    paymentMethod &&
    currentInvoice.get('subtotalAmount') *
      (paymentMethod.get('paymentMethod').get('invoiceAmountPercentage') / 100);

  const isAmountExceedingCardLeft =
    paymentMethod &&
    amountToPay > (paymentMethod?.get('leftAmount') ?? paymentMethod?.get('amount'));

  const isAmountExceedingLeftToPay = amountToPay > leftToPay;

  const isAmountExceedingPMPercentage =
    currentInvoice && paymentMethod && maxAllowedAmount && amountToPay > maxAllowedAmount;

  const isInvalidAmount =
    isAmountExceedingCardLeft || isAmountExceedingLeftToPay || isAmountExceedingPMPercentage;

  const canProceed =
    !isLoading &&
    paymentMethod &&
    !paymentMethodError &&
    !isInvalidAmount &&
    !isUpdatingInvoiceLoading;

  // handlers

  const handleOnApplyBarcode = () =>
    dispatch(fetchPaymentMethodByBarcode(registerId, barcodeNumber));

  const handleProceed = () => {
    const invoiceData = currentInvoice.toJS();
    const invoicePaymentSplit: IInvoicePaymentSplit = {
      paymentMethodId: paymentMethod.getIn(['paymentMethod', 'id']),
      paymentMethodInstanceId: paymentMethod.get('id'),
      type: PaymentsType.CUSTOM,
      paymentAmount: amountToPay,
      giftCardBarcode: barcodeNumber,
    };

    dispatch(
      updateInvoiceWithSync(
        module,
        clubId,
        invoiceData,
        invoicePaymentSplit,
        profileId,
        isPaymentStep,
      ),
    );
  };

  const resetStep = () => {
    setBarcodeNumber('');
    setAmountToPay(0);
    batch(() => {
      dispatch(resetFetchPMBarcode());
      dispatch(resetUpdateInvoiceWithSync());
    });
  };

  // effects

  useUpdatePaymentData(module, profileId, onClose);

  useEffect(() => {
    return resetStep;
    // Should perform reset only during unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maxAllowedAmount) {
      setAmountToPay(maxAllowedAmount);
    }
  }, [maxAllowedAmount]);

  // renders

  const AmountError = () => {
    if (isAmountExceedingCardLeft) {
      return <FormattedMessage {...messages.amountMoreThanLeft} />;
    }

    if (isAmountExceedingLeftToPay) {
      return <FormattedMessage {...messages.amountMoreThanLeftToPay} />;
    }

    if (isAmountExceedingPMPercentage) {
      return (
        <FormattedMessage
          {...messages.amountMoreThanPaymentMethodPercentage}
          values={{
            percentage: paymentMethod.get('paymentMethod').get('invoiceAmountPercentage'),
            amount: formatPrice(maxAllowedAmount),
          }}
        />
      );
    }

    return null;
  };

  const onScanningSuccess = useCallback((val: string) => {
    setBarcodeNumber(val);
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {!paymentMethod && (
          <>
            <Grid item xs={12}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOnApplyBarcode}
                      disabled={!barcodeNumber || isLoading}
                    >
                      <FormattedMessage {...commonMessages.applyBtn} />
                    </Button>
                  ),
                }}
                variant="outlined"
                label={<FormattedMessage {...inputLabels.barcode} />}
                fullWidth
                defaultValue={barcodeNumber}
                onChange={e => setBarcodeNumber(e.target.value)}
                error={!!paymentMethodError}
                helperText={paymentMethodError?.get('message')} // TODO: replace with errors
              />
            </Grid>
            <Grid item xs={12}>
              <DeviceActionButton
                messageDescriptor={commonMessages.scanBarcodeTitle}
                deviceType={DeviceType.Barcode}
                onScanningSuccess={onScanningSuccess}
              />
            </Grid>
          </>
        )}

        {!!paymentMethod && (
          <>
            <Grid item xs={12}>
              <BarcodeCard barcodePaymentMethod={paymentMethod} onClose={resetStep} />
            </Grid>
            <Grid item xs={12}>
              <NumberTextField
                value={amountToPay}
                onChange={v => setAmountToPay(Number(v))}
                onBlur={v => setAmountToPay(Number(v))}
                numberFormatProps={defaultPriceNumberProps}
                fullWidth
                variant="outlined"
                label={<FormattedMessage {...inputLabels.amountToPay} />}
                error={isInvalidAmount}
                helperText={<AmountError />}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Grid container spacing={2} className={classes.footerActions}>
        <Grid item sm={12} xs={12}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={!canProceed}
            onClick={handleProceed}
          >
            <FormattedMessage
              {...(amountToPay !== leftToPay ? commonMessages.proceedBtn : commonMessages.checkOut)}
            />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default OtherTypePaymentStep;
