import React, { useMemo, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import useRootSelector from 'common/hooks/useRootSelector';
import { useNavigate } from 'react-router-dom';

// components
import AddClockInDataModal from 'modules/timeclock/components/AddClockInDataModal/AddClockInDataModal';
import AddUnitDataModal from 'modules/timeclock/components/AddUnitDataModal/AddUnitDataModal';
import TimeclockHistoryTable from './TimeclockHistoryTable';
import TimeclockHistoryModal from './TimeclockHistoryModal';
import { ReactComponent as AddIcon } from 'img/icons/add_deprecated.svg';
import { ActionItem, ActionsMenu, Button } from 'common/components/index';

// state
import { ITimeclockListItem } from 'modules/timeclock/interfaces/timeclock';
import { CustomTheme } from 'common/ui/interfaces';
import {
  selectMyProfileTimeclockHistory,
  selectMyProfileTimeclockHistoryIsLoading,
} from 'modules/my-profile/state/profile/selectors';
import { selectCurrentUser } from 'modules/authentication/state/selectors';

// messages
import messages from 'common/components/EmployeeProfile/messages';
import permissionsMessages from 'modules/permissions/messages/messages';
import timeclockMessages from 'modules/timeclock/messages';

interface ITimeclockHistoryProps {
  recentTimeclockHistory: ITimeclockListItem[];
  isLoading: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  block: {
    width: '100%',
    border: `1px solid ${theme.palette.borderColor?.main}`,
    borderRadius: '3px',
  },
  blockTitle: {
    textTransform: 'uppercase',
    padding: theme.spacing(2, 0, 2, 0),
  },
}));

const TimeclockHistoryBlock = ({
  recentTimeclockHistory,
  isLoading,
}: ITimeclockHistoryProps): JSX.Element => {
  const navigate = useNavigate();

  const [isTimeclockHistoryModalOpen, setIsTimeclockHistoryModalOpen] = useState(false);
  const [isAddUnitDataModalOpen, setIsAddUnitDataModalOpen] = useState(false);
  const [isAddClockInDataModalOpen, setIsAddClockInDataModalOpen] = useState(false);

  const currentEmployee = useRootSelector(selectCurrentUser);
  const myProfileTimeclockHistory = useRootSelector(selectMyProfileTimeclockHistory);
  const timeclockHistoryLoading = useRootSelector(selectMyProfileTimeclockHistoryIsLoading);

  const timeclockHistoryList = useMemo(() => myProfileTimeclockHistory?.toJS(), [
    myProfileTimeclockHistory,
  ]);

  const classes = useStyles();

  return (
    <Box className={classes.block} pr={2} pl={2} pb={2.5} minWidth="50%">
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" color="textSecondary" className={classes.blockTitle}>
          <FormattedMessage {...messages.timeclockHistory} />
        </Typography>

        <Box display="flex">
          <Button
            onClick={() => navigate('/timeclock/my-payroll-report')}
            hasHoverStyle
            color="primary"
          >
            <FormattedMessage {...timeclockMessages.payrollReportLabel} />
          </Button>
          <Button
            onClick={() => setIsTimeclockHistoryModalOpen(true)}
            hasHoverStyle
            color="primary"
          >
            <FormattedMessage {...permissionsMessages.viewAll} />
          </Button>
          <Box>
            <ActionsMenu
              horizontal
              icon={
                <Button hasHoverStyle color="primary">
                  <AddIcon />
                </Button>
              }
            >
              <ActionItem key="1" onClick={() => setIsAddClockInDataModalOpen(true)}>
                <FormattedMessage {...timeclockMessages.clockInOption} />
              </ActionItem>
              <ActionItem key="2" onClick={() => setIsAddUnitDataModalOpen(true)}>
                <FormattedMessage {...timeclockMessages.unitOption} />
              </ActionItem>
            </ActionsMenu>
          </Box>
        </Box>
      </Box>

      <TimeclockHistoryTable
        disableSorting
        timeclockHistory={recentTimeclockHistory}
        isTimeclockHistoryLoading={isLoading}
        hidePagination
      />

      {isTimeclockHistoryModalOpen && (
        <TimeclockHistoryModal
          isOpen={isTimeclockHistoryModalOpen}
          onClose={() => setIsTimeclockHistoryModalOpen(false)}
          timeclockHistory={timeclockHistoryList}
          isTimeclockHistoryLoading={timeclockHistoryLoading}
        />
      )}

      {isAddClockInDataModalOpen && (
        <AddClockInDataModal
          isOpen={isAddClockInDataModalOpen}
          employeeId={currentEmployee.get('id')}
          onClose={() => setIsAddClockInDataModalOpen(false)}
          isProfile
        />
      )}

      {isAddUnitDataModalOpen && (
        <AddUnitDataModal
          isOpen={isAddUnitDataModalOpen}
          employeeId={currentEmployee.get('id')}
          onClose={() => setIsAddUnitDataModalOpen(false)}
          isProfile
        />
      )}
    </Box>
  );
};

export default TimeclockHistoryBlock;
