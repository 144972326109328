import React from 'react';
import useRootSelector from 'common/hooks/useRootSelector';

import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { resetUploadImage, uploadImage } from 'common/state/uploadImage/actions';
import {
  selectUploadedImage,
  selectUploadedImageDialogId,
  selectUploadImageError,
  selectUploadImageLoading,
} from 'common/state/uploadImage/selectors';

import { useAppDispatch } from 'store/hooks';

import { IAttachmentImt } from 'common/interfaces/uploadFile';
import { WebcamModal } from 'common/modals';

interface IWebcamImageModalProps {
  id: string;
  isOpen: boolean;
  onToggle: () => void;
  onSubmit: (uploadedImageUrl: IAttachmentImt) => void;
}

const WebcamImageModal = (props: IWebcamImageModalProps): JSX.Element => {
  const { isOpen, onToggle, id, onSubmit } = props;

  const dispatch = useAppDispatch();

  const loading = useRootSelector(selectUploadImageLoading());
  const requestError = useRootSelector(selectUploadImageError());
  const uploadedImage = useRootSelector(selectUploadedImage());
  const uploadedDialogId = useRootSelector(selectUploadedImageDialogId());

  const resetModal = () => {
    resetUploadImage();
  };

  useComponentDidUpdate(() => {
    if (uploadedImage && !requestError && uploadedDialogId === id) {
      onSubmit(uploadedImage);
      resetModal();
    }
  }, [uploadedImage, requestError, uploadedDialogId]);

  const onUploadImage = (file: File) => {
    dispatch(uploadImage(id, file));
  };

  return (
    <WebcamModal
      onUpload={onUploadImage}
      onToggle={onToggle}
      isOpen={isOpen}
      isLoading={loading}
      isError={Boolean(requestError)}
    />
  );
};

export default WebcamImageModal;
