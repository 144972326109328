import { useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';

import { selectUserSelectedOrganization } from 'modules/authentication/state/selectors';

import { PeakModules } from 'common/constants/peakModules';
import Services from 'services/network';
import { PermissionLevels } from 'common/constants/permissions';

import { IUserOrganizationImt } from 'common/interfaces/clients';
import { INamedEntity } from 'common/interfaces/common';
import { IEmployeeAccess } from 'common/interfaces/employee';

const useCheckEmployeeAccess = (
  module: PeakModules,
  isLeadsSubModule?: boolean,
): {
  handleChangeSalesperson: (option: INamedEntity<number>) => void;
  isFetchEmployeeAccessInfoLoading: boolean;
  isEmployeeHasNoPermissions: boolean;
} => {
  const selectedOrg: IUserOrganizationImt = useRootSelector(selectUserSelectedOrganization);

  const [isFetchEmployeeAccessInfoLoading, setIsFetchEmployeeAccessInfoLoading] = useState(false);
  const [isEmployeeHasNoPermissions, setIsEmployeeHasNoPermissions] = useState(false);

  const handleChangeSalesperson = async (option: INamedEntity<number>) => {
    let access: IEmployeeAccess;

    if (isEmployeeHasNoPermissions) {
      setIsEmployeeHasNoPermissions(false);
    }

    setIsFetchEmployeeAccessInfoLoading(true);

    switch (module) {
      case PeakModules.Crm:
        access = isLeadsSubModule
          ? await Services.Leads.fetchEmployeeAccessInfo(option.id)
          : await Services.TasksService.fetchEmployeeAccessInfo(option.id);
        break;
      default:
        access = isLeadsSubModule
          ? await Services.PTLeads.fetchEmployeeAccessInfo(option.id)
          : await Services.PtTasksService.fetchEmployeeAccessInfo(option.id);
    }

    setIsFetchEmployeeAccessInfoLoading(false);

    if (access.hasCorporateAccess) {
      return;
    }

    const isEmployeeHasNoCorporatePermissions =
      selectedOrg.get('permissionsLevel') === PermissionLevels.CORPORATE &&
      !access.hasCorporateAccess;

    const isEmployeeHasNoClubPermissions =
      selectedOrg.get('permissionsLevel') === PermissionLevels.CLIENT &&
      !access.clubs.find(clubItem => clubItem.id === selectedOrg.get('id'));

    if (isEmployeeHasNoCorporatePermissions || isEmployeeHasNoClubPermissions) {
      setIsEmployeeHasNoPermissions(true);
    }
  };

  return { handleChangeSalesperson, isFetchEmployeeAccessInfoLoading, isEmployeeHasNoPermissions };
};

export default useCheckEmployeeAccess;
