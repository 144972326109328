import React, { useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, SvgIcon, Typography } from '@material-ui/core';

import * as actions from 'modules/services/state/packages/actions';
import * as selectors from 'modules/services/state/packages/selectors';

import { useAppDispatch } from 'store/hooks';
import { useFetchPackage } from 'common/httpHooks/useFetchPackage';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { countPricePerBilling, countTaxedAmount } from 'modules/services/utils/billingUtils';

import {
  BillingOptionType,
  BillingOptionTypes,
  CreditCardFeeUnitsSymbols,
  DurationType,
  DurationTypes,
  FrequencyTypes,
} from 'modules/services/constants/packages';

import { IBillingOptionImt } from 'modules/services/interfaces/packages';
import { Message } from 'common/interfaces/common';
import { CustomTheme } from 'common/ui/interfaces';

import { DialogComponent, FieldInfo, LoadingBackdrop } from 'common/components';
import { ReactComponent as CrossIcon } from 'img/icons/times.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';

import inputLabels from 'common/messages/inputLabels';
import messages from 'modules/services/messages/messages';

const useStyles = makeStyles((theme: CustomTheme) => ({
  optionAllowBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    marginRight: theme.spacing(1),
    fontSize: '1rem',
  },
  optionDetailsWrapper: {
    marginTop: theme.spacing(1),
  },
}));

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  billingOptionId: string;
  packageId: string;
}

const ViewBillingOptionModal = ({
  isOpen,
  onClose,
  billingOptionId,
  packageId,
}: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const billingOption: IBillingOptionImt = useRootSelector(selectors.selectBillingOption);
  const isBillingOptionLoading: boolean = useRootSelector(selectors.selectBillingOptionLoading);

  const classes = useStyles();

  const renderIntlMessage = useRenderIntlMessage();

  const { isLoading, data } = useFetchPackage(packageId);

  useEffect(() => {
    dispatch(actions.fetchBillingOptionById(billingOptionId));

    return () => {
      dispatch(actions.resetBillingOptionById());
    };
  }, [billingOptionId, dispatch]);

  const { revenueCode } = data.billingSection || {};
  const { durationType, startDate, endDate, durationEditableNumber } = data.generalSection || {};

  const totalAmount = billingOption?.get('totalAmount');
  const paymentSchedule = billingOption?.getIn(['paymentEditableSchedule', 'value']);

  const durationOption = {
    type: durationType as DurationType,
    amount: durationEditableNumber?.value as number,
    fromDate: startDate,
    toDate: endDate,
  };

  const pricePerBillingAmount = countPricePerBilling(
    totalAmount,
    revenueCode?.totalTax || 0,
    durationOption,
    paymentSchedule,
  );
  const taxedTotalAmount =
    totalAmount && revenueCode ? countTaxedAmount(totalAmount, revenueCode.totalTax) : totalAmount;

  const renderAllowView = (isAllow: boolean, msg: Message) => {
    const IconComponent = isAllow ? CheckIcon : CrossIcon;

    return (
      <Typography component="p" className={classes.optionAllowBlock}>
        <SvgIcon
          color={isAllow ? 'primary' : 'error'}
          className={classes.statusIcon}
          component={IconComponent}
        />

        <Typography component="span">
          <FormattedMessage {...msg} />
        </Typography>
      </Typography>
    );
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      loading={isBillingOptionLoading || isLoading}
      title={billingOption?.get('title')}
      hideFooterButtons
      size="sm"
    >
      {!!billingOption && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FieldInfo
                label={<FormattedMessage {...inputLabels.type} />}
                description={BillingOptionTypes.translate(billingOption.get('type'))}
              />
            </Grid>
            <Grid item xs={4}>
              <FieldInfo
                label={<FormattedMessage {...inputLabels.totalAmount} />}
                description={`$${totalAmount?.toFixed(2)}`}
              />
            </Grid>
            <Grid item xs={8}>
              <FieldInfo
                label={<FormattedMessage {...inputLabels.taxedTotalAmount} />}
                description={`$${taxedTotalAmount?.toFixed(2)}`}
              />
            </Grid>
            {billingOption.get('type') !== BillingOptionType.PAID_IN_FULL && (
              <>
                <Grid item xs={4}>
                  <FieldInfo
                    label={<FormattedMessage {...inputLabels.paymentSchedule} />}
                    description={FrequencyTypes.translate(paymentSchedule)}
                  />
                </Grid>

                <Grid item xs={8}>
                  <FieldInfo
                    label={<FormattedMessage {...inputLabels.pricePerBilling} />}
                    description={`$${pricePerBillingAmount?.toFixed(2)}`}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              {renderAllowView(
                billingOption?.get('useAsDefault'),
                messages.defaultPaymentOptionLabel,
              )}
            </Grid>
            <Grid item xs={6}>
              {renderAllowView(
                !!billingOption?.getIn(['freeze', 'allow']),
                messages.allowMembershipFreezeLabel,
              )}

              {billingOption?.getIn(['freeze', 'allow']) && (
                <Box mt={1}>
                  <FieldInfo
                    label={<FormattedMessage {...inputLabels.daysAmount} />}
                    description={billingOption?.getIn(['freeze', 'editableDaysNumber', 'value'])}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={6}>
              {renderAllowView(
                billingOption?.getIn(['freeze', 'billingFrozenMembership']),
                messages.billingFrozenMembershipLabel,
              )}
            </Grid>
            {billingOption?.get('type') !== BillingOptionType.FINANCED && (
              <Grid item xs={8}>
                {renderAllowView(
                  billingOption?.getIn(['paymentSplitting', 'allow']),
                  messages.allowSplitEachPaymentLabel,
                )}

                {billingOption?.getIn(['paymentSplitting', 'allow']) && (
                  <Box mt={1}>
                    <FieldInfo
                      label={<FormattedMessage {...inputLabels.payments} />}
                      description={billingOption?.getIn([
                        'paymentSplitting',
                        'splitPaymentEditableNumber',
                        'value',
                      ])}
                    />
                  </Box>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              {renderAllowView(
                billingOption?.getIn(['paymentGrace', 'allow']),
                messages.allowPaymentGracePeriodLabel,
              )}

              {billingOption?.getIn(['paymentGrace', 'allow']) && (
                <Box className={classes.optionDetailsWrapper}>
                  <FieldInfo
                    label={<FormattedMessage {...inputLabels.duration} />}
                    description={`${billingOption?.getIn([
                      'paymentGrace',
                      'editableDurationNumber',
                      'value',
                    ])} ${renderIntlMessage(
                      DurationTypes.message(billingOption?.getIn(['paymentGrace', 'duration'])),
                    )}`}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              {renderAllowView(
                billingOption?.getIn(['creditCardServiceFee', 'enabled']),
                messages.creditCardFeeLabel,
              )}

              <Grid container spacing={1} className={classes.optionDetailsWrapper}>
                <Grid item xs={4}>
                  {billingOption?.getIn(['creditCardServiceFee', 'enabled']) && (
                    <FieldInfo
                      label={<FormattedMessage {...inputLabels.value} />}
                      description={billingOption?.getIn(['creditCardServiceFee', 'value'])}
                    />
                  )}
                </Grid>

                <Grid item xs={4}>
                  {billingOption?.getIn(['creditCardServiceFee', 'unit']) && (
                    <FieldInfo
                      label={<FormattedMessage {...inputLabels.unit} />}
                      description={CreditCardFeeUnitsSymbols.translate(
                        billingOption?.getIn(['creditCardServiceFee', 'unit']),
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <LoadingBackdrop isLoading={isBillingOptionLoading} />
    </DialogComponent>
  );
};

export default ViewBillingOptionModal;
