/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { Component } from 'react';
import { Box, List, StyledComponentProps, Typography, withStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { Connect } from 'common/decorators';
import { LoadingBackdrop, ScrollBox } from 'common/components';
import { CheckInBlock, Slider } from 'common/components/PersonProfile/components';
import SMSBlockSectionActions from './SMSBlockSectionActions/SMSBlockSectionActions';
import SMSHistoryModal from './SMSHistoryModal/SMSHistoryModal';
import {
  IMemberPhoneItemImt,
  ISendSmsRequestPayload,
  ISMSImt,
} from 'common/components/PersonProfile/interfaces';
import {
  makeSelectPersonCreateSmsActionResult,
  makeSelectPersonSMS,
  makeSelectPersonSMSLoading,
} from 'common/components/PersonProfile/state/selectors';
import * as actions from 'common/components/PersonProfile/state/actions';
import { fetchPersonCampaigns } from 'common/components/PersonProfile/state/actions';
import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';
import NewSMSModal from './NewSMSModal/NewSMSModal';
import SMSItem from './SMSItem/SMSItem';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { ActionResult, DictionaryList } from 'common/constants';
import { INamedEntityImt } from 'common/interfaces/common';
import { selectCurrentUserPhone } from 'modules/authentication/state/selectors';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import SMSDetailsModal from './SMSDetailsModal/SMSDetailsModal';
import { selectRoot } from 'common/hooks/useRootSelector';

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%',
      minHeight: '150px',
    },
    list: {
      padding: theme.spacing(0),
      marginRight: '-16px',
      height: 'calc(100% - 30px)',
      overflow: 'hidden',
    },
  });

interface ISMSBlockProps extends StyledComponentProps {
  personId: number;
  selectedClubId: string;
  personPhones?: ImmutableList<IMemberPhoneItemImt>;
  fetchCampaigns?: (personId: number, module: PeakModuleForNewPersonType) => void;
  fetchDictionaryPersonCampaigns?: (personId: number, module: PeakModuleForNewPersonType) => void;
  campaigns?: ImmutableList<INamedEntityImt>;
  sms?: ImmutableList<ISMSImt>;
  smsLoading?: boolean;
  createSmsActionResult?: ActionResult;
  isMobile?: boolean;
  fetchPersonSMS?: (personId: number, module: PeakModuleForNewPersonType) => void;
  fromPhone?: string;
  addPersonSMS?: (
    personId: number,
    sms: ISendSmsRequestPayload,
    module: PeakModuleForNewPersonType,
  ) => void;
  resetCreateSmsActionResult?: (personId: number) => void;
  module: PeakModuleForNewPersonType;
}

enum EModalTypes {
  isModalNewOpen = 'isModalNewOpen',
  isModalHistoryOpen = 'isModalHistoryOpen',
  isModalSMSDetailsOpen = 'isModalSMSDetailsOpen',
}

interface ISMSBlockState {
  [EModalTypes.isModalNewOpen]: boolean;
  [EModalTypes.isModalHistoryOpen]: boolean;
  [EModalTypes.isModalSMSDetailsOpen]: boolean;
  selectedSMS?: ISMSImt;
}

@(Connect({
  makeStateToProps: () => {
    const selectPersonSMS = selectRoot(makeSelectPersonSMS());
    const selectPersonSMSLoading = selectRoot(makeSelectPersonSMSLoading());
    const selectPersonCreateSmsActionResult = selectRoot(makeSelectPersonCreateSmsActionResult());

    return (state, ownProps) => ({
      sms: selectPersonSMS(state, ownProps),
      smsLoading: selectPersonSMSLoading(state, ownProps),
      createSmsActionResult: selectPersonCreateSmsActionResult(state, ownProps),
      campaigns: selectRoot(selectDictionaryList(DictionaryList.MESSAGING_CAMPAIGN_SMS))(state),
      fromPhone: selectRoot(selectCurrentUserPhone)(state),
    });
  },
  mapDispatchToProps: dispatch => ({
    fetchPersonSMS: (personId: number, module: PeakModuleForNewPersonType) =>
      dispatch(actions.fetchPersonSMS(personId, module)),
    addPersonSMS: (
      personId: number,
      sms: ISendSmsRequestPayload,
      module: PeakModuleForNewPersonType,
    ) => dispatch(actions.addPersonSMS(personId, sms, module)),
    resetCreateSmsActionResult: personId =>
      dispatch(actions.resetPersonCreateSmsActionResult(null, personId)),
    fetchDictionaryPersonCampaigns: (personId: number, module: PeakModuleForNewPersonType) =>
      dispatch(fetchDictionaryList(DictionaryList.MESSAGING_CAMPAIGN_SMS, { personId, module })),
    fetchCampaigns: (personId: number, module: PeakModuleForNewPersonType) =>
      dispatch(fetchPersonCampaigns(personId, module)),
  }),
}) as any)
@(withStyles(styles) as any)
class SMSBlock extends Component<ISMSBlockProps, ISMSBlockState> {
  constructor(props: ISMSBlockProps) {
    super(props);

    this.state = {
      isModalNewOpen: false,
      isModalHistoryOpen: false,
      isModalSMSDetailsOpen: false,
      selectedSMS: null,
    };
  }

  componentDidMount(): void {
    const { personId, fetchPersonSMS, module } = this.props;
    fetchPersonSMS(personId, module);
  }

  componentDidUpdate(prevProps: ISMSBlockProps, prevState: ISMSBlockState): void {
    const {
      selectedClubId,
      personId,
      fetchPersonSMS,
      createSmsActionResult,
      fetchDictionaryPersonCampaigns,
      resetCreateSmsActionResult,
      fetchCampaigns,
      module,
    } = this.props;

    const { isModalNewOpen } = this.state;

    if (isModalNewOpen && isModalNewOpen !== prevState.isModalNewOpen) {
      fetchDictionaryPersonCampaigns(personId, module);
    }

    if (createSmsActionResult === ActionResult.SUCCESS_ACTION) {
      fetchCampaigns(personId, module);
      resetCreateSmsActionResult(personId);
    }

    if (prevProps.selectedClubId === selectedClubId && prevProps.personId === personId) return;
    fetchPersonSMS(personId, module);
  }

  onOpenModal = (modalType: EModalTypes): void => {
    this.setState(prev => ({ ...prev, [modalType]: true }));
  };

  onCloseModal = (modalType: EModalTypes): void => {
    const { isModalSMSDetailsOpen } = this.state;

    this.setState(prev => {
      if (isModalSMSDetailsOpen && modalType !== EModalTypes.isModalSMSDetailsOpen) {
        return { ...prev, [modalType]: false };
      }

      return { ...prev, [modalType]: false, selectedSMS: null };
    });
  };

  onSubmitNewModal = (sms: ISendSmsRequestPayload): void => {
    const { personId, addPersonSMS, module } = this.props;

    addPersonSMS(personId, sms, module);
    this.onCloseModal(EModalTypes.isModalNewOpen);
  };

  onOpenReplyModal = (sms: ISMSImt): void => {
    this.setState({ [EModalTypes.isModalNewOpen]: true, selectedSMS: sms });
  };

  onOpenSMSDetailsModal = (sms: ISMSImt): void => {
    this.setState({ [EModalTypes.isModalSMSDetailsOpen]: true, selectedSMS: sms });
  };

  render(): JSX.Element {
    const { isModalNewOpen, isModalHistoryOpen, isModalSMSDetailsOpen, selectedSMS } = this.state;
    const {
      classes,
      isMobile,
      sms,
      smsLoading,
      campaigns,
      personPhones,
      fromPhone,
      personId,
      module,
    } = this.props;

    const listItems =
      Boolean(sms?.size) &&
      sms.map(item => (
        <SMSItem
          onClick={this.onOpenSMSDetailsModal}
          key={item.get('id')}
          fromPhone={fromPhone}
          sms={item}
          onReply={this.onOpenReplyModal}
        />
      ));

    return (
      <CheckInBlock
        title={<FormattedMessage {...commonMessages.smsLabel} />}
        buttonGroup={
          <SMSBlockSectionActions
            onAddNewClick={() => this.onOpenModal(EModalTypes.isModalNewOpen)}
            onHistoryClick={() => this.onOpenModal(EModalTypes.isModalHistoryOpen)}
          />
        }
        className={classes.wrapper}
      >
        {listItems ? (
          <List className={classes.list}>
            {isMobile === true && <Slider rows={3}>{listItems}</Slider>}
            {isMobile === false && <ScrollBox>{listItems}</ScrollBox>}
          </List>
        ) : (
          <Box className="empty-section-placeholder">
            <Typography className="empty-text">
              <FormattedMessage {...messages.emptySMSListTextMessage} />
            </Typography>
          </Box>
        )}

        <LoadingBackdrop isLoading={smsLoading} />

        <NewSMSModal
          personId={personId}
          fromPhone={fromPhone}
          isOpen={isModalNewOpen}
          selectedSMS={selectedSMS}
          campaigns={campaigns}
          personPhones={personPhones}
          onClose={() => this.onCloseModal(EModalTypes.isModalNewOpen)}
          onSubmit={this.onSubmitNewModal}
        />

        {isModalHistoryOpen && (
          <SMSHistoryModal
            onOpenSMSDetails={this.onOpenSMSDetailsModal}
            isOpen={isModalHistoryOpen}
            onClose={() => this.onCloseModal(EModalTypes.isModalHistoryOpen)}
            personId={personId}
            module={module}
          />
        )}

        {isModalSMSDetailsOpen && (
          <SMSDetailsModal
            onReply={() => this.onOpenReplyModal(selectedSMS)}
            smsId={selectedSMS?.get('id')}
            isOpen={isModalSMSDetailsOpen}
            onClose={() => this.onCloseModal(EModalTypes.isModalSMSDetailsOpen)}
            personId={personId}
            module={module}
          />
        )}
      </CheckInBlock>
    );
  }
}

export default SMSBlock;
