import { useSelector } from 'react-redux';
import { AppStoreTypeImt } from 'store';

export function selectRoot(selector) {
  return (state, ...args) => selector(state.root, ...args);
}

export default function useRootSelector(selector: (state: AppStoreTypeImt) => any): any {
  return useSelector(selectRoot(selector));
}
