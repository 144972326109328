import React, { useCallback, useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';

import {
  IFallthroughCaptureForm,
  IFallthroughCaptureImt,
} from 'common/components/PersonProfile/interfaces';

import * as actions from 'common/components/PersonProfile/state/actions';

import {
  selectPersonFallthroughCaptureActionLoading,
  selectPersonFallthroughCaptureActionResult,
} from 'common/components/PersonProfile/state/selectors';

import { useAppDispatch } from 'store/hooks';

import FallthroughCaptureModal from './FallthroughCaptureModal/FallthroughCaptureModal';
import FallthroughCaptureCard from './FallthroughCaptureCard/FallthroughCaptureCard';

import modalTitles from 'common/messages/modalTitles';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';

interface IProps {
  clientId: string;
  profileId: number;
  fallthroughCapture?: IFallthroughCaptureImt;
  deletePersonFallthroughCapture?: (clientId: string, personId: number) => void;
  module: PeakModuleForNewPersonType;
}

const FallthroughBlock = ({ profileId, fallthroughCapture, module }: IProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  const isFallthroughModalSubmitting = useRootSelector(
    selectPersonFallthroughCaptureActionLoading(profileId),
  );

  const fallthroughActionResult = useRootSelector(
    selectPersonFallthroughCaptureActionResult(profileId),
  );

  useEffect(() => {
    dispatch(actions.fetchPersonFallthroughCapture(profileId, module));
  }, [dispatch, profileId, module]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (fallthroughActionResult?.get('success')) {
      handleModalClose();
    }
  }, [fallthroughActionResult]);

  const handleSetFallthroughCapture = useCallback(
    (formValues: IFallthroughCaptureForm): void => {
      dispatch(actions.setPersonFallthroughCapture(profileId, formValues, module));
    },
    [dispatch, profileId, module],
  );

  const handleDeleteFallthroughCapture = useCallback(
    (captureId: string): void => {
      dispatch(actions.deletePersonFallthroughCapture(profileId, captureId, module));
    },
    [dispatch, profileId, module],
  );

  return (
    <>
      {isModalOpen && (
        <FallthroughCaptureModal
          fallthroughCapture={fallthroughCapture}
          openModal={isModalOpen}
          onClose={handleModalClose}
          onSubmit={handleSetFallthroughCapture}
          isSubmitting={isFallthroughModalSubmitting}
        />
      )}

      {fallthroughCapture ? (
        <FallthroughCaptureCard
          fallthroughCapture={fallthroughCapture}
          onRemove={handleDeleteFallthroughCapture}
        />
      ) : (
        <Button variant="contained" fullWidth onClick={handleModalOpen}>
          <FormattedMessage {...modalTitles.setFallthroughCapture} />
        </Button>
      )}
    </>
  );
};

export default FallthroughBlock;
