/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { Component } from 'react';
import { Box, List, StyledComponentProps, Typography, withStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { Connect } from 'common/decorators';
import {
  ICallNoteDto,
  ICallNoteDtoImt,
  IMemberPhoneItemImt,
  IRequestResultImt,
} from 'common/components/PersonProfile/interfaces';
import { IEmployeeListItemImt } from 'common/interfaces/dictionary';
import { DictionaryItem, DictionaryList } from 'common/constants/dictionaryConstants';
// Selectors
import {
  makeSelectPersonRecentCalls,
  makeSelectPersonRecentCallsLoading,
  makeSelectPersonUpdateCallAction,
} from 'common/components/PersonProfile/state/selectors';
import { selectDictionaryList } from 'common/state/dictionary/selectors';

// Actions
import { fetchDictionaryItem, fetchDictionaryList } from 'common/state/dictionary/actions';
import * as actions from 'common/components/PersonProfile/state/actions';

// Interfaces
import { INamedEntityImt } from 'common/interfaces/common';

// Components
import CallsItem from './CallsItem/CallsItem';
import { LoadingBackdrop, ScrollBox } from 'common/components';
import {
  CallDetailsModal,
  CheckInBlock,
  NewCallModal,
  Slider,
} from 'common/components/PersonProfile/components';
// Messages
import messages from 'common/components/PersonProfile/messages';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { fetchPersonCampaigns } from 'common/components/PersonProfile/state/actions';
import CallsBlockSectionActions from 'common/components/PersonProfile/components/CallsBlock/CallsBlockSectionActions/CallsBlockSectionActions';
import CallsHistoryModal from './modals/CallsHistoryModal/CallsHistoryModal';
import { selectRoot } from 'common/hooks/useRootSelector';

const styles = () =>
  createStyles({
    wrapper: {
      height: '100%',
      minHeight: '150px',
    },
    list: {
      padding: '0',
      marginRight: '-16px',
      height: 'calc(100% - 30px)',
      overflow: 'hidden',
    },
  });

interface ICallsBlockProps extends StyledComponentProps {
  personId: number;
  selectedClubId: string;
  personPhones?: ImmutableList<IMemberPhoneItemImt>;
  calls?: ImmutableList<ICallNoteDtoImt>;
  call?: ICallNoteDtoImt;
  isCallLoading?: boolean;
  callsLoading?: boolean;
  campaigns?: ImmutableList<INamedEntityImt>;
  employees?: ImmutableList<IEmployeeListItemImt>;
  isMobile?: boolean | null;
  createPersonCallActionState?: IRequestResultImt;
  fetchPersonCalls?: (personId: number, module: PeakModuleForNewPersonType) => void;
  fetchPersonCallById?: (personId: number, callId: string) => void;
  addPersonCall?: (personId: number, call: ICallNoteDto) => void;
  fetchEmployees?: (module: PeakModuleForNewPersonType) => void;
  resetCreateCallResult?: (personId: number) => void;
  fetchDictionaryPersonCampaigns?: (personId: number, module: PeakModuleForNewPersonType) => void;
  fetchCampaigns?: (personId: number, module: PeakModuleForNewPersonType) => void;
  fetchCampaignById?: (
    personId: number,
    campaignId: string,
    module: PeakModuleForNewPersonType,
  ) => void;
  module: PeakModuleForNewPersonType;
}

interface ICallsBlockState {
  isNewCallModalOpen: boolean;
  isCallDetailsModalOpen: boolean;
  isCallsHistoryModalOpen: boolean;
  selectedCallId: string;
}

@(Connect({
  makeStateToProps: () => {
    const selectPersonCalls = selectRoot(makeSelectPersonRecentCalls());
    const selectPersonCallsLoading = selectRoot(makeSelectPersonRecentCallsLoading());
    const selectUpdatePersonCallActionState = selectRoot(makeSelectPersonUpdateCallAction());

    return (state, ownProps) => ({
      calls: selectPersonCalls(state, ownProps),
      callsLoading: selectPersonCallsLoading(state, ownProps),
      employees: selectRoot(selectDictionaryList(DictionaryList.EMPLOYEES))(state),
      campaigns: selectRoot(selectDictionaryList(DictionaryList.MESSAGING_CAMPAIGN_PHONE))(state),
      createPersonCallActionState: selectUpdatePersonCallActionState(state, ownProps),
    });
  },
  mapDispatchToProps: dispatch => ({
    fetchPersonCalls: (personId: number, module: PeakModuleForNewPersonType) => {
      return dispatch(actions.fetchPersonRecentCalls(personId, module));
    },
    fetchPersonCallById: (personId: number, callId: string, module: PeakModuleForNewPersonType) => {
      dispatch(actions.fetchPersonCallById(callId, personId, module));
    },
    resetCreateCallResult: (personId: number) =>
      dispatch(actions.resetCreatePersonCallActionResult(null, personId)),
    addPersonCall: (personId: number, call: ICallNoteDto, module: PeakModuleForNewPersonType) =>
      dispatch(actions.addPersonCall(personId, call, module)),
    fetchEmployees: (module: PeakModuleForNewPersonType) =>
      dispatch(fetchDictionaryList(DictionaryList.EMPLOYEES, { module })),
    fetchDictionaryPersonCampaigns: (personId: number, module: PeakModuleForNewPersonType) =>
      dispatch(fetchDictionaryList(DictionaryList.MESSAGING_CAMPAIGN_PHONE, { personId, module })),
    fetchCampaigns: (personId: number, module: PeakModuleForNewPersonType) =>
      dispatch(fetchPersonCampaigns(personId, module)),
    fetchCampaignById: (personId: number, campaignId: string, module: PeakModuleForNewPersonType) =>
      dispatch(
        fetchDictionaryItem(DictionaryItem.CAMPAIGN_PHONE, { personId, campaignId, module }),
      ),
  }),
}) as any)
@(withStyles(styles) as any)
class CallsBlock extends Component<ICallsBlockProps, ICallsBlockState> {
  constructor(props) {
    super(props);

    this.state = {
      isNewCallModalOpen: false,
      isCallDetailsModalOpen: false,
      isCallsHistoryModalOpen: false,
      selectedCallId: null,
    };
  }

  componentDidMount(): void {
    const { personId, fetchPersonCalls, module } = this.props;

    fetchPersonCalls(personId, module);
  }

  componentDidUpdate(prevProps: ICallsBlockProps, prevState: ICallsBlockState): void {
    const {
      selectedClubId,
      personId,
      createPersonCallActionState,
      fetchCampaigns,
      fetchPersonCalls,
      resetCreateCallResult,
      fetchDictionaryPersonCampaigns,
      fetchEmployees,
      module,
    } = this.props;
    const { isNewCallModalOpen } = this.state;

    if (isNewCallModalOpen && isNewCallModalOpen !== prevState.isNewCallModalOpen) {
      fetchEmployees(module);
      fetchDictionaryPersonCampaigns(personId, module);
    }

    if (createPersonCallActionState?.getIn(['result', 'success'])) {
      resetCreateCallResult(personId);
      this.onCloseCallModal();
      fetchPersonCalls(personId, module);
      fetchCampaigns(personId, module);
    }

    if (prevProps.selectedClubId === selectedClubId && prevProps.personId === personId) return;
    fetchPersonCalls(personId, module);
  }

  getCallNoteDetails = (): void => {
    const { personId, fetchPersonCallById } = this.props;
    const { selectedCallId } = this.state;

    fetchPersonCallById(personId, selectedCallId);
  };

  getCampaignDetails = (campaignId: string): void => {
    const { personId, fetchCampaignById, module } = this.props;

    fetchCampaignById(personId, campaignId, module);
  };

  onOpenNewModal = (): void => {
    this.setState({ isNewCallModalOpen: true });
  };

  onCloseCallModal = (): void => {
    this.setState({ isNewCallModalOpen: false });
  };

  onOpenCallDetailsModal = (callId: string): void => {
    this.setState({ isCallDetailsModalOpen: true, selectedCallId: callId });
  };

  onCloseCallDetailsModal = (): void => {
    this.setState({ isCallDetailsModalOpen: false, selectedCallId: null });
  };

  onSubmitCallModal = (call: ICallNoteDto): void => {
    const { personId, addPersonCall } = this.props;

    addPersonCall(personId, call);
  };

  onOpenCallsHistoryModal = () => {
    this.setState({ isCallsHistoryModalOpen: true });
  };

  onCloseCallsHistoryModal = () => {
    this.setState({ isCallsHistoryModalOpen: false });
  };

  render(): JSX.Element {
    const { isNewCallModalOpen, isCallDetailsModalOpen, isCallsHistoryModalOpen } = this.state;
    const {
      classes,
      isMobile,
      calls,
      callsLoading,
      personId,
      campaigns,
      personPhones,
      employees,
      createPersonCallActionState,
      module,
    } = this.props;

    const listItems =
      !!calls &&
      !!calls.size &&
      calls.map(item => (
        <CallsItem key={item.get('id')} call={item} onClick={this.onOpenCallDetailsModal} />
      ));

    return (
      <CheckInBlock
        title={<FormattedMessage {...messages.callsTitle} />}
        buttonGroup={
          <CallsBlockSectionActions
            onHistoryClick={this.onOpenCallsHistoryModal}
            onAddClick={this.onOpenNewModal}
          />
        }
        className={classes.wrapper}
      >
        {listItems ? (
          <List className={classes.list}>
            {isMobile === true && <Slider rows={2}>{listItems}</Slider>}
            {isMobile === false && <ScrollBox>{listItems}</ScrollBox>}
          </List>
        ) : (
          <Box className="empty-section-placeholder">
            <Typography className="empty-text">
              <FormattedMessage {...messages.emptyCallsListTextMessage} />
            </Typography>
          </Box>
        )}

        {isNewCallModalOpen && (
          <NewCallModal
            isOpen={isNewCallModalOpen}
            campaigns={campaigns}
            personPhones={personPhones}
            employees={employees}
            fetchCampaign={this.getCampaignDetails}
            onClose={this.onCloseCallModal}
            onSubmit={this.onSubmitCallModal}
            isLoading={createPersonCallActionState?.get('isLoading')}
          />
        )}

        {isCallDetailsModalOpen && (
          <CallDetailsModal
            isOpen={isCallDetailsModalOpen}
            onClose={this.onCloseCallDetailsModal}
            onCallNoteLoad={this.getCallNoteDetails}
            personId={personId}
          />
        )}

        {isCallsHistoryModalOpen && (
          <CallsHistoryModal
            isOpen
            onOpenCallDetails={this.onOpenCallDetailsModal}
            module={module}
            personId={personId}
            onClose={this.onCloseCallsHistoryModal}
          />
        )}

        <LoadingBackdrop isLoading={callsLoading} />
      </CheckInBlock>
    );
  }
}

export default CallsBlock;
