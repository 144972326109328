import React, { useMemo } from 'react';
import cx from 'classnames';
import { makeStyles, Box, Grid, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import useRootSelector from 'common/hooks/useRootSelector';
// components
import { ButtonWithCircularProgress } from 'common/components';
import { CustomTheme } from 'common/ui/interfaces';
// state
import * as selectors from 'memberPortalModules/MemberProfile/state/invoice/selectors';
import { useSearchInvoiceProductsState } from 'common/createContext/searchInvoiceProductsContext';
// hooks
import { useAppDispatch } from 'store/hooks';
// messages
import messages from 'common/messages/messages';
import { IInvoiceDetailsImt } from 'common/interfaces/invoices';
import { Message } from 'common/interfaces/common';
import { priceRounding } from 'common/utils';
import { AS_PAID_INVOICE_PAYMENT_SPLIT_STATUS } from 'common/components/InvoiceOperating/constants';
import InvoiceTotals from 'common/components/InvoiceOperating/InvoiceTotals/InvoiceTotals';
import { proceedFreeInvoice } from 'memberPortalModules/MemberProfile/state/invoice/actions';

const useStyles = makeStyles((theme: CustomTheme) => ({
  footerDivider: {
    borderTop: `1px solid ${theme.palette.borderColor.light}`,
  },
  footer: {
    flex: '1 1 auto',
    paddingTop: theme.spacing(3),
    margin: theme.spacing(0, 2, 2, 2),
  },
  actionButton: {
    width: '100%',
    padding: theme.spacing(1),
  },
}));

interface IInvoiceFooterProps {
  invoice: IInvoiceDetailsImt;
  handleCancelInvoice: () => void;
  handleVoidInvoice: () => void;
  handleInvoiceActionClick: () => void;
  memberId: number;
}

export const InvoiceFooter = ({
  invoice,
  handleInvoiceActionClick,
  handleCancelInvoice,
  handleVoidInvoice,
  memberId,
}: IInvoiceFooterProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  // selectors

  const loadingCheckOut: boolean = useRootSelector(selectors.selectUpdatedInvoiceLoading);

  // constants

  const {
    toggleShowAddInventories,
    showSearchProducts: { isShowAddInventories, isShowAddServicePackages, isShowAddGiftCards },
  } = useSearchInvoiceProductsState();

  const convertedInvoice = useMemo(() => invoice?.toJS(), [invoice]);

  const totalInvoicePayments =
    convertedInvoice?.invoicePaymentSplits
      ?.filter(p => AS_PAID_INVOICE_PAYMENT_SPLIT_STATUS.includes(p.status))
      .reduce((acc, value) => {
        return acc + Number(value.paymentAmount);
      }, 0) || 0;

  const leftToPay = priceRounding(convertedInvoice.totalAmount - totalInvoicePayments);

  const isSearchProductsActive =
    isShowAddInventories || isShowAddServicePackages || isShowAddGiftCards;

  const isEmptyCart = !convertedInvoice.invoiceUnits?.length;

  const shouldVisibleProceedBtn = !isEmptyCart && !convertedInvoice?.totalAmount;

  const hasPaymentMethods = !!convertedInvoice?.invoicePaymentSplits?.length;

  // handlers

  const handleBackToCartClick = () => {
    toggleShowAddInventories(false);
  };

  const handleProceed = () => {
    dispatch(proceedFreeInvoice(convertedInvoice.id, memberId));
  };

  const getSubmitBtnTitle = (): Message => {
    if (shouldVisibleProceedBtn) {
      return messages.proceedBtn;
    }
    return messages.addPayMethod;
  };

  const getSubmitBtnHandler = (): (() => void) => {
    if (shouldVisibleProceedBtn) {
      return handleProceed;
    }
    return handleInvoiceActionClick;
  };

  // renders

  return (
    <Box
      className={cx(classes.footer, {
        [classes.footerDivider]: !isEmptyCart && !isSearchProductsActive,
      })}
    >
      {!isEmptyCart && !isSearchProductsActive ? (
        <InvoiceTotals
          payments={convertedInvoice?.invoicePaymentSplits}
          total={convertedInvoice.totalAmount}
          subtotal={convertedInvoice.subtotalAmount}
          tax={convertedInvoice.taxAmount}
          discount={convertedInvoice?.discount}
          leftToPay={leftToPay}
          isMemberPortal
        />
      ) : null}

      <Box pt={2}>
        <Grid container spacing={1}>
          {isSearchProductsActive ? (
            <Grid item xs={12}>
              <Button
                className={classes.actionButton}
                color="primary"
                type="button"
                variant="contained"
                onClick={handleBackToCartClick}
              >
                <FormattedMessage {...messages.backToCartBtn} />
              </Button>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <Button
                  className={classes.actionButton}
                  color="primary"
                  type="button"
                  onClick={hasPaymentMethods ? handleVoidInvoice : handleCancelInvoice}
                  disabled={isEmptyCart}
                >
                  <FormattedMessage
                    {...(hasPaymentMethods ? messages.voidInvoice : messages.cancelInvoice)}
                  />
                </Button>
              </Grid>

              <Grid item xs={12} md={6}>
                <ButtonWithCircularProgress
                  className={classes.actionButton}
                  type="button"
                  disabled={isEmptyCart}
                  isSubmitting={loadingCheckOut}
                  onClick={getSubmitBtnHandler()}
                >
                  <FormattedMessage {...getSubmitBtnTitle()} />
                </ButtonWithCircularProgress>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
