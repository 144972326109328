// libraries
import React, { useEffect } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';
// custom interfaces
import { IFee } from 'modules/services/interfaces/fees';
// hooks
import { useAppDispatch } from 'store/hooks';
// state
import * as selectors from 'modules/services/state/packages/selectors';
import * as actions from 'modules/services/state/packages/actions';
import { SearchInputWithOptions } from 'common/components';
import messages from '../../../../messages/messages';

interface IFeesSelectorProps {
  onInclude: (fee: IFee) => void;
}

const FeesSelector: React.FC<IFeesSelectorProps> = (props: IFeesSelectorProps): JSX.Element => {
  const intl = useIntl();
  const { onInclude } = props;

  // state
  const dispatch = useAppDispatch();

  const feesSearchResult = useRootSelector(selectors.selectPackageFeesList);
  const isFeesListLoading: boolean = useRootSelector(selectors.selectPackageFeesLoading);

  // effects

  useEffect(() => {
    dispatch(actions.fetchPackageFees());

    return () => {
      dispatch(actions.resetPackageFees());
    };
  }, [dispatch]);

  // handlers

  const uploadFeesBySearch = (search: string): void => {
    if (search) dispatch(actions.fetchPackageFees(search));
    else dispatch(actions.resetPackageFees());
  };

  // renders

  return (
    <SearchInputWithOptions<IFee>
      fullWidth
      isAutoDeselect
      options={feesSearchResult?.toJS()}
      loading={isFeesListLoading}
      placeholder={intl.formatMessage(messages.searchFeesLabel)}
      renderOption={({ title }: IFee) => <Box width="100%">{title}</Box>}
      getOptionLabel={option => option && option.title}
      getOptionsByValue={uploadFeesBySearch}
      onChange={(event, value) => onInclude(value as IFee)}
    />
  );
};

export default FeesSelector;
