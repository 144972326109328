// libraries
import React, { useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
// custom interfaces
import { ChangeStatusModal } from 'common/components';
import { IFeeImt } from '../../../interfaces/fees';
// hooks
import { useAppDispatch } from 'store/hooks';
// state
import * as actions from '../../../state/fees/actions';
import * as selectors from '../../../state/fees/selectors';

interface IFeeChangeStatusModalProps {
  fee: IFeeImt | null;
  onDone: () => void;
}

const FeeChangeStatusModal: React.FC<IFeeChangeStatusModalProps> = ({ fee, onDone }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const changeStatusResult = useRootSelector(selectors.selectFeeActionResult);
  const changeDataLoading = useRootSelector(selectors.selectFeeActionLoading);

  const handleOnSubmit = () => {
    if (fee) {
      const feeId = fee.get('id');

      if (feeId) {
        dispatch(actions.changeFeeStatus(feeId, !fee.get('active')));
      }
    }
  };

  useEffect(() => {
    setIsOpen(!!fee);

    return () => {
      dispatch(actions.resetChangeFeeStatus());
    };
  }, [dispatch, fee]);

  useEffect(() => {
    if (changeStatusResult && changeStatusResult.get('id')) {
      if (onDone) onDone();
    }
  }, [changeStatusResult, dispatch, onDone]);

  // renders

  return (
    !!fee && (
      <ChangeStatusModal
        isOpen={isOpen}
        itemTitle={fee.get('title')}
        isActive={fee.get('active')}
        isLoading={changeDataLoading}
        onSubmit={handleOnSubmit}
        onClose={() => setIsOpen(false)}
      />
    )
  );
};

export default React.memo(FeeChangeStatusModal);
