import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import PhoneInput from 'react-phone-number-input';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { Typography, Paper, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { FormattedMessage } from 'react-intl';
import { Info } from '@material-ui/icons';
// interfaces
import { IEmployeeListItem } from 'modules/employees/interfaces';
// state
import * as membersSelectors from 'common/state/newPerson/primaryInfo/selectors';
// components
import { CountryCodeSelect, CustomPhoneNumberInput } from 'common/components/PhoneNumberInput';
// messages
import messages from 'common/components/PersonProfile/messages';
import { IPrimaryMemberInfo } from 'common/components/PersonProfile/interfaces';
import { ILeadProfile } from 'modules/crm/interfaces/leads';
import { List as ImmutableList } from 'immutable';
import ListboxComponent from 'common/components/ListboxComponent/ListboxComponent';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { TextField } from 'common/components/index';
import { IObject } from 'common/interfaces/common';

interface UserAutocompleteProps<T> {
  autoComplete?: string;
  label: string | JSX.Element;
  isActiveMembersRestricted?: boolean;
  hideAutocomplete?: boolean;
  freeSolo?: boolean;
  disableClearable?: boolean;
  fullWidth: boolean;
  error?: boolean;
  loading?: boolean;
  maxLength?: number;
  isCreate?: boolean;
  helperText?: React.ReactNode;
  value: any;
  options: T;
  selectedMembers?: IObject[];
  onChange: (value) => void;
  renderOption?: (params) => React.ReactNode;
  getOptionsByValue: (searchString: string) => void;
  getOptionLabel?: (option: any) => string;
  clearResults?: () => void;
  isPhoneNumberAutocomplete?: boolean;

  InputProps?: Partial<StandardInputProps>;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  inputRef?: React.Ref<any>;
  // config for options
  itemSize?: number;
  limitCount?: number;
  minWidth?: number;
  recommended?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  textFieldRoot: {
    '&.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      padding: '0 65px 0 0',
    },
    '& > .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input': {
      padding: '22px 12px 10px 12px',
    },
  },
  phoneAutocompleteWrapper: {
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      padding: theme.spacing(2.75, 3, 1.25, 8.75),
    },

    '& .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input': {
      padding: 0,
    },
  },
  warningText: {
    padding: theme.spacing(1.5, 1.5, 0),
    color: theme.palette.secondary.contrastText,
    fontWeight: 500,
  },
  infoIcon: {
    marginRight: theme.spacing(0.5),
  },
}));

let timeoutId = null;

const CreateMemberAutocomplete = <T extends ImmutableList<IPrimaryMemberInfo | ILeadProfile>>(
  props: UserAutocompleteProps<T>,
): JSX.Element => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const {
    autoComplete,
    label,
    loading,
    fullWidth,
    error,
    helperText,
    value,
    options,
    maxLength,
    onChange,
    getOptionLabel,
    renderOption,
    freeSolo,
    disableClearable,
    getOptionsByValue,
    clearResults,
    InputProps,
    onBlur,
    isActiveMembersRestricted,
    hideAutocomplete,
    selectedMembers,
    isPhoneNumberAutocomplete,
    minWidth,
    itemSize,
    limitCount,
    recommended,
  } = props;

  const { inputRef } = props;

  const memberInfo = useRootSelector(membersSelectors.selectPrimaryInfo);

  const [isLoading, setIsLoading] = useState(false);

  const loadOptions = useCallback(
    searchString => {
      if (typeof searchString === 'string') getOptionsByValue(searchString);
    },
    [getOptionsByValue],
  );

  const onChangeSearch = e => {
    onChange(e);

    const searchString = e?.target?.value || e;

    if (typeof searchString === 'string') {
      setIsLoading(true);
    }

    if (!hideAutocomplete) {
      if (searchString === '' && clearResults) clearResults();
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => loadOptions(searchString), 500);
      if (searchString === '') {
        clearTimeout(timeoutId);
        clearResults();
      }
    }
  };

  useEffect(() => {
    if (value !== '' && open && !hideAutocomplete) {
      loadOptions(value);

      if (typeof value === 'string') {
        setIsLoading(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, setIsLoading]);

  useComponentDidUpdate(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [loading]);

  const getOptionDisabled = opt => {
    return (
      (!!selectedMembers && !!selectedMembers.find(m => m.member.person.id === opt.id)) ||
      memberInfo.get('id') === opt.id ||
      opt.type === 'Active member'
    );
  };

  const getOptionLabelParams = {
    getOptionLabel: getOptionLabel || undefined,
    renderOption,
    getOptionDisabled: isActiveMembersRestricted ? getOptionDisabled : undefined,
  };

  const convertedOptions = useMemo(() => (options ? options.toJS() : []), [options]);

  const ListboxProps = {
    itemSize: itemSize || 60,
    limitCount: limitCount || 7,
  };

  return (
    <MuiAutocomplete
      {...getOptionLabelParams}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        clearResults();
        setOpen(false);
      }}
      getOptionSelected={(option: IEmployeeListItem, valueEmployee: IEmployeeListItem) => {
        return option.id === valueEmployee.id;
      }}
      ListboxComponent={ListboxComponent}
      ListboxProps={ListboxProps}
      options={convertedOptions}
      inputValue={value || ''}
      loading={open && isLoading}
      freeSolo={freeSolo}
      disableClearable={disableClearable}
      renderInput={(params: any) => {
        const {
          inputProps: { value: inputValue, ...otherProps },
        } = params;

        return (
          <>
            {isPhoneNumberAutocomplete ? (
              <PhoneInput
                {...{ ...params, inputProps: otherProps, error, helperText, recommended }}
                onChange={onChangeSearch}
                international
                value={inputValue}
                className={classes.phoneAutocompleteWrapper}
                defaultCountry="US"
                countryCallingCodeEditable={false}
                limitMaxLength
                autoComplete="off"
                withCountryCallingCode
                inputComponent={CustomPhoneNumberInput}
                countrySelectComponent={CountryCodeSelect}
              />
            ) : (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                fullWidth={fullWidth}
                error={error}
                onChange={onChangeSearch}
                helperText={helperText}
                onBlur={onBlur}
                inputRef={inputRef}
                autoComplete={autoComplete}
                recommended={recommended}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                  maxLength,
                  form: {
                    autoComplete: 'off',
                  },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={
                  { ...InputProps, ...params.InputProps } || {
                    classes: {
                      root: classes.textFieldRoot,
                    },
                    ...params.InputProps,
                  }
                }
              />
            )}
          </>
        );
      }}
      PaperComponent={({ children, ...other }) => {
        return convertedOptions.length > 0 ? (
          <Paper style={{ minWidth }} {...other}>
            {isActiveMembersRestricted && value && (
              <Box className={classes.warningText} textAlign="center">
                <Typography>
                  <Box display="inline-flex" alignItems="center">
                    <Info fontSize="small" className={classes.infoIcon} />
                    <FormattedMessage {...messages.dlgWarning} />
                  </Box>
                </Typography>
              </Box>
            )}
            {children}
          </Paper>
        ) : null;
      }}
    />
  );
};

CreateMemberAutocomplete.defaultProps = {
  isPhoneNumberAutocomplete: false,
};

export default CreateMemberAutocomplete;
