import * as yup from 'yup';
import IntlService from 'services/intl/IntlService';
import { RewardsType } from './constants';
import messages from 'common/components/PersonProfile/messages';
import { getRequiredStringSchema } from 'common/utils/schema';
import { getRequiredErrorMessage } from 'common/utils/validation';

export const getRewardsTypeLabel = (type: RewardsType) => {
  switch (type) {
    case RewardsType.Prospect:
      return IntlService.formatMessage(messages.prospect);

    case RewardsType.Join:
      return IntlService.formatMessage(messages.join);

    case RewardsType.Redemption:
      return IntlService.formatMessage(messages.redemption);

    case RewardsType.Adjustment:
      return IntlService.formatMessage(messages.adjustment);

    case RewardsType.Sent:
      return IntlService.formatMessage(messages.sent);
    default:
      return '';
  }
};

export function getRewardValidationSchema() {
  return yup.object({
    points: yup.number().required(getRequiredErrorMessage),
    noteText: getRequiredStringSchema(),
    noteCode: yup
      .object({ id: yup.string(), active: yup.boolean(), title: yup.string() })
      .nullable()
      .required(getRequiredErrorMessage),
  });
}

export const getRefferalsClubInfo = (clubTitle: string, clubAddress: string) => {
  if (clubTitle && clubAddress) {
    return `${clubTitle}, ${clubAddress}`;
  } else if (clubTitle) {
    return clubTitle;
  } else {
    return '';
  }
};
