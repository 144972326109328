import React, { useEffect, useState } from 'react';
import { Box, Divider, IconButton, Paper, Popover, SvgIcon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import useRootSelector from 'common/hooks/useRootSelector';

import { useAppDispatch } from 'store/hooks';
import commonMessages from 'common/messages/messages';
import messages from 'common/components/PersonProfile/messages';
import {
  CUSTOMER_SERVICE_PHONE,
  MEMBER_SUPPORT_PHONE,
  MEMBER_SUPPORT_URL,
} from 'common/constants/peakContacts';
import { ReactComponent as DeleteIcon } from 'img/icons/x-bold_deprecated.svg';
import { ReactComponent as CopyIcon } from 'img/icons/copy.svg';
import { CustomTheme } from 'common/ui/interfaces';
import { copyToClipboard } from 'common/utils/copyToClipboard';
import TicketCreateModal from 'common/components/PersonProfile/components/Tickets/TicketCreateModal/TicketCreateModal';
import {
  selectAddTicketActionResult,
  selectAddTicketLoading,
} from 'common/state/peakContacts/selectors';
import { addTicket, addTicketSuccessAction } from 'common/state/peakContacts/actions';
import { ITicketCreateDto } from 'common/components/PersonProfile/interfaces';
import { Button } from 'common/components';
import { ActionResult } from 'common/constants';
import { PermissionLevels } from 'common/constants/permissions';

interface IContactPopoverProps {
  anchorEl: null | Element;
  currentUserLevel: PermissionLevels;
  onClose: () => void;
  isClientPortal: boolean;
}

const useStales = makeStyles((theme: CustomTheme) => ({
  root: {
    '& > .MuiPaper-root': {
      top: '72px !important',
      right: '8px !important',
      height: 'calc(100% - 80px)',
      width: '440px',

      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    padding: theme.spacing(2.5, 3),
  },
  closeBtnIcon: {
    color: theme.palette.darkBackground?.light,
  },
  copyBtnIcon: {
    color: theme.palette.darkBackground?.dark,
  },
  copyIcon: {
    width: 20,
    height: 20,
  },
  title: {
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
  },
  contactInfoBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5625, 0),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

const ContactPopover = (props: IContactPopoverProps) => {
  const { anchorEl, onClose, currentUserLevel, isClientPortal } = props;

  const dispatch = useAppDispatch();

  const addPersonTicketLoading = useRootSelector(selectAddTicketLoading);
  const actionResult = useRootSelector(selectAddTicketActionResult);

  const [ticketCreateModalOpen, setTicketCreateModalOpen] = useState(false);

  useEffect(() => {
    if (actionResult === ActionResult.SUCCESS_ACTION) {
      setTicketCreateModalOpen(false);
      dispatch(addTicketSuccessAction(null));
    }
  }, [actionResult, setTicketCreateModalOpen, dispatch]);

  const classes = useStales();

  const onClick = () => {
    setTicketCreateModalOpen(true);
  };

  const onCreateTicket = (attachments: File[], dto: ITicketCreateDto<number>) => {
    dispatch(addTicket(attachments, dto));
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={!!anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
      className={classes.root}
    >
      <Paper className={classes.paper}>
        <Box display="flex" justifyContent="space-between" alignItems="center" pb={2.5}>
          <Typography variant="h3">
            <FormattedMessage {...commonMessages.contactPeak} />
          </Typography>

          <IconButton size="small" onClick={onClose}>
            <SvgIcon className={classes.closeBtnIcon} component={DeleteIcon} />
          </IconButton>
        </Box>
        <Box pb={3}>
          <Typography variant="h6" color="textSecondary" className={classes.title}>
            <FormattedMessage {...commonMessages.customerService} />
          </Typography>
          <Box className={classes.contactInfoBlock}>
            <Typography variant="h6">{CUSTOMER_SERVICE_PHONE}</Typography>
            <IconButton
              className={classes.copyBtnIcon}
              size="small"
              onClick={() => copyToClipboard(CUSTOMER_SERVICE_PHONE)}
            >
              <CopyIcon className={classes.copyIcon} />
            </IconButton>
          </Box>
          <Divider />
        </Box>
        <Box pb={3} mt={1}>
          <Typography variant="h6" color="textSecondary" className={classes.title}>
            <FormattedMessage {...commonMessages.memberSupport} />
          </Typography>
          <Box className={classes.contactInfoBlock}>
            <Typography variant="h6" color="primary">
              {MEMBER_SUPPORT_URL}
            </Typography>
            <IconButton
              className={classes.copyBtnIcon}
              size="small"
              onClick={() => copyToClipboard(MEMBER_SUPPORT_URL)}
            >
              <CopyIcon className={classes.copyIcon} />
            </IconButton>
          </Box>
          <Box className={classes.contactInfoBlock} mt={1}>
            <Typography variant="h6">{MEMBER_SUPPORT_PHONE}</Typography>
            <IconButton
              className={classes.copyBtnIcon}
              size="small"
              onClick={() => copyToClipboard(MEMBER_SUPPORT_PHONE)}
            >
              <CopyIcon className={classes.copyIcon} />
            </IconButton>
          </Box>
          {currentUserLevel !== PermissionLevels.PEAK && <Divider />}
        </Box>
        {currentUserLevel !== PermissionLevels.PEAK && (
          <Button disabled={!isClientPortal} color="primary" variant="contained" onClick={onClick}>
            <FormattedMessage {...messages.newTicketTitle} />
          </Button>
        )}
      </Paper>
      {ticketCreateModalOpen && (
        <TicketCreateModal
          onCreateTicket={onCreateTicket}
          isLoading={addPersonTicketLoading}
          isOpen
          onClose={() => {
            setTicketCreateModalOpen(false);
          }}
        />
      )}
    </Popover>
  );
};

export default ContactPopover;
