import React, { useCallback, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { ListChildComponentProps } from 'react-window';
import { List as ImmutableList } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

import { useAppDispatch } from 'store/hooks';

import * as actions from 'modules/kid-zone/state/actions';
import * as selectors from 'modules/kid-zone/state/selectors';

import { PeakModules } from 'common/constants/peakModules';

import { IPersonSearchBarValues } from 'common/components/ManualCheckInPanel/components/PersonSearchBar/interfaces';
import { ICheckInManuallySearchImt, IKidProfileImt } from 'modules/kid-zone/interfaces';
import { ICheckInImt } from 'modules/front-desk/interfaces';

import { ManualCheckInPanel } from 'common/components';
import { ManualCheckInCardItem } from 'modules/kid-zone/containers/index';
import { CheckInAllModal } from 'modules/kid-zone/components';
import AddDateOfBirthdayModal from './components/AddDateOfBirthdayModal/AddDateOfBirthdayModal';
import { ActionResult } from 'common/constants';
import { selectPersonProfile } from 'common/components/PersonProfile/state/selectors';
import { fetchKidProfileInfo } from 'modules/kid-zone/state/actions';
import useRootSelector from 'common/hooks/useRootSelector';

interface IProps {
  onClose: () => void;
  profileId?: number;
}

const ManualCheckIn = ({ onClose, profileId }: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const isMembersLoading: boolean = useRootSelector(selectors.selectManualCheckInMembersLoading);
  const members: ImmutableList<ICheckInManuallySearchImt> = useRootSelector(
    selectors.selectManualCheckInMembers,
  );
  const checkInAllActionResult: ImmutableList<ICheckInImt> = useRootSelector(
    selectors.selectCheckInAllChildrenActionResult,
  );
  const addDOBActionResult = useRootSelector(selectors.selectAddDOBActionResult);
  const isAddDOBActionLoading: boolean = useRootSelector(selectors.selectAddDOBActionLoading);
  const kidProfile: IKidProfileImt | undefined = useRootSelector(selectPersonProfile(profileId));

  const [isSearchTyped, setIsSearchTyped] = useState(false);
  const [checkInAllChildren, setCheckInAllChildren] = useState<{
    cardIndex: number;
  }>({ cardIndex: null });
  const [personWithoutDOBData, setPersonWithoutDOBData] = useState<{
    childId: number | null;
    cardIndex: number | null;
  }>({
    childId: null,
    cardIndex: null,
  });

  useEffect(() => {
    if (checkInAllActionResult?.size) {
      setCheckInAllChildren({ cardIndex: null });
      onClose();
      batch(() => {
        dispatch(actions.resetCheckInAllChildrenActionResult());
        dispatch(actions.setKidZoneRightPanelPersonAction(null));
      });
    }
  }, [checkInAllActionResult, dispatch, onClose]);

  useEffect(() => {
    if (addDOBActionResult === ActionResult.SUCCESS_ACTION) {
      setPersonWithoutDOBData(prevState => {
        if (prevState.childId) {
          return { childId: null, cardIndex: null };
        }

        return prevState;
      });

      if (kidProfile) {
        const other = kidProfile.get('other');
        const isExistPerson = other.some(item => item.get('id') === personWithoutDOBData.childId);

        if (isExistPerson) {
          dispatch(fetchKidProfileInfo(kidProfile.get('id')));
        }
      }
      dispatch(actions.resetAddDateOfBirthActionResult());
    }
  }, [addDOBActionResult, dispatch, kidProfile, personWithoutDOBData]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetMembersAction());
    };
  }, [dispatch]);

  const handleSearchParamsChange = useCallback(
    (search: IPersonSearchBarValues) => {
      dispatch(actions.searchMembers(search));
    },
    [dispatch],
  );

  const handlePersonSearchBarFilled = useCallback((isFilled: boolean) => {
    setIsSearchTyped(isFilled);
  }, []);

  const handleCheckInKid = useCallback(
    (childId: number) => {
      onClose();
      dispatch(actions.setChildCheckInPanelId(childId));
    },
    [dispatch, onClose],
  );

  const handleCheckInAll = useCallback((cardIndex: number) => {
    setCheckInAllChildren({ cardIndex });
  }, []);

  const handleOpenAddDOBModal = useCallback((childId: number, cardIndex: number) => {
    setPersonWithoutDOBData({ childId, cardIndex });
  }, []);

  const addDateOfBirth = useCallback(
    (date: string) => {
      dispatch(
        actions.addDateOfBirth(personWithoutDOBData.childId, personWithoutDOBData.cardIndex, date),
      );
    },
    [dispatch, personWithoutDOBData],
  );

  const renderMemberItem = useCallback(
    ({ index }: ListChildComponentProps) => {
      const member = members.get(index);

      return (
        <ManualCheckInCardItem
          member={member}
          index={index}
          key={uuidv4()}
          onCheckInKid={handleCheckInKid}
          onCheckInAll={handleCheckInAll}
          onAddBirthday={handleOpenAddDOBModal}
        />
      );
    },
    [handleCheckInAll, handleCheckInKid, handleOpenAddDOBModal, members],
  );

  return (
    <>
      <ManualCheckInPanel
        members={members}
        needToRefetch={addDOBActionResult === ActionResult.SUCCESS_ACTION}
        isMembersLoading={isMembersLoading}
        isSearchTyped={isSearchTyped}
        onClose={onClose}
        onSearchFieldChange={handleSearchParamsChange}
        setIsSearchTyped={handlePersonSearchBarFilled}
        renderMemberItem={renderMemberItem}
        module={PeakModules.KidZone}
      />

      {checkInAllChildren.cardIndex !== null && (
        <CheckInAllModal
          isOpen
          onAddBirthday={(id: number) => handleOpenAddDOBModal(id, checkInAllChildren.cardIndex)}
          member={members.get(checkInAllChildren.cardIndex)}
          onClose={() => setCheckInAllChildren({ cardIndex: null })}
        />
      )}

      {!!personWithoutDOBData?.childId && (
        <AddDateOfBirthdayModal
          isOpen={!!personWithoutDOBData.childId}
          onClose={() => setPersonWithoutDOBData(null)}
          onSubmit={addDateOfBirth}
          isAddDOBActionLoading={isAddDOBActionLoading}
        />
      )}
    </>
  );
};

export default ManualCheckIn;
