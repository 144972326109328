import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';

import * as actions from 'common/components/PersonProfile/state/actions';
import * as selectors from 'common/components/PersonProfile/state/selectors';

import { useAppDispatch } from 'store/hooks';
import { makeTableParams } from 'common/utils/http';

import { ActionResult, TableOrderByParams } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';

import { IPaymentImt } from 'common/components/PersonProfile/interfaces';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import { ITableParams } from 'common/interfaces/table';

import { DialogComponent } from 'common/components/index';
import { InvoicesTable } from 'common/components/PersonProfile/components';

import commonMessages from 'common/messages/messages';
import tableFilters from 'common/messages/tableFilters';
import { VoidInvoiceModal } from 'common/components/InvoiceOperating/InvoiceModals';
import { selectCurrentUserAvailableClubs } from 'modules/authentication/state/selectors';
import { reportsEmployeeCommonFilter } from 'modules/reports/utils/filters';
import { InvoiceStatuses } from 'common/constants/invoices';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  personId: number;
  module: PeakModules;
}

const InvoicesModal = ({ isOpen, onClose, personId, module }: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const paymentsMeta: IPageMetaImt = useRootSelector(selectors.selectPaymentsMeta(personId));
  const payments: List<IPaymentImt> = useRootSelector(selectors.selectPayments(personId));
  const paymentsLoading: boolean = useRootSelector(selectors.selectPaymentsLoading(personId));
  const voidInvoiceActionResult = useRootSelector(
    selectors.selectVoidInvoiceActionResult(personId),
  );
  const clubs = useRootSelector(selectCurrentUserAvailableClubs);
  const voidInvoiceLoading = useRootSelector(selectors.selectVoidInvoiceLoading(personId));

  const [{ isVoidModalOpen, selectedInvoiceId }, setVoidModalState] = useState({
    isVoidModalOpen: false,
    selectedInvoiceId: null,
  });

  const tableFilterSettings: IFilterSettings[] = useMemo(
    () => [
      {
        name: 'range',
        title: <FormattedMessage {...tableFilters.period} />,
        type: FilterTypes.DATE_RANGE,
        options: {
          startDate: null,
          endDate: null,
        },
      },
      {
        name: 'statuses',
        title: <FormattedMessage {...tableFilters.status} />,
        type: FilterTypes.MULTIPLE,
        options: InvoiceStatuses.getFilterOptions(),
      },
      {
        name: 'employeeIds',
        title: <FormattedMessage {...tableFilters.employee} />,
        type: FilterTypes.MULTIPLE_WITH_PAGINATE,
        ...reportsEmployeeCommonFilter,
      },
      {
        name: 'clubIds',
        title: <FormattedMessage {...tableFilters.club} />,
        type: FilterTypes.MULTIPLE,
        options: clubs?.toJS().map(club => ({
          key: club.id,
          label: club.title,
          value: club.id,
        })),
      },
    ],
    [clubs],
  );

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilterSettings, null, {
      orderBy: TableOrderByParams.CREATED_DATE,
      order: 'desc',
    }),
  );

  useEffect(() => {
    dispatch(actions.fetchPersonPayments(personId, module, tableParams));
  }, [dispatch, module, personId, tableParams]);

  useEffect(() => {
    if (voidInvoiceActionResult === ActionResult.SUCCESS_ACTION) {
      setVoidModalState({
        isVoidModalOpen: false,
        selectedInvoiceId: null,
      });
      dispatch(actions.fetchPersonPayments(personId, module, tableParams));
      dispatch(actions.voidPersonPaymentActionResult(null, personId));
    }
  }, [voidInvoiceActionResult, dispatch, personId, setVoidModalState, tableParams, module]);

  const handleChangeTableProps = useCallback((tableProps: ITableParams): void => {
    setTableParams(tableProps);
  }, []);

  const handleVoidModalOpen = useCallback(
    (invoiceId: string) => {
      setVoidModalState({ isVoidModalOpen: true, selectedInvoiceId: invoiceId });
    },
    [setVoidModalState],
  );

  const onVoidInvoice = (reason: string) => {
    dispatch(actions.voidPersonInvoice(personId, selectedInvoiceId, reason, module));
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage {...commonMessages.invoicesTitle} />}
      size="xl"
      hideFooterButtons
    >
      <InvoicesTable
        setIsVoidModalOpen={handleVoidModalOpen}
        isExtendedView
        payments={payments}
        paymentsLoading={paymentsLoading}
        paymentsMeta={paymentsMeta}
        tableFilterSettings={tableFilterSettings}
        onChangeTableParams={handleChangeTableProps}
        tableParams={tableParams}
        module={module}
        personId={personId}
      />
      {isVoidModalOpen && (
        <VoidInvoiceModal
          isOpen={isVoidModalOpen}
          onClose={() => setVoidModalState({ isVoidModalOpen: false, selectedInvoiceId: null })}
          onSubmit={onVoidInvoice}
          isLoading={voidInvoiceLoading}
        />
      )}
    </DialogComponent>
  );
};

export default InvoicesModal;
